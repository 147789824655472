import useActivePlan from 'components/ActivePlans/hooks/useActivePlan';
import ActivePlanItem from 'components/ActivePlans/components/ActivePlanItem';
import ActivePlanItemSkeleton from 'components/ActivePlans/components/ActivePlanItemSkeleton';

const ActivePlan = () => {
  const { isLoadingUserInfo, isLoadingPlanPay, onPlanPayHandler, activePlan, subscriptionDetail } = useActivePlan();
  const { id, code, icon, features, name, price } = activePlan || {};
  const { starts_at_fa, ends_at_fa, trial_days, trial_ends_at_fa } = subscriptionDetail || {};

  if (isLoadingUserInfo) return <ActivePlanItemSkeleton />;

  return (
    <div className='pb-10'>
      <ActivePlanItem
        end_at={trial_ends_at_fa || ends_at_fa}
        start_at={starts_at_fa}
        trialDays={trial_days}
        features={features}
        name={name}
        icon={icon}
        price={price}
        code={code}
        plan_id={id}
        onPlanPayHandler={onPlanPayHandler}
        isLoadingPlanPay={isLoadingPlanPay}
      />
    </div>
  );
};

export default ActivePlan;
