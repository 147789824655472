import GatewayLayout from 'components/_Core/Layouts/Panel/PanelHomeGatewayLout/PanelHomeGatewayLayout';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';
import useRoute from 'core/hooks/useRoute';
import GatewayLinkSimpleUpdate from 'components/Gateway/GatewayLinks/Update/Simple/GatewayLinkSimpleUpdate';

const GatewayLinkSimpleUpdatePage = () => {
  const { routes } = useRoute();
  const headerTitle = 'ویرایش لینک پرداخت ساده';

  return (
    <GatewayLayout showPanelDashboardFooterNavbar={false} enableGatewayDetail={false} headerTitle={headerTitle} backUrl={routes.PANEL_HOME.link()}>
      <HeadMeta tabTitle={headerTitle} />
      <GatewayLinkSimpleUpdate />
    </GatewayLayout>
  );
};

export default GatewayLinkSimpleUpdatePage;
