import useModal from 'core/hooks/useModal';
import { useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import { useState } from 'react';
import { useAccountBankListQuery } from 'components/Gateway/components/AccountBank/hooks/react-query/useAccountBankListQuery';

function useAccountBank() {
  // hooks
  const queryClient = useQueryClient();
  const { modal: modalDrawer, hideModal: hideModalDrawer, showModal: showModalDrawer } = useModal();

  // query
  const { data: accountBanks, isFetching: isLoadingAccountBanks } = useAccountBankListQuery();

  // state
  const [bankCardType, setBankCardType] = useState<1 | 2>(1);

  // utils
  const hasOneAcceptedBankAccount = accountBanks?.data ? accountBanks?.data?.some((item) => item.status === 'ACTIVE') : false;

  const onReloadAccountBankHandler = () => {
    queryClient.invalidateQueries([endPointUrls.LINK_ACCOUNT_BANK]);
  };

  return {
    hasOneAcceptedBankAccount,
    modalDrawer,
    hideModalDrawer,
    showModalDrawer,
    onReloadAccountBankHandler,
    bankCardType,
    setBankCardType,
    accountBanks: accountBanks?.data,
    isLoadingAccountBanks,
  };
}

export default useAccountBank;
