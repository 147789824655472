import { FC } from 'react';
import ZpDrawer from 'components/_Core/UI/Drawer/ZpDrawer';
import LinkPreviewBackground from 'components/LinkPreview/components/Baackground/LinkPreviewBackground';
import LinkPreview from 'components/LinkPreview/LinkPreview';
import ZpButton from 'components/_Core/UI/Button/ZpButton';

interface ITemplatePreviewModal {
  modal: boolean;
  hideModal: () => void;
  onUpdateThemeHandler: () => void;
  isLoadingUpdateTheme: boolean;
}

const TemplatePreviewModal: FC<ITemplatePreviewModal> = ({ hideModal, modal, onUpdateThemeHandler, isLoadingUpdateTheme }) => {
  const Footer = (
    <div className='flex items-center justify-between space-x-5'>
      <ZpButton className='w-full' type='link' onClick={hideModal}>
        انصراف
      </ZpButton>

      <ZpButton className='w-full' type='primary' isLoading={isLoadingUpdateTheme} onClick={onUpdateThemeHandler}>
        تایید پوسته
      </ZpButton>
    </div>
  );

  return (
    <ZpDrawer
      destroyOnClose
      bodyStyle={{ zIndex: 5 }}
      headerStyle={{ zIndex: 10, backgroundColor: 'var(--white-color)' }}
      footerStyle={{ zIndex: 10, backgroundColor: 'var(--white-color)' }}
      placement='bottom'
      height='100%'
      open={modal}
      onClose={hideModal}
      footer={Footer}
      title='پیش نمایش پوسته'
    >
      <LinkPreviewBackground />
      <LinkPreview />
    </ZpDrawer>
  );
};

export default TemplatePreviewModal;
