import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  theme: 'light' | 'dark';
  lang: 'fa' | 'en';
  isEnglish: boolean;
  isOpenProfileSwitcher: boolean;
  isOpenBlockList: boolean;
  isOpenMenu: boolean;
  enableScroll: boolean;
} = {
  theme: (window.localStorage.getItem('theme') as 'dark' | 'light') || 'light',
  lang: (window.localStorage.getItem('lang') as 'fa' | 'en') || 'fa',
  isEnglish: window.localStorage.getItem('lang') === 'en' ? true : false,
  isOpenProfileSwitcher: false,
  isOpenBlockList: false,
  enableScroll: true,
  isOpenMenu: false,
};

export const mainInfoSlice = createSlice({
  name: 'mainInfo',
  initialState: initialState,
  reducers: {
    setTheme: (state, { payload }) => {
      state.theme = payload;
      window.localStorage.setItem('theme', payload);
    },
    setLang: (state, { payload }) => {
      state.lang = payload;
      window.localStorage.setItem('lang', payload);
    },
    setOpenProfileSwitcherHandler: (state, { payload }: { payload: boolean }) => {
      state.isOpenProfileSwitcher = payload;
    },
    setEnableScroll: (state, { payload }: { payload: boolean }) => {
      state.enableScroll = payload;
    },
    setOpenBlockListHandler: (state, { payload }: { payload: boolean }) => {
      state.isOpenBlockList = payload;
    },
    setOpenMenuHandler: (state, { payload }: { payload: boolean }) => {
      state.isOpenMenu = payload;
    },
  },
});

export const { setTheme, setLang, setOpenProfileSwitcherHandler, setEnableScroll, setOpenBlockListHandler, setOpenMenuHandler } = mainInfoSlice.actions;
export default mainInfoSlice.reducer;
