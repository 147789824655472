import { RootState } from 'core/store';
import { useEffect } from 'react';
import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';

interface IThemeProvider {
  children: ReactNode;
}

const ThemeProvider: FC<IThemeProvider> = ({ children }) => {
  const { lang, theme } = useSelector((store: RootState) => store.settingStore);

  useEffect(() => {
    const root = document.querySelector(':root');
    if (root) {
      root.setAttribute('color-scheme', theme);
      theme === 'dark' && root.classList.add('dark');
      theme === 'light' && root.classList.remove('dark');

      lang === 'en' && root.setAttribute('dir', 'ltr');
      lang === 'fa' && root.setAttribute('dir', 'rtl');
    }
  }, [theme, lang]);

  return <>{children}</>;
};

export default ThemeProvider;
