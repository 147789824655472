import ZpImage from 'components/_Core/UI/Image/ZpImage';
import createLinkIcon from 'assets/images/dashboardCreateLink.svg';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import useRoute from 'core/hooks/useRoute';

const DashboardCreateLink = () => {
  const navigate = useNavigate();
  const { routes } = useRoute();

  return (
    <div className='border border-natural-color-200 max-w-md rounded-lg mx-auto w-full px-4 py-3 shadow-sm'>
      <ZpImage src={createLinkIcon} />

      <div className='my-2 mb-4'>
        <h2 className='font-bold text-xl'>ساخت اولین {process.env.REACT_APP_BRAND_NAME_FA}</h2>
        <p className='text-natural-color-500'>لینک خود را بسازید و با دنیا در ارتباط باشید!</p>
      </div>

      <div className='text-left'>
        <ZpButton type='primary' onClick={() => navigate(routes.CREATE_LINK_STEP_1.link())}>
          <span className='flex items-center gap-3'>
            <span>ساخت لینک</span>
            <HiOutlineArrowLeft size={23} />
          </span>
        </ZpButton>
      </div>
    </div>
  );
};

export default DashboardCreateLink;
