import useRoute from 'core/hooks/useRoute';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { useDispatch } from 'react-redux';
import { setOpenProfileSwitcherHandler } from 'core/store/slice/setting/settingSlice';

const ProfileSwitchFooter = () => {
  // hooks
  const navigate = useNavigate();
  const { routes } = useRoute();
  const dispatch = useDispatch();

  return (
    <div className='grid grid-cols-2 space-x-3 space-x-reverse mr-2 ml-5'>
      <ZpButton
        type='primary'
        ghost
        onClick={() => {
          navigate(routes.CREATE_LINK_STEP_1.link());
          dispatch(setOpenProfileSwitcherHandler(false));
        }}
      >
        <div className='flex items-center space-x-3 space-x-reverse justify-center'>
          <AiOutlinePlus size={25} />
          <span>پروفایل جدید</span>
        </div>
      </ZpButton>

      <ZpButton
        onClick={() => {
          navigate(routes.LINKS.link);
          dispatch(setOpenProfileSwitcherHandler(false));
        }}
        type='primary'
      >
        داشبورد
      </ZpButton>
    </div>
  );
};

export default ProfileSwitchFooter;
