import React, { FC } from 'react';
import ZpMenu from 'components/_Core/UI/Menu/ZpMenu';
import { BrowserView, MobileView } from 'react-device-detect';
import { IMenuItem } from 'core/types/Components/MenuItem/PanelHomeFooterNavbarItemType';

interface IPanelMenu {
  menuItems: IMenuItem[];
}

const PanelMenu: FC<IPanelMenu> = ({ menuItems }) => {
  return (
    <>
      <BrowserView>
        <ZpMenu items={menuItems} getContainer={false} />
      </BrowserView>

      <MobileView>
        <ZpMenu items={menuItems} />
      </MobileView>
    </>
  );
};

export default PanelMenu;
