import ZpLink from 'components/_Core/UI/Link/ZpLink';
import { FC, ReactNode } from 'react';

export interface IBlockItem {
  title: string;
  description: string;
  icon: ReactNode;
  to?: string;
  onClick?: () => void;
}

const BlockItem: FC<IBlockItem> = ({ title, to, icon, description, onClick }) => {
  const content = (
    <>
      {icon}
      <div className='flex flex-col space-y-1'>
        <p className='font-bold'>{title}</p>
        <p className='text-sm text-color-paragraph'>{description}</p>
      </div>
    </>
  );

  const Item = (
    <div onClick={onClick} className='border border-natural-color-300 rounded-lg bg-white-color hover:bg-natural-color-100 px-4 space-x-3 space-x-reverse py-3 flex cursor-pointer'>
      {content}
    </div>
  );

  return to ? <ZpLink to={to}>{Item}</ZpLink> : Item;
};

export default BlockItem;
