import useLogout from 'core/helpers/logout/useLogout';
import useModal from 'core/hooks/useModal';
import useRoute from 'core/hooks/useRoute';
import { useNavigate } from 'react-router-dom';
import { setOpenMenuHandler } from 'core/store/slice/setting/settingSlice';
import { useDispatch } from 'react-redux';

const usePanelMasterFooterNavbar = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logout } = useLogout();
  const { routes } = useRoute();

  const showModalMenu = () => {
    dispatch(setOpenMenuHandler(true));
  };
  return { routes, logout, showModalMenu, navigate };
};

export default usePanelMasterFooterNavbar;
