import ZpSkeleton from 'components/_Core/UI/Skeleton/ZpSkeleton';

const LinkPreviewSkeleton = () => {
  return (
    <div className='flex flex-col justify-between h-full'>
      <div className='space-y-10'>
        <div className='text-center space-y-3'>
          <div>
            <ZpSkeleton width={150} height={150} circle />
          </div>
          <div className='space-y-3'>
            <div>
              <ZpSkeleton width={150} height={30} />
            </div>
            <div>
              <ZpSkeleton width={150} height={30} />
            </div>
          </div>
        </div>

        <div className='text-center space-y-3'>
          <div>
            <ZpSkeleton width={150} height={30} />
          </div>

          <div>
            <ZpSkeleton height={50} />
          </div>
          <div>
            <ZpSkeleton height={50} />
          </div>
        </div>

        <div className='text-center space-y-3'>
          <div>
            <ZpSkeleton width={150} height={30} />
          </div>

          <div>
            <ZpSkeleton height={50} />
          </div>
          <div>
            <ZpSkeleton height={50} />
          </div>
          <div>
            <ZpSkeleton height={50} />
          </div>
        </div>

        <div className='text-center space-y-3'>
          <div>
            <ZpSkeleton width={150} height={30} />
          </div>

          <div>
            <ZpSkeleton height={50} />
          </div>
          <div>
            <ZpSkeleton height={50} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkPreviewSkeleton;
