import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryOnSuccessHandler from 'core/helpers/queryOnSuccessHandler/useQueryOnSuccessHandler';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import useRoute from 'core/hooks/useRoute';

export const useGatewayUpdateQuery = () => {
  const { onSuccessHandler } = useQueryOnSuccessHandler();
  const { query } = useQueryUrlParam();
  const { gatewayId, linkId } = useRoute();

  return useMutationHook(
    [endPointUrls.GATEWAY(query?.linkId)],
    (data: any) => api.put(endPointUrls.GATEWAY(data?.linkId || query?.linkId || linkId, query?.gatewayId || gatewayId), data),
    {
      onSuccess: (res: any) => {
        onSuccessHandler(res);
      },
    }
  ) as UseMutationResult;
};
