import ExternalLinkBlock from 'components/BlocksComponents/ExternalLinkBlock/ExternalLinkBlock';
import BlockLayoutCreate from 'components/_Core/Layouts/Panel/PanelHomeBlockLayout/Create/PanelHomeBlockLayoutCreate';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';

const ExternalLinkBlockPage = () => {
  return (
    <BlockLayoutCreate>
      <HeadMeta tabTitle='لینک‌ها' />
      <ExternalLinkBlock />
    </BlockLayoutCreate>
  );
};

export default ExternalLinkBlockPage;
