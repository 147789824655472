import useLinkPreview from 'components/LinkPreview/hooks/useLinkPreview';
import EmptyLinkDetail from 'components/LinkPreview/components/Empty/EmptyLinkDetail';
import LinkDetailBlocksPreview from 'components/LinkPreview/components/LinkDetailBlocksPreview';

const LinkPreview = () => {
  const { linkDetail } = useLinkPreview();
  if (!linkDetail) return <></>;

  return <>{linkDetail?.blocks?.length === 0 ? <EmptyLinkDetail /> : <LinkDetailBlocksPreview link={linkDetail} />}</>;
};

export default LinkPreview;
