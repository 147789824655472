import { FC, memo, ReactNode } from 'react';
import PanelCreateGatewayLinksSteps from 'components/_Core/Layouts/Panel/PanelCreateGatewayLinkLayout/PanelCreateGatewayLinksSteps';
import MainLayout from 'components/_Core/Layouts/Main/MainLayout';
import MainHeaderLayout from 'components/_Core/Layouts/Main/MainHeader/MainHeaderLayout';
import useGatewayLinkLayout from 'components/_Core/Layouts/Panel/PanelCreateGatewayLinkLayout/hooks/useGatewayLinkLayout';

interface IPanelCreateGatewayLinkLayout {
  children: ReactNode;
}

const PanelCreateGatewayLinkLayout: FC<IPanelCreateGatewayLinkLayout> = ({ children }) => {
  const { deleteGatewayLinkHandler, isLoadingDeleteGatewayLink } = useGatewayLinkLayout();

  return (
    <MainLayout>
      <MainHeaderLayout headerTitle='ساخت لینک پرداخت جدید' closeLading={isLoadingDeleteGatewayLink} onCloseHandler={deleteGatewayLinkHandler} />

      <PanelCreateGatewayLinksSteps />

      <>{children}</>
    </MainLayout>
  );
};

export default memo(PanelCreateGatewayLinkLayout);
