import ZpSkeleton from 'components/_Core/UI/Skeleton/ZpSkeleton';

const TemplateListSkeleton = () => {
  return (
    <div className='flex flex-col justify-between h-full'>
      <div className='space-y-10'>
        <div className='space-y-5'>
          <div>
            <ZpSkeleton width={150} height={30} />
          </div>

          <div className='grid grid-cols-2 gap-6'>
            <div>
              <ZpSkeleton className='rounded-2xl' height={260} />
            </div>
            <div>
              <ZpSkeleton className='rounded-2xl' height={260} />
            </div>
            <div>
              <ZpSkeleton className='rounded-2xl' height={260} />
            </div>
            <div>
              <ZpSkeleton className='rounded-2xl' height={260} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateListSkeleton;
