import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryOnSuccessHandler from 'core/helpers/queryOnSuccessHandler/useQueryOnSuccessHandler';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import useRoute from 'core/hooks/useRoute';
import api from 'core/services/fetch-api/useApi';

export const useDeleteNavigationQuery = () => {
  const { onSuccessHandler } = useQueryOnSuccessHandler();
  const queryClient = useQueryClient();
  const { linkId, username } = useRoute();

  return useMutationHook([endPointUrls.NAVIGATION(linkId + 'delete')], (data: any) => api.delete(endPointUrls.NAVIGATION(linkId, data?.id)), {
    onSuccess: (res: any) => {
      if (res.status === 200) {
        onSuccessHandler(res);
        queryClient.invalidateQueries([endPointUrls.LINK_GET_BY_USERNAME(username + '')]);
      }
    },
  }) as UseMutationResult;
};
