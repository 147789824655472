import ZpRadioButtonGroup from 'components/_Core/UI/RadioButton/ZpRadioButtonGroup';
import ZpRadioButton from 'components/_Core/UI/RadioButton/ZpRadioButton';
import useProfileSwitch from 'components/_Core/Layouts/Panel/PanelHomeIndex/PanelHomeIndexFooterNavbar/ProfileSwitch/hooks/useProfileSwitch';
import { ILink } from 'core/types/Models/linkType';
import ProfileSwitchItem from 'components/_Core/Layouts/Panel/PanelHomeIndex/PanelHomeIndexFooterNavbar/ProfileSwitch/components/ProfileSwitchItem';

const ProfileSwitchList = ({ hideModal }: { hideModal: () => void }) => {
  const { links, linkDetail, onChangeProfileHandler } = useProfileSwitch(hideModal);

  if (!linkDetail?.data?.id) return <></>;

  return (
    <ZpRadioButtonGroup className='w-full radio-card space-y-4 flex flex-col' name='links' defaultValue={linkDetail?.data?.id}>
      <ZpRadioButton
        key={linkDetail?.data?.id}
        className='flex-row-reverse text-base hover:bg-natural-color-100 px-3 py-2 flex items-center justify-center bg-blue-light-color rounded-lg'
        onChange={(e) => onChangeProfileHandler(e, linkDetail?.data)}
        value={linkDetail?.data?.id}
      >
        <ProfileSwitchItem
          description={linkDetail?.data?.description || ''}
          title={linkDetail?.data?.title_fa}
          imgUrl={(process.env.REACT_APP_API_FILE_BASE_URL as string) + linkDetail?.data?.image_profile || undefined}
        />
      </ZpRadioButton>

      {links?.data?.map(
        (link: ILink) =>
          link.id !== linkDetail.data?.id && (
            <ZpRadioButton
              key={link.id}
              className='flex-row-reverse text-base hover:bg-natural-color-100 px-3 py-2 flex items-center justify-center bg-blue-light-color rounded-lg'
              onChange={(e) => onChangeProfileHandler(e, link)}
              value={link.id}
            >
              <ProfileSwitchItem
                description={link?.description || ''}
                title={link.title_fa}
                imgUrl={(process.env.REACT_APP_API_FILE_BASE_URL as string) + link.image_profile || undefined}
              />
            </ZpRadioButton>
          )
      )}
    </ZpRadioButtonGroup>
  );
};

export default ProfileSwitchList;
