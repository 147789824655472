import ZpSkeleton from 'components/_Core/UI/Skeleton/ZpSkeleton';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';

const CreateLinkStepThreeSkeleton = () => {
  return (
    <div className='flex flex-col justify-between h-full'>
      <ZpWrapper singleColumn>
        <ZpSkeleton width={150} height={30} />

        <div className='grid grid-cols-2 gap-5'>
          <span>
            <ZpSkeleton height={50} />
          </span>
          <span>
            <ZpSkeleton height={50} />
          </span>
          <span>
            <ZpSkeleton height={50} />
          </span>
          <span>
            <ZpSkeleton height={50} />
          </span>
          <span>
            <ZpSkeleton height={50} />
          </span>
        </div>
      </ZpWrapper>
    </div>
  );
};

export default CreateLinkStepThreeSkeleton;
