import L from 'leaflet';
import marker from 'assets/images/location.svg';

export const pinIcon = new L.Icon({
  iconUrl: marker,
  iconAnchor: undefined,
  popupAnchor: undefined,
  shadowUrl: undefined,
  shadowSize: undefined,
  shadowAnchor: undefined,
  iconSize: new L.Point(36, 36),
  className: '-mt-11',
});
