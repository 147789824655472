import BlockDraggable from 'components/BlockDraggable/BlockDraggable';
import BlockDraggableLayout from 'components/_Core/Layouts/Panel/PanelHomeBlockDraggableLayout/PanelHomeBlockDraggableLayout';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';

const BlockDraggablePage = () => {
  return (
    <BlockDraggableLayout>
      <HeadMeta tabTitle='جابجایی بلوک‌ها' />
      <BlockDraggable />
    </BlockDraggableLayout>
  );
};

export default BlockDraggablePage;
