import useRoute from 'core/hooks/useRoute';
import { TbMessage2 } from 'react-icons/tb';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { IoWalletOutline } from 'react-icons/io5';
import { FiLink2 } from 'react-icons/fi';
import { SlLocationPin } from 'react-icons/sl';
import { BsPatchQuestion } from 'react-icons/bs';
import { RiPageSeparator } from 'react-icons/ri';
import { MdOutlinePhoneInTalk } from 'react-icons/md';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import BlockItem, { IBlockItem } from 'components/Blocks/components/BlockItem/BlockItem';
import useModal from 'core/hooks/useModal';
import GatewayBlockType from 'components/_Core/UI/GatewayBlockType/GatewayBlockType';

export const useGetBlocksList = () => {
  // hooks
  const { modal, hideModal, showModal } = useModal();
  const { routes } = useRoute();
  const { pathname } = useQueryUrlParam();

  const blocks: IBlockItem[] = [
    { title: '‌پیام رسان‌ها', to: routes.PANEL_CREATE_BLOCK_MESSENGER.link(), description: 'به مخاطبان اجازه دهید به شما پیام دهند.', icon: <TbMessage2 size={26} /> },
    { title: 'شبکه‌های اجتماعی', to: routes.PANEL_CREATE_BLOCK_SOCIALMEDIA.link(), description: 'اینستاگرام، یوتیوب، لینکدین و ...', icon: <HiOutlineUserGroup size={26} /> },
    {
      title: 'لینک پرداخت',
      onClick: showModal,
      description: 'امکان پرداخت وجه را برای کاربرانتان فراهم کنید.',
      icon: <IoWalletOutline size={26} />,
    },
    {
      title: 'راه‌های ارتباطی',
      to: routes.PANEL_CREATE_BLOCK_CONNECTION.link(),
      description: 'شماره موبایل، پیامک، تلفن ثابت و ...',
      icon: <MdOutlinePhoneInTalk size={26} />,
    },
    { title: 'جداکننده', to: routes.PANEL_CREATE_BLOCK_DIVIDER.link(), description: 'محتوا ها را دسته بندی کنید.', icon: <RiPageSeparator size={26} /> },
    { title: 'لینک', to: routes.PANEL_CREATE_EXTERNAL_LINK.link(), description: 'هر نوع لینک را به صفحه اضافه کنید.', icon: <FiLink2 size={26} /> },
    { title: 'آدرس‌های من', to: routes.PANEL_CREATE_BLOCK_NAVIGATION.link(), description: 'آدرس دقیق را روی نقشه نمایش دهید.', icon: <SlLocationPin size={26} /> },
    { title: 'سوالات متداول', to: routes.PANEL_CREATE_BLOCK_FAQ.link(), description: 'سوالات پر تکرار مخاطبان را پاسخ دهید.', icon: <BsPatchQuestion size={26} /> },
  ];

  const currentBlock = blocks.filter((block) => block.to === pathname);

  return { blocks, currentBlock: currentBlock?.[0], modal, hideModal };
};

const BlockList = () => {
  const { blocks, modal, hideModal } = useGetBlocksList();
  return (
    <>
      <GatewayBlockType modal={modal} hideModal={hideModal} />

      <div className='flex flex-col space-y-5'>
        {blocks.map((block) => (
          <BlockItem key={block.title} {...block} />
        ))}
      </div>
    </>
  );
};

export default BlockList;
