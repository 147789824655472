import ZpBlockCreate from 'components/_Core/UI/BlockCreate/ZpBlockCreate';
import useSocialMediaBlock from './hooks/useSocialMediaBlock';
import { HiOutlineUserGroup } from 'react-icons/hi';
import SocialMediaBlockList from './components/List/SocialMediaBlockList';
import SocialMediaBlockCreate from './components/Create/SocialMediaBlockCreate';
import BlockListSkeleton from 'components/_Core/UI/BlockListSkeleton/BlockListSkeleton';

const SocialMediaBlock = () => {
  const {
    hideMasterModal,
    onFinishCreateLinkHandler,
    createForm,
    isLoadingSocialMediaType,
    blockTypeSelected,
    setBlockTypeSelected,
    childModal,
    hideChildModalHandler,
    isLoadingCreateSocialMedia,
    showChildModal,
    masterModal,
    showMasterModal,
    socialMediaTypeList,
    isLoadingLinkDetail,
    socialMediaList,
    hasBlock,
  } = useSocialMediaBlock();

  if (isLoadingSocialMediaType || isLoadingLinkDetail) return <BlockListSkeleton />;

  return (
    <>
      <ZpBlockCreate
        childModal={childModal}
        hasBlock={hasBlock}
        setBlockTypeSelected={setBlockTypeSelected}
        hideChildModal={hideChildModalHandler}
        showChildModal={showChildModal}
        blockTypes={socialMediaTypeList}
        masterModal={masterModal}
        hideMasterModal={hideMasterModal}
        showMasterModal={showMasterModal}
        blockTypeIcon={<HiOutlineUserGroup size={26} />}
        blockTypeTitle='شبکه های اجتماعی'
        blockTypeDescription='یکی از شبکه های اجتماعی را انتخاب کنید'
        title='شبکه اجتماعی'
      >
        <SocialMediaBlockCreate
          blockTypeSelected={blockTypeSelected}
          createForm={createForm}
          hideChildModalHandler={hideChildModalHandler}
          isLoadingCreateSocialMedia={isLoadingCreateSocialMedia}
          onFinishCreateLinkHandler={onFinishCreateLinkHandler}
        />
      </ZpBlockCreate>

      <SocialMediaBlockList blocks={socialMediaList} />
    </>
  );
};

export default SocialMediaBlock;
