import useModal from 'core/hooks/useModal';
import { useMessengerTypeQuery } from './react-query/useMessengerTypeQuery';
import { useState, useEffect } from 'react';
import { IBlockTypes } from 'core/types/Models/blockType';
import { Form } from 'antd';
import { useCreateMessengerQuery } from './react-query/useCreateMessengerQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import { useNavigate } from 'react-router';
import useRoute from 'core/hooks/useRoute';

const useMessengerBlock = () => {
  // hooks
  const { modal: masterModal, hideModal: hideMasterModal, showModal: showMasterModal } = useModal();
  const { modal: childModal, hideModal: hideChildModal, showModal: showChildModal } = useModal();
  const [createForm] = Form.useForm();
  const navigate = useNavigate();
  const { routes } = useRoute();

  // state
  const [blockTypeSelected, setBlockTypeSelected] = useState<IBlockTypes>();

  // store
  const { linkDetail } = useSelector((store: RootState) => store.linkStore);
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  // query
  const { isFetching: isLoadingMessengerType, data: MessengerTypeList } = useMessengerTypeQuery();
  const { isFetching: isLoadingLinkDetail } = useLinkDetailQuery();
  const { mutate: createMessenger, isLoading: isLoadingCreateMessenger } = useCreateMessengerQuery();

  const MessengerList = linkDetail?.data?.blocks?.filter((block) => block.type.toLowerCase().includes('messenger'));
  const hasBlock = !!MessengerList?.[0]?.data?.length;

  const hideChildModalHandler = () => {
    createForm.resetFields();
    hideChildModal();
  };

  const onFinishCreateLinkHandler = (values: { username: string; title: string }) => {
    createMessenger(
      { ...values, messenger_type_id: blockTypeSelected?.id, order: hasBlock ? MessengerList?.[0]?.data?.[MessengerList?.[0]?.data?.length - 1]?.order + 1 : 1 },
      {
        onSuccess: () => {
          hideMasterModal();
          hideChildModalHandler();
          navigate(routes.PANEL_HOME.link());
        },
      }
    );
  };

  useEffect(() => {
    createForm.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors.length]);

  return {
    masterModal,
    createForm,
    onFinishCreateLinkHandler,
    blockTypeSelected,
    isLoadingCreateMessenger,
    isLoadingLinkDetail,
    setBlockTypeSelected,
    hideMasterModal,
    showMasterModal,
    MessengerTypeList,
    isLoadingMessengerType,
    childModal,
    hideChildModalHandler,
    showChildModal,
    MessengerList,
    hasBlock,
  };
};

export default useMessengerBlock;
