import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryOnSuccessHandler from 'core/helpers/queryOnSuccessHandler/useQueryOnSuccessHandler';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';

export const useProfileUpdateQuery = () => {
  const { onSuccessHandler } = useQueryOnSuccessHandler();

  return useMutationHook(
    [endPointUrls.PROFILE_UPDATE],
    (data) => api.post(endPointUrls.PROFILE_UPDATE, data, { headers: { Authorization: window.sessionStorage.getItem('jwt') } }),
    {
      onSuccess: (res: any) => {
        onSuccessHandler(res);
      },
    }
  ) as UseMutationResult;
};
