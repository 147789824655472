import FaqBlock from 'components/BlocksComponents/FaqBlock/FaqBlock';
import BlockLayoutCreate from 'components/_Core/Layouts/Panel/PanelHomeBlockLayout/Create/PanelHomeBlockLayoutCreate';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';

const FaqBlockPage = () => {
  return (
    <BlockLayoutCreate>
      <HeadMeta tabTitle='سوالات متداول' />
      <FaqBlock />
    </BlockLayoutCreate>
  );
};

export default FaqBlockPage;
