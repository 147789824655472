import { IBlockDetail } from 'core/types/Models/linkType';
import { FC } from 'react';
import { IBlockTypeDetail } from 'core/types/Models/blockType';
import navigation from 'assets/images/icon/navigation.svg';
import PreviewTemplateBlockTitle from 'components/_Core/UI/PreviewTemplate/BlockTitle/PreviewTemplateBlockTitle';
import PreviewTemplateNavigation from 'components/_Core/UI/PreviewTemplate/Navigation/PreviewTemplateNavigation';

interface ILinkDetailNavigationBlock {
  blocks: IBlockDetail[];
  blockType: IBlockTypeDetail;
}

const LinkDetailNavigationBlock: FC<ILinkDetailNavigationBlock> = ({ blocks, blockType }) => {
  const staticIcon = navigation;

  return (
    <>
      <div className='w-full space-y-5'>
        <PreviewTemplateBlockTitle title={blockType.title} />

        {blocks?.map((block) => {
          return <PreviewTemplateNavigation key={block.id} block={block} staticIcon={staticIcon} />;
        })}
      </div>
    </>
  );
};

export default LinkDetailNavigationBlock;
