import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryOnSuccessHandler from 'core/helpers/queryOnSuccessHandler/useQueryOnSuccessHandler';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import useRoute from 'core/hooks/useRoute';
import api from 'core/services/fetch-api/useApi';
import { useNavigate } from 'react-router-dom';

export const useUpdateGatewayLinkQuery = (step?: 'GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_3' | 'GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_2', showModal?: () => void) => {
  // hooks
  const { onSuccessHandler } = useQueryOnSuccessHandler();
  const { routes, gatewayLinkId, gatewayId } = useRoute();
  const { query, filterParams } = useQueryUrlParam();
  const navigate = useNavigate();

  return useMutationHook(
    [endPointUrls.GATEWAY_LINK('update')],
    (data: any) => api.put(endPointUrls.GATEWAY_LINK(query?.gatewayId || gatewayId, query?.gatewayLinkId || gatewayLinkId), data),
    {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          onSuccessHandler(res);
          step && navigate(routes[step].link(filterParams));
          showModal && showModal();
        }
      },
    }
  ) as UseMutationResult;
};
