import { FC, memo } from 'react';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { FormInstance } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import useRoute from 'core/hooks/useRoute';
import MainFooterLayout from 'components/_Core/Layouts/Main/MainFooter/MainFooterLayout';

interface IProfileFooterFooter {
  isLoading: boolean;
  profileForm?: FormInstance;
}

const ProfileFooter: FC<IProfileFooterFooter> = ({ isLoading, profileForm }) => {
  const navigate = useNavigate();
  const { routes } = useRoute();
  const { key } = useLocation();

  return (
    <MainFooterLayout className='flex items-center justify-between space-x-5 space-x-reverse'>
      <ZpButton className='w-full' type='primary' ghost onClick={() => (key === 'default' ? navigate(routes.LINKS.link) : navigate(-1))}>
        انصراف
      </ZpButton>

      <ZpButton className='w-full' type='primary' isLoading={isLoading} onClick={() => profileForm?.submit()}>
        ثبت تغییرات
      </ZpButton>
    </MainFooterLayout>
  );
};

export default memo(ProfileFooter);
