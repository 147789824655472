import ZpInformation from 'components/_Core/UI/Information/ZpInformation';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import useCreateLinkStepOne from './hooks/useCreateLinkStepOne';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import PanelCreateLinkLayoutFooter from 'components/_Core/Layouts/Panel/PanelCreateLinkLayout/PanelCreateLinkLayoutFooter';
import CreateLinkStepOneSkeleton from './components/CreateLinkStepOneSkeleton';
import MainContentLayout from 'components/_Core/Layouts/Main/MainContentLayout/MainContentLayout';

const CreateLinkStepOne = () => {
  const {
    createLinkStepOneForm,
    isLoadingLinkDetail,
    isLoadingCheckUsername,
    onChangeUsernameHandler,
    usernameValue,
    onFinishCreateLinkStepOneHandler,
    checkUsernameResult,
    query,
    isLoadingCreateLinkStepOne,
  } = useCreateLinkStepOne();

  if (isLoadingLinkDetail)
    return (
      <MainContentLayout>
        <CreateLinkStepOneSkeleton />
      </MainContentLayout>
    );

  return (
    <>
      <MainContentLayout>
        <div className='flex flex-col justify-between h-full'>
          <ZpForm form={createLinkStepOneForm} onFinish={onFinishCreateLinkStepOneHandler} className='flex-1'>
            <ZpWrapper singleColumn>
              <div>
                <ZpFormItem
                  validateStatus={isLoadingCheckUsername ? 'validating' : checkUsernameResult === undefined ? '' : checkUsernameResult?.is_available ? 'success' : 'error'}
                  help={checkUsernameResult === undefined ? false : !checkUsernameResult?.is_available && 'آیدی لینک قبلا ثبت شده است'}
                  hasFeedback
                  noMargin
                  name='username'
                  rules={[{ required: true, message: 'آیدی لینک را وارد کنید' }]}
                >
                  <ZpInputLabel
                    value={usernameValue}
                    onChange={onChangeUsernameHandler}
                    disabled={!!query?.username}
                    required
                    textLeftFull
                    label='آیدی لینک'
                    suffix={`${process.env.REACT_APP_API_BASE_DOMAIN_NO_HTTP} / `}
                  />
                </ZpFormItem>
                <ZpInformation>
                  <p>آیدی {process.env.REACT_APP_BRAND_NAME_FA} شما فقط می‌تواند شامل حروف انگلیسی باشد.</p>
                  <p>همچنین دقت داشته باشید که آیدی لینک پس از عبور به مرحله بعدی، قابل ویرایش نمی‌باشد.</p>
                </ZpInformation>
              </div>

              <div>
                <ZpFormItem noMargin name='title_fa' rules={[{ required: true, message: 'عنوان فارسی لینک را وارد کنید' }]}>
                  <ZpInputLabel required label='عنوان فارسی لینک' />
                </ZpFormItem>
                <ZpInformation>عنوان فارسی لینک می تواند فقط ترکیبی از حروف فارسی و اعداد باشد. </ZpInformation>
              </div>
            </ZpWrapper>
          </ZpForm>
        </div>
      </MainContentLayout>

      <PanelCreateLinkLayoutFooter isLoading={isLoadingCreateLinkStepOne} createLinkForm={createLinkStepOneForm} />
    </>
  );
};

export default CreateLinkStepOne;
