import { FC } from 'react';
import useTemplatePreview from 'components/_Core/UI/PreviewTemplate/hooks/useTemplatePreview';

const LinkPreviewBackground: FC = () => {
  const { backgroundImageBody, backgroundColorBody } = useTemplatePreview();
  return (
    <>
      <div
        style={{
          filter: 'blur(15px)',
          height: '100%',
          width: '100%',
          top: 0,
          left: 0,
          backgroundImage: `url(${backgroundImageBody})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'fixed',
          zIndex: -5,
        }}
      />

      <div
        style={{
          backgroundColor: backgroundColorBody,
          height: '100vh',
          backgroundImage: `url(${backgroundImageBody})`,
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50%',
          backgroundSize: '100% 100vh',
          position: 'fixed',
          top: 0,
          left: '50%',
          transform: 'translate(-50%)',
          width: '100%',
          maxWidth: '448px',
          zIndex: -1,
        }}
      />
    </>
  );
};

export default LinkPreviewBackground;
