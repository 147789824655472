import { Form } from 'antd';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import { IBlockDetail } from 'core/types/Models/linkType';
import useUpdateFaq from '../../hooks/useUpdateFaq';
import ZpBlockDelete from 'components/_Core/UI/BlockDelete/ZpBlockDelete';
import ZpTextAreaLabel from 'components/_Core/UI/TextArea/ZpTextAreaLabel';

const FaqBlockUpdate = ({ block }: { block: IBlockDetail | undefined }) => {
  const [formUpdate] = Form.useForm();
  const { onFinishUpdateHandler, hideModal, modal, showModal, isLoadingUpdateFaq, isLoadingDeleteFaq, onDeleteHandler } = useUpdateFaq(formUpdate, block);

  return (
    <>
      <ZpBlockDelete modal={modal} hideModal={hideModal} isLoadingDelete={isLoadingDeleteFaq} onDeleteHandler={onDeleteHandler} blockId={block?.id} />

      <ZpForm form={formUpdate} onFinish={onFinishUpdateHandler}>
        <ZpWrapper singleColumn>
          <ZpFormItem rules={[{ required: true, message: 'سوال را وارد کنید' }]} name='question'>
            <ZpInputLabel required label='سوال' />
          </ZpFormItem>

          <ZpFormItem rules={[{ required: true, message: 'جواب را وارد کنید' }]} name='answer'>
            <ZpTextAreaLabel required label='جواب' />
          </ZpFormItem>

          <ZpFormItem button>
            <div className='flex items-center space-x-reverse space-x-5 justify-between'>
              <ZpButton buttonSize='xs' className='w-full' htmlType='button' danger ghost type='primary' isLoading={isLoadingDeleteFaq} onClick={showModal}>
                حذف
              </ZpButton>
              <ZpButton buttonSize='xs' className='w-full' htmlType='submit' type='primary' isLoading={isLoadingUpdateFaq}>
                ثبت تغییرات
              </ZpButton>
            </div>
          </ZpFormItem>
        </ZpWrapper>
      </ZpForm>
    </>
  );
};

export default FaqBlockUpdate;
