import { createSlice } from '@reduxjs/toolkit';
import { ISubscriptionPlan } from 'core/types/Models/userInfoType';
import { IPlan } from 'core/types/Models/planType';

const initialState: {
  subscriptionDetail: ISubscriptionPlan | null;
  activePlan: IPlan | null;
  trialDays: number | 0;
  hasPlan: boolean;
  canBuy: boolean;
  renewal: boolean;
} = {
  subscriptionDetail: null,
  activePlan: null,
  trialDays: 0,
  hasPlan: false,
  canBuy: false,
  renewal: false,
};

export const userPlanSlice = createSlice({
  name: 'userPlan',
  initialState: initialState,
  reducers: {
    setUserPlanDetail: (state, { payload }: { payload: ISubscriptionPlan }) => {
      state.subscriptionDetail = payload;
      state.activePlan = payload.plan;
      state.trialDays = payload.trial_days;
      state.hasPlan = payload.plan.code !== 'free';
      state.canBuy = payload.can_pay;
      state.renewal = !payload.has_trial;
    },
  },
});

export const { setUserPlanDetail } = userPlanSlice.actions;
export default userPlanSlice.reducer;
