import { useUpdateLinkQuery } from 'components/CreateLink/StepTwo/hooks/react-query/useUpdateLinkQuery';
import toastHandler from 'core/helpers/toast/toast';
import { useLinkCategoryQuery } from 'core/hooks/react-query/useLinkCategoryQuery';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import useSelected from 'core/hooks/useSelected';
import { useEffect } from 'react';

const useStepThree = () => {
  const { selectedTypeKeep, selectedTypeKeepHandler, setSelectedTypeKeep } = useSelected(undefined, false, true);

  // query
  const { mutate: updateLink, isLoading: isLoadingUpdateLinkStepTwo } = useUpdateLinkQuery(undefined, true);
  const { data: linkDetail, isFetching: isLoadingLinkDetail } = useLinkDetailQuery();
  const { data: linkCategories, isFetching: isLoadingLinkCategory } = useLinkCategoryQuery();

  const onFinishUpdateLinkStepThreeHandler = () => {
    const categories = selectedTypeKeep.map((item) => item.index);
    if (categories.length === 0) return toastHandler('error', 'یک یا چند حوزه فعالیت را انتخاب کنید.');
    updateLink({ categories, status: 1 });
  };

  useEffect(() => {
    const categories: { index: string | number; data?: any }[] | undefined = linkDetail?.data.categories.map((item) => ({ index: item.id, data: undefined }));
    categories && setSelectedTypeKeep(categories);
  }, [linkDetail]);

  return {
    selectedTypeKeep,
    selectedTypeKeepHandler,
    linkCategories,
    isLoading: isLoadingLinkDetail || isLoadingLinkCategory,
    onFinishUpdateLinkStepThreeHandler,
    isLoadingUpdateLinkStepTwo,
  };
};

export default useStepThree;
