import { useTemplateListQuery } from 'components/Template/components/TemplateList/hooks/react-query/useTemplateListQuery';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';

const useTemplate = () => {
  // query
  const { data: templates, isFetching: isLoadingTemplate } = useTemplateListQuery();
  const { isFetching: isLoadingLinkDetail } = useLinkDetailQuery();

  return { templates, isLoadingTemplate, isLoadingLinkDetail };
};

export default useTemplate;
