import ZpSkeleton from 'components/_Core/UI/Skeleton/ZpSkeleton';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import ZpDevider from 'components/_Core/UI/Devider/ZpDevider';

const CreateLinkStepOneSkeleton = () => {
  return (
    <div className='flex flex-col justify-between h-full'>
      <ZpWrapper singleColumn>
        <div>
          <ZpSkeleton height={40} />
        </div>

        <ZpDevider>اطلاعات لینک پرداخت</ZpDevider>
        <div>
          <ZpSkeleton height={40} />
        </div>
        <div>
          <ZpSkeleton height={150} />
        </div>
      </ZpWrapper>
    </div>
  );
};

export default CreateLinkStepOneSkeleton;
