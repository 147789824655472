import { FC, memo, ReactNode } from 'react';
import useRoute from 'core/hooks/useRoute';
import MainHeaderLayout from 'components/_Core/Layouts/Main/MainHeader/MainHeaderLayout';
import MainLayout from 'components/_Core/Layouts/Main/MainLayout';
import MainContentLayout from 'components/_Core/Layouts/Main/MainContentLayout/MainContentLayout';
import PanelMasterIndexFooterNavbar from 'components/_Core/Layouts/PanelMaster/PanelMasterIndex/PanelMasterIndexFooterNavbar/PanelMasterIndexFooterNavbar';

interface IPlanTransactionsLayout {
  children: ReactNode;
}

const PlanTransactionsLayout: FC<IPlanTransactionsLayout> = ({ children }) => {
  const { routes } = useRoute();

  return (
    <MainLayout>
      <MainHeaderLayout headerTitle='تراکنش‌های من' backUrlArrow={routes.LINKS.link} />

      <MainContentLayout>{children}</MainContentLayout>

      <PanelMasterIndexFooterNavbar showQuickAccessButton={false} />
    </MainLayout>
  );
};

export default memo(PlanTransactionsLayout);
