import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import useQueryOnErrorHandler from 'core/helpers/queryOnErrorHandler/useQueryOnErrorHandler';
import toastHandler from 'core/helpers/toast/toast';

const useQueryHook = (queryKey: QueryKey, queryFn: QueryFunction, queryOptions: UseQueryOptions = {}, showError = true) => {
  const { onErrorHandler } = useQueryOnErrorHandler();

  return useQuery(queryKey, queryFn, {
    onError: (res: any) => {
      if (res?.response?.status >= 500) {
        toastHandler('error', 'لطفا دوباره تلاش کنید');
      }

      onErrorHandler(res, showError);
    },
    ...queryOptions,
  });
};

export default useQueryHook;
