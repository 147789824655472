import ZpSkeleton from 'components/_Core/UI/Skeleton/ZpSkeleton';

const PanelCreateGatewayLinksStepsSkeleton = () => {
  return (
    <div className='py-5 mx-5 flex space-x-5 space-x-reverse justify-center max-w-full'>
      <div className='flex flex-col space-y-5 items-center'>
        <ZpSkeleton height={50} width={50} circle />
        <ZpSkeleton height={30} width={80} />
      </div>

      <div className='flex flex-col space-y-5 items-center'>
        <ZpSkeleton height={50} width={50} circle />
        <ZpSkeleton height={30} width={80} />
      </div>

      <div className='flex flex-col space-y-5 items-center'>
        <ZpSkeleton height={50} width={50} circle />
        <ZpSkeleton height={30} width={80} />
      </div>
    </div>
  );
};

export default PanelCreateGatewayLinksStepsSkeleton;
