import { FC } from 'react';
import useTemplate from 'components/Template/components/TemplateList/hooks/useTemplate';
import TemplateCategoryItem from 'components/Template/components/TemplateList/components/TemplateItem/TemplateCategoryItem';
import TemplateListSkeleton from 'components/Template/components/TemplateList/components/Skeleton/TemplateListSkeleton';

const TemplateList: FC = () => {
  const { templates, isLoadingTemplate, isLoadingLinkDetail } = useTemplate();

  if (isLoadingTemplate || isLoadingLinkDetail) return <TemplateListSkeleton />;

  return (
    <div className='space-y-11'>
      {templates?.data?.map((template) => (
        <TemplateCategoryItem key={template.id} templates={template?.templates} title={template.fa_title} />
      ))}
    </div>
  );
};

export default TemplateList;
