import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';
import PlansLayout from 'components/_Core/Layouts/PanelMaster/Plans/PlansLayout';
import Plans from 'components/Plans/Plans';

const PlansPage = () => {
  return (
    <PlansLayout>
      <HeadMeta tabTitle={`پلن‌های ${process.env.REACT_APP_BRAND_NAME_FA}`} />
      <Plans />
    </PlansLayout>
  );
};

export default PlansPage;
