import { forwardRef, memo, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { InputProps, InputRef } from 'antd';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';
import ZpInput from 'components/_Core/UI/Input/ZpInput';
import { twMerge } from 'tailwind-merge';
import { ClipLoader } from 'react-spinners';

export interface IZpInputLabel extends InputProps {
  label: string | ReactNode;
  labelBackground?: string;
  ltr?: boolean;
  className?: string;
  required?: boolean;
  value?: any;
  noLeftRound?: boolean;
  noRightRound?: boolean;
  textLeftFull?: boolean;
  textLeft?: boolean;
  isLoading?: boolean;
}

const ZpInputLabel = forwardRef<InputRef, IZpInputLabel>(
  ({ labelBackground, ltr = false, textLeftFull = false, isLoading, textLeft = false, label, className, required, noLeftRound = false, noRightRound = false, ...props }, ref) => {
    const { lang } = useSelector((store: RootState) => store.settingStore);
    const labelRef = useRef<HTMLLabelElement>(null);
    const [value, setValue] = useState<any>('');

    useLayoutEffect(() => {
      if (props.value || value) labelRef.current?.classList?.add('active-float-label');
      else labelRef.current?.classList?.remove('active-float-label');
    }, [props.value, value]);

    return (
      <div className='float-group text-left relative'>
        <ZpInput
          ref={ref}
          value={value}
          setValue={setValue}
          className={`h-[50px] ${noLeftRound ? 'rounded-tl-none rounded-bl-none' : ''} ${textLeftFull ? 'text-left-full ltr-full' : ''} ${textLeft ? 'text-left ltr' : ''} ${
            noRightRound ? 'rounded-tr-none rounded-br-none' : ''
          } ${className}`}
          {...props}
        />
        <label ref={labelRef} className={twMerge(`float-label ${labelBackground} ${lang === 'fa' ? (ltr ? 'left-3' : 'right-3') : 'left-3'}`)}>
          {required && <span className='text-error-color-300 inline-block relative top-1 mx-1'>*</span>}
          {label}
        </label>

        {isLoading && (
          <span className='absolute left-5 top-1/2 -translate-y-1/2 flex'>
            <ClipLoader color='var(--primary-color-main)' size={14} />
          </span>
        )}
      </div>
    );
  }
);

ZpInputLabel.displayName = 'ZpInputLabel';

export default memo(ZpInputLabel);
