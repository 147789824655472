import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import useAvatar from 'assets/images/useAvatar.svg';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import useProfile from './hooks/useProfile';
import ZpRadioButtonGroup from 'components/_Core/UI/RadioButton/ZpRadioButtonGroup';
import ZpRadioButton from 'components/_Core/UI/RadioButton/ZpRadioButton';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import ProfileFooterFooter from 'components/_Core/Layouts/PanelMaster/Profile/ProfileFooter';
import ProfileSkeleton from './components/ProfileSkeleton';
import MainContentLayout from 'components/_Core/Layouts/Main/MainContentLayout/MainContentLayout';
import ZpPremiumBadge from 'components/_Core/UI/PremiumBadge/ZpPremiumBadge';

const Profile = () => {
  const { profileForm, userInfo, isLoadingUserInfo, isLoadingUpdateProfile, onFinishUpdateProfile } = useProfile();

  if (isLoadingUserInfo)
    return (
      <MainContentLayout>
        <ProfileSkeleton />
      </MainContentLayout>
    );

  return (
    <>
      <MainContentLayout>
        <div className='flex flex-col h-full 448:mb-0 mb-40'>
          <div className='mb-10 flex items-center justify-center'>
            <ZpPremiumBadge width={40} height={40} type='B'>
              <ZpImage src={useAvatar} />
            </ZpPremiumBadge>
          </div>

          <ZpForm onFinish={onFinishUpdateProfile} form={profileForm}>
            <ZpWrapper singleColumn>
              <ZpFormItem rules={[{ required: true, message: 'نام خود را وارد کنید' }]} name='firstname'>
                <ZpInputLabel required label='نام' />
              </ZpFormItem>

              <ZpFormItem rules={[{ required: true, message: 'نام خانوادگی خود را وارد کنید' }]} name='lastname'>
                <ZpInputLabel required label='نام خانوادگی' />
              </ZpFormItem>

              {/*<ZpFormItem rules={[{ required: true, message: 'کدملی خود را وارد کنید' }]} name='ssn'>*/}
              {/*	<ZpInputLabel required disabled={!!userInfo?.ssn} label='کدملی' />*/}
              {/*</ZpFormItem>*/}

              <ZpFormItem rules={[{ required: true, message: 'شماره موبایل خود را وارد کنید' }]} name='mobile'>
                <ZpInputLabel required disabled={!!userInfo?.mobile} label='موبایل' />
              </ZpFormItem>

              <div className='text-right flex flex-col space-y-2'>
                <span>جنسیت</span>
                <ZpFormItem name='gender'>
                  <ZpRadioButtonGroup name='gender'>
                    <ZpRadioButton value={1}>مرد</ZpRadioButton>
                    <ZpRadioButton value={2}>زن</ZpRadioButton>
                  </ZpRadioButtonGroup>
                </ZpFormItem>
              </div>
            </ZpWrapper>
          </ZpForm>
        </div>
      </MainContentLayout>

      <ProfileFooterFooter isLoading={isLoadingUpdateProfile} profileForm={profileForm} />
    </>
  );
};

export default Profile;
