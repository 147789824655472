import { IBlockDetail } from 'core/types/Models/linkType';
import { FC, Fragment } from 'react';
import { IBlockTypeDetail } from 'core/types/Models/blockType';
import ZpBlockItem from 'components/_Core/UI/BlockList/ZpBlockItem';
import faq from 'assets/images/icon/faq.svg';
import PreviewTemplateFaq from 'components/_Core/UI/PreviewTemplate/Faq/PreviewTemplateFaq';
import PreviewTemplateBlockTitle from 'components/_Core/UI/PreviewTemplate/BlockTitle/PreviewTemplateBlockTitle';

interface ILinkDetailFaqBlockChild {
  blocks: IBlockDetail[];
  blockType: IBlockTypeDetail;
}

const LinkDetailFaqBlockChild: FC<ILinkDetailFaqBlockChild> = ({ blocks, blockType }) => {
  return (
    <>
      <div className='w-full space-y-3'>
        <PreviewTemplateBlockTitle title={blockType.title} />

        {blocks?.map((block) => {
          return (
            <Fragment key={block.id}>
              <PreviewTemplateFaq block={block} />
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

export default LinkDetailFaqBlockChild;
