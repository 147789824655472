import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import api from 'core/services/fetch-api/useApi';
import { IBlockTypesList } from 'core/types/Models/blockType';

export const useConnectionTypeQuery = () => {
  return useQueryHook([endPointUrls.CONNECTION_TYPE], () => api.get(endPointUrls.CONNECTION_TYPE), {
    select: (res: any) => res.data,
  }) as UseQueryResult<IBlockTypesList, Error>;
};
