import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';
import PlanTransactions from 'components/PlanTransactions/PlanTransactions';
import PlanTransactionsLayout from 'components/_Core/Layouts/PanelMaster/PlanTransactions/PlanTransactionsLayout';

const PlanTransactionsPage = () => {
  return (
    <PlanTransactionsLayout>
      <HeadMeta tabTitle='تراکنش‌های من' />
      <PlanTransactions />
    </PlanTransactionsLayout>
  );
};

export default PlanTransactionsPage;
