import { FC, memo } from 'react';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { HiOutlineArrowLeft, HiOutlineArrowRight } from 'react-icons/hi';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { FormInstance } from 'antd';
import MainFooterLayout from 'components/_Core/Layouts/Main/MainFooter/MainFooterLayout';

interface IPanelCreateLinkLayoutFooter {
  isLoading: boolean;
  createLinkForm?: FormInstance;
  onClick?: () => void;
  backStep?: () => void;
}

const PanelCreateLinkLayoutFooter: FC<IPanelCreateLinkLayoutFooter> = ({ createLinkForm, isLoading, onClick, backStep }) => {
  const { pathnameLastPart } = useQueryUrlParam();
  const step = pathnameLastPart ? +pathnameLastPart : 1;

  return (
    <MainFooterLayout className={`flex items-center ${step === 1 ? 'justify-end' : 'justify-between'}`}>
      {step !== 1 && (
        <ZpButton type='link' onClick={backStep}>
          <span className='flex items-center gap-2 justify-between'>
            <HiOutlineArrowRight size={22} />
            <span>مرحله قبل</span>
          </span>
        </ZpButton>
      )}

      <ZpButton type='primary' isLoading={isLoading} onClick={onClick || (() => createLinkForm?.submit())}>
        <span className='flex items-center gap-2 justify-between'>
          {step === 3 ? <span>ساخت لینک</span> : <span>مرحله بعد</span>}
          <HiOutlineArrowLeft size={22} />
        </span>
      </ZpButton>
    </MainFooterLayout>
  );
};

export default memo(PanelCreateLinkLayoutFooter);
