import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import { HiOutlineSearch } from 'react-icons/hi';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import useGatewayInvoiceSearchFilter from 'components/Gateway/components/GatewayInvoiceFilter/hooks/useGatewayInvoiceSearchFilter';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const GatewayInvoiceSearchText = () => {
  const { setSearchValue, searchValue, hasTextFilter, onSubmitSearchHandler, deleteOneQueryParam } = useGatewayInvoiceSearchFilter();

  return (
    <div className='flex items-center space-x-3 space-x-reverse'>
      <ZpInputLabel
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        label={
          <span className='flex items-start space-x-3 space-x-reverse'>
            <HiOutlineSearch size={20} />
            <span>جستجو</span>
          </span>
        }
        placeholder='نام و نام خانوادگی'
        suffix={hasTextFilter && <AiOutlineCloseCircle size={18} className='cursor-pointer' onClick={() => deleteOneQueryParam('filter[user.name]')} />}
      />
      <ZpButton type='primary' buttonSize='lg' className='min-w-[50px]' onClick={onSubmitSearchHandler}>
        <HiOutlineSearch size={20} />
      </ZpButton>
    </div>
  );
};

export default GatewayInvoiceSearchText;
