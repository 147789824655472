import { FC } from 'react';
import { blockBackgroundImageConfig, getImageUrl } from 'core/utils/utils';

interface ITemplateItemBlock {
  has_block_border: boolean;
  has_block_blur: boolean;
  block_border_radius: string;
  block_border_color: string;
  block_background_color: string;
  block_box_shadow?: string;
  block_background_image?: string;
}

const TemplateItemBlock: FC<ITemplateItemBlock> = ({
  block_border_color,
  block_background_color,
  has_block_border,
  block_border_radius,
  has_block_blur,
  block_background_image,
  block_box_shadow,
}) => {
  const blockStyles = {
    border: has_block_border ? `1px solid ${block_border_color}` : '',
    backgroundColor: block_background_color || 'transparent',
    borderRadius: block_border_radius || '0',
    backdropFilter: has_block_blur ? 'blur(10px)' : 'none',
    boxShadow: block_box_shadow || 'none',
  };
  const blockBackgroundImage = blockBackgroundImageConfig(getImageUrl(block_background_image));

  return (
    <div
      style={{
        ...blockStyles,
        ...blockBackgroundImage.object,
      }}
      className='w-[120px] p-3'
    ></div>
  );
};

export default TemplateItemBlock;
