import { SelectProps } from 'antd';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import ZpSelectLabel from 'components/_Core/UI/Select/ZpSelectLabel';
import { getImageUrl } from 'core/utils/utils';
import { useGatewayIconListQuery } from 'components/Gateway/GatewayLinks/Create/Professional/StepTwo/hooks/react-query/useGatewayIconListQuery';

const ProfileSelect = ({ ...props }) => {
  const { data: paymentLinkIcons, isFetching: isLoadingGatewayLinkIcons } = useGatewayIconListQuery();

  const options: SelectProps['options'] = paymentLinkIcons?.data?.map((item) => ({
    label: (
      <div className='flex items-center space-x-5 space-x-reverse'>
        <ZpImage src={getImageUrl(item.url)} className='rounded-full' width={27} height={27} />
      </div>
    ),
    value: +item.id,
  }));

  return <ZpSelectLabel showSearch={false} required label='انتخاب آیکون لینک پرداخت' options={options} loading={isLoadingGatewayLinkIcons} {...props} />;
};

export default ProfileSelect;
