import { FormInstance } from 'antd';
import { IBlockDetail } from 'core/types/Models/linkType';
import { useEffect } from 'react';
import { useUpdateMessengerQuery } from './react-query/useUpdateMessengerQuery';
import { useDeleteMessengerQuery } from './react-query/useDeleteMessengerQuery';
import useModal from 'core/hooks/useModal';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

const useUpdateMessenger = (form: FormInstance, block: IBlockDetail | undefined) => {
  // hooks
  const { hideModal, modal, showModal } = useModal();

  // query
  const { mutate: updateMessenger, isLoading: isLoadingUpdateMessenger } = useUpdateMessengerQuery();
  const { mutate: deleteMessenger, isLoading: isLoadingDeleteMessenger } = useDeleteMessengerQuery();

  // store
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  useEffect(() => {
    block && form.setFieldsValue({ ...block });
  }, []);

  useEffect(() => {
    const hasError = apiCatcherFormErrors.every((item) => item?.payload?.id === block?.id);
    hasError && form.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors]);

  const onFinishUpdateHandler = (values: { title: string; username: string }) => {
    updateMessenger({ ...values, messenger_type_id: block?.type?.id, order: block?.order, id: block?.id });
  };

  const onDeleteHandler = () => {
    deleteMessenger({ id: block?.id });
  };

  return { onFinishUpdateHandler, isLoadingUpdateMessenger, isLoadingDeleteMessenger, onDeleteHandler, hideModal, modal, showModal };
};

export default useUpdateMessenger;
