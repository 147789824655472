import { useGatewayInvoicesQuery } from 'components/Gateway/GatewayInvoices/hooks/react-query/useGatewayInvoicesQuery';
import GatewayInvoicesItem from 'components/Gateway/GatewayInvoices/components/GatewayInvoicesItem';
import GatewayInvoiceItemSkeleton from 'components/Gateway/GatewayInvoices/components/GatewayInvoiceItemSkeleton';
import GatewayInvoicesEmpty from 'components/Gateway/GatewayLinkInvoices/components/GatewayInvoicesEmpty';
import GatewayInvoiceFilter from 'components/Gateway/components/GatewayInvoiceFilter/GatewayInvoiceFilter';

const GatewayInvoices = () => {
  const { data: invoices, isFetching } = useGatewayInvoicesQuery();

  if (isFetching) return <GatewayInvoiceItemSkeleton />;

  return (
    <div className='h-full relative space-y-5'>
      <div className='flex items-center justify-between'>
        <h2 className='font-bold text-xl'>لیست تراکنش‌ها</h2>
      </div>

      {invoices?.data?.length !== 0 && <GatewayInvoiceFilter />}

      {(invoices === undefined || invoices?.data?.length === 0) && <GatewayInvoicesEmpty />}

      {invoices?.data?.map((invoice) => (
        <GatewayInvoicesItem key={invoice.id} {...invoice} />
      ))}
    </div>
  );
};

export default GatewayInvoices;
