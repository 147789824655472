import ZpUploadImage from 'components/_Core/UI/UploadImage/ZpUploadImage';
import { ILinkDetail } from 'core/types/Models/linkType';
import { MutableRefObject, FC, FormEvent } from 'react';
import ZpSkeleton from 'components/_Core/UI/Skeleton/ZpSkeleton';

interface IUpdateLinkImage {
  onUpdateImageHandler: (e: FormEvent<HTMLInputElement>) => void;
  imageRef: MutableRefObject<any>;
  linkDetail: ILinkDetail | undefined;
  isLoading?: boolean;
}

const UpdateLinkImage: FC<IUpdateLinkImage> = ({ isLoading, imageRef, linkDetail, onUpdateImageHandler }) => {
  if (isLoading)
    return (
      <div className='text-center'>
        <ZpSkeleton width={150} height={150} circle />
      </div>
    );

  console.log(linkDetail);

  return (
    <div className='flex flex-col items-center space-y-3'>
      <ZpUploadImage
        linkDetail={linkDetail}
        className='bg-primary-color-50'
        imageRef={imageRef}
        onUpdateImageHandler={onUpdateImageHandler}
        title={`آپلود تصویر ${process.env.REACT_APP_BRAND_NAME_FA}`}
      />

      <div className='bg-natural-color-100 px-4 py-2 rounded-lg text-base text-color-placeholder inline-block ltr'>
        {window.location.hostname}/{linkDetail?.data?.username}
      </div>
    </div>
  );
};

export default UpdateLinkImage;
