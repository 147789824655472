import ZpImage from 'components/_Core/UI/Image/ZpImage';
import EmptyGatewayIcon from 'assets/images/empty-gateway.svg';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import useRoute from 'core/hooks/useRoute';

function EmptyGateway() {
  const navigate = useNavigate();
  const { routes } = useRoute();

  return (
    <div className='relative max-w-sm w-full min-h-[calc(100vh_-_200px)]'>
      <div className='space-y-5 text-center text-color-paragraph absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
        <ZpImage src={EmptyGatewayIcon} />

        <p>درگاه پرداخت فعالی وجود ندارد.</p>

        <ZpButton type='primary' buttonSize='lg' onClick={() => navigate(routes.GATEWAY_CREATE.link())}>
          <span className='flex items-center space-x-3 space-x-reverse'>
            <span>درخواست درگاه پرداخت</span>
            <FiArrowLeft size={22} />
          </span>
        </ZpButton>
      </div>
    </div>
  );
}

export default EmptyGateway;
