import { ReactComponent as SuccessPaymentSVG } from 'assets/images/plan/success-payment.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { FiArrowLeft } from 'react-icons/fi';
import Container from 'components/_Core/Layouts/Container/Container';
import ZpLink from 'components/_Core/UI/Link/ZpLink';
import useRoute from 'core/hooks/useRoute';
import { useUserInfoQuery } from 'core/hooks/react-query/useUserInfoQuery';
import ZpSpinner from 'components/_Core/UI/Spinner/ZpSpinner';
import usePaymentReturnSuccess from 'components/PaymentReturnSuccess/hooks/usePaymentReturnSuccess';

const PaymentReturnSuccess = () => {
  const { routes, query, isLoadingUserInfo, userInfo } = usePaymentReturnSuccess();
  const userDetail = isLoadingUserInfo ? <ZpSpinner /> : userInfo?.data?.firstname;

  return (
    <Container>
      <div className='flex flex-col items-center justify-center space-y-8'>
        <SuccessPaymentSVG />

        <div className='space-y-5 text-center'>
          <h1 className='font-bold text-2xl'>پلن حرفه‌ای فعال شد!</h1>

          <p className='text-color-paragraph'>
            <span className='text-secondary-color-main'>{userDetail} عزیز</span> حساب کاربری شما به حرفه ای ارتقا پیدا کردید.از اینکه
            {process.env.REACT_APP_BRAND_NAME_FA} را برای ادامه مسیر پیشرفت خود انتخاب کردید بسیار سپاسگزاریم.
          </p>

          <p className='text-color-paragraph'>شماره پیگیری : {query?.ref_id || '--'}</p>
        </div>

        <ZpLink to={routes.LINKS.link} className='w-full'>
          <ZpButton type='primary' className='w-full' buttonIcon={<FiArrowLeft size={22} />}>
            شروع مسیر حرفه‌ای
          </ZpButton>
        </ZpLink>
      </div>
    </Container>
  );
};

export default PaymentReturnSuccess;
