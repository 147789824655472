import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import useRoute from 'core/hooks/useRoute';
import { IGatewayLink } from 'core/types/Models/gatewayLinkType';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { getImageUrl, separator } from 'core/utils/utils';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import GatewayLinkItemAction from 'components/Gateway/GatewayLinks/List/components/GatewayLinkItemAction';
import zarinpalIcon from 'assets/images/zarinPalIcon.svg';

const GatewayLinkItem: FC<IGatewayLink> = ({
  icon,
  title,
  id,
  description,
  total_amount_success_transactions,
  transactions_success_count,
  payment_ceiling,
  expired_at_fa,
  time_expired_at,
}) => {
  const navigate = useNavigate();
  const { routes } = useRoute();

  const isSimpleGatewayLink = !icon?.url;

  return (
    <div className='bg-white-color border border-border-color rounded-lg px-4 py-3'>
      <div className='flex justify-between items-center w-full'>
        <div className='flex items-center space-x-3 space-x-reverse'>
          <ZpImage src={isSimpleGatewayLink ? zarinpalIcon : getImageUrl(icon?.url)} className='rounded-full border border-border-color' width={40} height={40} />
          <span>{title}</span>
        </div>

        <GatewayLinkItemAction icon={icon} gatewayLinkId={id} />
      </div>

      <div className='my-5'>
        {!isSimpleGatewayLink && (
          <div className='py-4 border-b border-border-color flex items-center justify-between'>
            <div className='text-color-placeholder'>توضیحات</div>
            <div className='max-w-[20ch] line-clamp-1'>{description}</div>
          </div>
        )}

        {!isSimpleGatewayLink && (
          <div className='py-4 border-b border-border-color flex items-center justify-between'>
            <div className='text-color-placeholder'>سقف مبلغ لینک</div>
            {payment_ceiling ? <div>{separator(payment_ceiling)} (تومان)</div> : 'نامحدود'}
          </div>
        )}

        <div className='py-4 border-b border-border-color flex items-center justify-between'>
          <div className='text-color-placeholder'>تعداد تراکنش‌های موفق</div>
          <div>{transactions_success_count}</div>
        </div>

        <div className='py-4 border-b border-border-color flex items-center justify-between'>
          <div className='text-color-placeholder'>مجموع دریافتی</div>
          <div>{total_amount_success_transactions} (تومان)</div>
        </div>

        {!isSimpleGatewayLink && (
          <div className='py-4 flex items-center justify-between'>
            <div className='text-color-placeholder'>تاریخ انقضا</div>
            {expired_at_fa ||
              (time_expired_at ? (
                <div>
                  {expired_at_fa} {time_expired_at ? ` - ${time_expired_at}` : ''}
                </div>
              ) : (
                'نامحدود'
              ))}
          </div>
        )}
      </div>

      <div
        className='text-primary-color-main flex items-center space-x-1 mb-2 space-x-reverse cursor-pointer hover:underline'
        onClick={() => navigate(routes.GATEWAY_LINK_INVOICES.link(id))}
      >
        <span>لیست تراکنش‌ها</span>
        <MdOutlineKeyboardArrowLeft size={18} />
      </div>
    </div>
  );
};

export default GatewayLinkItem;
