import { FC, memo, ReactNode } from 'react';
import { useGatewayDetailQuery } from 'core/hooks/react-query/useGatewayDetailQuery';
import { useGatewayLinkDetailQuery } from 'core/hooks/react-query/useGatewayLinkDetailQuery';
import MainLayout from 'components/_Core/Layouts/Main/MainLayout';
import MainHeaderLayout from 'components/_Core/Layouts/Main/MainHeader/MainHeaderLayout';
import MainContentLayout from 'components/_Core/Layouts/Main/MainContentLayout/MainContentLayout';
import PanelHomeIndexFooterNavbar from 'components/_Core/Layouts/Panel/PanelHomeIndex/PanelHomeIndexFooterNavbar/PanelHomeIndexFooterNavbar';

interface IGatewayLayout {
  children: ReactNode;
  headerTitle: string;
  backUrl: string;
  enableGatewayDetail?: boolean;
  enableGatewayLinkDetail?: boolean;
  showPanelDashboardFooterNavbar?: boolean;
}

const PanelHomeGatewayLayout: FC<IGatewayLayout> = ({
  children,
  backUrl,
  headerTitle,
  enableGatewayDetail = true,
  enableGatewayLinkDetail = true,
  showPanelDashboardFooterNavbar = true,
}) => {
  const { isFetching: isLoadingGatewayDetail } = useGatewayDetailQuery(enableGatewayDetail);
  const { isFetching: isLoadingGatewayLinkDetail } = useGatewayLinkDetailQuery(enableGatewayLinkDetail);

  return (
    <MainLayout>
      <MainHeaderLayout headerTitle={headerTitle} backUrlArrow={backUrl} headerLading={isLoadingGatewayLinkDetail || isLoadingGatewayDetail} />

      {showPanelDashboardFooterNavbar ? <MainContentLayout>{children}</MainContentLayout> : children}

      {showPanelDashboardFooterNavbar && <PanelHomeIndexFooterNavbar showQuickAccessButton={false} />}
    </MainLayout>
  );
};

export default memo(PanelHomeGatewayLayout);
