import { useEffect, useState } from 'react';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';

const useGatewayInvoiceSearchFilter = () => {
  // state
  const [searchValue, setSearchValue] = useState('');

  // hooks
  const { query, setUrlParam, filterParams, deleteOneQueryParam } = useQueryUrlParam();

  const onSubmitSearchHandler = () => {
    setUrlParam({ ...query, 'filter[user.name]': searchValue.trim() });
  };

  useEffect(() => {
    query['filter[user.name]'] && setSearchValue(query['filter[user.name]']);
  }, [filterParams]);

  return { searchValue, setSearchValue, onSubmitSearchHandler, deleteOneQueryParam, hasTextFilter: !!query['filter[user.name]'] };
};

export default useGatewayInvoiceSearchFilter;
