import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface IContainer {
  children: ReactNode;
  className?: string;
  noPadding?: boolean;
  onClick?: () => void;
}

const Container: FC<IContainer> = ({ noPadding, children, className, onClick }) => {
  return (
    <div onClick={onClick} className={twMerge(`max-w-[1200px] w-full mx-auto ${noPadding ? 'px-0' : 'sm:px-7 px-4'} ${className}`)}>
      {children}
    </div>
  );
};

export default Container;
