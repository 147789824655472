import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryOnSuccessHandler from 'core/helpers/queryOnSuccessHandler/useQueryOnSuccessHandler';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import useRoute from 'core/hooks/useRoute';

export const useGatewayCreateQuery = () => {
  const { onSuccessHandler } = useQueryOnSuccessHandler();
  const { query, setUrlParam } = useQueryUrlParam();
  const { linkId, username } = useRoute();
  const queryClient = useQueryClient();

  return useMutationHook([endPointUrls.GATEWAY(linkId + '')], (data: any) => api.post(endPointUrls.GATEWAY(linkId + ''), data), {
    onSuccess: (res: any) => {
      onSuccessHandler(res);
      setUrlParam({ ...query, gatewayId: res?.data?.data?.id });
      queryClient.invalidateQueries([endPointUrls.LINK_GET_BY_USERNAME(username + '')]);
    },
  }) as UseMutationResult;
};
