import { FC, ReactNode } from 'react';
import Container from 'components/_Core/Layouts/Container/Container';
import { twMerge } from 'tailwind-merge';

interface IMainFooterLayout {
  children: ReactNode;
  className?: string;
}

const MainFooterLayout: FC<IMainFooterLayout> = ({ className = '', children }) => {
  return <Container className={twMerge(`border-t border-t-gray-color-300 bg-white-color h-[71px] 448:sticky fixed w-full bottom-0 z-50 ${className}`)}>{children}</Container>;
};

export default MainFooterLayout;
