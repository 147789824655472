import { FC } from 'react';
import ZpDrawer from 'components/_Core/UI/Drawer/ZpDrawer';
import ZpRadioButtonGroup from 'components/_Core/UI/RadioButton/ZpRadioButtonGroup';
import BankAccountCreateSheba from 'components/Gateway/components/AccountBank/components/BankAccountCreateSheba';
import BankAccountCreateConvertCardToSheba from 'components/Gateway/components/AccountBank/components/BankAccountCreateConvertCardToSheba';
import ZpInformation from 'components/_Core/UI/Information/ZpInformation';

interface IAccountBankCreate {
  modal: boolean;
  hasOneAcceptedBankAccount: boolean;
  hideModal: () => void;
  bankCardType: 1 | 2;
  setBankCardType: (val: 1 | 2) => void;
}

const AccountBankCreate: FC<IAccountBankCreate> = ({ hideModal, modal, setBankCardType, bankCardType, hasOneAcceptedBankAccount }) => {
  return (
    <ZpDrawer
      height={hasOneAcceptedBankAccount ? 'auto' : '100%'}
      roundedTop={hasOneAcceptedBankAccount}
      open={modal}
      onClose={hideModal}
      placement='bottom'
      closable={false}
      className='bg-white-natural-color'
    >
      {!hasOneAcceptedBankAccount && (
        <div className='bg-gray-color-100 dark:bg-natural-color-50 rounded-lg border-t-2 border-l-2 border-r-2 border-primary-color-main border-b-4 mb-5'>
          <ZpInformation>
            <p className='text-color-placeholder'>
              <span className='block text-color-main mb-2 text-base'>توجه</span>
              نام، نام خانوادگی و کد ملی شما باید با شماره شبا یا شماره کارت وارد شده مطابقت داشته باشید در غیر اینصورت درگاه پرداخت شما تایید و فعال نخواهد شد.
            </p>
          </ZpInformation>
        </div>
      )}

      <ZpRadioButtonGroup
        className='pt-5'
        onChange={(e) => setBankCardType(e.target.value)}
        value={bankCardType}
        options={[
          { label: 'شماره شبا', value: 1 },
          { label: 'شماره کارت', value: 2 },
        ]}
      />

      {bankCardType === 1 ? (
        <BankAccountCreateSheba hasOneAcceptedBankAccount={hasOneAcceptedBankAccount} hideModal={hideModal} />
      ) : (
        <BankAccountCreateConvertCardToSheba hasOneAcceptedBankAccount={hasOneAcceptedBankAccount} hideModal={hideModal} />
      )}
    </ZpDrawer>
  );
};

export default AccountBankCreate;
