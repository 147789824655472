import { useQueryClient } from '@tanstack/react-query';
import useRoute from 'core/hooks/useRoute';
import { setAuthenticated } from 'core/store/slice/mainInfo/mainInfoSlice';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { routes } = useRoute();
  const queryClient = useQueryClient();

  const logout = () => {
    Cookies.remove('jwt', { path: '/', domain: `.${window.location.hostname}` });
    Cookies.remove('dataLogin', { path: '/', domain: `.${window.location.hostname}` });
    queryClient.clear();
    dispatch(setAuthenticated(false));
    navigate(routes.LOGIN.link());
  };

  return { logout };
};

export default useLogout;
