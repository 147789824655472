import { FC } from 'react';
import { IBlockDetail } from 'core/types/Models/linkType';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { getImageUrl } from 'core/utils/utils';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import useTemplatePreview from 'components/_Core/UI/PreviewTemplate/hooks/useTemplatePreview';
import { twMerge } from 'tailwind-merge';

interface IPreviewTemplateBlocks {
  block?: IBlockDetail;
  staticIcon?: string;
}

const PreviewTemplateBlocks: FC<IPreviewTemplateBlocks> = ({ block, staticIcon }) => {
  const { blockStyles, blockBackgroundImage, has_block_icon, blockBorder, blockContentCenter, has_block_arrow, blockPadding } = useTemplatePreview('100%');

  return (
    <div
      style={{
        ...blockStyles,
        ...blockBackgroundImage.object,
      }}
      className={twMerge(`w-full p-2 space-x-3 space-x-reverse flex items-center justify-between ${blockBorder} ${blockPadding}`)}
    >
      <div className={`flex items-center space-x-4 space-x-reverse flex-1 ${blockContentCenter}`}>
        {has_block_icon && (
          <div className='max-w-[40px] w-full flex'>
            <ZpImage alt='' className='rounded-full h-full w-full' width={40} height={40} src={block?.type?.icon_url ? getImageUrl(block?.type?.icon_url) : staticIcon} />
          </div>
        )}
        <span className='line-clamp-2 text-block_name_color text-lg'>{block?.title || ''}</span>
      </div>

      {has_block_arrow && <MdOutlineKeyboardArrowLeft size={25} color='var(--block_name_color)' />}
    </div>
  );
};

export default PreviewTemplateBlocks;
