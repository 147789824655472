import { Form } from 'antd';
import { FormEvent, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useUpdateGatewayLinkQuery } from 'components/Gateway/GatewayLinks/Create/Professional/StepTwo/hooks/react-query/useUpdateGatewayLinkQuery';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import toastHandler from 'core/helpers/toast/toast';
import { useUpdateGatewayLinkImageQuery } from 'components/Gateway/GatewayLinks/Create/Professional/StepTwo/hooks/react-query/useUpdateGatewayLinkImageQuery';
import { useGatewayLinkDetailQuery } from 'core/hooks/react-query/useGatewayLinkDetailQuery';
import { useDeleteGatewayLinkImageQuery } from 'components/Gateway/hooks/react-query/useDeleteGatewayLinkImageQuery';

const useCreateGatewayLinkStepTwo = () => {
  //hooks
  const [updateLinkStepTwoForm] = Form.useForm();
  const { query } = useQueryUrlParam();
  const imageRef = useRef<HTMLInputElement>(null);

  // query
  const { mutate: updateGatewayLink, isLoading: isLoadingUpdateGatewayLinkStepTwo } = useUpdateGatewayLinkQuery('GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_3');
  const { data: gatewayLinkDetail, isFetching: isLoadingGatewayLinkDetail } = useGatewayLinkDetailQuery(true);
  const { mutate: uploadProfileImage, isLoading: isLoadingUploadProfileImage, data: imageUploadData, reset: resetUpdatedGatewayLinkImageData } = useUpdateGatewayLinkImageQuery();
  const { isLoading: isLoadingDeleteGatewayLinkImage, mutate: deleteGatewayLinkImage } = useDeleteGatewayLinkImageQuery();

  // store
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  const onFinishUpdateGatewayLinkStepTwoHandler = (values: { username: string; title_fa: string }) => {
    updateGatewayLink(values);
  };

  useEffect(() => {
    query?.gatewayLinkId && updateLinkStepTwoForm.setFieldsValue({ icon_id: gatewayLinkDetail?.data.icon?.id });
  }, [gatewayLinkDetail]);

  useEffect(() => {
    updateLinkStepTwoForm.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors.length]);

  // upload image
  const onUpdateImageHandler = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files?.[0]?.size && e.currentTarget?.files?.[0]?.size > 5120000) {
      toastHandler('error', 'سایز عکس باید کمتر از 5 مگابایت باشد');
    } else {
      const files = new FormData();
      if (e.currentTarget?.files?.[0]) {
        files.append('cover_image', e.currentTarget?.files?.[0]);
        uploadProfileImage(files as any);
      }
    }
  };

  const onDeleteGatewayLinkImageHandler = () => {
    deleteGatewayLinkImage({}, { onSuccess: () => resetUpdatedGatewayLinkImageData() });
  };

  return {
    updateLinkStepTwoForm,
    gatewayLinkDetail,
    imageUploadData,
    onFinishUpdateGatewayLinkStepTwoHandler,
    isLoadingUpdateGatewayLinkStepTwo,
    isLoading: isLoadingGatewayLinkDetail,
    isLoadingUploadProfileImage,
    imageRef,
    onUpdateImageHandler,
    onDeleteGatewayLinkImageHandler,
    isLoadingDeleteGatewayLinkImage,
  };
};

export default useCreateGatewayLinkStepTwo;
