import ZpPremiumBadge from 'components/_Core/UI/PremiumBadge/ZpPremiumBadge';
import profileIcon from 'assets/images/profile.svg';
import ZpImage from 'components/_Core/UI/Image/ZpImage';

const PanelMasterFooterNavbarImage = () => {
  return (
    <ZpPremiumBadge>
      <ZpImage src={profileIcon} />
    </ZpPremiumBadge>
  );
};

export default PanelMasterFooterNavbarImage;
