import { useEffect, useState } from 'react';
import useMasterQueryUrlParam from './useMasterQueryUrlParam';

const useQueryUrlParam = (number = true) => {
  const { filterQuery, ...props } = useMasterQueryUrlParam(number);

  const [filterParams, setFilterParams] = useState(filterQuery);

  useEffect(() => {
    setFilterParams(filterQuery);
  }, [filterQuery]);

  return { ...props, filterParams };
};

export default useQueryUrlParam;
