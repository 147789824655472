import { FC } from 'react';
import faq from 'assets/images/icon/faq.svg';
import ZpBlockItem from 'components/_Core/UI/BlockList/ZpBlockItem';
import { IBlockDetail } from 'core/types/Models/linkType';
import useTemplatePreview from 'components/_Core/UI/PreviewTemplate/hooks/useTemplatePreview';
import { twMerge } from 'tailwind-merge';

interface IPreviewTemplateFaq {
  block: IBlockDetail;
}

const PreviewTemplateFaq: FC<IPreviewTemplateFaq> = ({ block }) => {
  const {
    has_block_icon,
    blockBorder,
    blockBackgroundImage,
    has_block_arrow,
    has_block_border,
    faqBlockPadding,
    blockContentCenter,
    blockBackDropFilterStyle,
    blockBorderRadiusStyle,
    blockStyles,
  } = useTemplatePreview('100%');

  return (
    <ZpBlockItem
      arrowIconColor='var(--block_name_color)'
      panelClassName={twMerge(`${blockBorder} ${faqBlockPadding}`)}
      customIconUrl={faq}
      key={block.id}
      block={block}
      hasBlockIcon={has_block_icon}
      hasBlockArrow={has_block_arrow}
      hasBlockBorder={has_block_border}
      bordered={false}
      blockNameColor='text-block_name_color'
      hasBlockImage={true}
      centerContent={blockContentCenter}
      panelStyleObject={{ ...blockBackDropFilterStyle, ...blockBorderRadiusStyle, ...blockBackgroundImage.object, ...blockStyles }}
      forceRender={false}
    >
      <p className={`text-justify text-block_name_color ${blockContentCenter}`}>{block.answer}</p>
    </ZpBlockItem>
  );
};

export default PreviewTemplateFaq;
