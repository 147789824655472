import { FC, ReactNode, useLayoutEffect, useRef } from 'react';
import ZpDatePicker, { DatePickerType } from 'components/_Core/UI/DatePicker/ZpDatePicker';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { ClipLoader } from 'react-spinners';

interface IZpDatePickerLabel extends DatePickerType {
	label: string | ReactNode;
	ltr?: boolean;
	className?: string;
	required?: boolean;
	isLoading?: boolean;
	value?: any;
}

const ZpDatePickerLabel: FC<IZpDatePickerLabel> = ({ ltr = false, label, className, isLoading, required, ...props }) => {
	const { lang } = useSelector((store: RootState) => store.settingStore);
	const labelRef = useRef<HTMLLabelElement | null>(null);

	useLayoutEffect(() => {
		if (props.value) labelRef.current?.classList?.add('active-float-label');
		else labelRef.current?.classList?.remove('active-float-label');
	}, [props.value]);

	return (
		<div className='float-group relative'>
			<ZpDatePicker className={className} {...props} />
			<label ref={labelRef} className={`${props?.disabled ? 'date-picker-disable' : ''} float-label ${lang === 'fa' ? (ltr ? 'left-3' : 'right-3') : 'left-3'}`}>
				{required && <span className='text-error-color-300 inline-block relative top-1 mx-1'>*</span>}
				{label}
			</label>

			{isLoading && (
				<span className='absolute left-5 top-1/2 -translate-y-1/2 flex'>
					<ClipLoader color='var(--primary-color-main)'  size={14} />
				</span>
			)}
		</div>
	);
};

ZpDatePickerLabel.displayName = 'ZpDatePickerLabel';

export default ZpDatePickerLabel;
