import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import api from 'core/services/fetch-api/useApi';
import { useNavigate } from 'react-router-dom';
import useQueryOnErrorHandler from 'core/helpers/queryOnErrorHandler/useQueryOnErrorHandler';
import useRoute from '../useRoute';
import { IGatewayDetail } from 'core/types/Models/gatewayType';
import { useDispatch } from 'react-redux';
import { setGatewayDetail } from 'core/store/slice/gateway/gatewaySlice';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';

export const useGatewayDetailQuery = (enableGatewayDetail?: boolean) => {
  const { onErrorHandler } = useQueryOnErrorHandler();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { routes, gatewayId } = useRoute();
  const { query } = useQueryUrlParam();

  return useQueryHook([endPointUrls.GATEWAY_DETAIL(gatewayId + '')], () => api.get(endPointUrls.GATEWAY_DETAIL(query?.gatewayId || gatewayId + '')), {
    select: (res: any) => res.data,
    enabled: enableGatewayDetail && (!!gatewayId || !!query?.gatewayId),
    onSuccess: (res) => {
      dispatch(setGatewayDetail(res));
    },

    onError: async (err: any) => {
      onErrorHandler(err, true);

      if (err.response.status === 404) {
        navigate(routes.NOTFOUND);
      }
    },
  }) as UseQueryResult<IGatewayDetail, Error>;
};
