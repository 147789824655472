import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import { validateAge } from 'core/utils/utils';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import { FC } from 'react';
import useBankAccountCreate from 'components/Gateway/components/AccountBank/hooks/useBankAccountCreate';
import ZpDatePickerLabel from 'components/_Core/UI/DatePicker/ZpDatePickerLabel';
import useValidationFormMessages from 'core/hooks/useValidationFormMessages';
import Container from 'components/_Core/Layouts/Container/Container';

const BankAccountCreateSheba: FC<{ hideModal: () => void; hasOneAcceptedBankAccount: boolean }> = ({ hideModal, hasOneAcceptedBankAccount }) => {
  const { onFinishCreateShebaHandler, form, isLoadingAccountBank } = useBankAccountCreate(hideModal);
  const { nationalValidation, required, shebaNumberValidation } = useValidationFormMessages();

  return (
    <>
      <ZpForm form={form} onFinish={onFinishCreateShebaHandler} className='mb-16'>
        <ZpWrapper singleColumn className='pt-5'>
          <ZpFormItem name='iban' rules={shebaNumberValidation()}>
            <ZpInputLabel required textLeftFull label='شماره شبا' suffix='IR' />
          </ZpFormItem>

          {!hasOneAcceptedBankAccount && (
            <ZpFormItem name='first_name' rules={[required('نام شناسنامه‌ای خود')]}>
              <ZpInputLabel required label='نام' />
            </ZpFormItem>
          )}

          {!hasOneAcceptedBankAccount && (
            <ZpFormItem name='last_name' rules={[required('نام خانوادگی دقیق شناسنامه‌ای خود')]}>
              <ZpInputLabel required label='نام خانوادگی' />
            </ZpFormItem>
          )}

          {!hasOneAcceptedBankAccount && (
            <ZpFormItem name='ssn' rules={nationalValidation(true)}>
              <ZpInputLabel required label='کدملی' />
            </ZpFormItem>
          )}

          {!hasOneAcceptedBankAccount && (
            <ZpFormItem
              name='birthday'
              rules={[
                required('تاریخ تولد خود'),
                () => ({
                  validator(_, value) {
                    const isValid = validateAge(value);
                    if (isValid) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error('حداقل سن مجاز، بالای 18 سال باشد'));
                    }
                  },
                }),
              ]}
            >
              <ZpDatePickerLabel required label='انتخاب تاریخ تولد' />
            </ZpFormItem>
          )}
        </ZpWrapper>
      </ZpForm>

      <div className='bg-white-color absolute bottom-0 w-full right-0 py-3 z-[9999]'>
        <Container className='flex justify-between items-center space-x-reverse space-x-5'>
          <ZpButton type='primary' ghost className='w-full' htmlType='button' onClick={hideModal}>
            انضراف
          </ZpButton>
          <ZpButton isLoading={isLoadingAccountBank} type='primary' className='w-full' htmlType='submit' onClick={() => form.submit()}>
            ایجاد حساب بانکی
          </ZpButton>
        </Container>
      </div>
    </>
  );
};

export default BankAccountCreateSheba;
