import { FC, memo, ReactNode } from 'react';
import MainHeaderLayout from 'components/_Core/Layouts/Main/MainHeader/MainHeaderLayout';
import useRoute from 'core/hooks/useRoute';
import MainLayout from 'components/_Core/Layouts/Main/MainLayout';

interface IPanelUpdateLinkLayout {
  children: ReactNode;
}

const PanelUpdateLinkLayout: FC<IPanelUpdateLinkLayout> = ({ children }) => {
  const { routes } = useRoute();

  return (
    <MainLayout>
      <MainHeaderLayout headerTitle='ویرایش لینک' backUrlArrow={routes.LINKS.link} />

      <>{children}</>
    </MainLayout>
  );
};

export default memo(PanelUpdateLinkLayout);
