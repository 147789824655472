import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { blockBackgroundImageConfig, getImageUrl } from 'core/utils/utils';

const useTemplatePreview = (blockBgImageSize: 'contain' | string = 'contain') => {
  // store
  const { templateDetail } = useSelector((store: RootState) => store.templateStore);
  const { styles, background } = templateDetail || {};
  const { image: backgroundImageBody, default_color: backgroundColorBody } = background || {};
  const { has_block_icon, block_box_shadow, block_border_radius, has_block_arrow, has_block_border, has_block_blur, block_background_image, colors } = styles || {};
  const { block_background_color } = colors || {};
  const blockBackgroundImage = blockBackgroundImageConfig(getImageUrl(block_background_image), blockBgImageSize);

  // utils
  const blockStyles = {
    borderRadius: block_border_radius || '0',
    backdropFilter: has_block_blur ? 'blur(10px)' : 'none',
    boxShadow: block_box_shadow || 'none',
    backgroundColor: block_background_image ? 'transparent' : block_background_color,
  };
  const blockStyleText =
    `border-radius:${block_border_radius || '0'} !important; box-shadow:${block_box_shadow || 'none'} !important; backdrop-filter:${
      has_block_blur ? 'blur(10px)' : 'none'
    } !important; background-color: ${block_background_image ? 'transparent' : block_background_color} !important;` + blockBackgroundImage.cssText;
  const blockBorder = has_block_border ? 'border border-block_border_color' : '';
  const blockContentCenter = !has_block_arrow && !has_block_icon ? 'justify-center flex' : '';
  const blockPadding = !has_block_arrow && !has_block_icon ? 'p-3.5' : '';
  const faqBlockPadding = !has_block_arrow && !has_block_icon ? 'p-1.5' : '';
  const blockBorderRadiusStyle = { borderRadius: block_border_radius || '0' };
  const blockBackDropFilterStyle = { backdropFilter: has_block_blur ? 'blur(10px)' : 'none' };

  return {
    backgroundColorBody,
    backgroundImageBody: getImageUrl(backgroundImageBody),
    blockBackgroundImage,
    blockStyles,
    has_block_icon,
    blockBorder,
    blockContentCenter,
    blockBorderRadiusStyle,
    blockStyleText,
    has_block_arrow,
    has_block_border,
    blockBackDropFilterStyle,
    blockPadding,
    faqBlockPadding,
  };
};

export default useTemplatePreview;
