import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import api from 'core/services/fetch-api/useApi';
import { IAccountBankList } from 'core/types/Models/accountBankType';

export const useAccountBankListQuery = () => {
  return useQueryHook(
    [endPointUrls.LINK_ACCOUNT_BANK],
    () => api.get(endPointUrls.LINK_ACCOUNT_BANK),
    {
      select: (res: any) => res.data,
    },
    false
  ) as UseQueryResult<IAccountBankList, Error>;
};
