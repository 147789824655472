import React from 'react';
import { LAT_INITIAL, LNG_INITIAL } from 'core/constants/constant';
import { pinIcon } from 'core/constants/pinIcon';
import { IBlockDetail } from 'core/types/Models/linkType';
import ZpMap from 'components/_Core/UI/Map/ZpMap';
import ZpMapMarker from 'components/_Core/UI/Map/ZpMapMarker';

const MapNavigation = ({ block }: { block: IBlockDetail }) => {
  return (
    <ZpMap
      scrollWheelZoom={false}
      firstRender={!(block?.lng && block?.lat)}
      zoomLevel={15}
      height='h-[200px]'
      initialCenter={[block?.lat ? +block?.lat : LAT_INITIAL, block?.lng ? +block?.lng : LNG_INITIAL]}
    >
      <ZpMapMarker icon={pinIcon} position={[block?.lat ? +block?.lat : LAT_INITIAL, block?.lng ? +block?.lng : LNG_INITIAL]} />
    </ZpMap>
  );
};

export default MapNavigation;
