import { IBlock } from 'core/types/Models/linkType';
import { getBlockTypeDetail } from 'core/utils/utils';
import { FC } from 'react';
import BlockDraggableChild from './BlockDraggableChild';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSensors, useSensor, MouseSensor, TouchSensor, DndContext, closestCenter, DragEndEvent } from '@dnd-kit/core';
import BlockDividerDraggable from './BlockDividerDraggable';

interface IBlockDraggableMaster {
  masterBlocks: IBlock[];
  masterBlocksState: IBlock[];
  handleDrag: (event: DragEndEvent) => void;
}

const BlockDraggableMaster: FC<IBlockDraggableMaster> = ({ masterBlocks, handleDrag, masterBlocksState }) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDrag}>
      <SortableContext items={masterBlocks} strategy={verticalListSortingStrategy}>
        <div className='flex flex-col items-center space-y-16'>
          {masterBlocksState?.map((block) => {
            const blockType = getBlockTypeDetail(block);

            return block.type === 'App\\Models\\LinkApp\\Divider'
              ? block.data?.length > 0 && <BlockDividerDraggable key={block.id} masterBlocks={block} childBlocks={block.data} />
              : block.data?.length > 0 && <BlockDraggableChild key={block.id} blockType={blockType} links={masterBlocks} masterBlocks={block} childBlocks={block.data} />;
          })}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default BlockDraggableMaster;
