import { FormInstance } from 'antd';
import { IBlockDetail } from 'core/types/Models/linkType';
import { useEffect } from 'react';
import { useUpdateSocialMediaQuery } from './react-query/useUpdateSocialMediaQuery';
import { useDeleteSocialMediaQuery } from './react-query/useDeleteSocialMediaQuery';
import useModal from 'core/hooks/useModal';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

const useUpdateSocialMedia = (form: FormInstance, block: IBlockDetail | undefined) => {
  // hooks
  const { hideModal, modal, showModal } = useModal();

  // query
  const { mutate: updateSocialMedia, isLoading: isLoadingUpdateSocialMedia } = useUpdateSocialMediaQuery();
  const { mutate: deleteSocialMedia, isLoading: isLoadingDeleteSocialMedia } = useDeleteSocialMediaQuery();

  // store
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  useEffect(() => {
    block && form.setFieldsValue({ ...block });
  }, []);

  useEffect(() => {
    const hasError = apiCatcherFormErrors.every((item) => item?.payload?.id === block?.id);
    hasError && form.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors]);

  const onFinishUpdateHandler = (values: { title: string; username: string }) => {
    updateSocialMedia({ ...values, socialmediatype_id: block?.type?.id, order: block?.order, id: block?.id });
  };

  const onDeleteHandler = () => {
    deleteSocialMedia({ id: block?.id });
  };

  return { onFinishUpdateHandler, isLoadingUpdateSocialMedia, isLoadingDeleteSocialMedia, onDeleteHandler, hideModal, modal, showModal };
};

export default useUpdateSocialMedia;
