import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import ZpDrawer from 'components/_Core/UI/Drawer/ZpDrawer';
import useModal from 'core/hooks/useModal';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { Form } from 'antd';
import { extractNumberFromString, numberRegexWithCommaFormat, separator } from 'core/utils/utils';
import ZpInputNumberLabel from 'components/_Core/UI/Input/ZpInputNumberLabel';
import { useEffect } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const GatewayInvoiceStatus = () => {
  // hooks
  const [form] = Form.useForm();
  const { modal, showModal, hideModal } = useModal();
  const { query, setUrlParam, deleteOneQueryParam, filterParams } = useQueryUrlParam();

  const result =
    query['filter[amount_between]']?.[0] && query['filter[amount_between]']?.[1]
      ? `از ${separator(query['filter[amount_between]']?.[0])} الی ${separator(query['filter[amount_between]']?.[1])}`
      : query['filter[amount_between]']?.[0] && !query['filter[amount_between]']?.[1]
      ? `از ${separator(query['filter[amount_between]']?.[0])}`
      : !query['filter[amount_between]']?.[0] && query['filter[amount_between]']?.[1]
      ? `الی ${separator(query['filter[amount_between]'][1])}`
      : '';

  const onFinish = (values: { from: string; to: string }) => {
    (values.to || values.from) &&
      setUrlParam({ ...query, 'filter[amount_between]': [values.from ? extractNumberFromString(values.from) : '', values.to ? extractNumberFromString(values.to) : ''] });
  };

  useEffect(() => {
    (query['filter[amount_between]']?.[0] || query['filter[amount_between]']?.[1]) &&
      form.setFieldsValue({ from: query['filter[amount_between]'][0], to: query['filter[amount_between]'][1] });
  }, [filterParams]);

  return (
    <>
      <ZpDrawer
        roundedTop
        className='bg-white-natural-color'
        height='auto'
        placement='bottom'
        open={modal}
        onClose={hideModal}
        title={<span className='text-lg'>مبلغ تراکنش</span>}
      >
        <ZpForm form={form} onFinish={onFinish} className='mb-0'>
          <ZpWrapper singleColumn>
            <ZpFormItem rules={[{ pattern: numberRegexWithCommaFormat, message: 'مبلغ شامل عدد می‌باشد.' }]} name='from'>
              <ZpInputNumberLabel thousandSeparator label='از' suffixLabel='تومان' />
            </ZpFormItem>

            <ZpFormItem rules={[{ pattern: numberRegexWithCommaFormat, message: 'مبلغ شامل عدد می‌باشد.' }]} name='to'>
              <ZpInputNumberLabel thousandSeparator label='تا' suffixLabel='تومان' />
            </ZpFormItem>

            <ZpButton htmlType='submit' className='w-full' type='primary'>
              اعمال فیلتر
            </ZpButton>
          </ZpWrapper>
        </ZpForm>
      </ZpDrawer>

      {query['filter[amount_between]'] ? (
        <div className='border rounded-lg py-3 px-4 cursor-pointer border-primary-color-main' onClick={showModal}>
          <span className='flex items-center space-x-5 space-x-reverse justify-between text-primary-color-main'>
            <span>{result}</span>
            <AiOutlineCloseCircle
              size={20}
              onClick={(e) => {
                e.stopPropagation();
                deleteOneQueryParam('filter[amount_between]');
              }}
            />
          </span>
        </div>
      ) : (
        <div className='border border-border-color rounded-lg py-3 px-4 cursor-pointer hover:border-primary-color-main' onClick={showModal}>
          <span className='flex items-center space-x-5 space-x-reverse justify-center'>
            <span>مبلغ</span>
            <MdOutlineKeyboardArrowDown size={20} />
          </span>
        </div>
      )}
    </>
  );
};

export default GatewayInvoiceStatus;
