import { FC } from 'react';
import ZpDrawer from 'components/_Core/UI/Drawer/ZpDrawer';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import GatewayIcon from 'components/_Core/UI/Icon/GatewayIcon';
import useRoute from 'core/hooks/useRoute';
import { useNavigate } from 'react-router';

interface IGatewayBlockType {
  modal: boolean;
  hideModal: () => void;
}

const GatewayBlockType: FC<IGatewayBlockType> = ({ hideModal, modal }) => {
  const { gatewayCreateProfessionalDynamicRoute, gatewayCreateSimpleDynamicRoute } = useRoute();
  const navigate = useNavigate();

  return (
    <ZpDrawer roundedTop height='auto' className='' placement='bottom' closable={false} open={modal} onClose={hideModal}>
      <div className='flex space-x-3 space-x-reverse mb-10'>
        <GatewayIcon />

        <div>
          <h2 className='text-lg font-bold'>لینک پرداخت</h2>
          <p className='text-color-paragraph'>امکان پرداخت وجه را برای کاربرانتان فراهم کنید.</p>
        </div>
      </div>

      <div className='flex flex-col space-y-4'>
        <ZpButton onClick={() => navigate(gatewayCreateSimpleDynamicRoute)} type='primary' ghost>
          لینک پرداخت ساده
        </ZpButton>
        <ZpButton onClick={() => navigate(gatewayCreateProfessionalDynamicRoute)} type='primary' ghost>
          لینک پرداخت پیشرفته
        </ZpButton>
      </div>
    </ZpDrawer>
  );
};

export default GatewayBlockType;
