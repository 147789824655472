import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { IBlockTypes, IBlockTypesList } from 'core/types/Models/blockType';
import { FC, ReactNode } from 'react';

interface IBlockType {
  showChildModal?: () => void;
  setBlockTypeSelected?: (val: any) => void;
  blockTypes: IBlockTypesList | undefined;
  blockTypeIcon: ReactNode;
  blockTypeTitle?: string;
  blockTypeDescription?: string;
}

const BlockTypes: FC<IBlockType> = ({ showChildModal, setBlockTypeSelected, blockTypes, blockTypeIcon, blockTypeTitle, blockTypeDescription }) => {
  const showChildModalHandler = (value: IBlockTypes) => {
    setBlockTypeSelected?.(value);
    showChildModal && showChildModal();
  };

  return (
    <>
      <div className='flex space-x-3 space-x-reverse mb-5'>
        {blockTypeIcon}
        <div>
          <h2 className='text-lg font-bold'>{blockTypeTitle}</h2>
          <p className='text-color-paragraph'>{blockTypeDescription}</p>
        </div>
      </div>

      <div className='grid grid-cols-3 gap-5'>
        {blockTypes?.data?.map((blockType) => (
          <div
            className='bg-blue-light-color hover:bg-natural-color-300 cursor-pointer h-28 flex items-center justify-center rounded-lg'
            key={blockType.id}
            onClick={() => showChildModalHandler(blockType)}
          >
            <div className='flex flex-col items-center space-y-3'>
              <ZpImage className='rounded-full' src={process.env.REACT_APP_API_FILE_BASE_URL + blockType.icon_url || undefined} width={45} height={45} />
              <b>{blockType.fa_name}</b>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default BlockTypes;
