import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import api from 'core/services/fetch-api/useApi';
import { IGatewayLinkIconList } from 'core/types/Models/gatewayLinkIconType';

export const useGatewayIconListQuery = () => {
  return useQueryHook([endPointUrls.GATEWAY_LINK_ICON], () => api.get(endPointUrls.GATEWAY_LINK_ICON), {
    select: (res: any) => res.data,
  }) as UseQueryResult<IGatewayLinkIconList, Error>;
};
