import { useDeleteConnectionQuery } from 'components/BlocksComponents/ConnectionBlock/hooks/react-query/useDeleteConnectionQuery';
import { useDeleteDividerQuery } from 'components/BlocksComponents/DividerBlock/hooks/react-query/useDeleteDividerQuery';
import { useDeleteExternalLinkQuery } from 'components/BlocksComponents/ExternalLinkBlock/hooks/react-query/useDeleteExternalLinkQuery';
import { useDeleteFaqQuery } from 'components/BlocksComponents/FaqBlock/hooks/react-query/useDeleteFaqQuery';
import { useDeleteMessengerQuery } from 'components/BlocksComponents/MessengerBlock/hooks/react-query/useDeleteMessengerQuery';
import { useDeleteNavigationQuery } from 'components/BlocksComponents/NavigationBlock/hooks/react-query/useDeleteNavigationQuery';
import { useDeleteSocialMediaQuery } from 'components/BlocksComponents/SocialMediaBlock/hooks/react-query/useDeleteSocialMediaQuery';
import useModal from 'core/hooks/useModal';
import { IBlockTypeDetail } from 'core/types/Models/blockType';
import { useDeleteGatewayLinkQuery } from 'components/Gateway/GatewayLinks/List/hooks/react-query/useDeleteGatewayLinkQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

const useDashboardChild = (blockTypeDetail: IBlockTypeDetail, blockId: number | string) => {
  // hooks
  const { hideModal, modal, showModal } = useModal();

  // store
  const { linkGatewayDetail } = useSelector((store: RootState) => store.linkStore);

  // query
  const { mutate: deleteExternalLink, isLoading: isLoadingDeleteExternalLink } = useDeleteExternalLinkQuery();
  const { mutate: deleteMessenger, isLoading: isLoadingDeleteMessenger } = useDeleteMessengerQuery();
  const { mutate: deleteNavigation, isLoading: isLoadingDeleteNavigation } = useDeleteNavigationQuery();
  const { mutate: deleteSocialMedia, isLoading: isLoadingDeleteSocialMedia } = useDeleteSocialMediaQuery();
  const { mutate: deleteFaq, isLoading: isLoadingDeleteFaq } = useDeleteFaqQuery();
  const { mutate: deleteConnection, isLoading: isLoadingDeleteConnection } = useDeleteConnectionQuery();
  const { mutate: deleteDivider, isLoading: isLoadingDeleteDivider } = useDeleteDividerQuery();
  const { mutate: deleteGatewayLink, isLoading: isLoadingGatewayLink } = useDeleteGatewayLinkQuery(true);

  const onDeleteHandler = () => {
    switch (blockTypeDetail.name) {
      case 'externalLink':
        deleteExternalLink({ id: blockId });
        break;

      case 'messenger':
        deleteMessenger({ id: blockId });
        break;

      case 'navigation':
        deleteNavigation({ id: blockId });
        break;

      case 'socialMedia':
        deleteSocialMedia({ id: blockId });
        break;

      case 'faq':
        deleteFaq({ id: blockId });
        break;

      case 'connection':
        deleteConnection({ id: blockId });
        break;

      case 'divider':
        deleteDivider({ id: blockId });
        break;

      case 'paymentLink':
        deleteGatewayLink({ gatewayLinkId: blockId, gatewayId: linkGatewayDetail?.id });
        break;

      default:
        break;
    }
  };

  return {
    hideModal,
    modal,
    showModal,
    onDeleteHandler,
    isLoadingDelete:
      isLoadingDeleteExternalLink ||
      isLoadingDeleteMessenger ||
      isLoadingDeleteNavigation ||
      isLoadingDeleteSocialMedia ||
      isLoadingDeleteFaq ||
      isLoadingDeleteConnection ||
      isLoadingGatewayLink ||
      isLoadingDeleteDivider,
  };
};

export default useDashboardChild;
