import DashboardLinkList from 'components/DashboardLinkList/DashboardLinkList';
import PanelLinkLayout from 'components/_Core/Layouts/PanelMaster/PanelMasterIndex/PanelMasterIndexLayout';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';

const DashboardLinkListPage = () => {
  return (
    <PanelLinkLayout>
      <HeadMeta tabTitle='داشبورد اصلی' />
      <DashboardLinkList />
    </PanelLinkLayout>
  );
};

export default DashboardLinkListPage;
