import { AnchorHTMLAttributes, FC, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface IZpLink extends LinkProps {
  children: ReactNode;
  external?: boolean;
}

const ZpLink: FC<IZpLink & AnchorHTMLAttributes<HTMLAnchorElement>> = ({ children, to = '', external = false, ...props }) => {
  if (external)
    return (
      <a rel='noreferrer' href={to + ''} target='_blank' {...props}>
        {children}
      </a>
    );

  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

export default ZpLink;
