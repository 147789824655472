import { Form } from 'antd';
import { useCreateLinkQuery } from './react-query/useCreateLinkQuery';
import { useEffect, useState, FormEvent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import { useUpdateLinkQuery } from 'components/CreateLink/StepTwo/hooks/react-query/useUpdateLinkQuery';
import { useCheckUsernameQuery } from './react-query/useCheckUsernameQuery';
import { convertEnglishToPersian } from 'core/utils/utils';

const useCreateLinkStepOne = () => {
  //hooks
  const [createLinkStepOneForm] = Form.useForm();
  const { query } = useQueryUrlParam();

  // state
  const [usernameValue, setUsernameValue] = useState('');

  // query
  const { mutate: createLink, isLoading: isLoadingCreateLinkStepOne } = useCreateLinkQuery();
  const { mutate: updateLink, isLoading: isLoadingUpdateLinkStepOne } = useUpdateLinkQuery('CREATE_LINK_STEP_2');
  const { data: linkDetail, isFetching: isLoadingLinkDetail } = useLinkDetailQuery();
  const { mutate: checkUsername, isLoading: isLoadingCheckUsername, data: checkUsernameResult } = useCheckUsernameQuery();

  // store
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  useEffect(() => {
    query?.username && createLinkStepOneForm.setFieldsValue({ username: query?.username, title_fa: linkDetail?.data.title_fa });
  }, [linkDetail]);

  useEffect(() => {
    createLinkStepOneForm.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors.length]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (usernameValue.length > 2) {
        checkUsername({ username: usernameValue });
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [usernameValue]);

  const onFinishCreateLinkStepOneHandler = (values: { username: string; title_fa: string }) => {
    const formattedValues = { ...values, username: values.username.toLowerCase(), title_fa: convertEnglishToPersian(values.title_fa) };

    if (query?.id) {
      updateLink(formattedValues);
    } else {
      checkUsernameResult?.data.is_available && createLink(formattedValues);
    }
  };

  const onChangeUsernameHandler = (e: FormEvent<HTMLInputElement>) => {
    setUsernameValue(e.currentTarget.value);
  };

  return {
    createLinkStepOneForm,
    onChangeUsernameHandler,
    isLoadingCheckUsername,
    checkUsernameResult: checkUsernameResult?.data,
    usernameValue,
    onFinishCreateLinkStepOneHandler,
    isLoadingCreateLinkStepOne: isLoadingCreateLinkStepOne || isLoadingUpdateLinkStepOne,
    query,
    isLoadingLinkDetail,
  };
};

export default useCreateLinkStepOne;
