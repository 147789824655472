import { FC, MutableRefObject, memo, FormEvent } from 'react';
import { twMerge } from 'tailwind-merge';
import { RiDeleteBin6Line, RiImageAddFill } from 'react-icons/ri';
import { ILinkDetail } from 'core/types/Models/linkType';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { ReactComponent as EditProfileImage } from 'assets/images/icon/editProfileImage.svg';
import { IGatewayLinkDetail } from 'core/types/Models/gatewayLinkType';
import { getImageUrl } from 'core/utils/utils';
import ZpSkeleton from 'components/_Core/UI/Skeleton/ZpSkeleton';
import ZpTooltip from 'components/_Core/UI/Tooltip/ZpTooltip';
import ZpSpinnerFull from 'components/_Core/UI/Spinner/ZpSpinnerFull';

interface IZpUploadImage {
  className?: string;
  title?: string;
  onUpdateImageHandler?: (e: FormEvent<HTMLInputElement>) => void;
  imageRef?: MutableRefObject<any>;
  linkDetail?: ILinkDetail;
  gatewayLinkDetail?: IGatewayLinkDetail;
  isLoading?: boolean;
  isLoadingDeleteImage?: boolean;
  onDeleteImageHandler?: () => void;
}

const ZpUploadImage: FC<IZpUploadImage> = ({
  className,
  isLoadingDeleteImage = false,
  isLoading,
  onDeleteImageHandler,
  imageRef,
  onUpdateImageHandler,
  title = '',
  linkDetail,
  gatewayLinkDetail,
}) => {
  if (isLoading)
    return (
      <div className='text-center'>
        <ZpSkeleton width={150} height={150} circle />
      </div>
    );

  console.log(gatewayLinkDetail, 88);

  return (
    <>
      {linkDetail?.data?.image_profile ? (
        <div className='text-center relative'>
          <ZpImage src={getImageUrl(gatewayLinkDetail?.data?.cover_image || linkDetail?.data?.image_profile)} className='rounded-full' width={150} height={150} />
          <EditProfileImage className='absolute bottom-1 left-1/2 -translate-x-1/2 cursor-pointer' onClick={() => imageRef?.current?.click()} width={35} height={35} />
        </div>
      ) : gatewayLinkDetail?.data?.cover_image ? (
        <ZpSpinnerFull isLoading={isLoadingDeleteImage}>
          <div className='text-center relative'>
            <ZpImage
              loading='lazy'
              src={getImageUrl(gatewayLinkDetail?.data?.cover_image || linkDetail?.data?.image_profile)}
              className='rounded-lg border border-dashed border-border-color'
            />
            <div className='absolute -bottom-3 left-1/2 -translate-x-1/2 space-x-reverse space-x-4'>
              <ZpTooltip title='آپلود عکس' placement='bottom'>
                <EditProfileImage className='cursor-pointer shadow-lg rounded-full' onClick={() => imageRef?.current?.click()} width={35} height={35} />
              </ZpTooltip>

              {onDeleteImageHandler && (
                <ZpTooltip title='حذف عکس' placement='bottom'>
                  <RiDeleteBin6Line onClick={onDeleteImageHandler} className='cursor-pointer bg-white-color rounded-full p-1 shadow-lg' size={34} color='var(--error-color-main)' />
                </ZpTooltip>
              )}
            </div>
          </div>
        </ZpSpinnerFull>
      ) : (
        <div className='flex flex-col space-y-4'>
          {title && <h3>{title}</h3>}

          <div
            className={twMerge(
              `cursor-pointer h-36 rounded-lg bg-natural-color-50 hover:bg-natural-color-200 flex items-center justify-center border-2 border-natural-color-200 border-dashed ${className}`
            )}
            onClick={() => imageRef?.current?.click()}
          >
            <RiImageAddFill size={25} color='var(--natural-color-500)' />
          </div>
        </div>
      )}
      <input onChange={onUpdateImageHandler} type='file' ref={imageRef} accept='image/jpeg, image/jpg, image/png' className='hidden' />
    </>
  );
};

export default memo(ZpUploadImage);
