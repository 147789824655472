import { FormInstance } from 'antd';
import { IBlockDetail } from 'core/types/Models/linkType';
import { useEffect } from 'react';
import { useDeleteGatewayLinkBlockQuery } from 'components/BlocksComponents/GatewayBlock/hooks/react-query/useDeleteGatewayLinkBlockQuery';
import useModal from 'core/hooks/useModal';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

const useUpdateGateway = (form: FormInstance, block: IBlockDetail | undefined) => {
  // hooks
  const { hideModal, modal, showModal } = useModal();

  // query
  const { mutate: deleteGateway, isLoading: isLoadingDeleteGateway } = useDeleteGatewayLinkBlockQuery();

  // store
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);
  const { linkGatewayDetail } = useSelector((store: RootState) => store.linkStore);

  useEffect(() => {
    block && form.setFieldsValue({ ...block });
  }, []);

  useEffect(() => {
    const hasError = apiCatcherFormErrors.every((item) => item?.payload?.id === block?.id);
    hasError && form.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors]);

  const onDeleteHandler = () => {
    deleteGateway({ id: block?.id });
  };

  return { isLoadingDeleteGateway, onDeleteHandler, hideModal, modal, showModal, linkGatewayDetail };
};

export default useUpdateGateway;
