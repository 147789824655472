import GatewayLayout from 'components/_Core/Layouts/Panel/PanelHomeGatewayLout/PanelHomeGatewayLayout';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';
import useRoute from 'core/hooks/useRoute';
import GatewayInvoices from 'components/Gateway/GatewayInvoices/GatewayInvoices';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

const GatewayInvoicesPage = () => {
  const { routes } = useRoute();
  const { gatewayDetail } = useSelector((store: RootState) => store.gatewayStore);
  const headerTitle = `لیست تراکنش‌های ${gatewayDetail?.data?.title || '--'}`;

  return (
    <GatewayLayout headerTitle={headerTitle} backUrl={routes.LINKS.link}>
      <HeadMeta tabTitle={headerTitle} />
      <GatewayInvoices />
    </GatewayLayout>
  );
};

export default GatewayInvoicesPage;
