import { FC, memo, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import ZpSelect, { IZpSelect } from 'components/_Core/UI/Select/ZpSelect';

export interface IZpSelectLabel extends IZpSelect {
  label: string | ReactNode;
  ltr?: boolean;
  className?: string;
  required?: boolean;
  value?: any;
}

const ZpSelectLabel: FC<IZpSelectLabel> = ({ ltr = false, label, className, required, ...props }) => {
  const { lang } = useSelector((store: RootState) => store.settingStore);
  const labelRef = useRef<HTMLLabelElement | null>(null);
  const [value, setValue] = useState<any>('');

  useLayoutEffect(() => {
    if (props.value === 0 ? props.value + '' : props.value || value) labelRef.current?.classList?.add('active-float-label-select');
    else labelRef.current?.classList?.remove('active-float-label-select');
  }, [props.value, value]);

  return (
    <div className='float-group'>
      <ZpSelect setValue={setValue} className={`w-full text-color-main  ${className}`} {...props} />
      <label ref={labelRef} className={`float-label ${lang === 'fa' ? (ltr ? 'left-3' : 'right-3') : 'left-3'}`}>
        {required && <span className='text-error-color-300 inline-block relative top-1 mx-1'>*</span>}
        {label}
      </label>
    </div>
  );
};

export default memo(ZpSelectLabel);
