import { FormInstance } from 'antd';
import { useCreateGatewayLinkQuery } from 'components/Gateway/GatewayLinks/Create/Professional/StepOne/hooks/react-query/useCreateGatewayLinkQuery';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { useGatewayLinkDetailQuery } from 'core/hooks/react-query/useGatewayLinkDetailQuery';
import { IGatewayDetail } from 'core/types/Models/gatewayType';
import useModal from 'core/hooks/useModal';

const useGatewayLinkCreateSimple = (form?: FormInstance) => {
  //hooks
  const { query } = useQueryUrlParam();
  const { modal, showModal } = useModal();

  // query
  const { mutate: createGatewayLinkSimple, isLoading: isLoadingCreateGatewayLinkSimple } = useCreateGatewayLinkQuery();
  const { data: gatewayLinkDetail } = useGatewayLinkDetailQuery(true);

  // store
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  useEffect(() => {
    query?.gatewayLinkId && form && form.setFieldsValue({ ...gatewayLinkDetail?.data, linkTitle: gatewayLinkDetail?.data?.title });
  }, [gatewayLinkDetail]);

  useEffect(() => {
    form && form.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors.length]);

  const onFinishCreateGatewayLinkSimpleHandler = (values: { title: string }, res?: IGatewayDetail) => {
    createGatewayLinkSimple(
      { data: values, gatewayId: res?.data?.id },
      {
        onSuccess: () => showModal(),
      }
    );
  };

  return {
    onFinishCreateGatewayLinkSimpleHandler,
    modal,
    isLoadingCreateGatewayLinkSimple,
  };
};

export default useGatewayLinkCreateSimple;
