import { FC, ReactNode } from 'react';
import Container from 'components/_Core/Layouts/Container/Container';
import { twMerge } from 'tailwind-merge';

interface IMainContentLayout {
  children: ReactNode;
  className?: string;
}

const MainContentLayout: FC<IMainContentLayout> = ({ children, className = '' }) => {
  return <Container className={twMerge(`my-6 flex-1 relative ${className}`)}>{children}</Container>;
};

export default MainContentLayout;
