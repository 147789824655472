import ZpImage from 'components/_Core/UI/Image/ZpImage';
import EmptyGatewayLinkIcon from 'assets/images/empty-gateway-link.svg';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { FiArrowLeft } from 'react-icons/fi';
import useModal from 'core/hooks/useModal';
import GatewayBlockType from 'components/_Core/UI/GatewayBlockType/GatewayBlockType';

function EmptyGatewayLinks() {
  const { modal, hideModal, showModal } = useModal();

  return (
    <>
      <GatewayBlockType modal={modal} hideModal={hideModal} />

      <div className='relative max-w-sm w-full min-h-[calc(100vh_-_200px)]'>
        <div className='space-y-5 text-center text-color-paragraph absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full'>
          <ZpImage src={EmptyGatewayLinkIcon} />

          <p>هنوز لینک پرداختی ایجاد نکرده‌اید.</p>

          <ZpButton type='primary' buttonSize='lg' onClick={showModal}>
            <span className='flex items-center space-x-3 space-x-reverse'>
              <span>ساخت لینک پرداخت</span>
              <FiArrowLeft size={22} />
            </span>
          </ZpButton>
        </div>
      </div>
    </>
  );
}

export default EmptyGatewayLinks;
