import { usePlanPayQuery } from 'components/Plans/components/PlanList/hooks/react-query/usePlanPayQuery';
import { useUserInfoQuery } from 'core/hooks/react-query/useUserInfoQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

const useActivePlan = () => {
  // query
  const { isFetching: isLoadingUserInfo } = useUserInfoQuery();
  const { isLoading: isLoadingPlanPay, mutate: planPay } = usePlanPayQuery();

  // store
  const { activePlan, subscriptionDetail } = useSelector((store: RootState) => store.userPlanStore);

  const onPlanPayHandler = (plan_id: number) => {
    planPay({ plan_id });
  };

  return { isLoadingUserInfo, onPlanPayHandler, isLoadingPlanPay, activePlan, subscriptionDetail };
};

export default useActivePlan;
