import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryOnSuccessHandler from 'core/helpers/queryOnSuccessHandler/useQueryOnSuccessHandler';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import useRoute from 'core/hooks/useRoute';
import api from 'core/services/fetch-api/useApi';
import { useNavigate } from 'react-router-dom';

export const useCreateLinkQuery = () => {
  const { onSuccessHandler } = useQueryOnSuccessHandler();
  const { routes } = useRoute();
  const navigate = useNavigate();

  return useMutationHook([endPointUrls.LINK('create')], (data) => api.post(endPointUrls.LINK(), data), {
    onSuccess: (res: any) => {
      if (res.status === 200) {
        onSuccessHandler(res);
        navigate(routes.CREATE_LINK_STEP_2.link(`id=${res.data?.data?.id}&username=${res.data?.data?.username}`));
      }
    },
  }) as UseMutationResult;
};
