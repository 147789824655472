import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import api from 'core/services/fetch-api/useApi';
import { ILinkCategoryList } from 'core/types/Models/linkCategoryType';

export const useLinkCategoryQuery = () => {
  return useQueryHook([endPointUrls.CATEGORY], () => api.get(endPointUrls.CATEGORY), {
    select: (res: any) => res.data,
  }) as UseQueryResult<ILinkCategoryList, Error>;
};
