import { IBlockDetail } from 'core/types/Models/linkType';
import { FC, Fragment } from 'react';
import { IBlockTypeDetail } from 'core/types/Models/blockType';
import { getBlockLink } from 'core/utils/utils';
import externalLink from 'assets/images/externalLink.svg';
import PreviewTemplateBlockTitle from 'components/_Core/UI/PreviewTemplate/BlockTitle/PreviewTemplateBlockTitle';
import PreviewTemplateBlocks from 'components/_Core/UI/PreviewTemplate/Blocks/PreviewTemplateBlocks';

interface ILinkDetailBlockChild {
  blocks: IBlockDetail[];
  blockType: IBlockTypeDetail;
}

const LinkDetailBlockChild: FC<ILinkDetailBlockChild> = ({ blocks, blockType }) => {
  const staticIcon = blockType.name === 'externalLink' ? externalLink : '';

  return (
    <>
      <div className='w-full space-y-3'>
        <PreviewTemplateBlockTitle title={blockType.title} />

        {blocks?.map((block) => {
          const { blockLink } = getBlockLink(block);
          const link = block.value ? `${blockLink}${block.value?.trim() || ''}` : block.url ? block.url : block.link ? block.link : '#';

          return (
            <Fragment key={block.id}>
              <a rel='noreferrer' target='_blank' href={link} className='block'>
                <PreviewTemplateBlocks block={block} staticIcon={staticIcon} />
              </a>
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

export default LinkDetailBlockChild;
