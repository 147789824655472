import { FC } from 'react';

interface ILinkCategoryItem {
  title: string;
  isSelected: boolean;
  onClick: () => void;
}

const LinkCategoryItem: FC<ILinkCategoryItem> = ({ title = '', onClick, isSelected }) => {
  return (
    <div
      className={`border border-natural-color-300 rounded-lg bg-natural-color-50 px-4 py-3 flex items-center justify-center cursor-pointer ${
        isSelected ? 'bg-primary-color-300 border-4 border-primary-color-100 text-white' : ''
      }`}
      onClick={onClick}
    >
      {title}
    </div>
  );
};

export default LinkCategoryItem;
