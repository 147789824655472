import { ReactComponent as PremiumBadgeASVG } from 'assets/images/plan/premium-label-active.svg';
import { ReactComponent as PremiumBadgeBSVG } from 'assets/images/plan/premium-label-active-3.svg';
import { FC, ReactNode } from 'react';
import ZpTrialBadge from 'components/_Core/UI/PremiumBadge/ZpTrialBadge';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

interface IZpPremiumBadge {
  children: ReactNode;
  type?: 'A' | 'B';
  width?: number;
  height?: number;
  forceShow?: boolean;
  hiddenBadge?: boolean;
}

const ZpPremiumBadge: FC<IZpPremiumBadge> = ({ children, type = 'A', width = 25, height = 25, forceShow = false, hiddenBadge = false }) => {
  if (hiddenBadge) return <>{children}</>;
  const { hasPlan, trialDays } = useSelector((store: RootState) => store.userPlanStore);
  console.log(hasPlan, trialDays);

  const Badge = (
    <div className='absolute -top-2 -left-2'>
      <div className='relative inline-block'>
        {trialDays ? <ZpTrialBadge trialCount={trialDays} /> : null}
        {type === 'A' ? <PremiumBadgeASVG width={width} height={height} /> : <PremiumBadgeBSVG width={width} height={height} />}
      </div>
    </div>
  );
  return (
    <div className='relative'>
      {children}
      {hasPlan || forceShow ? Badge : null}
    </div>
  );
};

export default ZpPremiumBadge;
