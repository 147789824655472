import { MenuProps } from 'antd';
import ZpDropDown from 'components/_Core/UI/DropDown/ZpDropDown';
import { FiMoreVertical } from 'react-icons/fi';
import ZpLink from 'components/_Core/UI/Link/ZpLink';
import useRoute from 'core/hooks/useRoute';
import { AiOutlineDelete, AiTwotoneEdit } from 'react-icons/ai';
import ZpDrawer from 'components/_Core/UI/Drawer/ZpDrawer';
import useGatewayLinkAction from 'components/Gateway/GatewayLinks/List/hooks/useGatewayLinkAction';
import GatewayLinkDeleteModalLink from 'components/Gateway/GatewayLinks/List/components/GatewayLinkDeleteModalLink';
import { IGatewayLinkIcon } from 'core/types/Models/gatewayLinkIconType';

const GatewayLinkItemAction = ({ gatewayLinkId, icon }: { gatewayLinkId: number; icon: IGatewayLinkIcon | null }) => {
  // hooks
  const { hideModal, modal, showModalHandler, openDropDown, onOpenChange, isLoadingDeleteGatewayLink, onChangeStatusHandler } = useGatewayLinkAction();
  const { routes } = useRoute();

  const items: MenuProps['items'] = [
    {
      label: (
        <ZpLink to={icon ? routes.GATEWAY_LINK_PROFESSIONAL_UPDATE.link(gatewayLinkId) : routes.GATEWAY_LINK_SIMPLE_UPDATE.link(gatewayLinkId)} className='flex items-center'>
          <AiTwotoneEdit size={16} className='ml-1' />
          <span>ویرایش لینک</span>
        </ZpLink>
      ),
      key: '0',
    },
    {
      label: (
        <p className='flex items-center text-error-color-300' onClick={() => showModalHandler()}>
          <AiOutlineDelete size={16} className='ml-1' color='var(--error-color-300)' />
          <span>حذف لینک پرداخت</span>
        </p>
      ),
      key: '1',
    },
  ];

  return (
    <>
      <ZpDrawer height='auto' placement='bottom' closable={false} open={modal} onClose={hideModal}>
        <GatewayLinkDeleteModalLink hideModal={hideModal} gatewayLinkId={gatewayLinkId} isLoadingUpdateLink={isLoadingDeleteGatewayLink} onSubmitHandler={onChangeStatusHandler} />
      </ZpDrawer>
      <ZpDropDown menu={{ items }} open={openDropDown} onOpenChange={onOpenChange}>
        <FiMoreVertical size={25} className='cursor-pointer' />
      </ZpDropDown>
    </>
  );
};

export default GatewayLinkItemAction;
