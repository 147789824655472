import Container from 'components/_Core/Layouts/Container/Container';
import ZpSkeleton from 'components/_Core/UI/Skeleton/ZpSkeleton';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';

const CreateGatewayLinkStepThreeSkeleton = () => {
  return (
    <Container className='h-full'>
      <div className='flex flex-col justify-between h-full'>
        <ZpWrapper singleColumn>
          <ZpSkeleton width={250} height={30} />

          <ZpSkeleton height={50} />
          <ZpSkeleton height={50} />
          <ZpSkeleton height={100} />
          <ZpSkeleton height={100} />
          <ZpSkeleton height={30} />
        </ZpWrapper>
      </div>
    </Container>
  );
};

export default CreateGatewayLinkStepThreeSkeleton;
