import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { FC } from 'react';

interface IProfileSwitchItem {
  imgUrl: string | undefined;
  title: string;
  description: string;
}

const ProfileSwitchItem: FC<IProfileSwitchItem> = ({ imgUrl, title, description }) => {
  return (
    <div className='flex items-center space-x-4 space-x-reverse'>
      <ZpImage src={imgUrl || undefined} className='rounded-full' width={50} height={50} />
      <div className='space-y-1'>
        <p>{title}</p>
        <p className='text-color-placeholder text-sm line-clamp-1'>{description}</p>
      </div>
    </div>
  );
};

export default ProfileSwitchItem;
