import GatewayLayout from 'components/_Core/Layouts/Panel/PanelHomeGatewayLout/PanelHomeGatewayLayout';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';
import useRoute from 'core/hooks/useRoute';
import GatewayCreateEmpty from 'pages/GatewayLink/Create/GatewayCreateEmpty/components/GatewayCreateEmpty';

const GatewayCreateEmptyPage = () => {
  const { routes } = useRoute();
  const headerTitle = 'لینک‌های درگاه پرداخت';

  return (
    <GatewayLayout headerTitle={headerTitle} backUrl={routes.GATEWAY_DETAIL.link()}>
      <HeadMeta tabTitle={headerTitle} />
      <GatewayCreateEmpty />
    </GatewayLayout>
  );
};

export default GatewayCreateEmptyPage;
