import Dashboard from 'components/Dashboard/Dashboard';
import PanelLinkDashboard from 'components/_Core/Layouts/Panel/PanelHomeIndex/PanelHomeIndexLayout';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';

const DashboardPage = () => {
  const { linkDetail } = useSelector((store: RootState) => store.linkStore);
  return (
    <PanelLinkDashboard>
      <HeadMeta tabTitle={`داشبورد ${linkDetail?.data?.title_fa || ''}`} />
      <Dashboard />
    </PanelLinkDashboard>
  );
};

export default DashboardPage;
