import { FC } from 'react';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import expire from 'assets/images/icon/expire.png';
import zarinPalIcon from 'assets/images/zarinPalIcon.svg';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { IBlockDetail } from 'core/types/Models/linkType';
import useTemplatePreview from 'components/_Core/UI/PreviewTemplate/hooks/useTemplatePreview';
import { twMerge } from 'tailwind-merge';

interface IPreviewTemplateSimpleGatewayLink {
  block: IBlockDetail;
}

const PreviewTemplateSimpleGatewayLink: FC<IPreviewTemplateSimpleGatewayLink> = ({ block }) => {
  const { has_block_icon, blockBackgroundImage, blockStyles, blockBorder, has_block_arrow, blockContentCenter, blockPadding } = useTemplatePreview('100%');

  return (
    <div className='w-full space-y-3'>
      <div className='relative'>
        {block?.remaining_date === null
          ? false
          : block?.remaining_date <= 0 && <ZpImage src={expire} alt='expire' width={100} height={100} className='absolute left-4 -top-5 z-50' />}

        <div
          style={{ ...blockStyles, ...blockBackgroundImage.object }}
          className={twMerge(
            `w-full p-2 space-x-3 space-x-reverse flex items-center justify-between ${blockBorder} ${blockPadding} ${
              block?.remaining_date === null ? '' : block?.remaining_date <= 0 ? 'opacity-50' : ''
            }`
          )}
        >
          <div className={`flex items-center space-x-4 space-x-reverse flex-1 ${blockContentCenter}`}>
            {has_block_icon && (
              <div className='max-w-[40px] w-full flex'>
                <ZpImage alt='simple gateway link' className='rounded-full h-full w-full' width={40} height={40} src={zarinPalIcon} />
              </div>
            )}
            <span className='line-clamp-2 text-block_name_color text-lg'>{block.title}</span>
          </div>

          {has_block_arrow && <MdOutlineKeyboardArrowLeft size={25} color='var(--block_name_color)' />}
        </div>
      </div>
    </div>
  );
};

export default PreviewTemplateSimpleGatewayLink;
