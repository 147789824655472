import { FormRule } from 'antd';
import { mobileNumberRegex, numberRegex, phoneNumberRegex } from 'core/utils/utils';

const useValidationFormMessages = () => {
  const required = (name: string, emptyMessage = false, required = true): FormRule => ({ message: emptyMessage ? '' : `${name} را وارد کنید`, required });

  const nationalValidation = (required = false): FormRule[] => [
    ...(required ? [{ required: true, message: 'کدملی را وارد کنید' }] : []),
    { whitespace: false, message: 'فاصله را حذف کنید' },
    { min: 10, message: 'کدملی 10 رقم می‌باشد' },
    { max: 10, message: 'کدملی 10 رقم می‌باشد' },
    { pattern: numberRegex, message: 'کدملی شامل عدد می‌باشد' },
  ];

  const mobileValidation = (required = false): FormRule[] => [
    ...(required ? [{ required: true, message: 'موبایل را وارد کنید' }] : []),
    { whitespace: false, message: 'فاصله را حذف کنید' },
    { min: 11, message: 'موبایل 11 رقم می‌باشد' },
    { max: 11, message: 'موبایل 11 رقم می‌باشد' },
    { pattern: mobileNumberRegex, message: 'فرمت موبایل صحیح نیست' },
  ];

  const phoneValidation = (required = false): FormRule[] => [
    ...(required ? [{ required: true, message: 'شماره تلفن را وارد کنید' }] : []),
    { whitespace: false, message: 'فاصله را حذف کنید' },
    { min: 10, message: 'شماره تلفن 10 رقم می‌باشد' },
    { max: 10, message: 'شماره تلفن 10 رقم می‌باشد' },
    { pattern: phoneNumberRegex, message: 'فرمت شماره تلفن صحیح نیست' },
  ];

  const postalCodeValidation = (required = false): FormRule[] => [
    ...(required ? [{ required: true, message: 'کدپستی را وارد کنید' }] : []),
    { whitespace: false, message: 'فاصله را حذف کنید' },
    { min: 10, message: 'کدپستی 10 رقم می‌باشد' },
    { max: 10, message: 'کدپستی 10 رقم می‌باشد' },
    { pattern: numberRegex, message: 'فرمت کدپستی صحیح نیست' },
  ];

  const shebaNumberValidation = (required = false): FormRule[] => [
    ...(required ? [{ required: true, message: 'شماره شبا را وارد کنید' }] : []),
    { whitespace: false, message: 'فاصله را حذف کنید' },
    { min: 24, message: 'شماره شبا 24 رقم می‌باشد' },
    { max: 24, message: 'شماره شبا 24 رقم می‌باشد' },
    { pattern: numberRegex, message: 'شماره شبا شامل عدد می‌باشد' },
  ];

  return { required, nationalValidation, mobileValidation, phoneValidation, postalCodeValidation, shebaNumberValidation };
};

export default useValidationFormMessages;
