import PrivateLayout from 'components/_Core/Layouts/PrivateLayout/PrivateLayout';
import useRoute from 'core/hooks/useRoute';
import { FC, memo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface IPrivateRoute {
  role: boolean;
  isAuthenticated: boolean;
}

const PrivateRoute: FC<IPrivateRoute> = ({ role, isAuthenticated }) => {
  const { routes } = useRoute();

  if (isAuthenticated && !!role)
    return (
      <PrivateLayout>
        <Outlet />
      </PrivateLayout>
    );
  else return <Navigate to={routes.NOTFOUND} />;
};

export default memo(PrivateRoute);
