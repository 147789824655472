import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryOnSuccessHandler from 'core/helpers/queryOnSuccessHandler/useQueryOnSuccessHandler';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import useRoute from 'core/hooks/useRoute';
import api from 'core/services/fetch-api/useApi';

export const useCreateGatewayLinkBlockQuery = (isRequestToProfile = true) => {
  const queryClient = useQueryClient();
  const { linkId, username } = useRoute();
  const { onSuccessHandler } = useQueryOnSuccessHandler();

  return useMutationHook(
    [endPointUrls.GATEWAY_LINK_BLOCK(linkId || '', 'create')],
    (data: any) => api.post(endPointUrls.GATEWAY_LINK_BLOCK(linkId || '', data?.gatewayLinkId), data),
    {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          onSuccessHandler(res);
          isRequestToProfile && queryClient.invalidateQueries([endPointUrls.LINK_GET_BY_USERNAME(username + '')]);
        }
      },
    }
  ) as UseMutationResult;
};
