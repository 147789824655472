import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryOnSuccessHandler from 'core/helpers/queryOnSuccessHandler/useQueryOnSuccessHandler';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import api from 'core/services/fetch-api/useApi';
import useRoute from 'core/hooks/useRoute';
import { ILinkMutationResponse } from 'core/types/Models/linkType';

export const useUpdateLinkProfileImageQuery = () => {
  const { onSuccessHandler } = useQueryOnSuccessHandler();
  const { query } = useQueryUrlParam();
  const { linkId } = useRoute();

  return useMutationHook([endPointUrls.LINK_PROFILE_IMAGE(query?.id || linkId)], (data: any) => api.post(endPointUrls.LINK_PROFILE_IMAGE(query?.id || linkId), data), {
    onSuccess: (res: any) => {
      onSuccessHandler(res);
    },
  }) as UseMutationResult<ILinkMutationResponse, Error>;
};
