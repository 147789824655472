import EmptyGateway from 'components/Gateway/List/components/EmptyGateway';
import ZpSpinnerFull from 'components/_Core/UI/Spinner/ZpSpinnerFull';

const GatewayList = () => {
  return (
    <ZpSpinnerFull className='h-full' isLoading={true}>
      <div className='h-full relative'>
        <EmptyGateway />
      </div>
    </ZpSpinnerFull>
  );
};

export default GatewayList;
