import ZpBlockCreate from 'components/_Core/UI/BlockCreate/ZpBlockCreate';
import useMessengerBlock from './hooks/useMessengerBlock';
import { HiOutlineUserGroup } from 'react-icons/hi';
import MessengerBlockList from './components/List/MessengerBlockList';
import MessengerBlockCreate from './components/Create/MessengerBlockCreate';
import BlockListSkeleton from 'components/_Core/UI/BlockListSkeleton/BlockListSkeleton';

const MessengerBlock = () => {
  const {
    hideMasterModal,
    onFinishCreateLinkHandler,
    createForm,
    isLoadingMessengerType,
    blockTypeSelected,
    setBlockTypeSelected,
    childModal,
    hideChildModalHandler,
    isLoadingCreateMessenger,
    showChildModal,
    masterModal,
    showMasterModal,
    MessengerTypeList,
    isLoadingLinkDetail,
    MessengerList,
    hasBlock,
  } = useMessengerBlock();

  if (isLoadingMessengerType || isLoadingLinkDetail) return <BlockListSkeleton />;

  return (
    <>
      <ZpBlockCreate
        childModal={childModal}
        hasBlock={hasBlock}
        setBlockTypeSelected={setBlockTypeSelected}
        hideChildModal={hideChildModalHandler}
        showChildModal={showChildModal}
        blockTypes={MessengerTypeList}
        masterModal={masterModal}
        hideMasterModal={hideMasterModal}
        showMasterModal={showMasterModal}
        blockTypeIcon={<HiOutlineUserGroup size={26} />}
        blockTypeTitle='پیام رسان‌ها'
        blockTypeDescription='یکی از پیام رسان‌ها را انتخاب کنید'
        title='پیام رسان'
      >
        <MessengerBlockCreate
          blockTypeSelected={blockTypeSelected}
          createForm={createForm}
          hideChildModalHandler={hideChildModalHandler}
          isLoadingCreateMessenger={isLoadingCreateMessenger}
          onFinishCreateLinkHandler={onFinishCreateLinkHandler}
        />
      </ZpBlockCreate>

      <MessengerBlockList blocks={MessengerList} />
    </>
  );
};

export default MessengerBlock;
