import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import api from 'core/services/fetch-api/useApi';
import { setLinkList } from 'core/store/slice/link/linkSlice';
import { ILinkList } from 'core/types/Models/linkType';
import { useDispatch } from 'react-redux';
import useRoute from 'core/hooks/useRoute';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';

export const useLinksListQuery = () => {
  const dispatch = useDispatch();
  const { routes } = useRoute();
  const { pathname } = useQueryUrlParam();
  const preventRoutes = [routes.PAYMENT_RETURN_FAILED.link, routes.PAYMENT_RETURN_SUCCESS.link];

  const enabled = !preventRoutes.some((route) => route === pathname);

  return useQueryHook([endPointUrls.LINK('list')], () => api.get(endPointUrls.LINK()), {
    select: (res: any) => res.data,
    keepPreviousData: true,
    onSuccess(res) {
      const response = res as ILinkList;

      dispatch(setLinkList(response));
    },
    enabled,
  }) as UseQueryResult<ILinkList, Error>;
};
