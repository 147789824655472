import BlockLayoutCreate from 'components/_Core/Layouts/Panel/PanelHomeBlockLayout/Create/PanelHomeBlockLayoutCreate';
import SocialMediaBlock from 'components/BlocksComponents/SocialMediaBlock/SocialMediaBlock';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';

const SocialMediaBlockPage = () => {
  return (
    <BlockLayoutCreate>
      <HeadMeta tabTitle='شبکه های اجتماعی' />
      <SocialMediaBlock />
    </BlockLayoutCreate>
  );
};

export default SocialMediaBlockPage;
