import { ConfigProvider, theme } from 'antd';
import useColors from 'assets/css/colors/useColors';
import { FC, ReactNode } from 'react';
import fa from 'antd/locale/fa_IR';
import en from 'antd/locale/en_US';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

interface IAntProvider {
  children: ReactNode;
}

const AntProvider: FC<IAntProvider> = ({ children }) => {
  const { getMainTheme } = useColors();
  const { isEnglish, theme: themeSetting } = useSelector((store: RootState) => store.settingStore);

  return (
    <ConfigProvider
      input={{ autoComplete: 'off' }}
      locale={isEnglish ? en : fa}
      direction={isEnglish ? 'ltr' : 'rtl'}
      theme={{
        ...(themeSetting === 'dark' ? { algorithm: theme.darkAlgorithm } : {}),
        token: {
          colorPrimary: getMainTheme['primary-color-main'],
          colorPrimaryHover: getMainTheme['primary-color-300'],
          colorLink: getMainTheme['primary-color-300'],
          colorLinkHover: getMainTheme['primary-color-main'],
          colorLinkActive: getMainTheme['primary-color-main'],
          colorTextDisabled: getMainTheme['color-disable'],
          colorBgElevated: getMainTheme['white-color'],
          colorBorder: getMainTheme['border-color'],
        },
        components: { Input: { fontSize: 16 } },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default AntProvider;
