import { IBlock } from 'core/types/Models/linkType';
import { getBlockTypeDetail } from 'core/utils/utils';
import { FC } from 'react';
import DashboardBlockChild from './DashboardBlockChild';
import DashboardDividerBlock from './DashboardDividerBlock';
import DashboardGatewayLinkBlock from 'components/Dashboard/components/DashboardGatewayLinkBlock';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { AiOutlinePlus } from 'react-icons/ai';
import DashboardGatewayLinkFakeBlock from 'components/Dashboard/components/DashboardGatewayLinkFakeBlock';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import DashboardNavigationBlock from 'components/Dashboard/components/DashboardNavigationBlock';
import { setOpenBlockListHandler } from 'core/store/slice/setting/settingSlice';

interface IDashboardBlockMaster {
  blocks: IBlock[];
}

const DashboardBlockMaster: FC<IDashboardBlockMaster> = ({ blocks }) => {
  // hooks
  const dispatch = useDispatch();

  // store
  const { hasGatewayLinks } = useSelector((store: RootState) => store.linkStore);

  return (
    <div className='flex flex-col items-center space-y-8'>
      {!hasGatewayLinks && <DashboardGatewayLinkFakeBlock />}

      {blocks?.map((block) => {
        const blockType = getBlockTypeDetail(block);

        return block.type === 'App\\Models\\LinkApp\\Divider'
          ? block?.data?.length > 0 && <DashboardDividerBlock key={block.id} blockType={blockType} blocks={block.data} />
          : block.type === 'App\\Models\\LinkApp\\PaymentLink'
          ? block?.data?.length > 0 && <DashboardGatewayLinkBlock key={block.id} blockType={blockType} blocks={block.data} />
          : block.type === 'App\\Models\\LinkApp\\Navigation'
          ? block?.data?.length > 0 && <DashboardNavigationBlock key={block.id} blockType={blockType} blocks={block.data} />
          : block?.data?.length > 0 && <DashboardBlockChild key={block.id} blockType={blockType} blocks={block.data} />;
      })}

      <ZpButton type='primary' ghost className='w-full' onClick={() => dispatch(setOpenBlockListHandler(true))}>
        <span className='flex text-lg items-center space-x-3 justify-center space-x-reverse'>
          <span>بلوک جدید</span>
          <AiOutlinePlus size={22} />
        </span>
      </ZpButton>
    </div>
  );
};

export default DashboardBlockMaster;
