import GatewayLayout from 'components/_Core/Layouts/Panel/PanelHomeGatewayLout/PanelHomeGatewayLayout';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';
import useRoute from 'core/hooks/useRoute';
import GatewayLinkCreateSimple from 'components/Gateway/GatewayLinks/Create/Simple/GatewayLinkCreateSimple';

const GatewayLinkCreateSimplePage = () => {
  const { routes } = useRoute();
  const headerTitle = 'ساخت لینک پرداخت ساده';

  return (
    <GatewayLayout showPanelDashboardFooterNavbar={false} headerTitle={headerTitle} backUrl={routes.PANEL_HOME.link()}>
      <HeadMeta tabTitle={headerTitle} />
      <GatewayLinkCreateSimple />
    </GatewayLayout>
  );
};

export default GatewayLinkCreateSimplePage;
