import ZpImage from 'components/_Core/UI/Image/ZpImage';
import logo from 'assets/images/logo/logo.svg';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { Fragment } from 'react';
import { IContact } from 'core/types/Components/Support/contactType';
import telegram from 'assets/images/socialMedia/telegram.svg';
import whatsapp from 'assets/images/socialMedia/whatsapp.svg';
import instagram from 'assets/images/socialMedia/instagram.svg';
import linkedin from 'assets/images/socialMedia/linkedin.svg';

const Support = () => {
  const contacts: IContact[] = [
    {
      id: 1,
      title: `راه‌های ارتباطی با ${process.env.REACT_APP_BRAND_NAME_FA}`,
      contacts: [
        { id: 1, title: 'تلگرام ما', icon: telegram, link: 'https://google.com' },
        { id: 2, title: 'واتس‌اپ ما', icon: whatsapp, link: 'https://google.com' },
      ],
    },
    {
      id: 2,
      title: `شبکه‌های اجتماعی ${process.env.REACT_APP_BRAND_NAME_FA}`,
      contacts: [
        { id: 1, title: 'اینستاگرام ما', icon: instagram, link: 'https://google.com' },
        { id: 2, title: 'کانال تلگرامی ما', icon: telegram, link: 'https://google.com' },
        { id: 3, title: 'لینکدین ما', icon: linkedin, link: 'https://google.com' },
      ],
    },
  ];

  return (
    <div className='text-center space-y-10'>
      <div className='space-y-3'>
        <ZpImage src={logo} className='rounded-full bg-primary-color-50 p-5' width={150} height={150} />
        <h2 className='text-3xl font-bold'>{process.env.REACT_APP_BRAND_NAME_FA}</h2>
        <p className='text-lg font-bold'>مشکلی پیش اومده؟! با ما تماس بگیرید.</p>
      </div>

      {contacts.map((contact) => (
        <ContactItem key={contact.id} {...contact} />
      ))}
    </div>
  );
};

const ContactItem = ({ contacts, title }: IContact) => {
  return (
    <div>
      <h2 className='mb-4 text-xl font-bold'>{title}</h2>

      <div className='space-y-3'>
        {contacts.map((contact) => (
          <Fragment key={contact.id}>
            <a rel='noreferrer' target='_blank' href={contact.link} className='block'>
              <div className='bg-[var(--block-item-bg)] hover:bg-natural-color-300 border border-gray-color-300 rounded-lg w-full py-3	 px-5 space-x-3 space-x-reverse flex items-center justify-between'>
                <div className='flex items-center space-x-4 space-x-reverse'>
                  <ZpImage className='rounded-full' width={45} height={45} src={contact.icon || undefined} />
                  <span className='break-all max-w-[25ch]'>{contact.title}</span>
                </div>

                <MdOutlineKeyboardArrowLeft size={25} color='var(--natural-color-400)' />
              </div>
            </a>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default Support;
