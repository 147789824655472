import { FC } from 'react';
import { ReactComponent as TemplateCategorySVG } from 'assets/images/icon/template-category.svg';
import { ITemplate } from 'core/types/Models/templateType';
import TemplateItem from 'components/Template/components/TemplateList/components/TemplateItem/TemplateItem';
import ZpPremiumBadge from 'components/_Core/UI/PremiumBadge/ZpPremiumBadge';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

interface ITemplateCategoryItem {
  title: string;
  templates: ITemplate[];
}

const TemplateCategoryItem: FC<ITemplateCategoryItem> = ({ templates, title }) => {
  const { hasPlan } = useSelector((store: RootState) => store.userPlanStore);

  return (
    <div className='space-y-5'>
      <div className='flex items-center space-x-3 space-x-reverse'>
        <TemplateCategorySVG />
        <span>{title}</span>
      </div>

      <div className='grid grid-cols-2 gap-6'>
        {templates.map((template) => (
          <ZpPremiumBadge hiddenBadge={template?.is_free || hasPlan} forceShow={template?.is_free ? false : !hasPlan} type='A' width={50} height={50} key={template.id}>
            <TemplateItem template={template} />
          </ZpPremiumBadge>
        ))}
      </div>
    </div>
  );
};

export default TemplateCategoryItem;
