import ZpBlockCreate from 'components/_Core/UI/BlockCreate/ZpBlockCreate';
import useConnectionBlock from './hooks/useConnectionBlock';
import { HiOutlineUserGroup } from 'react-icons/hi';
import ConnectionBlockList from './components/List/ConnectionBlockList';
import ConnectionBlockCreate from './components/Create/ConnectionBlockCreate';
import BlockListSkeleton from 'components/_Core/UI/BlockListSkeleton/BlockListSkeleton';

const ConnectionBlock = () => {
  const {
    hideMasterModal,
    onFinishCreateLinkHandler,
    createForm,
    isLoadingConnectionType,
    blockTypeSelected,
    setBlockTypeSelected,
    childModal,
    hideChildModalHandler,
    isLoadingCreateConnection,
    showChildModal,
    masterModal,
    showMasterModal,
    ConnectionTypeList,
    isLoadingLinkDetail,
    ConnectionList,
    hasBlock,
  } = useConnectionBlock();

  if (isLoadingConnectionType || isLoadingLinkDetail) return <BlockListSkeleton />;

  return (
    <>
      <ZpBlockCreate
        childModal={childModal}
        hasBlock={hasBlock}
        setBlockTypeSelected={setBlockTypeSelected}
        hideChildModal={hideChildModalHandler}
        showChildModal={showChildModal}
        blockTypes={ConnectionTypeList}
        masterModal={masterModal}
        hideMasterModal={hideMasterModal}
        showMasterModal={showMasterModal}
        blockTypeIcon={<HiOutlineUserGroup size={26} />}
        blockTypeTitle='راه‌های ارتباطی'
        blockTypeDescription='یکی از راه‌های ارتباطی را انتخاب کنید'
        title='راه ارتباطی'
        masterDrawerHeight='auto'
        masterDrawerRoundedTop
      >
        <ConnectionBlockCreate
          blockTypeSelected={blockTypeSelected}
          createForm={createForm}
          hideChildModalHandler={hideChildModalHandler}
          isLoadingCreateConnection={isLoadingCreateConnection}
          onFinishCreateLinkHandler={onFinishCreateLinkHandler}
        />
      </ZpBlockCreate>

      <ConnectionBlockList blocks={ConnectionList} />
    </>
  );
};

export default ConnectionBlock;
