import { Form } from 'antd';
import { IBlockDetail } from 'core/types/Models/linkType';
import useUpdateDivider from '../../hooks/useUpdateDivider';
import ZpBlockDelete from 'components/_Core/UI/BlockDelete/ZpBlockDelete';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { getImageUrl } from 'core/utils/utils';

const DividerBlockUpdate = ({ block }: { block: IBlockDetail | undefined }) => {
  const [formUpdate] = Form.useForm();
  const { hideModal, modal, showModal, isLoadingDeleteDivider, onDeleteHandler } = useUpdateDivider(formUpdate, block);

  return (
    <>
      <ZpBlockDelete modal={modal} hideModal={hideModal} isLoadingDelete={isLoadingDeleteDivider} onDeleteHandler={onDeleteHandler} blockId={block?.id} />

      <div className='flex items-center justify-between space-x-reverse space-x-7'>
        <ZpImage src={getImageUrl(block?.image)} />
        <RiDeleteBin6Line className='cursor-pointer' size={35} color='var(--error-color-300)' onClick={showModal} />
      </div>
    </>
  );
};

export default DividerBlockUpdate;
