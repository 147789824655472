import ZpImage from 'components/_Core/UI/Image/ZpImage';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { FC } from 'react';
import useRoute from 'core/hooks/useRoute';
import { useNavigate } from 'react-router-dom';
import { FiAlertTriangle } from 'react-icons/fi';
import { ILink } from 'core/types/Models/linkType';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { getImageUrl } from 'core/utils/utils';
import LinkAction from 'components/DashboardLinkList/components/Links/components/LinkAction';
import CopyLink from 'components/DashboardLinkList/components/Links/components/CopyLink';

interface ILinkItem {
  linkDetail: ILink;
}

const LinkItem: FC<ILinkItem> = ({ linkDetail }) => {
  const { description, title_fa, image_profile, id, status_code, username, blocks } = linkDetail;
  const { routes } = useRoute();
  const navigate = useNavigate();

  return (
    <div className='border relative bg-white-color border-natural-color-200 max-w-md rounded-lg mx-auto w-full px-4 pt-14 pb-8 shadow-sm'>
      <div className='text-center'>
        <ZpImage src={getImageUrl(image_profile)} className='rounded-full' width={100} height={100} />

        <LinkAction status={status_code} username={username + ''} linkId={id} />

        <div>
          <h2 className='font-bold text-lg'>{title_fa}</h2>
          <span className='text-natural-color-500'>{description}</span>
          {status_code === 0 ? (
            <p className='flex items-center justify-center my-4 text-sm text-error-color-300'>
              <FiAlertTriangle size={16} className='ml-1' color='var(--error-color-300)' />
              <span>غیر فعال</span>
            </p>
          ) : (
            <p className='flex items-center justify-center my-4 text-sm text-success-color-300'>
              <AiOutlineCheckCircle size={16} className='ml-1' color='var(--success-color-300)' />
              <span>فعال</span>
            </p>
          )}
        </div>

        <CopyLink username={username} />

        <div className='flex items-center justify-between space-x-5 space-x-reverse'>
          <ZpButton className='w-full' buttonSize='xs' type='primary' onClick={() => navigate(routes.PANEL_HOME.link(username))}>
            تنظیمات لینک
          </ZpButton>

          {blocks?.length !== 0 && (
            <a rel='noreferrer' href={`${window.location.origin}/${username}`} onClick={(e) => blocks?.length === 0 && e.preventDefault()} target='_blank' className='block w-full'>
              <ZpButton disabled={blocks?.length === 0} buttonSize='xs' className='w-full' type='primary' ghost>
                نمایش
              </ZpButton>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default LinkItem;
