import ZpImage from 'components/_Core/UI/Image/ZpImage';
import ZpDrawer from 'components/_Core/UI/Drawer/ZpDrawer';
import ProfileSwitchFooter from 'components/_Core/Layouts/Panel/PanelHomeIndex/PanelHomeIndexFooterNavbar/ProfileSwitch/components/ProfileSwitchFooter';
import ProfileSwitchList from 'components/_Core/Layouts/Panel/PanelHomeIndex/PanelHomeIndexFooterNavbar/ProfileSwitch/components/ProfileSwitchList';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { getImageUrl } from 'core/utils/utils';
import { setOpenProfileSwitcherHandler } from 'core/store/slice/setting/settingSlice';

const ProfileSwitch = () => {
  // hooks
  const dispatch = useDispatch();

  const { linkDetail } = useSelector((store: RootState) => store.linkStore);
  const { isOpenProfileSwitcher } = useSelector((store: RootState) => store.settingStore);

  return (
    <>
      <ZpDrawer
        height={410}
        forceRender
        placement='bottom'
        closable={false}
        footer={<ProfileSwitchFooter />}
        open={isOpenProfileSwitcher}
        onClose={() => dispatch(setOpenProfileSwitcherHandler(false))}
      >
        <ProfileSwitchList hideModal={() => dispatch(setOpenProfileSwitcherHandler(false))} />
      </ZpDrawer>

      <ZpImage
        onClick={() => dispatch(setOpenProfileSwitcherHandler(true))}
        src={getImageUrl(linkDetail?.data?.image_profile)}
        className='cursor-pointer border border-border-color p-1 rounded-full'
        width={55}
        height={55}
      />
    </>
  );
};

export default ProfileSwitch;
