import { FC } from 'react';

interface IRenderHtml {
  data: string;
  hasImage?: boolean;
}

const RenderHtml: FC<IRenderHtml> = ({ data = '', hasImage = true }) => {
  return <div dangerouslySetInnerHTML={{ __html: hasImage ? data : data.replace(/<img[^>]*>/g, '') }} />;
};

export default RenderHtml;
