import { FormInstance } from 'antd';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import navigation from 'assets/images/icon/navigation.svg';
import { FC } from 'react';
import ZpTextAreaLabel from 'components/_Core/UI/TextArea/ZpTextAreaLabel';
import useValidationFormMessages from 'core/hooks/useValidationFormMessages';
import ZpMap from 'components/_Core/UI/Map/ZpMap';
import { Marker } from 'react-leaflet';
import { pinIcon } from 'core/constants/pinIcon';
import { LAT_INITIAL, LNG_INITIAL } from 'core/constants/constant';

interface INavigationBlockCreate {
  createForm: FormInstance;
  isLoadingCreateNavigation: boolean;
  onFinishCreateLinkHandler: (val: any) => void;
  hideChildModalHandler: () => void;
  zoomLevel: number;
  setZoomLevel: (val: number) => void;
  setLatAndLng: (val: [number, number]) => void;
  latAndLng: [number, number] | undefined;
}

const NavigationBlockCreate: FC<INavigationBlockCreate> = ({
  latAndLng,
  zoomLevel,
  setLatAndLng,
  setZoomLevel,
  createForm,
  isLoadingCreateNavigation,
  hideChildModalHandler,
  onFinishCreateLinkHandler,
}) => {
  const { required } = useValidationFormMessages();

  return (
    <>
      <div className='flex items-center space-x-reverse space-x-5 mb-5'>
        <ZpImage className='rounded-full' src={navigation} width={45} height={45} />
        <h2 className='text-lg font-bold'>آدرس من</h2>
      </div>

      <ZpForm className='mb-0' form={createForm} onFinish={onFinishCreateLinkHandler}>
        <ZpWrapper className='mb-0' singleColumn>
          <ZpFormItem rules={[required('عنوان آدرس')]} name='title'>
            <ZpInputLabel required label='عنوان آدرس' />
          </ZpFormItem>

          <ZpMap height='h-[200px]' setZoomLevel={setZoomLevel} setLatAndLng={setLatAndLng} firstRender={true} initialCenter={[LAT_INITIAL, LNG_INITIAL]} zoomLevel={zoomLevel}>
            <Marker icon={pinIcon} position={[latAndLng?.[0] || LAT_INITIAL, latAndLng?.[1] || LNG_INITIAL]} />
          </ZpMap>

          <ZpFormItem name='description'>
            <ZpTextAreaLabel rows={2} label='جزئیات آدرس' />
          </ZpFormItem>

          <ZpFormItem button>
            <div className='flex items-center space-x-reverse space-x-5 justify-between'>
              <ZpButton className='w-full' buttonSize='xs' htmlType='button' ghost type='primary' onClick={hideChildModalHandler}>
                انصراف
              </ZpButton>
              <ZpButton className='w-full' buttonSize='xs' htmlType='submit' type='primary' isLoading={isLoadingCreateNavigation}>
                ساخت بلوک
              </ZpButton>
            </div>
          </ZpFormItem>
        </ZpWrapper>
      </ZpForm>
    </>
  );
};

export default NavigationBlockCreate;
