import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import api from 'core/services/fetch-api/useApi';
import useRoute from 'core/hooks/useRoute';
import { IGatewayInvoiceList } from 'core/types/Models/gatewayInvoicesType';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';

export const useGatewayInvoicesQuery = () => {
  const { gatewayId } = useRoute();
  const { filterParams } = useQueryUrlParam();

  return useQueryHook(
    [endPointUrls.GATEWAY_INVOICES(gatewayId + ''), filterParams],
    () => api.get(endPointUrls.GATEWAY_INVOICES(gatewayId + '', filterParams)),
    {
      select: (res: any) => res.data,
    },
    false
  ) as UseQueryResult<IGatewayInvoiceList, Error>;
};
