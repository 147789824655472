import { FC, memo, ReactNode } from 'react';
import PanelHomeBlockDraggableLayoutFooter from 'components/_Core/Layouts/Panel/PanelHomeBlockDraggableLayout/PanelHomeBlockDraggableLayoutFooter/PanelHomeBlockDraggableLayoutFooter';
import MainLayout from 'components/_Core/Layouts/Main/MainLayout';
import MainHeaderLayout from 'components/_Core/Layouts/Main/MainHeader/MainHeaderLayout';
import useRoute from 'core/hooks/useRoute';
import MainContentLayout from 'components/_Core/Layouts/Main/MainContentLayout/MainContentLayout';

interface IPanelHomeBlockDraggableLayout {
  children: ReactNode;
}

const PanelHomeBlockDraggableLayout: FC<IPanelHomeBlockDraggableLayout> = ({ children }) => {
  const { routes } = useRoute();

  return (
    <MainLayout>
      <MainHeaderLayout headerTitle='جابجایی بلوک' backUrlArrow={routes.PANEL_HOME.link()} />

      <MainContentLayout>{children}</MainContentLayout>

      <PanelHomeBlockDraggableLayoutFooter />
    </MainLayout>
  );
};

export default memo(PanelHomeBlockDraggableLayout);
