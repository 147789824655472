import useModal from 'core/hooks/useModal';
import { useSocialMediaTypeQuery } from './react-query/useSocialMediaTypeQuery';
import { useState, useEffect } from 'react';
import { IBlockTypes } from 'core/types/Models/blockType';
import { Form } from 'antd';
import { useCreateSocialMediaQuery } from './react-query/useCreateSocialMediaQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import { useNavigate } from 'react-router';
import useRoute from 'core/hooks/useRoute';

const useSocialMediaBlock = () => {
  // hooks
  const { modal: masterModal, hideModal: hideMasterModal, showModal: showMasterModal } = useModal();
  const { modal: childModal, hideModal: hideChildModal, showModal: showChildModal } = useModal();
  const [createForm] = Form.useForm();
  const navigate = useNavigate();
  const { routes } = useRoute();

  // state
  const [blockTypeSelected, setBlockTypeSelected] = useState<IBlockTypes>();

  // store
  const { linkDetail } = useSelector((store: RootState) => store.linkStore);
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  // query
  const { isFetching: isLoadingSocialMediaType, data: socialMediaTypeList } = useSocialMediaTypeQuery();
  const { isFetching: isLoadingLinkDetail } = useLinkDetailQuery();
  const { mutate: createSocialMedia, isLoading: isLoadingCreateSocialMedia } = useCreateSocialMediaQuery();

  const socialMediaList = linkDetail?.data?.blocks?.filter((block) => block.type.toLowerCase().includes('socialmedia'));
  const hasBlock = !!socialMediaList?.[0]?.data?.length;

  const hideChildModalHandler = () => {
    createForm.resetFields();
    hideChildModal();
  };

  const onFinishCreateLinkHandler = (values: { username: string; title: string }) => {
    createSocialMedia(
      { ...values, socialmediatype_id: blockTypeSelected?.id, order: hasBlock ? socialMediaList?.[0]?.data?.[socialMediaList?.[0]?.data?.length - 1]?.order + 1 : 1 },
      {
        onSuccess: () => {
          hideMasterModal();
          hideChildModalHandler();
          navigate(routes.PANEL_HOME.link());
        },
      }
    );
  };

  useEffect(() => {
    createForm.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors.length]);

  return {
    masterModal,
    createForm,
    onFinishCreateLinkHandler,
    blockTypeSelected,
    isLoadingCreateSocialMedia,
    isLoadingLinkDetail,
    setBlockTypeSelected,
    hideMasterModal,
    showMasterModal,
    socialMediaTypeList,
    isLoadingSocialMediaType,
    childModal,
    hideChildModalHandler,
    showChildModal,
    socialMediaList,
    hasBlock,
  };
};

export default useSocialMediaBlock;
