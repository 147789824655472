import CreateLinkStepTwo from 'components/CreateLink/StepTwo/CreateLinkStepTwo';
import PanelCreateLinkLayout from 'components/_Core/Layouts/Panel/PanelCreateLinkLayout/PanelCreateLinkLayout';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';

const CreateLinkStepTwoPage = () => {
  return (
    <PanelCreateLinkLayout>
      <HeadMeta tabTitle='ایجاد لینک - مرحله 2' />
      <CreateLinkStepTwo />
    </PanelCreateLinkLayout>
  );
};

export default CreateLinkStepTwoPage;
