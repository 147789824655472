import { FormEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useUpdateGatewayLinkImageQuery } from 'components/Gateway/GatewayLinks/Create/Professional/StepTwo/hooks/react-query/useUpdateGatewayLinkImageQuery';
import { useUpdateGatewayLinkQuery } from 'components/Gateway/GatewayLinks/Create/Professional/StepTwo/hooks/react-query/useUpdateGatewayLinkQuery';
import toastHandler from 'core/helpers/toast/toast';
import { Form } from 'antd';
import { useGatewayLinkDetailQuery } from 'core/hooks/react-query/useGatewayLinkDetailQuery';
import { convertEnglishDateToJalali, convertJalaliDateToEnglish, disableBackToUrl, extractNumberFromString } from 'core/utils/utils';
import { IGatewayLinkPayload } from 'core/types/Models/gatewayLinkType';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import useRoute from 'core/hooks/useRoute';
import { useDeleteGatewayLinkImageQuery } from 'components/Gateway/hooks/react-query/useDeleteGatewayLinkImageQuery';

const useGatewayLinkProfessionalUpdate = () => {
  //hooks
  const [updateGatewayLinkForm] = Form.useForm();
  const imageRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { routes } = useRoute();
  const { key, state } = useLocation();

  // query
  const { mutate: uploadProfileImage, isLoading: isLoadingUploadProfileImage, data: imageUploadData, reset: resetUpdatedGatewayLinkImageData } = useUpdateGatewayLinkImageQuery();
  const { mutate: updateGatewayLink, isLoading: isLoadingUpdateGatewayLink } = useUpdateGatewayLinkQuery();
  const { isFetching: isLoadingGatewayLinkDetail } = useGatewayLinkDetailQuery();
  const { isLoading: isLoadingDeleteGatewayLinkImage, mutate: deleteGatewayLinkImage } = useDeleteGatewayLinkImageQuery();

  // store
  const { gatewayLinkDetail } = useSelector((store: RootState) => store.gatewayStore);
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  // state
  const [limitAmount, setLimitAmount] = useState(false);
  const [limitDate, setLimitDate] = useState(false);
  const [limitTransaction, setLimitTransaction] = useState(false);

  const onFinishUpdateLinkHandler = (values: IGatewayLinkPayload) => {
    updateGatewayLink(
      {
        ...values,
        is_show_process_bar: limitAmount ? values?.is_show_process_bar : false,
        payment_ceiling: limitAmount ? extractNumberFromString(values.payment_ceiling + '') : null,
        expired_at: limitDate ? convertJalaliDateToEnglish(values.expired_at) : null,
        time_expired_at: limitDate ? values.time_expired_at : null,
        deposit_min: limitTransaction ? extractNumberFromString(values.deposit_min + '') : null,
        deposit_max: limitTransaction ? extractNumberFromString(values.deposit_max + '') : null,
      },
      { onSuccess: () => (key === 'default' || state?.disableBackToUrl ? navigate(routes.PANEL_HOME.link(), { state: disableBackToUrl }) : navigate(-1)) }
    );
  };

  // upload image
  const onUpdateImageHandler = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files?.[0]?.size && e.currentTarget?.files?.[0]?.size > 5120000) {
      toastHandler('error', 'سایز عکس باید کمتر از 5 مگابایت باشد');
    } else {
      const files = new FormData();
      if (e.currentTarget?.files?.[0]) {
        files.append('cover_image', e.currentTarget?.files?.[0]);
        uploadProfileImage(files as any);
      }
    }
  };

  useEffect(() => {
    setLimitAmount(limitAmount || !!gatewayLinkDetail?.data?.payment_ceiling || !!gatewayLinkDetail?.data?.is_show_process_bar);
    setLimitDate(limitDate || !!gatewayLinkDetail?.data?.expired_at || !!gatewayLinkDetail?.data?.time_expired_at);
    setLimitTransaction(limitTransaction || !!gatewayLinkDetail?.data?.deposit_min || !!gatewayLinkDetail?.data?.deposit_max);

    updateGatewayLinkForm.setFieldsValue({
      ...gatewayLinkDetail?.data,
      ...(gatewayLinkDetail?.data?.expired_at ? { expired_at: convertEnglishDateToJalali(gatewayLinkDetail?.data?.expired_at) } : {}),
      ...(gatewayLinkDetail?.data?.payment_ceiling !== 0 ? { payment_ceiling: gatewayLinkDetail?.data?.payment_ceiling } : { payment_ceiling: undefined }),
      ...(gatewayLinkDetail?.data?.deposit_max !== 0 ? { deposit_max: gatewayLinkDetail?.data?.deposit_max } : { deposit_max: undefined }),
      ...(gatewayLinkDetail?.data?.deposit_min !== 0 ? { deposit_min: gatewayLinkDetail?.data?.deposit_min } : { deposit_min: undefined }),
      icon_id: gatewayLinkDetail?.data.icon?.id,
    });
  }, [gatewayLinkDetail]);

  useEffect(() => {
    updateGatewayLinkForm.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors.length]);

  const onDeleteGatewayLinkImageHandler = () => {
    deleteGatewayLinkImage({}, { onSuccess: () => resetUpdatedGatewayLinkImageData() });
  };

  return {
    onFinishUpdateLinkHandler,
    gatewayLinkDetail,
    imageUploadData,
    isLoadingUpdateGatewayLink,
    isLoadingUploadProfileImage,
    onUpdateImageHandler,
    imageRef,
    updateGatewayLinkForm,
    limitAmount,
    setLimitAmount,
    isLoadingGatewayLinkDetail,
    limitTransaction,
    setLimitTransaction,
    limitDate,
    setLimitDate,
    onDeleteGatewayLinkImageHandler,
    isLoadingDeleteGatewayLinkImage,
  };
};

export default useGatewayLinkProfessionalUpdate;
