import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import ZpDrawer from 'components/_Core/UI/Drawer/ZpDrawer';
import useModal from 'core/hooks/useModal';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { convertDatePickerRange, convertEnglishDateToJalali } from 'core/utils/utils';
import { useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import ZpCalendar from 'components/_Core/UI/Calendar/ZpCalendar';
import { DateObject } from 'react-multi-date-picker';

const GatewayInvoiceStatus = () => {
  // hooks
  const { modal, showModal, hideModal } = useModal();
  const { query, setUrlParam, deleteOneQueryParam, filterParams } = useQueryUrlParam();

  const [date, setDate] = useState<DateObject[] | DateObject | null | string[]>(null);

  const result =
    query['filter[date_between]']?.[0] && query['filter[date_between]']?.[1]
      ? `${convertEnglishDateToJalali(query['filter[date_between]']?.[0])} -- ${convertEnglishDateToJalali(query['filter[date_between]']?.[1])}`
      : query['filter[date_between]']?.[0] && !query['filter[date_between]']?.[1]
      ? `${convertEnglishDateToJalali(query['filter[date_between]']?.[0])}`
      : '';

  const onSubmitDateFilterHandler = () => {
    const dates = convertDatePickerRange(date as DateObject[] | null);

    (dates?.[0] || dates?.[1]) && setUrlParam({ ...query, 'filter[date_between]': [dates?.[0] || '', dates?.[1] || ''] });
  };

  const hideModalHandler = () => {
    hideModal();
    setDate(null);
  };

  useEffect(() => {
    (query['filter[date_between]']?.[0] || query['filter[date_between]']?.[1]) &&
      setDate([
        convertEnglishDateToJalali(query['filter[date_between]'][0]),
        ...(query['filter[date_between]'][1] ? [convertEnglishDateToJalali(query['filter[date_between]'][1])] : []),
      ]);
  }, [filterParams]);

  return (
    <>
      <ZpDrawer
        roundedTop
        className='bg-white-natural-color'
        height='auto'
        placement='bottom'
        open={modal}
        onClose={hideModalHandler}
        title={<span className='text-lg'>تاریخ تراکنش</span>}
      >
        <ZpWrapper singleColumn>
          <div className='flex justify-center'>
            <ZpCalendar value={date} onChange={setDate} range />
          </div>

          <ZpButton className='w-full' type='primary' onClick={onSubmitDateFilterHandler}>
            اعمال فیلتر
          </ZpButton>
        </ZpWrapper>
      </ZpDrawer>

      {query['filter[date_between]'] ? (
        <div className='border rounded-lg py-3 px-4 cursor-pointer border-primary-color-main' onClick={showModal}>
          <span className='flex items-center space-x-5 space-x-reverse justify-between text-primary-color-main'>
            <span>{result}</span>
            <AiOutlineCloseCircle
              size={20}
              onClick={(e) => {
                e.stopPropagation();
                deleteOneQueryParam('filter[date_between]');
              }}
            />
          </span>
        </div>
      ) : (
        <div className='border border-border-color rounded-lg py-3 px-4 cursor-pointer hover:border-primary-color-main' onClick={showModal}>
          <span className='flex items-center space-x-5 space-x-reverse justify-center'>
            <span>تاریخ</span>
            <MdOutlineKeyboardArrowDown size={20} />
          </span>
        </div>
      )}
    </>
  );
};

export default GatewayInvoiceStatus;
