import { FC, memo, ReactNode } from 'react';
import PanelHomeIndexFooterNavbar from 'components/_Core/Layouts/Panel/PanelHomeIndex/PanelHomeIndexFooterNavbar/PanelHomeIndexFooterNavbar';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import ProfileWidget from 'components/ProfileWidget/ProfileWidget';
import ThemeWidget from 'components/_Core/ThemeWidget/ThemWidget';
import MainHeaderLayout from 'components/_Core/Layouts/Main/MainHeader/MainHeaderLayout';
import MainContentLayout from 'components/_Core/Layouts/Main/MainContentLayout/MainContentLayout';
import MainLayout from 'components/_Core/Layouts/Main/MainLayout';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import logo from 'assets/images/logo/logo.svg';

interface IPanelHomeIndexLayout {
  children: ReactNode;
}

const PanelHomeIndexLayout: FC<IPanelHomeIndexLayout> = ({ children }) => {
  const { data: linkDetail } = useLinkDetailQuery();

  return (
    <MainLayout>
      <MainHeaderLayout>
        <div className='flex items-center justify-between h-full'>
          <div className='flex items-center space-x-3 space-x-reverse'>
            <ZpImage src={logo} width={50} />
            <span className='text-base'>{linkDetail?.data?.title_fa}</span>
          </div>

          {/*<div className='flex items-center space-x-5 space-x-reverse'>*/}
          {/*  <ThemeWidget />*/}
          {/*  <ProfileWidget />*/}
          {/*</div>*/}
        </div>
      </MainHeaderLayout>

      <MainContentLayout>{children}</MainContentLayout>

      <PanelHomeIndexFooterNavbar />
    </MainLayout>
  );
};

export default memo(PanelHomeIndexLayout);
