import MessengerBlock from 'components/BlocksComponents/MessengerBlock/MessengerBlock';
import BlockLayoutCreate from 'components/_Core/Layouts/Panel/PanelHomeBlockLayout/Create/PanelHomeBlockLayoutCreate';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';

const MessengerBlockPage = () => {
  return (
    <BlockLayoutCreate>
      <HeadMeta tabTitle='پیام رسان ها' />
      <MessengerBlock />
    </BlockLayoutCreate>
  );
};

export default MessengerBlockPage;
