import { FormInstance } from 'antd';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import externalLink from 'assets/images/externalLink.svg';
import { FC } from 'react';

interface IExternalLinkBlockCreate {
  createForm: FormInstance;
  isLoadingCreateExternalLink: boolean;
  onFinishCreateLinkHandler: (val: any) => void;
  hideChildModalHandler: () => void;
}

const ExternalLinkBlockCreate: FC<IExternalLinkBlockCreate> = ({ createForm, isLoadingCreateExternalLink, hideChildModalHandler, onFinishCreateLinkHandler }) => {
  return (
    <>
      <div className='flex items-center space-x-reverse space-x-5 mb-5'>
        <ZpImage className='rounded-full' src={externalLink} width={45} height={45} />
        <h2 className='text-lg font-bold'>لینک</h2>
      </div>

      <ZpForm form={createForm} onFinish={onFinishCreateLinkHandler}>
        <ZpWrapper singleColumn>
          <ZpFormItem rules={[{ required: true, message: 'عنوان لینک را وارد کنید' }]} name='title'>
            <ZpInputLabel required label='عنوان لینک' />
          </ZpFormItem>

          <ZpFormItem
            rules={[
              { required: true, message: 'آدرس لینک را وارد کنید' },
              { type: 'url', message: 'فرمت آدرس لینک اشتباه است' },
            ]}
            name='url'
          >
            <ZpInputLabel required textLeft label='آدرس لینک' />
          </ZpFormItem>

          <ZpFormItem button>
            <div className='flex items-center space-x-reverse space-x-5 justify-between'>
              <ZpButton buttonSize='xs' className='w-full' htmlType='button' ghost type='primary' onClick={hideChildModalHandler}>
                انصراف
              </ZpButton>
              <ZpButton buttonSize='xs' className='w-full' htmlType='submit' type='primary' isLoading={isLoadingCreateExternalLink}>
                ساخت بلوک
              </ZpButton>
            </div>
          </ZpFormItem>
        </ZpWrapper>
      </ZpForm>
    </>
  );
};

export default ExternalLinkBlockCreate;
