import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';

const UpdateLinkTitle = () => {
  return (
    <>
      <ZpFormItem name='title_fa' rules={[{ required: true, message: 'عنوان فارسی لینک را وارد کنید' }]}>
        <ZpInputLabel required label='عنوان فارسی لینک' />
      </ZpFormItem>
      <ZpFormItem name='description'>
        <ZpInputLabel label='توضیحات عنوان لینک' />
      </ZpFormItem>
    </>
  );
};

export default UpdateLinkTitle;
