import ZpBlockCreate from 'components/_Core/UI/BlockCreate/ZpBlockCreate';
import useNavigationBlock from 'components/BlocksComponents/NavigationBlock/hooks/useNavigationBlock';
import BlockListSkeleton from 'components/_Core/UI/BlockListSkeleton/BlockListSkeleton';
import NavigationBlockCreate from 'components/BlocksComponents/NavigationBlock/components/Create/NavigationBlockCreate';
import NavigationBlockList from 'components/BlocksComponents/NavigationBlock/components/List/NavigationBlockList';

const NavigationBlock = () => {
  const {
    onFinishCreateLinkHandler,
    createForm,
    childModal,
    hideChildModalHandler,
    isLoadingCreateNavigation,
    showChildModal,
    isLoadingLinkDetail,
    navigationList,
    hasBlock,
    zoomLevel,
    setZoomLevel,
    setLatAndLng,
    latAndLng,
  } = useNavigationBlock();

  if (isLoadingLinkDetail) return <BlockListSkeleton />;

  return (
    <>
      <ZpBlockCreate childModal={childModal} hasBlock={hasBlock} hideChildModal={hideChildModalHandler} showChildModal={showChildModal} title='آدرس من' isTypeList={false}>
        <NavigationBlockCreate
          zoomLevel={zoomLevel}
          setZoomLevel={setZoomLevel}
          setLatAndLng={setLatAndLng}
          latAndLng={latAndLng}
          createForm={createForm}
          hideChildModalHandler={hideChildModalHandler}
          isLoadingCreateNavigation={isLoadingCreateNavigation}
          onFinishCreateLinkHandler={onFinishCreateLinkHandler}
        />
      </ZpBlockCreate>

      <NavigationBlockList blocks={navigationList} />
    </>
  );
};

export default NavigationBlock;
