import { FC } from 'react';

interface IZpTrialBadge {
  trialCount: number;
  isPremiumBadge?: boolean;
}

const ZpTrialBadge: FC<IZpTrialBadge> = ({ trialCount, isPremiumBadge = true }) => {
  if (!trialCount) return <></>;

  return <span className={`w-4 h-4 text-sm bg-error-color-main text-white rounded-full block text-center ${isPremiumBadge ? 'absolute -top-1 -right-1' : ''}`}>{trialCount}</span>;
};

export default ZpTrialBadge;
