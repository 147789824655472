import useRoute from 'core/hooks/useRoute';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { useNavigate } from 'react-router-dom';
import useStepThree from 'components/Gateway/GatewayLinks/Create/Professional/StepThree/hooks/useStepThree';
import Container from 'components/_Core/Layouts/Container/Container';
import CreateGatewayLinkStepThreeSkeleton from 'components/Gateway/GatewayLinks/Create/Professional/StepThree/components/CreateGatewayLinkStepThreeSkeleton';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import ZpDatePickerLabel from 'components/_Core/UI/DatePicker/ZpDatePickerLabel';
import { DateObject } from 'react-multi-date-picker';
import { minDateToday, persianCalendar } from 'core/utils/utils';
import ZpTimeSelect from 'components/_Core/UI/Time/ZpTimeSelect';
import { BsCalendar2Date } from 'react-icons/bs';
import ZpSwitch from 'components/_Core/UI/Switch/ZpSwitch';
import ZpCheckbox from 'components/_Core/UI/Checkbox/ZpCheckbox';
import ZpCustomCollapse from 'components/_Core/UI/Collapse/ZpCustomCollapse';
import ZpInputNumberLabel from 'components/_Core/UI/Input/ZpInputNumberLabel';
import PanelCreateGatewayLinkLayoutFooter from 'components/_Core/Layouts/Panel/PanelCreateGatewayLinkLayout/PanelCreateGatewayLinkLayoutFooter';
import GatewayCreateLinkSuccess from 'components/_Core/UI/GatewayCreateLinkSuccess/GatewayCreateLinkSuccess';

const GatewayLinkCreateProfessionalStepThree = () => {
  const {
    isLoadingUpdateGatewayLinkStepTwo,
    onFinishUpdateLinkStepThreeHandler,
    gatewayLinkStepThreeForm,
    isLoading,
    limitTransaction,
    setLimitTransaction,
    setLimitAmount,
    limitAmount,
    limitDate,
    setLimitDate,
    modal,
  } = useStepThree();

  const { routes } = useRoute();
  const { filterParams } = useQueryUrlParam();
  const navigate = useNavigate();

  if (isLoading) return <CreateGatewayLinkStepThreeSkeleton />;

  return (
    <>
      <GatewayCreateLinkSuccess open={modal} />

      <Container className='h-full flex-1'>
        <ZpForm initialValues={{ is_show_process_bar: false }} form={gatewayLinkStepThreeForm} onFinish={onFinishUpdateLinkStepThreeHandler}>
          <ZpWrapper singleColumn>
            <div className='flex items-start space-x-5 py-3 space-x-reverse'>
              <ZpSwitch checked={limitAmount} onChange={(e) => setLimitAmount(e)} />

              <div>
                <p className='font-bold text-lg'>تعیین مبلغ هدف</p>
                <p className='text-color-placeholder'>با فعال کردن این گزینه می توانید حداکثر مبلغ مورد نیاز خود را مشخص کنید.</p>
              </div>
            </div>

            <ZpCustomCollapse open={limitAmount}>
              <div className='flex flex-col space-y-3'>
                <ZpFormItem rules={[{ required: limitAmount, message: 'سقف مبلغ را  انگلیسی وارد کنید' }]} name='payment_ceiling'>
                  <ZpInputNumberLabel required label='سقف مبلغ' suffixLabel='تومان' thousandSeparator />
                </ZpFormItem>

                <ZpFormItem valuePropName='checked' name='is_show_process_bar'>
                  <ZpCheckbox className='text-lg font-bold'> نمایش وضعیت پرداخت برای کاربران</ZpCheckbox>
                </ZpFormItem>
              </div>
            </ZpCustomCollapse>

            <div className='flex items-start space-x-5 py-3 space-x-reverse'>
              <ZpSwitch checked={limitDate} onChange={(e) => setLimitDate(e)} />

              <div>
                <p className='font-bold text-lg'>تعیین بازه زمانی برای لینک پرداخت</p>
                <p className='text-color-placeholder'>برای لینک پرداخت خود تاریخ و ساعت مشخص کنید.</p>
              </div>
            </div>

            <ZpCustomCollapse open={limitDate}>
              <div className='flex flex-col space-y-3'>
                <ZpFormItem name='expired_at' rules={[() => minDateToday('-'), { required: limitDate, message: 'تاریخ انقضا لینک پرداخت را وارد کنید' }]}>
                  <ZpDatePickerLabel
                    minDate={new DateObject(persianCalendar)}
                    label={
                      <div className='flex items-start space-x-2 space-x-reverse'>
                        <BsCalendar2Date size={18} />
                        <span>تاریخ انقضا لینک پرداخت</span>
                      </div>
                    }
                  />
                </ZpFormItem>

                <ZpFormItem rules={[{ required: limitDate, message: 'ساعت انقضا لینک پرداخت را وارد کنید' }]} name='time_expired_at'>
                  <ZpTimeSelect />
                </ZpFormItem>
              </div>
            </ZpCustomCollapse>

            <div>
              <div className='flex items-start py-3 space-x-5 space-x-reverse'>
                <ZpSwitch checked={limitTransaction} onChange={(e) => setLimitTransaction(e)} />

                <div>
                  <p className='font-bold text-lg'>تعیین سقف واریز هر تراکنش</p>
                  <p className='text-color-placeholder'>میزان مبلغ واریز در هر تراکنش را مشخص کنید. </p>
                </div>
              </div>

              <ZpCustomCollapse open={limitTransaction}>
                <div className='flex flex-col space-y-3'>
                  <ZpFormItem rules={[{ required: limitTransaction, message: 'حداقل واریزی را انگلیسی وارد کنید' }]} name='deposit_min'>
                    <ZpInputNumberLabel label='حداقل واریزی' suffixLabel='تومان' thousandSeparator />
                  </ZpFormItem>

                  <ZpFormItem rules={[{ required: limitTransaction, message: 'حداکثر واریزی را انگلیسی وارد کنید' }]} name='deposit_max'>
                    <ZpInputNumberLabel label='حداکثر واریزی' suffixLabel='تومان' thousandSeparator />
                  </ZpFormItem>
                </div>
              </ZpCustomCollapse>
            </div>
          </ZpWrapper>
        </ZpForm>
      </Container>

      <PanelCreateGatewayLinkLayoutFooter
        backStep={() => navigate(routes.GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_2.link(filterParams))}
        isLoading={isLoadingUpdateGatewayLinkStepTwo}
        createLinkForm={gatewayLinkStepThreeForm}
      />
    </>
  );
};

export default GatewayLinkCreateProfessionalStepThree;
