import userIcon from 'assets/images/icon/user.svg';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { FC } from 'react';
import { BsExclamationCircleFill } from 'react-icons/bs';
import ZpTooltip from 'components/_Core/UI/Tooltip/ZpTooltip';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { separator } from 'core/utils/utils';
import useRoute from 'core/hooks/useRoute';

const getZpLevel = (level: string | undefined) => {
  if (level === 'new' || level === 'blue' || level === 'basic') return { level_text: 'سطح آبی', amount: 2000000 };
  if (level === 'silver') return { level_text: 'سطح نقره‌ای', amount: 100000000 };
  if (level === 'gold') return { level_text: 'سطح طلایی', amount: 100000000 };
  return { level_text: '', amount: 0 };
};

const ZarinPalLevel: FC = () => {
  const { userInfo } = useSelector((store: RootState) => store.userStore);
  const { level_text, amount } = getZpLevel(userInfo?.data?.zp_level?.toLowerCase());
  const { routes } = useRoute();

  return (
    <div className='flex items-center space-x-reverse space-x-2'>
      <ZpImage width={18} src={userIcon} />
      <span className='text-blue-color-main'>{level_text}</span>
      {/*<ZpTooltip*/}
      {/*  title={*/}
      {/*    <p onClick={(e) => e.stopPropagation()}>*/}
      {/*      <span>در این سطح در هر تراکنش فقط مبلغ {separator(amount)} تومان قابل واریز است . برای ارتقای سطح عضویت خود می توانید به</span>*/}
      {/*      <a href={routes.ZARINPAL} target='_blank' rel='noreferrer' className='text-warning-color-400 hover:underline mx-1 inline-block'>*/}
      {/*        پنل زرین پال*/}
      {/*      </a>*/}
      {/*      <span>خود رجوع کنید.</span>*/}
      {/*    </p>*/}
      {/*  }*/}
      {/*>*/}
      {/*  <BsExclamationCircleFill size={17} color='var(--natural-color-500)' />*/}
      {/*</ZpTooltip>*/}
    </div>
  );
};

export default ZarinPalLevel;
