import GatewayLayout from 'components/_Core/Layouts/Panel/PanelHomeGatewayLout/PanelHomeGatewayLayout';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';
import useRoute from 'core/hooks/useRoute';
import GatewayLinkProfessionalUpdate from 'components/Gateway/GatewayLinks/Update/Professional/GatewayLinkProfessionalUpdate';

const GatewayLinkProfessionalUpdatePage = () => {
  const { routes } = useRoute();
  const headerTitle = 'ویرایش لینک پرداخت پیشرفته';

  return (
    <GatewayLayout showPanelDashboardFooterNavbar={false} enableGatewayDetail={false} headerTitle={headerTitle} backUrl={routes.PANEL_HOME.link()}>
      <HeadMeta tabTitle={headerTitle} />
      <GatewayLinkProfessionalUpdate />
    </GatewayLayout>
  );
};

export default GatewayLinkProfessionalUpdatePage;
