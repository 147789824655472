import { FC } from 'react';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import MapNavigation from 'components/LinkPreview/components/Navigation/MapNavigation';
import navigation2 from 'assets/images/icon/navigation2.svg';
import MapNavigationButtons from 'components/LinkPreview/components/Navigation/MapNavigationButtons';
import { IBlockDetail } from 'core/types/Models/linkType';
import useTemplatePreview from 'components/_Core/UI/PreviewTemplate/hooks/useTemplatePreview';

interface IPreviewTemplateNavigation {
  block: IBlockDetail;
  staticIcon: string;
}

const PreviewTemplateNavigation: FC<IPreviewTemplateNavigation> = ({ block, staticIcon }) => {
  const { has_block_icon, blockBackgroundImage, blockStyles, blockBorder } = useTemplatePreview();

  return (
    <div style={{ ...blockStyles, ...blockBackgroundImage.object }} className={`w-full relative py-5 px-7 ${blockBorder}`}>
      <div className='absolute w-full h-full top-0 right-0 -z-10' />

      <div className='space-y-5'>
        <div className='space-y-5'>
          <div className='space-x-3 space-x-reverse flex items-center justify-between'>
            <div className='flex items-center space-x-4 space-x-reverse'>
              {has_block_icon && (
                <div className='w-11 h-11'>
                  <ZpImage alt='' className='rounded-full h-full w-full' width={45} height={45} src={staticIcon} />
                </div>
              )}
              <span className='line-clamp-2 text-block_name_color text-lg'>{block.title}</span>
            </div>
          </div>

          <div className='space-y-3'>
            <MapNavigation block={block} />

            {block?.description && (
              <div className='border border-block_border_color rounded-lg p-3 flex items-center space-x-3 space-x-reverse'>
                <div className='max-w-[20px] w-full flex'>
                  <ZpImage alt='icon' className='rounded-full' width={17} height={25} src={navigation2} />
                </div>

                <p className='text-block_name_color text-sm'>{block?.description}</p>
              </div>
            )}
          </div>
        </div>

        <MapNavigationButtons block={block} />
      </div>
    </div>
  );
};

export default PreviewTemplateNavigation;
