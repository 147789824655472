import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryOnSuccessHandler from 'core/helpers/queryOnSuccessHandler/useQueryOnSuccessHandler';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import useRoute from 'core/hooks/useRoute';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';

export const useDeleteGatewayLinkImageQuery = () => {
  const { onSuccessHandler } = useQueryOnSuccessHandler();
  const { gatewayId, gatewayLinkId } = useRoute();
  const { query } = useQueryUrlParam();
  const queryClient = useQueryClient();

  return useMutationHook(
    [endPointUrls.GATEWAY_LINK_DELETE_IMAGE(query?.gatewayId || gatewayId + '', 'delete')],
    () => api.delete(endPointUrls.GATEWAY_LINK_DELETE_IMAGE(query?.gatewayId || gatewayId + '', query?.gatewayLinkId || gatewayLinkId)),
    {
      onSuccess: (res: any) => {
        onSuccessHandler(res);
        queryClient.invalidateQueries([endPointUrls.GATEWAY_LINK(query?.gatewayId || gatewayId, query?.gatewayLinkId || gatewayLinkId)]);
      },
    }
  ) as UseMutationResult;
};
