import ZpDrawer from 'components/_Core/UI/Drawer/ZpDrawer';
import { FC, memo } from 'react';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { ReactComponent as Lock } from 'assets/images/icon/menu-icon/lock.svg';
import logo from 'assets/images/logo/logo-text.svg';
import premiumIcon from 'assets/images/icon/menu-icon/premium.svg';
import { IMenuItem } from 'core/types/Components/MenuItem/PanelHomeFooterNavbarItemType';
import ZpLink from 'components/_Core/UI/Link/ZpLink';
import { isSelectedItemRoute } from 'core/utils/utils';
import { DrawerProps } from 'antd';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import ThemeWidget from 'components/_Core/ThemeWidget/ThemWidget';
import ZpPremiumBadge from 'components/_Core/UI/PremiumBadge/ZpPremiumBadge';
import { setOpenMenuHandler } from 'core/store/slice/setting/settingSlice';
import ZpTrialBadge from 'components/_Core/UI/PremiumBadge/ZpTrialBadge';
import PlanDrawer from 'components/Plans/components/PlanDrawer/PlanDrawer';
import useZpModal from 'components/_Core/UI/Menu/hooks/useZpMenu';

interface IZpMenu extends DrawerProps {
  items: IMenuItem[];
}

const ZpMenu: FC<IZpMenu> = ({ items, getContainer }) => {
  const { showMenuHandler, hideModalPlan, showModalPlan, modalPlan, name, mobile, isOpenMenu, dispatch, hasPlan } = useZpModal();

  return (
    <>
      <PlanDrawer open={modalPlan} hideDrawer={hideModalPlan} />

      <ZpDrawer
        closable={false}
        open={isOpenMenu}
        onClose={() => dispatch(setOpenMenuHandler(false))}
        placement='right'
        forceRender
        getContainer={getContainer}
        afterOpenChange={showMenuHandler}
        className='fixed w-[220px]'
      >
        <div className='space-y-3.5'>
          <div className='text-center'>
            <ZpImage src={logo} width={150} />
          </div>

          <div className='space-y-3'>
            <ZpPremiumBadge width={40} height={40}>
              <div className='bg-primary-color-main text-white rounded-md flex flex-col px-3 py-1'>
                <span>{name}</span>
                <span>{mobile}</span>
              </div>
            </ZpPremiumBadge>

            {!hasPlan && (
              <div
                onClick={showModalPlan}
                className='bg-secondary-color-main text-white rounded-md flex items-center space-x-3 space-x-reverse px-3 py-2 cursor-pointer hover:bg-secondary-color-300'
              >
                <ZpImage src={premiumIcon} />
                <span className='text-base font-bold'>حرفه‌ای باش!</span>
              </div>
            )}
          </div>
        </div>

        <div className='space-y-3 mt-7'>
          <ThemeWidget />
          {items.map((item) => (
            <MenuItem key={item.title} {...item} />
          ))}
        </div>
      </ZpDrawer>
    </>
  );
};

const MenuItem: FC<IMenuItem> = ({ title, icon, to, onClick, premium, keyRoute, component, trialDays, external }) => {
  const { pathname } = useQueryUrlParam();
  const active = !keyRoute ? isSelectedItemRoute(to + '', pathname) : pathname.includes(keyRoute);

  const Item = component ? (
    <>{component}</>
  ) : (
    <div className={`flex items-center justify-between cursor-pointer px-2 py-1.5 rounded-lg ${active ? 'bg-natural-color-100' : ''}`} onClick={onClick}>
      <div className='flex items-center space-x-reverse space-x-4'>
        <span className='flex'>{icon}</span>
        <span>{title}</span>
      </div>
      {!!trialDays ? <ZpTrialBadge isPremiumBadge={false} trialCount={trialDays || 0} /> : premium && <Lock />}
    </div>
  );
  return to ? (
    <ZpLink external={external} className='block' to={to}>
      {Item}
    </ZpLink>
  ) : (
    Item
  );
};

export default memo(ZpMenu);
