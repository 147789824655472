import { Form } from 'antd';
import { IBlockDetail } from 'core/types/Models/linkType';
import useUpdateGateway from 'components/BlocksComponents/GatewayBlock/hooks/useUpdateGateway';
import ZpBlockDelete from 'components/_Core/UI/BlockDelete/ZpBlockDelete';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import useRoute from 'core/hooks/useRoute';
import { useNavigate } from 'react-router';

const GatewayBlockUpdate = ({ block }: { block: IBlockDetail | undefined }) => {
  const [formUpdate] = Form.useForm();
  const { routes } = useRoute();
  const navigate = useNavigate();
  const { hideModal, modal, showModal, isLoadingDeleteGateway, onDeleteHandler, linkGatewayDetail } = useUpdateGateway(formUpdate, block);

  return (
    <>
      <ZpBlockDelete modal={modal} hideModal={hideModal} isLoadingDelete={isLoadingDeleteGateway} onDeleteHandler={onDeleteHandler} blockId={block?.id} />

      <div className='flex items-center justify-between space-x-reverse space-x-7'>
        <ZpButton danger type='primary' ghost onClick={showModal}>
          حذف
        </ZpButton>
        <ZpButton
          type='primary'
          onClick={() =>
            block?.icon
              ? navigate(routes.GATEWAY_LINK_PROFESSIONAL_UPDATE.link(block?.id, linkGatewayDetail?.id))
              : navigate(routes.GATEWAY_LINK_SIMPLE_UPDATE.link(block?.id, linkGatewayDetail?.id))
          }
        >
          ویرایش
        </ZpButton>
      </div>
    </>
  );
};

export default GatewayBlockUpdate;
