import { createSlice } from '@reduxjs/toolkit';
import { ILinkDetail, ILinkList } from 'core/types/Models/linkType';
import { IGateway } from 'core/types/Models/gatewayType';
import { IStatusResult } from 'core/types/Models/accountBankType';
import { getStatus } from 'core/utils/utils';

const initialState: {
  linkDetail: ILinkDetail | null;
  linkList: ILinkList | null;
  hasGateway: boolean;
  hasGatewayLinks: boolean;
  hasMoreThanOneGatewayLink: boolean;
  hasActiveGateway: boolean;
  gatewayStatus: IStatusResult | null;
  linkGatewayDetail: IGateway | null;
} = {
  linkDetail: null,
  linkList: null,
  hasGateway: false,
  linkGatewayDetail: null,
  hasGatewayLinks: false,
  hasMoreThanOneGatewayLink: false,
  hasActiveGateway: false,
  gatewayStatus: null,
};

export const linkSlice = createSlice({
  name: 'link',
  initialState: initialState,
  reducers: {
    setLinkDetail: (state, { payload }: { payload: ILinkDetail }) => {
      state.linkDetail = payload;
      state.hasGateway = payload?.data?.has_gateway;
      state.gatewayStatus = payload?.data?.gateways?.length === 0 ? null : getStatus(payload?.data?.gateways?.[0]?.status);
      state.hasActiveGateway = payload?.data?.gateways?.length === 0 ? false : payload?.data?.gateways?.[0]?.status === 'ACTIVE';
      state.hasMoreThanOneGatewayLink = payload?.data?.has_gateway && (payload?.data?.gateways?.length === 0 ? false : payload?.data?.gateways?.[0]?.payment_links?.length >= 1);
      state.hasGatewayLinks = payload?.data?.gateways?.length === 0 ? false : payload?.data?.gateways?.[0]?.payment_links?.length !== 0;
      state.linkGatewayDetail = payload?.data?.gateways?.length === 0 ? null : payload?.data?.gateways?.[0];
    },
    setLinkList: (state, { payload }) => {
      state.linkList = payload;
    },
  },
});

export const { setLinkDetail, setLinkList } = linkSlice.actions;
export default linkSlice.reducer;
