import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import api from 'core/services/fetch-api/useApi';
import useQueryUrlParam from '../useQueryUrlParam';
import { useNavigate } from 'react-router-dom';
import useQueryOnErrorHandler from 'core/helpers/queryOnErrorHandler/useQueryOnErrorHandler';
import useRoute from '../useRoute';
import { IGatewayLinkDetail } from 'core/types/Models/gatewayLinkType';
import { setGatewayLinkDetail } from 'core/store/slice/gateway/gatewaySlice';
import { useDispatch } from 'react-redux';

export const useGatewayLinkDetailQuery = (enableGatewayLinkDetail?: boolean) => {
  // hooks
  const dispatch = useDispatch();
  const { query } = useQueryUrlParam();
  const { onErrorHandler } = useQueryOnErrorHandler();
  const navigate = useNavigate();
  const { routes, gatewayLinkId, gatewayId } = useRoute();

  return useQueryHook(
    [endPointUrls.GATEWAY_LINK(query?.gatewayId || gatewayId, query?.gatewayLinkId || gatewayLinkId)],
    () => api.get(endPointUrls.GATEWAY_LINK(query?.gatewayId || gatewayId, query?.gatewayLinkId || gatewayLinkId)),
    {
      select: (res: any) => res.data,
      enabled: !!(enableGatewayLinkDetail && (!!query?.gatewayId || !!gatewayId) && (!!gatewayLinkId || !!query?.gatewayLinkId)),

      onSuccess: (res) => {
        dispatch(setGatewayLinkDetail(res));
      },

      onError: async (err: any) => {
        onErrorHandler(err, true);

        if (err.response.status === 404) {
          navigate(routes.NOTFOUND);
        }
      },
    }
  ) as UseQueryResult<IGatewayLinkDetail, Error>;
};
