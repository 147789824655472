import ZpImage from 'components/_Core/UI/Image/ZpImage';
import empty from 'assets/images/emptyBlock.svg';

const EmptyLinkDetail = () => {
  return (
    <div className='flex items-center justify-center h-full'>
      <div className='text-center'>
        <ZpImage width={400} height={400} alt='بلاک وجود ندارد' src={empty} />
        <p className='font-bold text-xl'>بلوکی وجود ندارد!</p>
      </div>
    </div>
  );
};

export default EmptyLinkDetail;
