import useAccountBank from 'components/Gateway/components/AccountBank/hooks/useAccountBank';
import ZpSelectLabel from 'components/_Core/UI/Select/ZpSelectLabel';
import { Divider, SelectProps } from 'antd';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { getStatus } from 'core/utils/utils';
import AccountBankCreate from 'components/Gateway/components/AccountBank/components/AccountBankCreate';
import Reload from 'components/_Core/UI/Reload';

const AccountBank = ({ ...props }) => {
  const {
    showModalDrawer,
    modalDrawer,
    hideModalDrawer,
    onReloadAccountBankHandler,
    bankCardType,
    setBankCardType,
    isLoadingAccountBanks,
    accountBanks,
    hasOneAcceptedBankAccount,
  } = useAccountBank();

  const options: SelectProps['options'] = accountBanks?.map((item) => ({
    label: (
      <div className='flex items-center space-x-5 space-x-reverse'>
        <ZpImage src={item.issuing_bank.slug_image} className='rounded-full' width={25} />
        <p className='flex items-center justify-between flex-1'>
          <span>{item.iban}</span>
          <span style={{ color: getStatus(item.status).color }}>{getStatus(item.status).message}</span>
        </p>
      </div>
    ),
    value: +item.id,
    filter: item.iban,
    disabled: getStatus(item.status).disabled,
  }));

  return (
    <>
      <AccountBankCreate
        hasOneAcceptedBankAccount={hasOneAcceptedBankAccount}
        setBankCardType={setBankCardType}
        bankCardType={bankCardType}
        modal={modalDrawer}
        hideModal={hideModalDrawer}
      />

      <ZpSelectLabel
        showSearch={false}
        required
        label='حساب بانکی'
        options={options}
        loading={isLoadingAccountBanks}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <div className='flex items-center justify-between px-4'>
              <ZpButton type='link' onClick={showModalDrawer} className='px-0'>
                <span className='flex items-center space-x-3 space-x-reverse'>
                  <AiOutlinePlusCircle size={22} />
                  <span>افزودن حساب بانکی</span>
                </span>
              </ZpButton>

              <Reload onReloadHandler={onReloadAccountBankHandler} isLoading={isLoadingAccountBanks} />
            </div>
          </>
        )}
        {...props}
      />
    </>
  );
};

export default AccountBank;
