import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import UpdateLinkImage from './components/UpdateLinkImage/UpdateLinkImage';
import UpdateLinkTitle from './components/UpdateLinkTitle/UpdateLinkTitle';
import PanelUpdateLinkLayoutFooter from 'components/_Core/Layouts/Panel/PanelUpdateLinkLayout/PanelUpdateLinkLayoutFooter';
import LinkCategoryItems from 'components/CreateLink/StepThree/components/LinkCategoryItems';
import useUpdateLink from './hooks/useUpdateLink';
import UpdateLinkSkeleton from './components/UpdateLinkSkeleton/UpdateLinkSkeleton';
import MainContentLayout from 'components/_Core/Layouts/Main/MainContentLayout/MainContentLayout';

const UpdateLink = () => {
  const {
    selectedTypeKeep,
    imageRef,
    onUpdateImageHandler,
    selectedTypeKeepHandler,
    linkDetail,
    linkCategories,
    updateLinkForm,
    onFinishUpdateLinkHandler,
    isLoadingDetail,
    isLoadingUpdateLink,
    isLoadingUploadProfileImage,
    imageUploadData,
  } = useUpdateLink();

  if (isLoadingDetail)
    return (
      <MainContentLayout>
        <UpdateLinkSkeleton />
      </MainContentLayout>
    );

  return (
    <>
      <MainContentLayout>
        <div className='flex flex-col justify-between min-h-screen'>
          <ZpForm onFinish={onFinishUpdateLinkHandler} form={updateLinkForm}>
            <ZpWrapper singleColumn>
              <UpdateLinkImage
                isLoading={isLoadingUploadProfileImage}
                imageRef={imageRef}
                linkDetail={imageUploadData?.data || linkDetail}
                onUpdateImageHandler={onUpdateImageHandler}
              />
              <UpdateLinkTitle />

              <div>
                <p className='mb-2'>حوزه فعالیت :</p>
                <LinkCategoryItems selectedTypeKeep={selectedTypeKeep} selectedTypeKeepHandler={selectedTypeKeepHandler} linkCategories={linkCategories} />
              </div>
            </ZpWrapper>
          </ZpForm>
        </div>
      </MainContentLayout>

      <PanelUpdateLinkLayoutFooter isLoading={isLoadingUpdateLink} updateLinkForm={updateLinkForm} />
    </>
  );
};

export default UpdateLink;
