import emptyBlock from 'assets/images/emptyBlock.svg';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { useCreateBlockTemplateQuery } from 'components/Dashboard/hooks/react-query/useCreateBlockTemplateQuery';
import { setOpenBlockListHandler } from 'core/store/slice/setting/settingSlice';
import { useDispatch } from 'react-redux';

const EmptyDashboard = () => {
  // hooks
  const dispatch = useDispatch();

  // query
  const { mutate: createBlockTemplate, isLoading } = useCreateBlockTemplateQuery();

  return (
    <div className='absolute text-center space-y-8 top-36 left-1/2 -translate-x-1/2 max-w-[300px] w-full'>
      <div>
        <ZpImage src={emptyBlock} />
        <p className='font-bold text-xl text-center'>بلوکی وجود ندارد!</p>
      </div>

      <div className='flex flex-col space-y-4'>
        <ZpButton fullWith type='primary' onClick={() => dispatch(setOpenBlockListHandler(true))}>
          ساخت اولین بلوک
        </ZpButton>

        <ZpButton fullWith ghost type='primary' onClick={createBlockTemplate} isLoading={isLoading}>
          استفاده از نمونه آماده
        </ZpButton>
      </div>
    </div>
  );
};

export default EmptyDashboard;
