import { Form } from 'antd';
import { FormEvent, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useUpdateLinkQuery } from './react-query/useUpdateLinkQuery';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import toastHandler from 'core/helpers/toast/toast';
import { useUpdateLinkProfileImageQuery } from './react-query/useUpdateLinkProfileImageQuery';

const useCreateLinkStepTwo = () => {
  //hooks
  const [updateLinkStepTwoForm] = Form.useForm();
  const { query } = useQueryUrlParam();
  const imageRef = useRef<HTMLInputElement>(null);

  // query
  const { mutate: updateLink, isLoading: isLoadingUpdateLinkStepTwo } = useUpdateLinkQuery('CREATE_LINK_STEP_3');
  const { data: linkDetail, isFetching: isLoadingLinkDetail } = useLinkDetailQuery();
  const { mutate: uploadProfileImage, isLoading: isLoadingUploadProfileImage, data: imageUploadData } = useUpdateLinkProfileImageQuery();

  // store
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  const onFinishUpdateLinkStepTwoHandler = (values: { username: string; title_fa: string }) => {
    // if (!linkDetail?.data?.image_profile) return toastHandler('error', 'عکس پروفایل خود را آپلود کنید');
    updateLink(values);
  };

  useEffect(() => {
    query?.username && updateLinkStepTwoForm.setFieldsValue({ description: linkDetail?.data.description });
  }, [linkDetail]);

  useEffect(() => {
    updateLinkStepTwoForm.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors.length]);

  // upload image
  const onUpdateImageHandler = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files?.[0]?.size && e.currentTarget?.files?.[0]?.size > 5120000) {
      toastHandler('error', 'سایز عکس باید کمتر از 5 مگابایت باشد');
    } else {
      const files = new FormData();
      if (e.currentTarget?.files?.[0]) {
        files.append('profile_image', e.currentTarget?.files?.[0]);
        uploadProfileImage(files as any);
      }
    }
  };

  return {
    updateLinkStepTwoForm,
    imageUploadData,
    linkDetail,
    onFinishUpdateLinkStepTwoHandler,
    isLoadingUpdateLinkStepTwo,
    isLoadingLinkDetail,
    isLoadingUploadProfileImage,
    imageRef,
    onUpdateImageHandler,
  };
};

export default useCreateLinkStepTwo;
