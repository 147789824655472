import { Button, ButtonProps } from 'antd';
import React, { FC, memo, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import style from 'components/_Core/UI/Button/ZpButton.module.scss';
import { PulseLoader } from 'react-spinners';

interface IZpButton extends ButtonProps {
  children: ReactNode;
  buttonIcon?: ReactNode;
  className?: string;
  isLoading?: boolean;
  fullWith?: boolean;
  buttonSize?: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
}

const ZpButton: FC<IZpButton> = ({ children, ghost, buttonIcon, className, type, buttonSize = 'base', isLoading = false, fullWith, ...props }) => {
  const buttonIconContent = (
    <span className='flex items-center justify-center space-x-3 space-x-reverse'>
      <span>{children}</span>
      {buttonIcon}
    </span>
  );

  const Content = isLoading ? (
    <PulseLoader className='relative top-1' color={props.danger ? '#fff' : 'var(--primary-color-400)'} size={10} />
  ) : buttonIcon ? (
    buttonIconContent
  ) : (
    children
  );

  return (
    <Button
      type={type}
      ghost={ghost}
      className={twMerge(`${style[`btn-size--${buttonSize}`]} ${fullWith ? 'w-full' : ''} ${isLoading && type === 'primary' && !ghost ? 'bg-primary-color-100' : ''} ${className}`)}
      {...props}
    >
      {Content}
    </Button>
  );
};

export default memo(ZpButton);
