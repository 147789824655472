import CopyLink from 'components/DashboardLinkList/components/Links/components/CopyLink';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';
import {
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import logoIcon from 'assets/images/logo/logo.svg';
import LinkShareSkeleton from './components/LinkShareSkeleton';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';

const LinkShare = () => {
  const { linkDetail } = useSelector((store: RootState) => store.linkStore);
  const { isFetching: isFetchingLinkDetail } = useLinkDetailQuery();

  if (isFetchingLinkDetail) return <LinkShareSkeleton />;

  return (
    <div className='space-y-5'>
      <p className='text-color-paragraph'>
        ساخت {process.env.REACT_APP_BRAND_NAME_FA} به تنهایی کافی نیست ، میتوانید با انتشار لینک در شبکه های اجتماعی خود ، با مخاطبین خود بیشتر در ارتباط باشد.
      </p>

      <CopyLink className='bg-block-item-bg' isIcon isPreview username={linkDetail?.data.username || ''} />

      <div className='flex items-center space-x-4 space-x-reverse justify-center'>
        <FacebookShareButton url={`${window.location.hostname}/${linkDetail?.data.username || ''}`}>
          <FacebookIcon size={45} round />
        </FacebookShareButton>

        <TelegramShareButton url={`${window.location.hostname}/${linkDetail?.data.username || ''}`}>
          <TelegramIcon size={45} round />
        </TelegramShareButton>

        <WhatsappShareButton url={`${window.location.hostname}/${linkDetail?.data.username || ''}`}>
          <WhatsappIcon size={45} round />
        </WhatsappShareButton>

        <LinkedinShareButton url={`${window.location.hostname}/${linkDetail?.data.username || ''}`}>
          <LinkedinIcon size={45} round />
        </LinkedinShareButton>

        <TwitterShareButton url={`${window.location.hostname}/${linkDetail?.data.username || ''}`}>
          <TwitterIcon size={45} round />
        </TwitterShareButton>
      </div>

      <div className='rounded-lg border bg-[var(--shareLink-bg)] border-natural-color-200 py-5 px-4 mx-4'>
        <div className='text-center space-y-6 flex flex-col items-center justify-center'>
          <div className='space-y-2'>
            <ZpImage
              src={(process.env.REACT_APP_API_FILE_BASE_URL as string) + linkDetail?.data?.image_profile || undefined}
              className='rounded-full border border-natural-color-300'
              width={120}
              height={120}
            />
            <p className='text-xl font-bold'>{linkDetail?.data.title_fa}</p>
            <p className='text-color-paragraph'>{linkDetail?.data.description}</p>
          </div>

          <div className='space-y-2'>
            <ZpImage src={logoIcon} width={75} />
            <p className='text-primary-color-400 dark:text-primary-color-100'>با من از طریق لینک زیر در ارتباط باشید:</p>
            <p className='text-primary-color-400 dark:text-primary-color-100 text-xl font-bold'>
              {window.location.hostname}/{linkDetail?.data.username || ''}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkShare;
