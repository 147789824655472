import { FormInstance } from 'antd';
import { IBlockDetail } from 'core/types/Models/linkType';
import { useEffect, useState } from 'react';
import { useUpdateNavigationQuery } from 'components/BlocksComponents/NavigationBlock/hooks/react-query/useUpdateNavigationQuery';
import { useDeleteNavigationQuery } from 'components/BlocksComponents/NavigationBlock/hooks/react-query/useDeleteNavigationQuery';
import useModal from 'core/hooks/useModal';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';
import toastHandler from 'core/helpers/toast/toast';
import { LAT_INITIAL, LNG_INITIAL } from 'core/constants/constant';

const useUpdateNavigation = (form: FormInstance, block: IBlockDetail | undefined) => {
  // hooks
  const { hideModal, modal, showModal } = useModal();

  // query
  const { mutate: updateNavigation, isLoading: isLoadingUpdateNavigation } = useUpdateNavigationQuery();
  const { mutate: deleteNavigation, isLoading: isLoadingDeleteNavigation } = useDeleteNavigationQuery();

  // state
  const [latAndLng, setLatAndLng] = useState<[number, number]>([block?.lat ? +block?.lat : LAT_INITIAL, block?.lng ? +block?.lng : LNG_INITIAL]);
  const [zoomLevel, setZoomLevel] = useState(10);

  // store
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  useEffect(() => {
    block && form.setFieldsValue({ ...block });
  }, []);

  useEffect(() => {
    const hasError = apiCatcherFormErrors.every((item) => item?.payload?.id === block?.id);
    hasError && form.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors]);

  const onFinishUpdateHandler = (values: { description: string; title: string; lat: string; lng: string }) => {
    if (zoomLevel < 14 || !latAndLng) return toastHandler('error', 'موقعیت خود را از روی نقشه انتخاب کنید');

    updateNavigation({ ...values, lat: latAndLng && latAndLng[0] + '', lng: latAndLng && latAndLng[1] + '', order: block?.order, id: block?.id });
  };

  const onDeleteHandler = () => {
    deleteNavigation({ id: block?.id });
  };

  return {
    onFinishUpdateHandler,
    isLoadingUpdateNavigation,
    isLoadingDeleteNavigation,
    onDeleteHandler,
    hideModal,
    modal,
    showModal,
    latAndLng,
    setLatAndLng,
    zoomLevel,
    setZoomLevel,
  };
};

export default useUpdateNavigation;
