import { Input, InputProps, InputRef } from 'antd';
import { forwardRef, memo } from 'react';
import { twMerge } from 'tailwind-merge';

export interface IInput extends InputProps {
  className?: string;
  value?: any;
  setValue?: (val: any) => void;
}

const ZpInput = forwardRef<InputRef, IInput>(({ className, value, setValue, ...props }, ref) => {
  return (
    <Input
      value={value}
      onChange={setValue ? (e) => setValue(e.currentTarget.value) : () => {}}
      ref={ref}
      className={twMerge(`disabled:bg-[var(--input-disable-bg)] bg-white-natural-color disabled:text-gray-color-500 text-color-main py-3 px-4 ${className}`)}
      {...props}
    />
  );
});

ZpInput.displayName = 'ZpInput';

export default memo(ZpInput);
