import LinkPreview from 'components/LinkPreview/LinkPreview';
import PanelLinkPreview from 'components/_Core/Layouts/Panel/PanelPreviewLayout/PanelPreviewLayout';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';

const LinkPreviewPage = () => {
  return (
    <PanelLinkPreview>
      <HeadMeta tabTitle='پیش نمایش' />
      <LinkPreview />
    </PanelLinkPreview>
  );
};

export default LinkPreviewPage;
