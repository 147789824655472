import { Helmet } from 'react-helmet-async';
import { FC } from 'react';

interface IHeadMeta {
  tabTitle: string;
}

export const HeadMeta: FC<IHeadMeta> = ({ tabTitle }) => {
  return (
    <Helmet>
      <title>
        {tabTitle} {process.env.REACT_APP_TAB_NAME_FA}
      </title>
    </Helmet>
  );
};
