import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryOnSuccessHandler from 'core/helpers/queryOnSuccessHandler/useQueryOnSuccessHandler';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import { ICardToIbanMutationResponse } from 'core/types/Models/cardToIbanType';

export const useConvertToShebaQuery = () => {
  const { onSuccessHandler } = useQueryOnSuccessHandler();

  return useMutationHook([endPointUrls.CARD_TO_IBAN], (data: any) => api.post(endPointUrls.CARD_TO_IBAN, data), {
    onSuccess: (res: any) => {
      onSuccessHandler(res);
    },
  }) as UseMutationResult<ICardToIbanMutationResponse>;
};
