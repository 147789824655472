import useModal from 'core/hooks/useModal';
import { Form } from 'antd';
import { useCreateExternalLinkQuery } from './react-query/useCreateExternalLinkQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import useRoute from 'core/hooks/useRoute';

const useExternalLinkBlock = () => {
  // hooks
  const { modal: childModal, hideModal: hideChildModal, showModal: showChildModal } = useModal();
  const [createForm] = Form.useForm();
  const navigate = useNavigate();
  const { routes } = useRoute();

  // store
  const { linkDetail } = useSelector((store: RootState) => store.linkStore);
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  // query
  const { isFetching: isLoadingLinkDetail } = useLinkDetailQuery();
  const { mutate: createExternalLink, isLoading: isLoadingCreateExternalLink } = useCreateExternalLinkQuery();

  const ExternalLinkList = linkDetail?.data?.blocks?.filter((block) => block.type.toLowerCase().includes('externallink'));
  const hasBlock = !!ExternalLinkList?.[0]?.data?.length;

  const hideChildModalHandler = () => {
    createForm.resetFields();
    hideChildModal();
  };

  const onFinishCreateLinkHandler = (values: { username: string; title: string }) => {
    createExternalLink(
      { ...values, order: hasBlock ? ExternalLinkList?.[0]?.data?.[ExternalLinkList?.[0]?.data?.length - 1]?.order + 1 : 1 },
      {
        onSuccess: () => {
          hideChildModalHandler();
          navigate(routes.PANEL_HOME.link());
        },
      }
    );
  };

  useEffect(() => {
    createForm.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors.length]);

  return {
    onFinishCreateLinkHandler,
    createForm,
    childModal,
    hideChildModalHandler,
    isLoadingCreateExternalLink,
    showChildModal,
    isLoadingLinkDetail,
    ExternalLinkList,
    hasBlock,
  };
};

export default useExternalLinkBlock;
