import { FC, memo, ReactNode } from 'react';
import useRoute from 'core/hooks/useRoute';
import MainHeaderLayout from 'components/_Core/Layouts/Main/MainHeader/MainHeaderLayout';
import MainLayout from 'components/_Core/Layouts/Main/MainLayout';
import MainContentLayout from 'components/_Core/Layouts/Main/MainContentLayout/MainContentLayout';
import PanelHomeIndexFooterNavbar from 'components/_Core/Layouts/Panel/PanelHomeIndex/PanelHomeIndexFooterNavbar/PanelHomeIndexFooterNavbar';

interface IPanelTemplateLayout {
  children: ReactNode;
}

const PanelTemplateLayout: FC<IPanelTemplateLayout> = ({ children }) => {
  const { routes } = useRoute();

  return (
    <MainLayout>
      <MainHeaderLayout headerTitle='قالب‌های آماده' backUrlArrow={routes.PANEL_HOME.link()} />

      <MainContentLayout>{children}</MainContentLayout>

      <PanelHomeIndexFooterNavbar showQuickAccessButton={false} />
    </MainLayout>
  );
};

export default memo(PanelTemplateLayout);
