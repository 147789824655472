import { IBlock, IBlockDetail } from 'core/types/Models/linkType';
import { FC } from 'react';
import { ReactComponent as DragLight } from 'assets/images/icon/drag-loght.svg';
import { ReactComponent as DragDark } from 'assets/images/icon/drag-dark.svg';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { getImageUrl } from 'core/utils/utils';

interface IBlockDividerDraggable {
  childBlocks: IBlockDetail[];
  masterBlocks: IBlock;
}

const BlockDividerDraggable: FC<IBlockDividerDraggable> = ({ childBlocks, masterBlocks }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: masterBlocks.id });
  const { theme } = useSelector((store: RootState) => store.settingStore);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      style={style}
      className='flex items-center justify-center bg-[var(--block-item-bg)] border-2 border-dashed border-gray-color-300 rounded-lg w-full p-3 space-x-3 space-x-reverse'
    >
      <div className='flex max-w-[25px] w-full' ref={setNodeRef} {...attributes} {...listeners}>
        {theme === 'light' ? <DragDark className='cursor-move' /> : <DragLight className='cursor-move' />}
      </div>

      <div className='flex'>
        <ZpImage src={getImageUrl(childBlocks?.[0]?.image)} />
      </div>
    </div>
  );
};

export default BlockDividerDraggable;
