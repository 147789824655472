import ZpSkeleton from 'components/_Core/UI/Skeleton/ZpSkeleton';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';

const CreateLinkStepOneSkeleton = () => {
  return (
    <div className='flex flex-col justify-between h-full'>
      <ZpWrapper singleColumn>
        <div>
          <ZpSkeleton height={40} />
        </div>

        <div>
          <ZpSkeleton height={40} />
        </div>
      </ZpWrapper>
    </div>
  );
};

export default CreateLinkStepOneSkeleton;
