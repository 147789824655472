import { RootState } from 'core/store';
import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';

interface IZpDevider {
  children: string | ReactNode;
  className?: string;
  marginTop?: boolean;
  orientation?: 'right' | 'left' | 'center';
}

const ZpDevider: FC<IZpDevider> = ({ className = '', orientation = 'right', children, marginTop = true, ...props }) => {
  const { isEnglish } = useSelector((store: RootState) => store.settingStore);

  return (
    <div className={`flex items-center mb-6 ${marginTop ? 'mt-8' : 'mt-0'} ${className}`} {...props}>
      {orientation !== 'right' && <hr className='w-full border border-primary-color-main' />}
      <h4
        className={` ${orientation === 'right' && (isEnglish ? 'mr-4' : 'ml-4')} ${orientation === 'left' && (isEnglish ? 'ml-4' : 'mr-4')} ${
          orientation === 'center' && 'mx-4'
        }  whitespace-nowrap text-primary-color-main`}
      >
        {typeof children === 'string' ? <b className='text-base'>{children}</b> : children}
      </h4>
      {orientation !== 'left' && <hr className='w-full border border-primary-color-main' />}
    </div>
  );
};

export default ZpDevider;
