import { Form } from 'antd';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import { IBlockDetail } from 'core/types/Models/linkType';
import useUpdateConnection from '../../hooks/useUpdateConnection';
import ZpBlockDelete from 'components/_Core/UI/BlockDelete/ZpBlockDelete';
import { Rule } from 'antd/es/form';
import { numberRegex } from 'core/utils/utils';

const ConnectionBlockUpdate = ({ block }: { block: IBlockDetail | undefined }) => {
  const [formUpdate] = Form.useForm();
  const { onFinishUpdateHandler, hideModal, modal, showModal, isLoadingUpdateConnection, isLoadingDeleteConnection, onDeleteHandler } = useUpdateConnection(formUpdate, block);

  const isNumber =
    block?.type?.en_name.toLowerCase().includes('mobile') || block?.type?.en_name.toLowerCase().includes('phone') || block?.type?.en_name.toLowerCase().includes('sms');

  const isEmail = block?.type?.en_name.toLowerCase().includes('email');

  const emailValidation: Rule = isEmail ? { type: 'email', message: `فرمت ${block?.type?.fa_name} اشتباه است` } : {};
  const numberValidation: Rule = isNumber
    ? {
        pattern: numberRegex,
        message: `فرمت ${block?.type?.fa_name} اشتباه است`,
      }
    : {};

  return (
    <>
      <ZpBlockDelete modal={modal} hideModal={hideModal} isLoadingDelete={isLoadingDeleteConnection} onDeleteHandler={onDeleteHandler} blockId={block?.id} />

      <ZpForm form={formUpdate} onFinish={onFinishUpdateHandler}>
        <ZpWrapper singleColumn>
          <ZpFormItem rules={[{ required: true, message: `عنوان ${block?.type?.fa_name} را وارد کنید` }]} name='title'>
            <ZpInputLabel required label={`عنوان ${block?.type?.fa_name}`} />
          </ZpFormItem>

          <ZpFormItem
            rules={[
              { required: true, message: `${block?.type?.fa_name} را وارد کنید` },
              { ...numberValidation, ...emailValidation },
            ]}
            name='value'
          >
            <ZpInputLabel textLeft required label={`${block?.type?.fa_name}`} />
          </ZpFormItem>

          <ZpFormItem button>
            <div className='flex items-center space-x-reverse space-x-5 justify-between'>
              <ZpButton buttonSize='xs' className='w-full' htmlType='button' danger ghost type='primary' isLoading={isLoadingDeleteConnection} onClick={showModal}>
                حذف
              </ZpButton>
              <ZpButton buttonSize='xs' className='w-full' htmlType='submit' type='primary' isLoading={isLoadingUpdateConnection}>
                ثبت تغییرات
              </ZpButton>
            </div>
          </ZpFormItem>
        </ZpWrapper>
      </ZpForm>
    </>
  );
};

export default ConnectionBlockUpdate;
