import { FC } from 'react';
import { separator } from 'core/utils/utils';
import { GoPrimitiveDot } from 'react-icons/go';
import { IPlanTransaction } from 'core/types/Models/planTransactionType';

const PlanTransactionItem: FC<IPlanTransaction> = ({ amount, status_text, status, date_fa, time, ref_code }) => {
  return (
    <div className='bg-white-color border border-border-color rounded-lg px-4 py-3'>
      <div className='flex justify-between items-center w-full'>
        <div className='flex items-center space-x-3 space-x-reverse'>
          <span className='font-bold text-xl'>{separator(amount)}</span>
          <span className='bg-natural-color-100 p-1.5 rounded-lg'>تومان</span>
        </div>

        <span
          className={`flex items-center space-x-1 space-x-reverse py-1 px-2 rounded-full ${
            status === 1 ? 'text-warning-color-400 bg-warning-color-50' : status === 3 ? 'text-success-color-300 bg-success-color-50' : 'text-error-color-300 bg-error-color-50'
          }`}
        >
          <GoPrimitiveDot size={18} color={`${status === 1 ? 'var(--warning-color-400)' : status === 3 ? 'var(--success-color-300)' : 'var(--error-color-300)'}`} />
          <span>{status_text}</span>
        </span>
      </div>

      <div className='mt-5'>
        <div className='py-4 border-b border-border-color flex items-center justify-between'>
          <div className='text-color-placeholder'>شماره پیگیری</div>
          <div>{ref_code || ''}</div>
        </div>

        <div className='py-4 flex items-center justify-between'>
          <div className='text-color-placeholder'>تاریخ پرداخت</div>
          <div>
            {date_fa} - {time}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanTransactionItem;
