import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';
import Template from 'components/Template/Template';
import PanelTemplateLayout from 'components/_Core/Layouts/Panel/PanelTemplateLayout/PanelTemplateLayout';

const TemplatePage = () => {
  return (
    <PanelTemplateLayout>
      <HeadMeta tabTitle='قالب‌های آماده' />
      <Template />
    </PanelTemplateLayout>
  );
};

export default TemplatePage;
