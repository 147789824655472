import GatewayLayout from 'components/_Core/Layouts/Panel/PanelHomeGatewayLout/PanelHomeGatewayLayout';
import GatewayList from 'components/Gateway/List/GatewayList';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';
import useRoute from 'core/hooks/useRoute';

const GatewayListPage = () => {
  const { routes } = useRoute();
  const headerTitle = 'داشبورد درگاه پرداخت';

  return (
    <GatewayLayout headerTitle={headerTitle} backUrl={routes.LINKS.link}>
      <HeadMeta tabTitle={headerTitle} />
      <GatewayList />
    </GatewayLayout>
  );
};

export default GatewayListPage;
