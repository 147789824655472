import { createSlice } from '@reduxjs/toolkit';
import { IGatewayDetail } from 'core/types/Models/gatewayType';
import { IGatewayLinkDetail } from 'core/types/Models/gatewayLinkType';

const initialState: { gatewayDetail: IGatewayDetail | null; gatewayLinkDetail: IGatewayLinkDetail | null } = {
  gatewayDetail: null,
  gatewayLinkDetail: null,
};

export const gatewaySlice = createSlice({
  name: 'gateway',
  initialState: initialState,
  reducers: {
    setGatewayDetail: (state, { payload }) => {
      state.gatewayDetail = payload;
    },
    setGatewayLinkDetail: (state, { payload }) => {
      state.gatewayLinkDetail = payload;
    },
  },
});

export const { setGatewayDetail, setGatewayLinkDetail } = gatewaySlice.actions;
export default gatewaySlice.reducer;
