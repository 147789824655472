export const endPointUrls = Object.freeze({
  // authentication
  OTP_TEST: '/panel/auth/testOtp',
  OTP: '/panel/auth/otp',
  CHECK_OTP: '/panel/auth/check',
  PROFILE_UPDATE: '/profile/user/update',
  USER_INFO: '/panel/auth/getInfo',

  // card to iban
  CARD_TO_IBAN: '/link/cardToIban',

  // links
  LINK: (linkId?: string | number) => `/link/links${linkId ? `/${linkId}` : ''}`,
  LINK_GET_BY_USERNAME: (username: string) => `/link/links/username/${username}`,
  LINK_CREATE_BLOCK_TEMPLATE: (linkId: string | number) => `/link/links/${linkId}/template`,
  LINK_SORTING: (linkId: string | number) => `/link/links/${linkId}/block/sorting`,
  LINK_CHECK_USERNAME: (username: string) => `/link/links/check/${username.toLowerCase().trim()}`,

  // link profile image
  LINK_PROFILE_IMAGE: (linkId: string | number) => `/link/links/${linkId}/upload/profile`,

  // category
  CATEGORY: '/link/categories',

  // social media
  SOCIAL_MEDIA: (linkId: string | number | undefined, socialMediaId?: string | number) => `/link/links/${linkId}/socials${socialMediaId ? `/${socialMediaId}` : ''}`,
  SOCIAL_MEDIA_SORTING: (linkId: string | number | undefined) => `/link/links/${linkId}/socials/sorting`,
  SOCIAL_MEDIA_TYPE: '/link/socials/types',

  // messenger
  MESSENGER: (linkId: string | number | undefined, messengerId?: string | number) => `/link/links/${linkId}/messengers${messengerId ? `/${messengerId}` : ''}`,
  MESSENGER_SORTING: (linkId: string | number | undefined) => `/link/links/${linkId}/messengers/sorting`,
  MESSENGER_TYPE: '/link/messengers/types',

  // navigation
  NAVIGATION: (linkId: string | number | undefined, navigationId?: string | number) => `/link/links/${linkId}/navigations${navigationId ? `/${navigationId}` : ''}`,
  NAVIGATION_SORTING: (linkId: string | number | undefined) => `/link/links/${linkId}/navigations/sorting`,
  NAVIGATION_TYPE: '/link/navigations/types',

  // connection
  CONNECTION: (linkId: string | number | undefined, connectionId?: string | number) => `/link/links/${linkId}/connections${connectionId ? `/${connectionId}` : ''}`,
  CONNECTION_SORTING: (linkId: string | number | undefined) => `/link/links/${linkId}/connections/sorting`,
  CONNECTION_TYPE: '/link/connections/types',

  // Divider
  DIVIDER_LIST: '/link/dividers',
  DIVIDER: (linkId: string | number | undefined, dividerId?: string | number) => `/link/links/${linkId}/dividers/${dividerId}`,

  // external Link
  EXTERNAL_LINK: (linkId: string | number | undefined, externalLinkId?: string | number) => `/link/links/${linkId}/externalLinks${externalLinkId ? `/${externalLinkId}` : ''}`,
  EXTERNAL_LINK_SORTING: (linkId: string | number | undefined) => `/link/links/${linkId}/externalLinks/sorting`,

  // faq
  FAQ: (linkId: string | number | undefined, externalLinkId?: string | number) => `/link/links/${linkId}/faqs${externalLinkId ? `/${externalLinkId}` : ''}`,
  FAQ_SORTING: (linkId: string | number | undefined) => `/link/links/${linkId}/faqs/sorting`,

  // gateway
  LINK_ACCOUNT_BANK: '/link/bankAccounts',
  GATEWAY_LIST: '/link/gateways',
  GATEWAY_DETAIL: (gatewayId: string | number) => `/link/gateways${gatewayId ? `/${gatewayId}` : ''}`,
  GATEWAY: (linkId: string | number, gatewayId?: string | number) => `/link/links/${linkId}/gateways${gatewayId ? `/${gatewayId}` : ''}`,
  GATEWAY_LINK: (gatewayId: string | number, gatewayLinkId?: string | number) => `/link/gateways/${gatewayId}/paymentLinks${gatewayLinkId ? `/${gatewayLinkId}` : ''}`,
  GATEWAY_LINK_BLOCK: (linkId: string | number, gatewayLinkId?: string | number) => `/link/links/${linkId}/paymentLinks${gatewayLinkId ? `/${gatewayLinkId}` : ''}`,
  GATEWAY_LINK_ICON: '/link/paymentLinks/icons',
  GATEWAY_LINK_UPLOAD_IMAGE: (gatewayId: string | number, gatewayLinkId: string | number) => `/link/gateways/${gatewayId}/paymentLinks/${gatewayLinkId}/upload`,
  GATEWAY_LINK_DELETE_IMAGE: (gatewayId: string | number, gatewayLinkId: string | number) => `/link/gateways/${gatewayId}/paymentLinks/${gatewayLinkId}/profile`,
  GATEWAY_LINK_INVOICES: (gatewayId: string | number, gatewayLinkId: string | number, query?: string) =>
    `/link/gateways/${gatewayId}/paymentLinks/${gatewayLinkId}/transactions${query ? `?${query}` : ''}`,
  GATEWAY_INVOICES: (gatewayId: string | number, query?: string) => `/link/gateways/${gatewayId}/transactions${query ? `?${query}` : ''}`,

  // plan
  PLAN_LIST: '/link/plans',
  PLAN_PAY: '/link/plans/pay',
  PLAN_TRANSACTION_LIST: '/link/plans/transactions',

  // templates
  TEMPLATE_LIST: '/link/templates',
});
