import { MdOutlineDashboardCustomize } from 'react-icons/md';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import ZpDrawer from 'components/_Core/UI/Drawer/ZpDrawer';
import { FC, ReactNode } from 'react';
import { IBlockTypesList } from 'core/types/Models/blockType';
import BlockTypes from 'components/_Core/UI/BlockCreate/components/BlockTypes';
import 'components/_Core/UI/BlockCreate/blockCreate.scss';
import DeviderList from 'components/_Core/UI/BlockCreate/components/DeviderList';
import PaymentLinkList from 'components/_Core/UI/BlockCreate/components/PaymentLinkList';
import { IGateway } from 'core/types/Models/gatewayType';

interface IZpBlockCreate {
  title: string;
  children: ReactNode;
  hasBlock: boolean;
  showChildModal?: () => void;
  hideChildModal?: () => void;
  childModal?: boolean;
  showMasterModal?: () => void;
  masterModal?: boolean;
  isPaymentBlock?: boolean;
  hideMasterModal?: () => void;
  isTypeList?: boolean;
  masterDrawerRoundedTop?: boolean;
  blockTypeTitle?: string;
  blockTypeDescription?: string;
  setBlockTypeSelected?: (val: any) => void;
  onFinishMasterHandler?: (payload: any) => void;
  blockTypeIcon?: ReactNode;
  customContent?: ReactNode;
  blockTypes?: IBlockTypesList | undefined;
  childDrawerHeight?: number | string;
  masterDrawerHeight?: number | string;
  gateway?: IGateway;
}

const ZpBlockCreate: FC<IZpBlockCreate> = ({
  title,
  blockTypeDescription,
  blockTypeTitle,
  childDrawerHeight = 'auto',
  masterDrawerHeight = '100%',
  children,
  blockTypes,
  childModal,
  setBlockTypeSelected,
  hideChildModal,
  blockTypeIcon,
  showChildModal,
  hideMasterModal,
  showMasterModal,
  masterModal,
  customContent,
  hasBlock,
  isTypeList = true,
  isPaymentBlock = false,
  gateway,
  onFinishMasterHandler,
  masterDrawerRoundedTop,
}) => {
  const isDivider = blockTypes?.data?.every((item) => item.image);

  return (
    <>
      <ZpDrawer height={childDrawerHeight} placement='bottom' className='blockTypeDrawer bg-white-natural-color' closable={false} open={childModal} onClose={hideChildModal}>
        {children}
      </ZpDrawer>

      <ZpDrawer
        height={masterDrawerHeight}
        placement='bottom'
        closable={false}
        footer={
          <ZpButton className='w-full' ghost type='primary' onClick={hideMasterModal}>
            انصراف
          </ZpButton>
        }
        roundedTop={masterDrawerRoundedTop}
        open={masterModal}
        onClose={hideMasterModal}
      >
        {isPaymentBlock ? (
          <PaymentLinkList
            onFinishCreateGatewayLinkHandler={onFinishMasterHandler}
            gateway={gateway}
            blockTypeDescription={blockTypeDescription || ''}
            blockTypeTitle={blockTypeTitle || ''}
          />
        ) : isDivider ? (
          <DeviderList
            blockTypeIcon={blockTypeIcon}
            blockTypes={blockTypes}
            blockTypeTitle={blockTypeTitle || ''}
            blockTypeDescription={blockTypeDescription || ''}
            onFinishMasterHandler={onFinishMasterHandler}
          />
        ) : (
          <BlockTypes
            blockTypeDescription={blockTypeDescription}
            blockTypeIcon={blockTypeIcon}
            blockTypeTitle={blockTypeTitle}
            blockTypes={blockTypes}
            setBlockTypeSelected={setBlockTypeSelected}
            showChildModal={showChildModal}
          />
        )}
      </ZpDrawer>

      <div className='border border-dashed border-natural-color-300 rounded-lg bg-white-natural-color p-4'>
        <h2>ساخت بلوک</h2>

        <div className='flex flex-col items-center space-y-4'>
          <div className='bg-natural-color-100 inline-block w-14 h-14 rounded-full'>
            <div className='flex items-center justify-center w-full h-full'>
              <MdOutlineDashboardCustomize size={30} color='var(--natural-color-500)' />
            </div>
          </div>

          {customContent ? (
            customContent
          ) : (
            <>
              {!hasBlock && <p className='text-color-placeholder text-sm'>اولین {title || ''} خود را بسازید</p>}
              <ZpButton buttonSize='xs' fullWith type='primary' onClick={isTypeList ? showMasterModal : showChildModal}>
                ساخت بلوک
              </ZpButton>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ZpBlockCreate;
