import { FC, ReactNode } from 'react';

interface IZpCustomCollapse {
  open: boolean;
  children: ReactNode;
  height?: string;
}
const ZpCustomCollapse: FC<IZpCustomCollapse> = ({ open, height = 'max-h-[500px]', children }) => {
  return <div className={` ${open ? '' : 'overflow-hidden'} transition-all duration-300 ${open ? `${height} py-2` : 'max-h-0 -p-2'}`}>{children}</div>;
};

export default ZpCustomCollapse;
