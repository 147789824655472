import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { IBlockDetail } from 'core/types/Models/linkType';
import { FC, Fragment } from 'react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import ZpBlockDelete from 'components/_Core/UI/BlockDelete/ZpBlockDelete';
import { IBlockTypeDetail } from 'core/types/Models/blockType';
import useDashboardChild from '../hooks/useDashboardChild';
import { useNavigate } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';
import navigationIcon from 'assets/images/icon/navigation.svg';
import navigationIcon2 from 'assets/images/icon/navigation2.svg';
import ZpMap from 'components/_Core/UI/Map/ZpMap';
import { Marker } from 'react-leaflet';
import { pinIcon } from 'core/constants/pinIcon';
import { LAT_INITIAL, LNG_INITIAL } from 'core/constants/constant';

interface IDashboardNavigationBlock {
  blocks: IBlockDetail[];
  blockType: IBlockTypeDetail;
}

const DashboardNavigationBlock: FC<IDashboardNavigationBlock> = ({ blocks, blockType }) => {
  // hooks
  const navigate = useNavigate();

  return (
    <div className='w-full space-y-3'>
      <p className='text-lg font-bold text-center text-gray-color-700'>{blockType.title}</p>

      {blocks?.map((block) => {
        const { modal, hideModal, showModal, isLoadingDelete, onDeleteHandler } = useDashboardChild(blockType, block.id);

        return (
          <Fragment key={block.id}>
            <ZpBlockDelete modal={modal} hideModal={hideModal} isLoadingDelete={isLoadingDelete} onDeleteHandler={onDeleteHandler} blockId={block?.id} />

            <div className='bg-block-item-bg border border-gray-color-300 rounded-lg w-full p-2 pb-3 flex flex-col space-y-5'>
              <div className='space-x-3 space-x-reverse flex items-center justify-between'>
                <div className='flex items-center space-x-4 space-x-reverse flex-1'>
                  <div className='max-w-[40px] w-full flex'>
                    <ZpImage className='rounded-full' width={40} height={40} src={navigationIcon} />
                  </div>

                  <span className='line-clamp-2'>{block.title}</span>
                </div>

                <div className='space-x-3 space-x-reverse max-w-[60px] w-full flex'>
                  <FaRegEdit className='cursor-pointer' size={22} onClick={() => navigate(blockType.route, { state: { blockId: block.id } })} />
                  <RiDeleteBin6Line className='cursor-pointer' size={22} color='var(--error-color-300)' onClick={showModal} />
                </div>
              </div>

              <div className='px-2 space-y-3'>
                <ZpMap
                  scrollWheelZoom={false}
                  firstRender={!(block?.lng && block?.lat)}
                  zoomLevel={16}
                  height='h-[200px]'
                  initialCenter={[block?.lat ? +block?.lat : LAT_INITIAL, block?.lng ? +block?.lng : LNG_INITIAL]}
                >
                  <Marker icon={pinIcon} position={[block?.lat ? +block?.lat : LAT_INITIAL, block?.lng ? +block?.lng : LNG_INITIAL]} />
                </ZpMap>

                {block?.description && (
                  <div className='bg-block-item-bg border border-border-color rounded-lg p-3 flex items-center space-x-3 space-x-reverse'>
                    <div className='max-w-[20px] w-full flex'>
                      <ZpImage className='rounded-full' width={17} height={25} src={navigationIcon2} />
                    </div>

                    <p className='text-color-paragraph text-sm'>{block?.description}</p>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default DashboardNavigationBlock;
