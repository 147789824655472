import DashboardCreateLink from './components/DashboardCreateLink/DashboardCreateLink';
import Links from './components/Links/Links';
import { useLinksListQuery } from './components/Links/hooks/react-query/useLinksListQuery';
import LinkItemSkeleton from './components/Links/components/LinkItemSkeleton';

const DashboardLinkList = () => {
  const { data: linkList, isFetching } = useLinksListQuery();

  return (
    <div className='flex flex-col space-y-7'>
      {linkList?.data?.length === 0 && <DashboardCreateLink />}
      {isFetching && [1, 2, 3].map((item) => <LinkItemSkeleton key={item} />)}
      <Links linkList={linkList} />
    </div>
  );
};

export default DashboardLinkList;
