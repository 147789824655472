import PanelUpdateLinkLayout from 'components/_Core/Layouts/Panel/PanelUpdateLinkLayout/PanelUpdateLinkLayout';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';
import UpdateLink from 'components/UpdateLink/UpdateLink';

const UpdateLinkPage = () => {
  return (
    <PanelUpdateLinkLayout>
      <HeadMeta tabTitle='ویرایش لینک' />
      <UpdateLink />
    </PanelUpdateLinkLayout>
  );
};

export default UpdateLinkPage;
