import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import api from 'core/services/fetch-api/useApi';
import { IPlanTransactionList } from 'core/types/Models/planTransactionType';

export const usePlanTransactionListQuery = () => {
  return useQueryHook(
    [endPointUrls.PLAN_TRANSACTION_LIST],
    () => api.get(endPointUrls.PLAN_TRANSACTION_LIST),
    {
      select: (res: any) => res.data,
    },
    false
  ) as UseQueryResult<IPlanTransactionList, Error>;
};
