import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { FiArrowLeft } from 'react-icons/fi';
import { FC } from 'react';

interface IPlanSubscriptionButton {
  isLoadingPlanPay: boolean;
  canBuyPlan: boolean;
  onPlanPayHandler: (val: number) => void;
  plan_id: number;
}

const PlanSubscriptionButton: FC<IPlanSubscriptionButton> = ({ onPlanPayHandler, isLoadingPlanPay, plan_id, canBuyPlan }) => {
  return (
    <ZpButton
      isLoading={isLoadingPlanPay}
      onClick={() => onPlanPayHandler(plan_id)}
      type='primary'
      buttonIcon={<FiArrowLeft size={22} />}
      className='absolute left-1/2 -translate-x-1/2 -bottom-5'
    >
      {canBuyPlan ? 'خرید پلن حرفه‌ای' : '14 روز مهلت تست رایگان'}
    </ZpButton>
  );
};

export default PlanSubscriptionButton;
