import EmptyGatewayLinks from 'components/Gateway/GatewayLinks/List/components/EmptyGatewayLinks';

const GatewayCreateEmpty = () => {
  return (
    <div className='h-full relative'>
      <EmptyGatewayLinks />
    </div>
  );
};

export default GatewayCreateEmpty;
