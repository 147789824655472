import { FormInstance } from 'antd';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import { IBlockTypes } from 'core/types/Models/blockType';
import { FC } from 'react';
import { getImageUrl } from 'core/utils/utils';

interface IMessengerBlockCreate {
  createForm: FormInstance;
  isLoadingCreateMessenger: boolean;
  blockTypeSelected: IBlockTypes | undefined;
  onFinishCreateLinkHandler: (val: any) => void;
  hideChildModalHandler: () => void;
}

const MessengerBlockCreate: FC<IMessengerBlockCreate> = ({ createForm, isLoadingCreateMessenger, blockTypeSelected, hideChildModalHandler, onFinishCreateLinkHandler }) => {
  return (
    <>
      <div className='flex items-center space-x-reverse space-x-5 mb-5'>
        <ZpImage className='rounded-full' src={getImageUrl(blockTypeSelected?.icon_url)} width={45} height={45} />
        <h2 className='text-lg font-bold'>{blockTypeSelected?.fa_name}</h2>
      </div>

      <ZpForm form={createForm} onFinish={onFinishCreateLinkHandler}>
        <ZpWrapper singleColumn>
          <ZpFormItem rules={[{ required: true, message: `عنوان ${blockTypeSelected?.fa_name} را وارد کنید` }]} name='title'>
            <ZpInputLabel required label={`عنوان ${blockTypeSelected?.fa_name}`} />
          </ZpFormItem>

          <ZpFormItem rules={[{ required: true, message: `آیدی ${blockTypeSelected?.fa_name} را وارد کنید` }]} name='username'>
            <ZpInputLabel suffix='@' textLeftFull required label={`آیدی ${blockTypeSelected?.fa_name}`} />
          </ZpFormItem>

          <ZpFormItem button>
            <div className='flex items-center space-x-reverse space-x-5 justify-between'>
              <ZpButton className='w-full' buttonSize='xs' htmlType='button' ghost type='primary' onClick={hideChildModalHandler}>
                انصراف
              </ZpButton>
              <ZpButton className='w-full' buttonSize='xs' htmlType='submit' type='primary' isLoading={isLoadingCreateMessenger}>
                ساخت بلوک
              </ZpButton>
            </div>
          </ZpFormItem>
        </ZpWrapper>
      </ZpForm>
    </>
  );
};

export default MessengerBlockCreate;
