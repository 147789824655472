import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import api from 'core/services/fetch-api/useApi';
import { IGatewayList } from 'core/types/Models/gatewayType';
import useRoute from 'core/hooks/useRoute';

export const useProfileGatewayListQuery = () => {
  const { linkId } = useRoute();

  return useQueryHook(
    [endPointUrls.GATEWAY(linkId || 0)],
    () => api.get(endPointUrls.GATEWAY(linkId || 0)),
    {
      select: (res: any) => res.data,
    },
    false
  ) as UseQueryResult<IGatewayList, Error>;
};
