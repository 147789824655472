import { ILink } from 'core/types/Models/linkType';
import { FC } from 'react';
import LinkDetailBlockMaster from './LinkDetailBlockMaster';
import { getImageUrl } from 'core/utils/utils';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

interface ILinkDetailBlocksPreview {
  link: ILink | undefined;
}

const LinkDetailBlocksPreview: FC<ILinkDetailBlocksPreview> = ({ link }) => {
  // store
  const { templateDetail } = useSelector((store: RootState) => store.templateStore);
  const { logo } = templateDetail || {};

  if (!link) return <></>;

  return (
    <>
      <div className='text-center space-y-5 flex flex-col items-center justify-center mb-16'>
        <div className='w-40 h-40 rounded-full border-2 border-white'>
          <ZpImage alt='' src={getImageUrl(link?.image_profile)} className='rounded-full w-full h-full' height={156} width={156} />
        </div>

        <div className='space-y-2'>
          <p className='text-xl font-bold text-profile_title_color'>{link?.title_fa}</p>
          <p className='text-profile_description_color'>{link?.description}</p>
        </div>
      </div>

      <LinkDetailBlockMaster blocks={link?.blocks} username={link?.username} />

      <div className='text-center mt-16 '>
        <ZpImage alt='logo' src={getImageUrl(logo)} />
      </div>
    </>
  );
};

export default LinkDetailBlocksPreview;
