import ZpSkeleton from 'components/_Core/UI/Skeleton/ZpSkeleton';

const ProfileSkeleton = () => {
  return (
    <div className='flex flex-col justify-between h-full'>
      <div className='space-y-5'>
        <div className='text-center'>
          <ZpSkeleton height={100} width={100} circle />
        </div>

        <div>
          <ZpSkeleton height={50} />
        </div>

        <div>
          <ZpSkeleton height={50} />
        </div>

        <div>
          <ZpSkeleton height={50} />
        </div>

        <div>
          <ZpSkeleton height={50} />
        </div>

        <div>
          <ZpSkeleton height={50} width={120} />
        </div>
      </div>
    </div>
  );
};

export default ProfileSkeleton;
