import useModal from 'core/hooks/useModal';
import { useConnectionTypeQuery } from './react-query/useConnectionTypeQuery';
import { useState, useEffect } from 'react';
import { IBlockTypes } from 'core/types/Models/blockType';
import { Form } from 'antd';
import { useCreateConnectionQuery } from './react-query/useCreateConnectionQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import { useNavigate } from 'react-router';
import useRoute from 'core/hooks/useRoute';

const useConnectionBlock = () => {
  // hooks
  const { modal: masterModal, hideModal: hideMasterModal, showModal: showMasterModal } = useModal();
  const { modal: childModal, hideModal: hideChildModal, showModal: showChildModal } = useModal();
  const [createForm] = Form.useForm();
  const navigate = useNavigate();
  const { routes } = useRoute();

  // state
  const [blockTypeSelected, setBlockTypeSelected] = useState<IBlockTypes>();

  // store
  const { linkDetail } = useSelector((store: RootState) => store.linkStore);
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  // query
  const { isFetching: isLoadingConnectionType, data: ConnectionTypeList } = useConnectionTypeQuery();
  const { isFetching: isLoadingLinkDetail } = useLinkDetailQuery();
  const { mutate: createConnection, isLoading: isLoadingCreateConnection } = useCreateConnectionQuery();

  const ConnectionList = linkDetail?.data?.blocks?.filter((block) => block.type.toLowerCase().includes('connection'));
  const hasBlock = !!ConnectionList?.[0]?.data?.length;

  const hideChildModalHandler = () => {
    createForm.resetFields();
    hideChildModal();
  };

  const onFinishCreateLinkHandler = (values: { value: string; title: string }) => {
    createConnection(
      { ...values, connection_type_id: blockTypeSelected?.id, order: hasBlock ? ConnectionList?.[0]?.data?.[ConnectionList?.[0]?.data?.length - 1]?.order + 1 : 1 },
      {
        onSuccess: () => {
          hideMasterModal();
          hideChildModalHandler();
          navigate(routes.PANEL_HOME.link());
        },
      }
    );
  };

  useEffect(() => {
    createForm.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors.length]);

  return {
    masterModal,
    createForm,
    onFinishCreateLinkHandler,
    blockTypeSelected,
    isLoadingCreateConnection,
    isLoadingLinkDetail,
    setBlockTypeSelected,
    hideMasterModal,
    showMasterModal,
    ConnectionTypeList,
    isLoadingConnectionType,
    childModal,
    hideChildModalHandler,
    showChildModal,
    ConnectionList,
    hasBlock,
  };
};

export default useConnectionBlock;
