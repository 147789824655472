import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { FC } from 'react';
import zarinpalIcon from 'assets/images/zarinPalIcon.svg';
import GatewayBlockType from 'components/_Core/UI/GatewayBlockType/GatewayBlockType';
import useModal from 'core/hooks/useModal';
import { BsArrowLeft } from 'react-icons/bs';

const DashboardGatewayLinkFakeBlock: FC = () => {
  const { modal, hideModal, showModal } = useModal();

  return (
    <>
      <GatewayBlockType modal={modal} hideModal={hideModal} />

      <div className='w-full space-y-3'>
        <p className='text-lg font-bold text-center text-gray-color-700'>لینک های پرداخت من</p>

        <div className='bg-[var(--block-item-bg)] border border-dashed border-gray-color-300 rounded-lg w-full p-2 space-x-3 space-x-reverse flex items-center justify-between'>
          <div className='flex items-center space-x-4 space-x-reverse flex-1'>
            <div className='max-w-[40px] w-full flex'>
              <ZpImage className='rounded-full' width={40} height={40} src={zarinpalIcon} />
            </div>

            <span className='line-clamp-1'>لینک پرداخت خود را تکمیل کنید</span>
          </div>

          <div className='max-w-[33px] w-full flex'>
            <BsArrowLeft className='cursor-pointer' size={25} onClick={showModal} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardGatewayLinkFakeBlock;
