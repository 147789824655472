import React from 'react';
import { useUserInfoQuery } from 'core/hooks/react-query/useUserInfoQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import useRoute from 'core/hooks/useRoute';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';

const usePaymentReturnSuccess = () => {
  // hooks
  const { isFetching: isLoadingUserInfo } = useUserInfoQuery();
  const { routes } = useRoute();
  const { query } = useQueryUrlParam();

  // store
  const { userInfo } = useSelector((store: RootState) => store.userStore);

  return { isLoadingUserInfo, userInfo, routes, query };
};

export default usePaymentReturnSuccess;
