import { FC } from 'react';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import expire from 'assets/images/icon/expire.png';
import { getImageUrl } from 'core/utils/utils';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { BsArrowLeft } from 'react-icons/bs';
import { IBlockDetail } from 'core/types/Models/linkType';
import useTemplatePreview from 'components/_Core/UI/PreviewTemplate/hooks/useTemplatePreview';

interface IPreviewTemplateProfessionalGatewayLink {
  block: IBlockDetail;
}

const PreviewTemplateProfessionalGatewayLink: FC<IPreviewTemplateProfessionalGatewayLink> = ({ block }) => {
  const notExpire = block?.remaining_date === null || block?.remaining_date > 0;
  const { blockBackgroundImage, blockStyles, blockBorder, blockBorderRadiusStyle } = useTemplatePreview();

  return (
    <div className='w-full space-y-3'>
      <div className='space-y-11'>
        <div className='relative'>
          {notExpire ? false : <ZpImage src={expire} alt='expire' width={100} height={100} className='absolute left-4 -top-5 z-50' />}

          <div className={` ${notExpire ? '' : 'opacity-50'}`}>
            <div style={{ ...blockStyles, ...blockBackgroundImage.object }} className={`space-y-10 text-center py-5 px-7 ${blockBorder}`}>
              <div className='relative'>
                {block?.cover_image && <ZpImage src={getImageUrl(block?.cover_image)} alt={block?.title} style={blockBorderRadiusStyle} className='shadow-md' />}
                <div className='rounded-full shadow-lg absolute w-12 h-12 -bottom-5 left-1/2 -translate-x-1/2 '>
                  <ZpImage src={getImageUrl(block?.icon?.url)} alt={block?.title} className='w-12 h-12 rounded-full' />
                </div>
              </div>
              <div className='space-y-7'>
                <div className='space-y-2'>
                  <h2 className='font-bold text-xl text-block_name_color'>{block.title}</h2>
                  <p className='text-block_name_color whitespace-pre'>{block.description}</p>
                </div>

                {notExpire && (
                  <div>
                    <ZpButton
                      style={blockBorderRadiusStyle}
                      ghost
                      buttonIcon={<BsArrowLeft size={25} />}
                      type='primary'
                      className='w-full text-ghost_button_color border-ghost_button_color'
                    >
                      جزئیات بیشتر
                    </ZpButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewTemplateProfessionalGatewayLink;
