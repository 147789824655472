import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useUpdateGatewayLinkQuery } from 'components/Gateway/GatewayLinks/Create/Professional/StepTwo/hooks/react-query/useUpdateGatewayLinkQuery';
import { Form } from 'antd';
import { useGatewayLinkDetailQuery } from 'core/hooks/react-query/useGatewayLinkDetailQuery';
import { disableBackToUrl } from 'core/utils/utils';
import { IGatewayLinkPayload } from 'core/types/Models/gatewayLinkType';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import useRoute from 'core/hooks/useRoute';

const useGatewayLinkSimpleUpdate = () => {
  //hooks
  const [updateGatewayLinkForm] = Form.useForm();
  const navigate = useNavigate();
  const { routes } = useRoute();
  const { key, state } = useLocation();

  // query
  const { mutate: updateGatewayLink, isLoading: isLoadingUpdateGatewayLink } = useUpdateGatewayLinkQuery();
  const { isFetching: isLoadingGatewayLinkDetail } = useGatewayLinkDetailQuery();

  // store
  const { gatewayLinkDetail } = useSelector((store: RootState) => store.gatewayStore);
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  const onFinishUpdateLinkHandler = (values: IGatewayLinkPayload) => {
    updateGatewayLink(values, { onSuccess: () => (key === 'default' || state?.disableBackToUrl ? navigate(routes.PANEL_HOME.link(), { state: disableBackToUrl }) : navigate(-1)) });
  };

  useEffect(() => {
    updateGatewayLinkForm.setFieldsValue({
      ...gatewayLinkDetail?.data,
    });
  }, [gatewayLinkDetail]);

  useEffect(() => {
    updateGatewayLinkForm.setFields(apiCatcherFormErrors);
  }, [JSON.stringify(apiCatcherFormErrors)]);

  return {
    isLoadingUpdateGatewayLink,
    isLoadingGatewayLinkDetail,
    onFinishUpdateLinkHandler,
    updateGatewayLinkForm,
  };
};

export default useGatewayLinkSimpleUpdate;
