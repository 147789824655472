import ZpBlockCreate from 'components/_Core/UI/BlockCreate/ZpBlockCreate';
import useExternalLinkBlock from './hooks/useExternalLinkBlock';
import ExternalLinkBlockList from './components/List/ExternalLinkBlockList';
import ExternalLinkBlockCreate from './components/Create/ExternalLinkBlockCreate';
import BlockListSkeleton from 'components/_Core/UI/BlockListSkeleton/BlockListSkeleton';

const ExternalLinkBlock = () => {
  const { onFinishCreateLinkHandler, createForm, childModal, hideChildModalHandler, isLoadingCreateExternalLink, showChildModal, isLoadingLinkDetail, ExternalLinkList, hasBlock } =
    useExternalLinkBlock();

  if (isLoadingLinkDetail) return <BlockListSkeleton />;

  return (
    <>
      <ZpBlockCreate childModal={childModal} hasBlock={hasBlock} hideChildModal={hideChildModalHandler} showChildModal={showChildModal} title='لینک' isTypeList={false}>
        <ExternalLinkBlockCreate
          createForm={createForm}
          hideChildModalHandler={hideChildModalHandler}
          isLoadingCreateExternalLink={isLoadingCreateExternalLink}
          onFinishCreateLinkHandler={onFinishCreateLinkHandler}
        />
      </ZpBlockCreate>

      <ExternalLinkBlockList blocks={ExternalLinkList} />
    </>
  );
};

export default ExternalLinkBlock;
