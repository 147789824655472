import useRoute from 'core/hooks/useRoute';
import { FC, memo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface IPublicRoute {
  isAuthenticated: boolean;
}

const PublicRoute: FC<IPublicRoute> = ({ isAuthenticated }) => {
  const { routes } = useRoute();

  if (!isAuthenticated) return <Outlet />;
  else return <Navigate to={isAuthenticated ? routes.LINKS.link : routes.ROOT} />;
};

export default memo(PublicRoute);
