import ZpUploadImage from 'components/_Core/UI/UploadImage/ZpUploadImage';
import ZpInformation from 'components/_Core/UI/Information/ZpInformation';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import ProfileSelect from 'components/Gateway/GatewayLinks/Create/Professional/StepTwo/components/GatewayIconList';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import useGatewayLinkProfessionalUpdate from 'components/Gateway/GatewayLinks/Update/Professional/hooks/useGatewayLinkProfessionalUpdate';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import ZpTextAreaLabel from 'components/_Core/UI/TextArea/ZpTextAreaLabel';
import ZpSwitch from 'components/_Core/UI/Switch/ZpSwitch';
import ZpCustomCollapse from 'components/_Core/UI/Collapse/ZpCustomCollapse';
import ZpInputNumberLabel from 'components/_Core/UI/Input/ZpInputNumberLabel';
import ZpCheckbox from 'components/_Core/UI/Checkbox/ZpCheckbox';
import { minDateToday, persianCalendar } from 'core/utils/utils';
import ZpDatePickerLabel from 'components/_Core/UI/DatePicker/ZpDatePickerLabel';
import { DateObject } from 'react-multi-date-picker';
import { BsCalendar2Date } from 'react-icons/bs';
import ZpTimeSelect from 'components/_Core/UI/Time/ZpTimeSelect';
import ZpDevider from 'components/_Core/UI/Devider/ZpDevider';
import GatewayFooter from 'components/_Core/Layouts/Panel/PanelHomeGatewayLout/PanelHomeGatewayFormFooter';
import useRoute from 'core/hooks/useRoute';
import GatewayLinkProfessionalUpdateSkeleton from 'components/Gateway/GatewayLinks/Update/Professional/components/GatewayLinkProfessionalUpdateSkeleton';
import MainContentLayout from 'components/_Core/Layouts/Main/MainContentLayout/MainContentLayout';

const GatewayLinkProfessionalUpdate = () => {
  const {
    gatewayLinkDetail,
    isLoadingUploadProfileImage,
    isLoadingUpdateGatewayLink,
    isLoadingGatewayLinkDetail,
    onFinishUpdateLinkHandler,
    onUpdateImageHandler,
    updateGatewayLinkForm,
    imageRef,
    limitAmount,
    setLimitAmount,
    limitTransaction,
    setLimitTransaction,
    limitDate,
    setLimitDate,
    imageUploadData,
    isLoadingDeleteGatewayLinkImage,
    onDeleteGatewayLinkImageHandler,
  } = useGatewayLinkProfessionalUpdate();

  const { routes } = useRoute();

  if (isLoadingGatewayLinkDetail)
    return (
      <MainContentLayout>
        <GatewayLinkProfessionalUpdateSkeleton />
      </MainContentLayout>
    );

  return (
    <>
      <MainContentLayout>
        <ZpForm onFinish={onFinishUpdateLinkHandler} form={updateGatewayLinkForm}>
          <ZpWrapper singleColumn>
            <div>
              <ZpUploadImage
                isLoading={isLoadingUploadProfileImage}
                gatewayLinkDetail={imageUploadData?.data || gatewayLinkDetail || undefined}
                imageRef={imageRef}
                onUpdateImageHandler={onUpdateImageHandler}
                title='بارگذاری تصویر'
                onDeleteImageHandler={onDeleteGatewayLinkImageHandler}
                isLoadingDeleteImage={isLoadingDeleteGatewayLinkImage}
              />
              {!gatewayLinkDetail?.data?.cover_image && (
                <ZpInformation className='text-color-placeholder'>
                  می توانید یک تصویر یا لوگو برای توصیف لینک پرداخت خود انتخاب کنید.حجم فایل باید کوچکتر از 5 مگابایت باشد.
                </ZpInformation>
              )}
            </div>

            <ZpFormItem name='title' rules={[{ required: true, message: 'نام لینک پرداخت را وارد کنید' }]}>
              <ZpInputLabel required label='نام لینک پرداخت' />
            </ZpFormItem>

            <ZpFormItem name='description'>
              <ZpTextAreaLabel label='توضیحات لینک پرداخت' />
            </ZpFormItem>

            <ZpFormItem noMargin name='icon_id' rules={[{ required: true, message: 'آیکون لینک پرداخت را وارد کنید' }]}>
              <ProfileSelect />
            </ZpFormItem>

            <ZpDevider>محدودیت‌های لینک پرداخت</ZpDevider>

            <div>
              <div className='flex items-start space-x-5 space-x-reverse'>
                <ZpSwitch checked={limitAmount} onChange={(e) => setLimitAmount(e)} />

                <div>
                  <p className='font-bold text-lg'>تعیین مبلغ هدف</p>
                  <p className='text-color-placeholder'>با فعال کردن این گزینه می توانید حداکثر مبلغ مورد نیاز خود را مشخص کنید.</p>
                </div>
              </div>

              <ZpCustomCollapse open={limitAmount}>
                <div className='flex flex-col space-y-3'>
                  <ZpFormItem rules={[{ required: limitAmount, message: 'سقف مبلغ را  انگلیسی وارد کنید' }]} name='payment_ceiling'>
                    <ZpInputNumberLabel required label='سقف مبلغ' suffixLabel='تومان' thousandSeparator />
                  </ZpFormItem>

                  <ZpFormItem valuePropName='checked' name='is_show_process_bar'>
                    <ZpCheckbox className='text-lg font-bold'> نمایش وضعیت پرداخت برای کاربران</ZpCheckbox>
                  </ZpFormItem>
                </div>
              </ZpCustomCollapse>
            </div>

            <div>
              <div className='flex items-start space-x-5 space-x-reverse'>
                <ZpSwitch checked={limitDate} onChange={(e) => setLimitDate(e)} />

                <div>
                  <p className='font-bold text-lg'>تعیین بازه زمانی برای لینک پرداخت</p>
                  <p className='text-color-placeholder'>برای لینک پرداخت خود تاریخ و ساعت مشخص کنید.</p>
                </div>
              </div>

              <ZpCustomCollapse open={limitDate}>
                <div className='flex flex-col space-y-3'>
                  <ZpFormItem name='expired_at' rules={[() => minDateToday('-'), { required: limitDate, message: 'تاریخ انقضا لینک پرداخت را وارد کنید' }]}>
                    <ZpDatePickerLabel
                      minDate={new DateObject(persianCalendar)}
                      label={
                        <div className='flex items-start space-x-2 space-x-reverse'>
                          <BsCalendar2Date size={18} />
                          <span>تاریخ انقضا لینک پرداخت</span>
                        </div>
                      }
                    />
                  </ZpFormItem>

                  <ZpFormItem rules={[{ required: limitDate, message: 'ساعت انقضا لینک پرداخت را وارد کنید' }]} name='time_expired_at'>
                    <ZpTimeSelect />
                  </ZpFormItem>
                </div>
              </ZpCustomCollapse>
            </div>

            <div>
              <div className='flex items-start space-x-5 space-x-reverse'>
                <ZpSwitch checked={limitTransaction} onChange={(e) => setLimitTransaction(e)} />

                <div>
                  <p className='font-bold text-lg'>تعیین سقف واریز هر تراکنش</p>
                  <p className='text-color-placeholder'>میزان مبلغ واریز در هر تراکنش را مشخص کنید. </p>
                </div>
              </div>

              <ZpCustomCollapse open={limitTransaction}>
                <div className='flex flex-col space-y-3'>
                  <ZpFormItem rules={[{ required: limitTransaction, message: 'حداقل واریزی را انگلیسی وارد کنید' }]} name='deposit_min'>
                    <ZpInputNumberLabel label='حداقل واریزی' suffixLabel='تومان' thousandSeparator />
                  </ZpFormItem>

                  <ZpFormItem rules={[{ required: limitTransaction, message: 'حداکثر واریزی را انگلیسی وارد کنید' }]} name='deposit_max'>
                    <ZpInputNumberLabel label='حداکثر واریزی' suffixLabel='تومان' thousandSeparator />
                  </ZpFormItem>
                </div>
              </ZpCustomCollapse>
            </div>
          </ZpWrapper>
        </ZpForm>
      </MainContentLayout>

      <GatewayFooter form={updateGatewayLinkForm} backUrl={routes.PANEL_HOME.link()} isLoading={isLoadingUpdateGatewayLink || isLoadingUploadProfileImage} />
    </>
  );
};

export default GatewayLinkProfessionalUpdate;
