import { SelectProps } from 'antd';
import ZpSelectLabel from 'components/_Core/UI/Select/ZpSelectLabel';
import { timeGenerator } from 'core/utils/utils';
import { BsClock } from 'react-icons/bs';

const ZpTimeSelect = ({ ...props }) => {
  const options: SelectProps['options'] = timeGenerator()?.map((item) => ({
    label: item,
    value: `${item}`,
  }));

  return (
    <ZpSelectLabel
      showSearch={false}
      options={options}
      label={
        <div className='flex items-start space-x-2 space-x-reverse'>
          <BsClock size={18} />
          <span>انتخاب زمان</span>
        </div>
      }
      {...props}
    />
  );
};

export default ZpTimeSelect;
