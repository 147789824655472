import ZpDrawer from 'components/_Core/UI/Drawer/ZpDrawer';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import BlockList from 'components/Blocks/components/BlockList/BlockList';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { setOpenBlockListHandler } from 'core/store/slice/setting/settingSlice';

const BlockListDrawer = () => {
  const { isOpenBlockList } = useSelector((store: RootState) => store.settingStore);
  const dispatch = useDispatch();

  const onCloseBlockListDrawer = () => dispatch(setOpenBlockListHandler(false));

  return (
    <ZpDrawer
      placement='bottom'
      title='بلوک‌ها'
      height='100%'
      className='bg-white-natural-color'
      open={isOpenBlockList}
      onClose={onCloseBlockListDrawer}
      footer={
        <ZpButton className='w-full' ghost type='primary' onClick={onCloseBlockListDrawer}>
          انصراف
        </ZpButton>
      }
    >
      <BlockList />
    </ZpDrawer>
  );
};

export default BlockListDrawer;
