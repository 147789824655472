import ZpInformation from 'components/_Core/UI/Information/ZpInformation';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import CreateLinkStepOneSkeleton from 'components/Gateway/GatewayLinks/Create/Professional/StepOne/components/CreateLinkStepOneSkeleton';
import useRoute from 'core/hooks/useRoute';
import { useNavigate } from 'react-router-dom';
import PanelCreateGatewayLinkLayoutFooter from 'components/_Core/Layouts/Panel/PanelCreateGatewayLinkLayout/PanelCreateGatewayLinkLayoutFooter';
import GatewayCreate from 'components/Gateway/Create/GatewayCreate';
import ZpDevider from 'components/_Core/UI/Devider/ZpDevider';
import useGatewayCreate from 'components/Gateway/hooks/useGatewayCreate';
import GatewayCreateLinkSuccess from 'components/_Core/UI/GatewayCreateLinkSuccess/GatewayCreateLinkSuccess';
import MainContentLayout from 'components/_Core/Layouts/Main/MainContentLayout/MainContentLayout';

const GatewayLinkCreateSimple = () => {
  const { routes } = useRoute();
  const navigate = useNavigate();

  const { createGatewayForm, onFinishCreateGatewayHandler, isLoadingGatewayLinkDetail, isLoadingCreateGateway, modal } = useGatewayCreate();

  if (isLoadingGatewayLinkDetail)
    return (
      <MainContentLayout>
        <CreateLinkStepOneSkeleton />
      </MainContentLayout>
    );

  return (
    <>
      <GatewayCreateLinkSuccess open={modal} />

      <MainContentLayout>
        <ZpForm form={createGatewayForm} onFinish={onFinishCreateGatewayHandler} className='flex-1 space-y-10'>
          <GatewayCreate />

          <ZpDevider marginTop={false}>اطلاعات لینک پرداخت</ZpDevider>

          <div className='flex flex-col justify-between h-full'>
            <ZpWrapper singleColumn>
              <div>
                <ZpFormItem noMargin name='linkTitle' rules={[{ required: true, message: 'نام لینک پرداخت را وارد کنید' }]}>
                  <ZpInputLabel required label='نام لینک پرداخت' placeholder='برای مثال : حمایت مالی از کودکان سرطانی' />
                </ZpFormItem>
                <ZpInformation className='text-color-placeholder'>برای لینک پرداخت خود عنوانی انتخاب کنید مانند: “لینک پرداخت حق مشاوره”</ZpInformation>
              </div>
            </ZpWrapper>
          </div>
        </ZpForm>
      </MainContentLayout>

      <PanelCreateGatewayLinkLayoutFooter
        backStep={() => navigate(routes.PANEL_HOME.link())}
        backButtonText='انصراف'
        submitButtonText='ساخت لینک پرداخت'
        isLoading={isLoadingCreateGateway}
        createLinkForm={createGatewayForm}
      />
    </>
  );
};

export default GatewayLinkCreateSimple;
