import { FC, useLayoutEffect } from 'react';
import RenderHtml from 'components/_Core/UI/RenderHtml/RenderHtml';
import { IBlockDetail } from 'core/types/Models/linkType';

interface IPreviewTemplateDivider {
  block: IBlockDetail;
}

const PreviewTemplateDivider: FC<IPreviewTemplateDivider> = ({ block }) => {
  useLayoutEffect(() => {
    // document.querySelector('');
    const svg = document.getElementById('divider-svg');
    if (svg) {
      const elements = svg.children;

      console.log(elements);
      for (let i = 0; i < elements.length; i++) {
        console.log(elements[i]);
      }
      // const elements = svg.getElementsByClassName('primaryColor');
      // for (let i = 0; i < elements.length; i++) elements[i].style.fill = color;
    }
  }, []);
  // divider - svg;

  return (
    <div className='flex justify-center'>
      <RenderHtml data={block.code + ''} />
    </div>
  );
};

export default PreviewTemplateDivider;
