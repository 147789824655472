import { RootState } from 'core/store';
import { useSelector } from 'react-redux';
import { getCssVariable } from 'core/utils/utils';

const useColors = () => {
  const { theme } = useSelector((store: RootState) => store.settingStore);

  const mainTheme = {
    dark: {
      'primary-color-50': getCssVariable('--primary-color-50'),
      'primary-color-100': getCssVariable('--primary-color-100'),
      'primary-color-main': getCssVariable('--primary-color-main'),
      'primary-color-300': getCssVariable('--primary-color-300'),
      'primary-color-400': getCssVariable('--primary-color-400'),

      'color-disable': '#94a3b8',
      'border-color': getCssVariable('--border-color'),
      'white-color': getCssVariable('--white-color'),
    },
    light: {
      'primary-color-50': getCssVariable('--primary-color-50'),
      'primary-color-100': getCssVariable('--primary-color-100'),
      'primary-color-main': getCssVariable('--primary-color-main'),
      'primary-color-300': getCssVariable('--primary-color-300'),
      'primary-color-400': getCssVariable('--primary-color-400'),

      'color-disable': '#94a3b8',
      'border-color': getCssVariable('--border-color'),
      'white-color': getCssVariable('--white-color'),
    },
  };

  return { getMainTheme: { ...mainTheme[theme] }, mainTheme };
};

export default useColors;
