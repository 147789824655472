import { Form } from 'antd';
import { useUpdateLinkProfileImageQuery } from 'components/CreateLink/StepTwo/hooks/react-query/useUpdateLinkProfileImageQuery';
import { useUpdateLinkQuery } from 'components/CreateLink/StepTwo/hooks/react-query/useUpdateLinkQuery';
import toastHandler from 'core/helpers/toast/toast';
import { useLinkCategoryQuery } from 'core/hooks/react-query/useLinkCategoryQuery';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import useRoute from 'core/hooks/useRoute';
import useSelected from 'core/hooks/useSelected';
import { RootState } from 'core/store';
import { useEffect, useRef, FormEvent } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { convertEnglishToPersian } from 'core/utils/utils';

const useUpdateLink = () => {
  // hooks
  const [updateLinkForm] = Form.useForm();
  const { selectedTypeKeep, selectedTypeKeepHandler, setSelectedTypeKeep } = useSelected(undefined, false, true);
  const imageRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { routes } = useRoute();

  // query
  const { mutate: updateLink, isLoading: isLoadingUpdateLink } = useUpdateLinkQuery();
  const { data: linkDetail, isFetching: isLoadingLinkDetail } = useLinkDetailQuery();
  const { data: linkCategories, isFetching: isLoadingLinkCategory } = useLinkCategoryQuery();
  const { mutate: uploadProfileImage, isLoading: isLoadingUploadProfileImage, data: imageUploadData } = useUpdateLinkProfileImageQuery();

  // store
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  const onFinishUpdateLinkHandler = (values: { title_fa: string; description: string }) => {
    const formattedValues = { ...values, title_fa: convertEnglishToPersian(values.title_fa) };
    const categories = selectedTypeKeep.map((item) => item.index);

    if (categories.length === 0) return toastHandler('error', 'یک یا چند حوزه فعالیت را انتخاب کنید.');
    updateLink({ ...formattedValues, categories }, { onSuccess: () => navigate(routes.LINKS.link) });
  };

  useEffect(() => {
    const categories: { index: string | number; data?: any }[] | undefined = linkDetail?.data.categories.map((item) => ({ index: item.id, data: undefined }));
    categories && setSelectedTypeKeep(categories);
  }, [linkDetail]);

  useEffect(() => {
    updateLinkForm.setFieldsValue({ ...linkDetail?.data });
  }, [linkDetail]);

  useEffect(() => {
    updateLinkForm.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors.length]);

  // upload image
  const onUpdateImageHandler = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files?.[0]?.size && e.currentTarget?.files?.[0]?.size > 5120000) {
      toastHandler('error', 'سایز عکس باید کمتر از 5 مگابایت باشد');
    } else {
      const files = new FormData();
      if (e.currentTarget?.files?.[0]) {
        files.append('profile_image', e.currentTarget?.files?.[0]);
        uploadProfileImage(files as any);
      }
    }
  };

  return {
    selectedTypeKeep,
    imageRef,
    linkDetail,
    onUpdateImageHandler,
    updateLinkForm,
    onFinishUpdateLinkHandler,
    selectedTypeKeepHandler,
    linkCategories,
    isLoadingDetail: isLoadingLinkDetail || isLoadingLinkCategory,
    isLoadingUpdateLink,
    isLoadingUploadProfileImage,
    imageUploadData,
  };
};

export default useUpdateLink;
