import { Form } from 'antd';
import { useAccountBankCreateQuery } from 'components/Gateway/components/AccountBank/hooks/react-query/useAccountBankCreateQuery';
import { useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import { IAccountBankPayload } from 'core/types/Models/accountBankType';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useEffect } from 'react';
import { convertJalaliDateToEnglish } from 'core/utils/utils';

function useBankAccountCreate(hideModal: () => void) {
  // hooks
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  // query
  const { mutate: createAccountBank, isLoading: isLoadingAccountBank } = useAccountBankCreateQuery();

  // store
  const { userInfo } = useSelector((store: RootState) => store.userStore);
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  const onFinishCreateShebaHandler = (values: IAccountBankPayload) => {
    console.log(values.birthday);
    createAccountBank(
      {
        ...values,
        iban: `IR${values.iban}`,
        ssn: values.ssn || userInfo?.data?.ssn,
        birthday: userInfo?.data?.birthday || convertJalaliDateToEnglish(values.birthday),
      },
      {
        onSuccess: () => {
          hideModal();
          queryClient.invalidateQueries([endPointUrls.LINK_ACCOUNT_BANK]);
        },
      }
    );
  };

  useEffect(() => {
    form.setFieldsValue({ first_name: userInfo?.data?.firstname, last_name: userInfo?.data?.lastname, ssn: userInfo?.data?.ssn, birthday: userInfo?.data?.birthday_convert });
  }, []);

  useEffect(() => {
    form.setFields(apiCatcherFormErrors);
  }, [JSON.stringify(apiCatcherFormErrors)]);

  return { form, onFinishCreateShebaHandler, isLoadingAccountBank, userInfo };
}

export default useBankAccountCreate;
