import { IBlock } from 'core/types/Models/linkType';
import DividerBlockUpdate from '../Update/DividerBlockUpdate';
import { FC } from 'react';

interface IDividerBlockList {
  blocks: IBlock[] | undefined;
}

const DividerBlockList: FC<IDividerBlockList> = ({ blocks }) => {
  return (
    <>
      {blocks?.length && blocks?.length > 0 ? (
        <div className='grid space-y-5 mt-5'>
          {blocks?.map(
            (block) =>
              block.data?.length > 0 &&
              block.data.map((item) => (
                <div key={item.id} className='border border-gray-color-300 py-5 px-6 rounded-lg'>
                  <DividerBlockUpdate block={item} />
                </div>
              ))
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default DividerBlockList;
