import useCopyToClipboard from 'core/hooks/useCopyToClipboard';
import { IoMdCopy } from 'react-icons/io';
import { AiOutlineEye } from 'react-icons/ai';
import ZpTooltip from 'components/_Core/UI/Tooltip/ZpTooltip';
import { twMerge } from 'tailwind-merge';

const CopyLink = ({ username, isIcon, isPreview, className }: { username: string; isIcon?: boolean; isPreview?: boolean; className?: string }) => {
  const { copyHandler } = useCopyToClipboard();

  return (
    <div className={twMerge(`my-6 py-3 px-4 border border-gray-color-300 rounded-lg bg-gray-color-50 flex items-center justify-between ${className}`)}>
      {isIcon ? (
        <div className='flex items-center space-x-5 space-x-reverse'>
          <ZpTooltip title='کپی لینک'>
            <IoMdCopy className='cursor-pointer' size={25} onClick={() => copyHandler(`${window.location.hostname}/${username}`)} />
          </ZpTooltip>
          {isPreview && (
            <ZpTooltip title='پیش نمایش'>
              <a rel='noreferrer' href={`${window.location.origin}/${username}`} target='_blank' className='flex'>
                <AiOutlineEye className='cursor-pointer' size={25} />
              </a>
            </ZpTooltip>
          )}
        </div>
      ) : (
        <span className='text-secondary-color-main cursor-pointer hover:underline' onClick={() => copyHandler(`${window.location.hostname}/${username}`)}>
          کپی لینک
        </span>
      )}
      <span className='text-color-main break-all ltr'>
        {window.location.hostname}/{username}
      </span>
    </div>
  );
};

export default CopyLink;
