import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { FC } from 'react';

interface IGatewayLinkDeleteModalLink {
  gatewayLinkId: number;
  hideModal(): void;
  onSubmitHandler(id: number, hideModal: () => void): void;
  isLoadingUpdateLink: boolean;
}

const GatewayLinkDeleteModalLink: FC<IGatewayLinkDeleteModalLink> = ({ hideModal, onSubmitHandler, isLoadingUpdateLink, gatewayLinkId }) => {
  return (
    <div className='flex flex-col space-y-8 items-center justify-center h-full'>
      <p className='text-lg'>آیا می‌خواهید حذف کنید ؟</p>

      <div className='flex space-x-5 space-x-reverse'>
        <ZpButton ghost type='primary' onClick={hideModal}>
          انصراف
        </ZpButton>
        <ZpButton type='primary' danger isLoading={isLoadingUpdateLink} onClick={() => onSubmitHandler(gatewayLinkId, hideModal)}>
          بله حذف می‌کنم
        </ZpButton>
      </div>
    </div>
  );
};

export default GatewayLinkDeleteModalLink;
