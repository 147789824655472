import { FC, memo, ReactNode } from 'react';
import MainLayout from 'components/_Core/Layouts/Main/MainLayout';
import MainContentLayout from 'components/_Core/Layouts/Main/MainContentLayout/MainContentLayout';
import MainHeaderLayout from 'components/_Core/Layouts/Main/MainHeader/MainHeaderLayout';
import useRoute from 'core/hooks/useRoute';
import { useGetBlocksList } from 'components/Blocks/components/BlockList/BlockList';
import PanelHomeIndexFooterNavbar from 'components/_Core/Layouts/Panel/PanelHomeIndex/PanelHomeIndexFooterNavbar/PanelHomeIndexFooterNavbar';

interface IPanelHomeBlockLayoutCreate {
  children: ReactNode;
}

const PanelHomeBlockLayoutCreate: FC<IPanelHomeBlockLayoutCreate> = ({ children }) => {
  const { routes } = useRoute();
  const { currentBlock } = useGetBlocksList();

  return (
    <MainLayout>
      <MainHeaderLayout headerTitle={currentBlock?.title} backUrlArrow={routes.PANEL_HOME.link()} />

      <MainContentLayout>{children}</MainContentLayout>

      <PanelHomeIndexFooterNavbar showQuickAccessButton={false} />
    </MainLayout>
  );
};

export default memo(PanelHomeBlockLayoutCreate);
