import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { ILink } from 'core/types/Models/linkType';
import { FC } from 'react';
import DashboardBlockMaster from './DashboardBlockMaster';
import { getImageUrl } from 'core/utils/utils';

interface IDashboardBlocksPreview {
  link: ILink;
}

const DashboardBlocksPreview: FC<IDashboardBlocksPreview> = ({ link }) => {
  return (
    <>
      <div className='text-center space-y-5 flex flex-col items-center justify-center mb-10'>
        <ZpImage src={getImageUrl(link?.image_profile)} className='rounded-full' width={120} height={120} />

        <div className='space-y-2'>
          <p className='text-xl font-bold'>{link.title_fa}</p>
          <p className='text-color-paragraph'>{link.description}</p>
        </div>
      </div>

      <DashboardBlockMaster blocks={link.blocks} />
    </>
  );
};

export default DashboardBlocksPreview;
