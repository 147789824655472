import { IBlock } from 'core/types/Models/linkType';
import GatewayBlockUpdate from 'components/BlocksComponents/GatewayBlock/components/Update/GatewayBlockUpdate';
import ZpBlockItem from 'components/_Core/UI/BlockList/ZpBlockItem';

const GatewayLinkBlockList = ({ blocks }: { blocks: IBlock[] | undefined }) => {
  return (
    <>
      {blocks?.length && blocks?.length > 0 ? (
        <div className='grid space-y-5 mt-5'>
          {blocks?.map(
            (block) =>
              block.data?.length > 0 &&
              block.data.map((item) => (
                <ZpBlockItem key={item.id} block={item}>
                  <GatewayBlockUpdate block={item} />
                </ZpBlockItem>
              ))
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default GatewayLinkBlockList;
