import { Form } from 'antd';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import { IBlockDetail } from 'core/types/Models/linkType';
import useUpdateMessenger from '../../hooks/useUpdateMessenger';
import ZpBlockDelete from 'components/_Core/UI/BlockDelete/ZpBlockDelete';

const MessengerBlockUpdate = ({ block }: { block: IBlockDetail | undefined }) => {
  const [formUpdate] = Form.useForm();
  const { onFinishUpdateHandler, hideModal, modal, showModal, isLoadingUpdateMessenger, isLoadingDeleteMessenger, onDeleteHandler } = useUpdateMessenger(formUpdate, block);

  return (
    <>
      <ZpBlockDelete modal={modal} hideModal={hideModal} isLoadingDelete={isLoadingDeleteMessenger} onDeleteHandler={onDeleteHandler} blockId={block?.id} />

      <ZpForm form={formUpdate} onFinish={onFinishUpdateHandler}>
        <ZpWrapper singleColumn>
          <ZpFormItem rules={[{ required: true, message: `عنوان ${block?.type?.fa_name} را وارد کنید` }]} name='title'>
            <ZpInputLabel required label={`عنوان ${block?.type?.fa_name}`} />
          </ZpFormItem>

          <ZpFormItem rules={[{ required: true, message: `آیدی ${block?.type?.fa_name} را وارد کنید` }]} name='username'>
            <ZpInputLabel suffix='@' textLeftFull required label={`آیدی ${block?.type?.fa_name}`} />
          </ZpFormItem>

          <ZpFormItem button>
            <div className='flex items-center space-x-reverse space-x-5 justify-between'>
              <ZpButton buttonSize='xs' className='w-full' htmlType='button' danger ghost type='primary' isLoading={isLoadingDeleteMessenger} onClick={showModal}>
                حذف
              </ZpButton>
              <ZpButton buttonSize='xs' className='w-full' htmlType='submit' type='primary' isLoading={isLoadingUpdateMessenger}>
                ثبت تغییرات
              </ZpButton>
            </div>
          </ZpFormItem>
        </ZpWrapper>
      </ZpForm>
    </>
  );
};

export default MessengerBlockUpdate;
