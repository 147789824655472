import { FC, memo } from 'react';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { FormInstance } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import useRoute from 'core/hooks/useRoute';
import MainFooterLayout from 'components/_Core/Layouts/Main/MainFooter/MainFooterLayout';

interface IPanelHomeGatewayFormFooter {
  isLoading?: boolean;
  form?: FormInstance;
  backUrl?: string;
}

const PanelHomeGatewayFormFooter: FC<IPanelHomeGatewayFormFooter> = ({ isLoading, form, backUrl }) => {
  // hooks
  const navigate = useNavigate();
  const { routes } = useRoute();
  const { key } = useLocation();

  return (
    <MainFooterLayout className='flex items-center justify-between'>
      <ZpButton type='primary' ghost onClick={() => (key === 'default' ? navigate(backUrl || routes.PANEL_LINK_GATEWAY.link()) : navigate(-1))}>
        انصراف
      </ZpButton>

      <ZpButton type='primary' isLoading={isLoading} onClick={() => form?.submit()}>
        ثبت
      </ZpButton>
    </MainFooterLayout>
  );
};

export default memo(PanelHomeGatewayFormFooter);
