import { FC, ReactNode, memo } from 'react';
import { HiOutlineInformationCircle } from 'react-icons/hi';

interface IZpInformation {
  children: ReactNode;
  className?: string;
}

const ZpInformation: FC<IZpInformation> = ({ className, children }) => {
  return (
    <div className={`flex gap-3 px-2 py-3 ${className}`}>
      <div>
        <HiOutlineInformationCircle size={20} />
      </div>
      <span className='text-color-paragraph pt-0.5 text-sm'>{children}</span>
    </div>
  );
};

export default memo(ZpInformation);
