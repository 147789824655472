import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpInformation from 'components/_Core/UI/Information/ZpInformation';
import ZpUploadImage from 'components/_Core/UI/UploadImage/ZpUploadImage';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import useCreateGatewayLinkStepTwo from 'components/Gateway/GatewayLinks/Create/Professional/StepTwo/hooks/useCreateGatewayLinkStepTwo';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import { useNavigate } from 'react-router-dom';
import useRoute from 'core/hooks/useRoute';
import Container from 'components/_Core/Layouts/Container/Container';
import CreateLinkStepTwoSkeleton from 'components/Gateway/GatewayLinks/Create/Professional/StepTwo/components/CreateLinkStepTwoSkeleton';
import ProfileSelect from 'components/Gateway/GatewayLinks/Create/Professional/StepTwo/components/GatewayIconList';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import PanelCreateGatewayLinkLayoutFooter from 'components/_Core/Layouts/Panel/PanelCreateGatewayLinkLayout/PanelCreateGatewayLinkLayoutFooter';

const GatewayLinkCreateProfessionalStepTwo = () => {
  const {
    updateLinkStepTwoForm,
    imageRef,
    onUpdateImageHandler,
    imageUploadData,
    isLoadingUpdateGatewayLinkStepTwo,
    onFinishUpdateGatewayLinkStepTwoHandler,
    gatewayLinkDetail,
    isLoading,
    isLoadingUploadProfileImage,
    onDeleteGatewayLinkImageHandler,
    isLoadingDeleteGatewayLinkImage,
  } = useCreateGatewayLinkStepTwo();
  const { routes } = useRoute();
  const navigate = useNavigate();
  const { filterParams } = useQueryUrlParam();

  if (isLoading) return <CreateLinkStepTwoSkeleton />;

  return (
    <>
      <Container className='h-full flex-1'>
        <div className='flex flex-col justify-between h-full'>
          <ZpForm form={updateLinkStepTwoForm} onFinish={onFinishUpdateGatewayLinkStepTwoHandler} className='flex-1'>
            <ZpWrapper singleColumn>
              <div>
                <ZpUploadImage
                  isLoading={isLoadingUploadProfileImage}
                  gatewayLinkDetail={imageUploadData?.data || gatewayLinkDetail}
                  imageRef={imageRef}
                  onUpdateImageHandler={onUpdateImageHandler}
                  title='بارگذاری تصویر'
                  onDeleteImageHandler={onDeleteGatewayLinkImageHandler}
                  isLoadingDeleteImage={isLoadingDeleteGatewayLinkImage}
                />
                {!gatewayLinkDetail?.data?.cover_image && (
                  <ZpInformation className='text-color-placeholder'>
                    می توانید یک تصویر یا لوگو برای توصیف لینک پرداخت خود انتخاب کنید.حجم فایل باید کوچکتر از 5 مگابایت باشد.
                  </ZpInformation>
                )}
              </div>

              <ZpFormItem noMargin name='icon_id' rules={[{ required: true, message: 'آیکون لینک پرداخت را وارد کنید' }]}>
                <ProfileSelect />
              </ZpFormItem>
            </ZpWrapper>
          </ZpForm>
        </div>
      </Container>

      <PanelCreateGatewayLinkLayoutFooter
        backStep={() => navigate(routes.GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_1.link(filterParams))}
        isLoading={isLoadingUpdateGatewayLinkStepTwo}
        createLinkForm={updateLinkStepTwoForm}
      />
    </>
  );
};

export default GatewayLinkCreateProfessionalStepTwo;
