import useModal from 'core/hooks/useModal';
import { useCreateGatewayLinkBlockQuery } from './react-query/useCreateGatewayLinkBlockQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import { useProfileGatewayListQuery } from 'components/BlocksComponents/GatewayBlock/hooks/react-query/useProfileGatewayListQuery';
import { useQueryClient } from '@tanstack/react-query';
import useRoute from 'core/hooks/useRoute';
import { endPointUrls } from 'core/constants/endPointUrls';
import { useNavigate } from 'react-router';

const useGatewayBlock = () => {
  // hooks
  const { modal: masterModal, hideModal: hideMasterModal, showModal: showMasterModal } = useModal();
  const queryClient = useQueryClient();
  const { linkId } = useRoute();
  const navigate = useNavigate();
  const { routes } = useRoute();

  // store
  const { linkDetail } = useSelector((store: RootState) => store.linkStore);

  // query
  const { isFetching: isLoadingProfileGatewayList, data: profileGatewayList } = useProfileGatewayListQuery();
  const { isFetching: isLoadingLinkDetail } = useLinkDetailQuery();
  const { mutate: createGatewayLinkBlock, isLoading: isLoadingCreateGatewayLinkBlock } = useCreateGatewayLinkBlockQuery();

  // console.log(gatewayLinkBlockList);

  const GatewayLinkList = linkDetail?.data?.blocks?.filter((block) => block.type.toLowerCase().includes('paymentlink'));
  const hasBlock = !!GatewayLinkList?.[0]?.data?.length;

  const onFinishCreateGatewayLinkHandler = (gatewayLinkId: number | string) => {
    createGatewayLinkBlock(
      { gatewayLinkId, title: 'لینک پرداخت' },
      {
        onSuccess: () => {
          hideMasterModal();
          navigate(routes.PANEL_HOME.link());
          return queryClient.invalidateQueries([endPointUrls.GATEWAY(linkId || 0)]);
        },
      }
    );
  };

  const onReloadHandler = () => {
    queryClient.invalidateQueries([endPointUrls.GATEWAY(linkId || 0)]);
  };

  return {
    hideMasterModal,
    isLoadingCreateGatewayLinkBlock,
    masterModal,
    isLoadingProfileGatewayList,
    showMasterModal,
    isLoadingLinkDetail,
    profileGatewayList,
    GatewayLinkList,
    onReloadHandler,
    hasBlock,
    gateway: profileGatewayList?.data?.[0],
    onFinishCreateGatewayLinkHandler,
  };
};

export default useGatewayBlock;
