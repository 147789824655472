import ConnectionBlock from 'components/BlocksComponents/ConnectionBlock/ConnectionBlock';
import BlockLayoutCreate from 'components/_Core/Layouts/Panel/PanelHomeBlockLayout/Create/PanelHomeBlockLayoutCreate';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';

const ConnectionBlockPage = () => {
  return (
    <BlockLayoutCreate>
      <HeadMeta tabTitle='راه‌های ارتباطی' />
      <ConnectionBlock />
    </BlockLayoutCreate>
  );
};

export default ConnectionBlockPage;
