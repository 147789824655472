import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useModal from 'core/hooks/useModal';
import { RootState } from 'core/store';
import { setEnableScroll } from 'core/store/slice/setting/settingSlice';
import { isBrowser } from 'react-device-detect';
import { scrollToSection } from 'core/utils/utils';

const useZpModal = () => {
  // hooks
  const dispatch = useDispatch();
  const { showModal: showModalPlan, modal: modalPlan, hideModal: hideModalPlan } = useModal();

  // store
  const { userInfo } = useSelector((store: RootState) => store.userStore);
  const { hasPlan } = useSelector((store: RootState) => store.userPlanStore);
  const { isOpenMenu } = useSelector((store: RootState) => store.settingStore);
  const { name, mobile } = userInfo?.data || {};

  useEffect(() => {
    !isOpenMenu && dispatch(setEnableScroll(true));
  }, [isOpenMenu]);

  const showMenuHandler = () => {
    isBrowser && scrollToSection('main-section', undefined, 0);
  };

  return { name, mobile, showModalPlan, modalPlan, hideModalPlan, showMenuHandler, isOpenMenu, dispatch, hasPlan };
};

export default useZpModal;
