import { useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useModal from 'core/hooks/useModal';
import { scrollToSection } from 'core/utils/utils';
import { useDeleteGatewayLinkQuery } from 'components/Gateway/GatewayLinks/List/hooks/react-query/useDeleteGatewayLinkQuery';
import useRoute from 'core/hooks/useRoute';

const useGatewayLinkAction = () => {
  // hooks
  const { hideModal, modal, showModal } = useModal();
  const { gatewayId } = useRoute();
  const { modal: openDropDown, hideModal: hideDropdown, showModal: showDropdown } = useModal();
  const queryClient = useQueryClient();

  // query
  const { mutate: deleteGatewayLink, isLoading: isLoadingDeleteGatewayLink } = useDeleteGatewayLinkQuery();

  const onOpenChange = () => {
    if (openDropDown) {
      hideDropdown();
    } else {
      showDropdown();
    }
  };

  const showModalHandler = () => {
    showModal();
    hideDropdown();
  };

  const onChangeStatusHandler = (gatewayLinkId: number, hideModal: () => void) => {
    deleteGatewayLink(
      { gatewayLinkId },
      {
        onSuccess: () => {
          hideModal();
          scrollToSection('main-section', undefined, 0);
          return queryClient.invalidateQueries([endPointUrls.GATEWAY_LINK(gatewayId + '')]);
        },
      }
    );
  };

  return { hideModal, modal, showModalHandler, openDropDown, onOpenChange, onChangeStatusHandler, isLoadingDeleteGatewayLink };
};

export default useGatewayLinkAction;
