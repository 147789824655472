import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { AiOutlinePlus } from 'react-icons/ai';
import useGatewayLinkList from 'components/Gateway/GatewayLinks/List/hooks/useGatewayLinkList';
import GatewayLinkItem from 'components/Gateway/GatewayLinks/List/components/GatewayLinkItem';
import GatewayLinkItemSkeleton from 'components/Gateway/GatewayLinks/List/components/GatewayLinkItemSkeleton';
import EmptyGatewayLinks from 'components/Gateway/GatewayLinks/List/components/EmptyGatewayLinks';
import GatewayBlockType from 'components/_Core/UI/GatewayBlockType/GatewayBlockType';
import useModal from 'core/hooks/useModal';

const GatewayLinksList = () => {
  // hooks
  const { modal, hideModal, showModal } = useModal();
  const { gatewayLinkList, isLoadingGatewayLinkList } = useGatewayLinkList();

  if (isLoadingGatewayLinkList) return <GatewayLinkItemSkeleton />;

  return (
    <>
      <GatewayBlockType modal={modal} hideModal={hideModal} />

      <div className='h-full relative'>
        <div className='flex items-center justify-between'>
          <h2 className='font-bold text-xl'>لینک‌های پرداخت شما</h2>

          {gatewayLinkList === undefined || gatewayLinkList.data.length === 0 ? (
            false
          ) : (
            <ZpButton buttonSize='sm' type='primary' ghost onClick={showModal}>
              <span className='flex items-center space-x-3 space-x-reverse'>
                <span>لینک پرداخت جدید</span>
                <AiOutlinePlus size={22} />
              </span>
            </ZpButton>
          )}
        </div>

        {(gatewayLinkList === undefined || gatewayLinkList?.data?.length === 0) && <EmptyGatewayLinks />}

        <div className='space-y-5 mt-7'>
          {gatewayLinkList?.data.map((item) => (
            <GatewayLinkItem key={item.id} {...item} />
          ))}
        </div>
      </div>
    </>
  );
};

export default GatewayLinksList;
