import { useDispatch, useSelector } from 'react-redux';
import { setEnableScroll } from 'core/store/slice/setting/settingSlice';
import { RootState } from 'core/store';
import { isBrowser } from 'react-device-detect';

const useCheckEndScroll = () => {
  // hooks
  const dispatch = useDispatch();

  const { isOpenMenu } = useSelector((store: RootState) => store.settingStore);
  const checkEndScroll = (e: any) => {
    // const isBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    const scrollPosition = e.target.scrollTop;

    scrollPosition === 0 && isOpenMenu && isBrowser && dispatch(setEnableScroll(!isOpenMenu));
  };

  return { checkEndScroll };
};

export default useCheckEndScroll;
