import { RxHamburgerMenu } from 'react-icons/rx';
import { AiFillPlusCircle } from 'react-icons/ai';
import { IPanelFooterItem } from 'core/types/Components/PanelFooterItem/PanelHomeFooterItemType';
import MainFooterLayout from 'components/_Core/Layouts/Main/MainFooter/MainFooterLayout';
import { IMenuItem } from 'core/types/Components/MenuItem/PanelHomeFooterNavbarItemType';
import { ReactComponent as ActivePlanSvg } from 'assets/images/icon/menu-icon/active-plan.svg';
import { ReactComponent as FinancialSvg } from 'assets/images/icon/menu-icon/financial.svg';
import { ReactComponent as TemplateSvg } from 'assets/images/icon/menu-icon/template.svg';
import { ReactComponent as LogoutSvg } from 'assets/images/icon/menu-icon/logout.svg';
import { ReactComponent as BlogSvg } from 'assets/images/icon/menu-icon/blog.svg';
import { ReactComponent as BlockMoveSvg } from 'assets/images/icon/menu-icon/blockMove.svg';
import { ReactComponent as ChartSvg } from 'assets/images/icon/menu-icon/chart.svg';
import { ReactComponent as ShareSvg } from 'assets/images/icon/menu-icon/share.svg';
import { ReactComponent as DashboardSvg } from 'assets/images/icon/menu-icon/dashboard.svg';
import ProfileSwitch from 'components/_Core/Layouts/Panel/PanelHomeIndex/PanelHomeIndexFooterNavbar/ProfileSwitch/ProfileSwitch';
import { FC } from 'react';
import BlockListDrawer from 'components/Blocks/components/BlockList/BlockListDrawer';
import usePanelHomeFooterNavbar from 'components/_Core/Layouts/Panel/PanelHomeIndex/PanelHomeIndexFooterNavbar/hooks/usePanelHomeFooterNavbar';
import PanelMenu from 'components/_Core/UI/Menu/PanelMenu';
import PlanDrawer from 'components/Plans/components/PlanDrawer/PlanDrawer';

interface IPanelHomeIndexFooterNavbar {
  showQuickAccessButton?: boolean;
}

const PanelHomeIndexFooterNavbar: FC<IPanelHomeIndexFooterNavbar> = ({ showQuickAccessButton = true }) => {
  const { showModalBlockList, showModalMenu, logout, routes, blogHandler, gatewayRoute, dragAndDropRoute, hideModalPlan, showModalPlan, modalPlan, hasPlan, trialDays } =
    usePanelHomeFooterNavbar();

  const navigators: IPanelFooterItem[] = [
    { id: 1, icon: <RxHamburgerMenu size={30} />, onClick: showModalMenu },
    { id: 2, component: <ProfileSwitch /> },
  ];

  const menuItems: IMenuItem[] = [
    { title: 'داشبورد اصلی', icon: <DashboardSvg />, to: routes.LINKS.link },
    { title: 'مدیریت مالی', icon: <FinancialSvg />, to: gatewayRoute, keyRoute: 'gateway' },
    { title: 'جابجایی بلوک', icon: <BlockMoveSvg />, ...dragAndDropRoute },
    { title: 'قالب‌های آماده', icon: <TemplateSvg />, onClick: showModalPlan, to: routes.PANEL_TEMPLATE.link() },
    { title: 'انتشار لینک', icon: <ShareSvg />, to: routes.PANEL_SHARE.link() },
    { title: 'وبلاگ', icon: <BlogSvg />, ...blogHandler },
    { title: 'آمار کلیک و بازدید', icon: <ChartSvg />, premium: !hasPlan, trialDays, onClick: showModalPlan },
    { title: 'دامنه اختصاصی', icon: <ActivePlanSvg />, premium: !hasPlan, trialDays, onClick: showModalPlan },
    { title: 'خروج', icon: <LogoutSvg />, onClick: logout },
  ];
  console.log(menuItems);

  return (
    <>
      <PanelMenu menuItems={menuItems} />
      <BlockListDrawer />
      <PlanDrawer open={modalPlan} hideDrawer={hideModalPlan} />

      <MainFooterLayout className='flex items-center justify-between sm:px-10'>
        {navigators.map((nav) => (
          <PanelHomeFooterNavbarItem key={nav.id} {...nav} />
        ))}

        {showQuickAccessButton && (
          <>
            <div className='bg-white absolute -top-4 left-1/2 -translate-x-1/2 w-10 h-10 rounded-full shadow-xl' />
            <AiFillPlusCircle
              onClick={showModalBlockList}
              stroke='var(--white-color)'
              size={65}
              className='absolute -top-8 left-1/2 -translate-x-1/2 cursor-pointer'
              color='var(--primary-color-main)'
            />
          </>
        )}
      </MainFooterLayout>
    </>
  );
};

const PanelHomeFooterNavbarItem = ({ icon, onClick, component }: IPanelFooterItem) => {
  const Item = (
    <div className='flex flex-col w-[55px] items-center relative cursor-pointer' onClick={onClick}>
      {icon}
    </div>
  );

  return component ? <>{component}</> : Item;
};

export default PanelHomeIndexFooterNavbar;
