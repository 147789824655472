import { Form } from 'antd';
import { useUserInfoQuery } from 'core/hooks/react-query/useUserInfoQuery';
import { RootState } from 'core/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useProfileUpdateQuery } from './react-query/useProfileUpdateQuery';

const useProfile = () => {
  // hooks
  const [profileForm] = Form.useForm();

  // store
  const { userInfo } = useSelector((store: RootState) => store.userStore);

  // query
  const { isFetching: isLoadingUserInfo } = useUserInfoQuery();

  const { mutate: updateProfile, isLoading: isLoadingUpdateProfile } = useProfileUpdateQuery();

  const onFinishUpdateProfile = (values: { firstname: string; lastname: string; gender: 1 | 2 }) => {
    updateProfile({ ...values, gender: values.gender || 1 });
  };

  useEffect(() => {
    profileForm.setFieldsValue({ ...userInfo?.data });
  }, [userInfo]);

  return { userInfo: userInfo?.data, profileForm, onFinishUpdateProfile, isLoadingUpdateProfile, isLoadingUserInfo };
};

export default useProfile;
