import { ILinkList } from 'core/types/Models/linkType';
import LinkItem from './components/LinkItem';

const Links = ({ linkList }: { linkList?: ILinkList }) => {
  return (
    <>
      {linkList?.data?.map((link) => (
        <LinkItem linkDetail={link} key={link.id} />
      ))}
    </>
  );
};

export default Links;
