import ZpBlockCreate from 'components/_Core/UI/BlockCreate/ZpBlockCreate';
import useFaqBlock from './hooks/useFaqBlock';
import FaqBlockList from './components/List/FaqBlockList';
import FaqBlockCreate from './components/Create/FaqBlockCreate';
import BlockListSkeleton from 'components/_Core/UI/BlockListSkeleton/BlockListSkeleton';

const FaqBlock = () => {
  const { onFinishCreateLinkHandler, createForm, childModal, hideChildModalHandler, isLoadingCreateFaq, showChildModal, isLoadingLinkDetail, FaqList, hasBlock } = useFaqBlock();

  if (isLoadingLinkDetail) return <BlockListSkeleton />;

  return (
    <>
      <ZpBlockCreate childModal={childModal} hasBlock={hasBlock} hideChildModal={hideChildModalHandler} showChildModal={showChildModal} title='سوالات متداول' isTypeList={false}>
        <FaqBlockCreate
          createForm={createForm}
          hideChildModalHandler={hideChildModalHandler}
          isLoadingCreateFaq={isLoadingCreateFaq}
          onFinishCreateLinkHandler={onFinishCreateLinkHandler}
        />
      </ZpBlockCreate>

      <FaqBlockList blocks={FaqList} />
    </>
  );
};

export default FaqBlock;
