import useModal from 'core/hooks/useModal';
import { Form } from 'antd';
import { useCreateNavigationQuery } from 'components/BlocksComponents/NavigationBlock/hooks/react-query/useCreateNavigationQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import useRoute from 'core/hooks/useRoute';
import toastHandler from 'core/helpers/toast/toast';

const useNavigationBlock = () => {
  // hooks
  const { modal: childModal, hideModal: hideChildModal, showModal: showChildModal } = useModal();
  const [createForm] = Form.useForm();
  const navigate = useNavigate();
  const { routes } = useRoute();

  // store
  const { linkDetail } = useSelector((store: RootState) => store.linkStore);
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  // state
  const [latAndLng, setLatAndLng] = useState<[number, number]>();
  const [zoomLevel, setZoomLevel] = useState(10);

  // query
  const { isFetching: isLoadingLinkDetail } = useLinkDetailQuery();
  const { mutate: createNavigation, isLoading: isLoadingCreateNavigation } = useCreateNavigationQuery();

  const navigationList = linkDetail?.data?.blocks?.filter((block) => block.type.toLowerCase().includes('navigation'));
  const hasBlock = !!navigationList?.[0]?.data?.length;

  const hideChildModalHandler = () => {
    createForm.resetFields();
    hideChildModal();
  };

  const onFinishCreateLinkHandler = (values: { description: string; title: string; lat: string; lng: string }) => {
    if (zoomLevel < 14 || !latAndLng) return toastHandler('error', 'موقعیت خود را از روی نقشه انتخاب کنید');

    createNavigation(
      {
        ...values,
        lat: latAndLng && latAndLng[0] + '',
        lng: latAndLng && latAndLng[1] + '',
        order: hasBlock ? navigationList?.[0]?.data?.[navigationList?.[0]?.data?.length - 1]?.order + 1 : 1,
      },
      {
        onSuccess: () => {
          hideChildModalHandler();
          navigate(routes.PANEL_HOME.link());
        },
      }
    );
  };

  useEffect(() => {
    createForm.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors.length]);

  return {
    onFinishCreateLinkHandler,
    createForm,
    childModal,
    hideChildModalHandler,
    isLoadingCreateNavigation,
    showChildModal,
    isLoadingLinkDetail,
    navigationList,
    latAndLng,
    setLatAndLng,
    zoomLevel,
    setZoomLevel,
    hasBlock,
  };
};

export default useNavigationBlock;
