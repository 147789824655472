import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { FC, memo } from 'react';
const { TextArea } = Input;

export interface IZpTextArea extends TextAreaProps {
  setValue?: (val: any) => void;
  className?: string;
}

const ZpTextArea: FC<IZpTextArea> = ({ setValue, className = '', ...props }) => {
  return (
    <TextArea
      className={`disabled:bg-[var(--input-disable-bg)] bg-white-natural-color disabled:text-gray-color-500 text-color-main ${className}`}
      onChange={(e) => (setValue ? setValue(e.currentTarget.value) : {})}
      rows={3}
      {...props}
    />
  );
};

export default memo(ZpTextArea);
