import { ReactComponent as ActivePlanSVG } from 'assets/images/plan/active-plan.svg';
import { ReactComponent as PremiumLabelSVG } from 'assets/images/plan/premium-label-active-4.svg';
import { FC } from 'react';
import { IPlanFeature, planCodeType } from 'core/types/Models/planType';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { getImageUrl, separator } from 'core/utils/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import ActivePlanSubscriptionButton from 'components/ActivePlans/components/ActivePlanSubscriptionButton';

interface IPlanItem {
  icon: string | undefined;
  name: string | undefined;
  price: number | undefined;
  features: IPlanFeature[] | undefined;
  code: planCodeType | undefined;
  isLoadingPlanPay: boolean | undefined;
  onPlanPayHandler: (val: number) => void | undefined;
  plan_id: number | undefined;
  start_at: string | undefined;
  end_at: string | undefined;
  trialDays: number | undefined;
}

const ActivePlanItem: FC<IPlanItem> = ({ name = '', icon, price, features, code, plan_id, onPlanPayHandler, isLoadingPlanPay, end_at, trialDays, start_at }) => {
  const { activePlan, canBuy, renewal } = useSelector((store: RootState) => store.userPlanStore);
  const isPro = code === 'pro';
  const isFree = code === 'free';

  const planTitle = (
    <div>
      <span>پلن {name}</span> {trialDays ? <span className='text-xl'>({trialDays} روز تست)</span> : null}
    </div>
  );

  return (
    <div className='px-7 py-6 rounded-2xl shadow-lg text-center bg-block-item-bg relative space-y-6'>
      {activePlan?.code === code && <ActivePlanSVG className='absolute right-0 top-0' />}
      {isPro && <PremiumLabelSVG className='absolute -left-5 -top-12' />}

      <div className='space-y-5'>
        <ZpImage src={getImageUrl(icon)} />
        <h2 className={`font-bold text-2xl border border-border-color rounded-lg leading-10 py-2 ${isPro ? 'text-primary-color-main dark:text-white' : ''}`}>{planTitle}</h2>
      </div>

      <div className='p-4 space-y-3 border-b border-border-color'>
        <div className='flex justify-between items-center'>
          <span className='text-color-placeholder'>هزینه پلن :</span>
          <span>{price ? `${separator(price || 0)} تومان` : 'رایگان'}</span>
        </div>

        <div className='flex justify-between items-center'>
          <span className='text-color-placeholder'>تاریخ شروع پلن :</span>
          <span>{start_at}</span>
        </div>

        <div className='flex justify-between items-center'>
          <span className='text-color-placeholder'>تاریخ پایان پلن :</span>
          <span>{isFree ? 'مادام العمر' : end_at}</span>
        </div>
      </div>

      <div className='text-right text-color-placeholder space-y-3 pb-5'>
        <p>جزئیات پلن : </p>
        <ul className='mr-8 text-color-paragraph space-y-2'>
          {features?.map((feature) => (
            <li key={feature.id} className='list-disc'>
              {feature.name || ''}
            </li>
          ))}
        </ul>
      </div>

      {isPro && canBuy && (
        <ActivePlanSubscriptionButton canBuyPlan={renewal} isLoadingPlanPay={isLoadingPlanPay || false} onPlanPayHandler={onPlanPayHandler} plan_id={plan_id || 0} />
      )}
    </div>
  );
};

export default ActivePlanItem;
