import { Drawer, DrawerProps } from 'antd';
import { FC, ReactNode } from 'react';

interface IZpDrawer extends DrawerProps {
  children: ReactNode;
  roundedTop?: boolean;
}

const ZpDrawer: FC<IZpDrawer> = ({ children, className, roundedTop = false, ...props }) => {
  return (
    <Drawer className={`max-w-md mx-auto ${roundedTop ? 'drawer-rounded-top' : ''} ${className}`} {...props}>
      {children}
    </Drawer>
  );
};

export default ZpDrawer;
