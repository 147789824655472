import ZpBlockItem from 'components/_Core/UI/BlockList/ZpBlockItem';
import { IBlock } from 'core/types/Models/linkType';
import faq from 'assets/images/icon/faq.svg';
import FaqBlockUpdate from '../Update/FaqBlockUpdate';

const FaqBlockList = ({ blocks }: { blocks: IBlock[] | undefined }) => {
  return (
    <>
      {blocks?.[0]?.data?.length && blocks?.[0]?.data?.length > 0 ? (
        <div className='grid space-y-5 mt-5'>
          {blocks?.map(
            (block) =>
              block.data?.length > 0 &&
              block.data.map((item) => (
                <ZpBlockItem customIconUrl={faq} key={item.id} block={item}>
                  <FaqBlockUpdate block={item} />
                </ZpBlockItem>
              ))
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default FaqBlockList;
