import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import api from 'core/services/fetch-api/useApi';
import { setUserInfo } from 'core/store/slice/userInfo/userInfoSlice';
import { IUserInfo } from 'core/types/Models/userInfoType';
import { useDispatch } from 'react-redux';
import { setUserPlanDetail } from 'core/store/slice/userPlan/userPlanSlice';

export const useUserInfoQuery = () => {
  const dispatch = useDispatch();

  return useQueryHook([endPointUrls.USER_INFO], () => api.post(endPointUrls.USER_INFO, {}), {
    select: (res: any) => res.data,
    onSuccess(res) {
      const response = res as IUserInfo;
      dispatch(setUserInfo(response));
      dispatch(setUserPlanDetail(response.data.subscription_plan));
    },
  }) as UseQueryResult<IUserInfo, Error>;
};
