import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { IBlockTypesList } from 'core/types/Models/blockType';
import { FC, ReactNode } from 'react';
import RenderHtml from 'components/_Core/UI/RenderHtml/RenderHtml';

interface IDeviderList {
  blockTypes: IBlockTypesList | undefined;
  blockTypeIcon: ReactNode;
  blockTypeTitle: string;
  blockTypeDescription: string;
  onFinishMasterHandler: ((payload: any) => void) | undefined;
}

const DeviderList: FC<IDeviderList> = ({ onFinishMasterHandler, blockTypes, blockTypeIcon, blockTypeTitle, blockTypeDescription }) => {
  return (
    <>
      <div className='flex space-x-3 space-x-reverse mb-5'>
        {blockTypeIcon}
        <div>
          <h2 className='text-lg font-bold'>{blockTypeTitle}</h2>
          <p className='text-color-paragraph'>{blockTypeDescription}</p>
        </div>
      </div>

      <div className='grid grid-cols-1 gap-5'>
        {blockTypes?.data?.map((blockType) => (
          <div
            className='bg-blue-light-color px-4 h-16 flex items-center justify-center rounded-lg cursor-pointer hover:bg-natural-color-100'
            key={blockType.id}
            onClick={() => onFinishMasterHandler && onFinishMasterHandler(blockType.id)}
          >
            {/*<RenderHtml data={blockType.code + ''} />*/}
            <ZpImage src={(blockType.image ? process.env.REACT_APP_API_FILE_BASE_URL + blockType.image : undefined) || undefined} />
          </div>
        ))}
      </div>
    </>
  );
};

export default DeviderList;
