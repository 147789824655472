import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import api from 'core/services/fetch-api/useApi';
import useRoute from 'core/hooks/useRoute';
import { IGatewayLinkList } from 'core/types/Models/gatewayLinkType';

export const useGatewayLinkListQuery = () => {
  const { gatewayId } = useRoute();

  return useQueryHook(
    [endPointUrls.GATEWAY_LINK(gatewayId + '')],
    () => api.get(endPointUrls.GATEWAY_LINK(gatewayId + '')),
    {
      select: (res: any) => res.data,
    },
    false
  ) as UseQueryResult<IGatewayLinkList, Error>;
};
