import { CSSProperties, FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface IMainLayout {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

const MainLayout: FC<IMainLayout> = ({ children, className = '', style = {} }) => {
  return (
    <div style={style} className={twMerge(`flex flex-col min-h-screen 448:pb-0 pb-24 ${className}`)}>
      {children}
    </div>
  );
};

export default MainLayout;
