import { FC, memo, ReactNode } from 'react';
import logo from 'assets/images/logo/logo.svg';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import PanelMasterIndexFooterNavbar from 'components/_Core/Layouts/PanelMaster/PanelMasterIndex/PanelMasterIndexFooterNavbar/PanelMasterIndexFooterNavbar';
import ProfileWidget from 'components/ProfileWidget/ProfileWidget';
import ThemeWidget from 'components/_Core/ThemeWidget/ThemWidget';
import MainLayout from 'components/_Core/Layouts/Main/MainLayout';
import MainHeaderLayout from 'components/_Core/Layouts/Main/MainHeader/MainHeaderLayout';
import MainContentLayout from 'components/_Core/Layouts/Main/MainContentLayout/MainContentLayout';

interface IPanelMasterIndexLayout {
  children: ReactNode;
}

const PanelMasterIndexLayout: FC<IPanelMasterIndexLayout> = ({ children }) => {
  return (
    <MainLayout>
      <MainHeaderLayout>
        <div className='flex items-center justify-between h-full'>
          <ZpImage src={logo} width={50} />

          {/*<div className='flex items-center space-x-5 space-x-reverse'>*/}
          {/*  <ThemeWidget />*/}
          {/*  <ProfileWidget />*/}
          {/*</div>*/}
        </div>
      </MainHeaderLayout>

      <MainContentLayout>{children}</MainContentLayout>

      <PanelMasterIndexFooterNavbar />
    </MainLayout>
  );
};

export default memo(PanelMasterIndexLayout);
