import GatewayBlock from 'components/BlocksComponents/GatewayBlock/GatewayBlock';
import BlockLayoutCreate from 'components/_Core/Layouts/Panel/PanelHomeBlockLayout/Create/PanelHomeBlockLayoutCreate';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';

const GatewayBlockPage = () => {
  return (
    <BlockLayoutCreate>
      <HeadMeta tabTitle='لینک‌های پرداخت' />
      <GatewayBlock />
    </BlockLayoutCreate>
  );
};

export default GatewayBlockPage;
