import { FormInstance } from 'antd';
import { useCreateGatewayLinkQuery } from 'components/Gateway/GatewayLinks/Create/Professional/StepOne/hooks/react-query/useCreateGatewayLinkQuery';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { useUpdateGatewayLinkQuery } from 'components/Gateway/GatewayLinks/Create/Professional/StepTwo/hooks/react-query/useUpdateGatewayLinkQuery';
import { useGatewayLinkDetailQuery } from 'core/hooks/react-query/useGatewayLinkDetailQuery';
import { IGatewayDetail } from 'core/types/Models/gatewayType';
import { useNavigate } from 'react-router-dom';
import useRoute from 'core/hooks/useRoute';

const useCreateGatewayLinkStepOne = (form: FormInstance) => {
  //hooks
  const { query } = useQueryUrlParam();
  const navigate = useNavigate();
  const { routes } = useRoute();

  // query
  const { mutate: createGatewayLink, isLoading: isLoadingCreateLinkStepOne } = useCreateGatewayLinkQuery();
  const { mutate: updateGatewayLink, isLoading: isLoadingUpdateGatewayLinkStepTwo } = useUpdateGatewayLinkQuery('GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_2');
  const { data: gatewayLinkDetail } = useGatewayLinkDetailQuery(true);

  // store
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  useEffect(() => {
    query?.gatewayLinkId && form.setFieldsValue({ ...gatewayLinkDetail?.data, linkTitle: gatewayLinkDetail?.data?.title });
  }, [gatewayLinkDetail]);

  useEffect(() => {
    form.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors.length]);

  const onFinishCreateGatewayLinkStepOneHandler = (values: { title: string; description: string }, res?: IGatewayDetail) => {
    if (query?.gatewayLinkId) {
      updateGatewayLink(values);
    } else {
      createGatewayLink(
        { data: values, gatewayId: res?.data?.id },
        {
          onSuccess: (response: any) =>
            navigate(routes.GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_2.link(`gatewayId=${res?.data?.id || query?.gatewayId}&gatewayLinkId=${response?.data?.data?.id}`)),
        }
      );
    }
  };

  return {
    onFinishCreateGatewayLinkStepOneHandler,
    isLoadingCreateGatewayLinkStepOne: isLoadingCreateLinkStepOne || isLoadingUpdateGatewayLinkStepTwo,
  };
};

export default useCreateGatewayLinkStepOne;
