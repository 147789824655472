import { FC, memo } from 'react';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { FormInstance } from 'antd';
import { useNavigate } from 'react-router-dom';
import useRoute from 'core/hooks/useRoute';
import MainFooterLayout from 'components/_Core/Layouts/Main/MainFooter/MainFooterLayout';

interface IPanelUpdateLinkLayoutFooter {
  isLoading: boolean;
  updateLinkForm?: FormInstance;
}

const PanelUpdateLinkLayoutFooter: FC<IPanelUpdateLinkLayoutFooter> = ({ isLoading, updateLinkForm }) => {
  const navigate = useNavigate();
  const { routes } = useRoute();

  return (
    <MainFooterLayout className='flex items-center justify-between'>
      <ZpButton type='primary' ghost onClick={() => navigate(routes.LINKS.link)}>
        انصراف
      </ZpButton>

      <ZpButton type='primary' isLoading={isLoading} onClick={() => updateLinkForm?.submit()}>
        ثبت تغییرات
      </ZpButton>
    </MainFooterLayout>
  );
};

export default memo(PanelUpdateLinkLayoutFooter);
