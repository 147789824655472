import { useEffect } from 'react';
import { Form } from 'antd';
import { IGatewayPayload } from 'core/types/Models/gatewayType';
import { convertAllPropertyToEnNumber, convertEnglishToPersian, convertJalaliDateToEnglish } from 'core/utils/utils';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { useNavigate } from 'react-router-dom';
import useRoute from 'core/hooks/useRoute';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useGatewayUpdateQuery } from 'components/Gateway/hooks/react-query/useGatewayUpdateQuery';
import { useAccountBankListQuery } from 'components/Gateway/components/AccountBank/hooks/react-query/useAccountBankListQuery';

function useGatewayUpdate() {
  //hooks
  const [form] = Form.useForm();
  const { pathname } = useQueryUrlParam(false);
  const navigate = useNavigate();
  const { routes } = useRoute();

  // query
  const { mutate: updateGateway, isLoading: isLoadingUpdateGateway } = useGatewayUpdateQuery();
  const { data: accountBanks, isFetching: isLoadingAccountBanks } = useAccountBankListQuery();

  // store
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);
  const { gatewayDetail } = useSelector((store: RootState) => store.gatewayStore);
  const { linkDetail } = useSelector((store: RootState) => store.linkStore);
  const { userInfo } = useSelector((store: RootState) => store.userStore);

  const onRequestToCreateGatewayHandler = (formattedValues: IGatewayPayload) => {
    updateGateway(formattedValues, {
      onSuccess: () => {
        if (pathname === routes.GATEWAY_UPDATE.link()) return navigate(routes.GATEWAY_DETAIL.link());
      },
    });
  };

  const onFinishCreateGatewayHandler = (values: IGatewayPayload) => {
    const { ssn, birthday_convert } = userInfo?.data || {};
    const formattedValuesToEnglish = convertAllPropertyToEnNumber(
      { ssn: values.ssn || ssn, birthday: convertJalaliDateToEnglish(values.birthday || birthday_convert + ''), bank_account_id: values.bank_account_id },
      false
    );
    const formattedValues = { ...formattedValuesToEnglish, title: convertEnglishToPersian(values.title || linkDetail?.data?.title_fa + '') };

    onRequestToCreateGatewayHandler(formattedValues);
  };

  useEffect(() => {
    form.setFields(apiCatcherFormErrors);
  }, [JSON.stringify(apiCatcherFormErrors)]);

  useEffect(() => {
    form.setFieldsValue({
      ssn: userInfo?.data?.ssn,
      birthday: userInfo?.data?.birthday_convert,
      title: gatewayDetail?.data?.title,
      linkId: gatewayDetail?.data?.link?.id,
      bank_account_id: gatewayDetail?.data?.bank_account_id ? +gatewayDetail?.data?.bank_account_id : undefined,
    });
  }, [gatewayDetail?.data, userInfo?.data]);

  return {
    form,
    onFinishCreateGatewayHandler,
    isLoadingUpdateGateway,
    accountBanks: accountBanks?.data,
    isLoadingAccountBanks,
  };
}

export default useGatewayUpdate;
