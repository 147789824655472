import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import AccountBank from 'components/Gateway/components/AccountBank/AccountBank';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import ZpDevider from 'components/_Core/UI/Devider/ZpDevider';
import ZpInformation from 'components/_Core/UI/Information/ZpInformation';

const GatewayCreate = () => {
  const { hasMoreThanOneGatewayLink } = useSelector((store: RootState) => store.linkStore);

  if (hasMoreThanOneGatewayLink) return <></>;

  return (
    <>
      <ZpDevider marginTop={false}>اطلاعات بانکی</ZpDevider>

      <ZpWrapper singleColumn>
        <div>
          <ZpFormItem
            rules={[
              {
                required: true,
                message: 'حساب بانکی را وارد کنید',
              },
            ]}
            name='bank_account_id'
          >
            <AccountBank />
          </ZpFormItem>

          <ZpInformation className='text-color-placeholder'>لطفا شماره شبای متعلق به پروفایل خود را وارد نمایید.</ZpInformation>
        </div>
      </ZpWrapper>
    </>
  );
};

export default GatewayCreate;
