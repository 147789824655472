import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';
import ActivePlansLayout from 'components/_Core/Layouts/PanelMaster/ActivePlans/ActivePlansLayout';
import ActivePlan from 'components/ActivePlans/ActivePlan';

const ActivePlanPage = () => {
  return (
    <ActivePlansLayout>
      <HeadMeta tabTitle='پلن فعال من' />
      <ActivePlan />
    </ActivePlansLayout>
  );
};

export default ActivePlanPage;
