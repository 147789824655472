import LinkShare from 'components/LinkShare/LinkShare';
import PanelLinkShare from 'components/_Core/Layouts/Panel/PanelShareLayout/PanelShareLayout';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';

const LinkSharePage = () => {
  return (
    <PanelLinkShare>
      <HeadMeta tabTitle='انتشار لینک' />
      <LinkShare />
    </PanelLinkShare>
  );
};

export default LinkSharePage;
