import { FC } from 'react';
import DatePicker, { CalendarProps, DatePickerProps } from 'react-multi-date-picker';
import classes from 'components/_Core/UI/DatePicker/style/style.module.scss';
import persian from 'react-date-object/calendars/persian';
import persian_fa from 'react-date-object/locales/persian_fa';
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

export type DatePickerType = DatePickerProps & CalendarProps;

interface IZpDatePicker extends DatePickerType {
	placeholder?: string;
	className?: string;
}

const ZpDatePicker: FC<IZpDatePicker> = ({ className, ...props }) => {
	const { theme } = useSelector((store: RootState) => store.settingStore);

	return (
		<DatePicker
			format='YYYY-MM-DD'
			className={`shadow-lg ${theme === 'dark' ? 'bg-dark' : ''} ${className}`}
			inputClass={`${props?.disabled ? `date-picker-disable ${classes['date-picker-disable']}` : ''} ${props['aria-invalid'] ? 'border-error-color-main' : ''} ${
				classes['dataPicker-input']
			}`}
			calendar={persian}
			locale={persian_fa}
			calendarPosition='bottom-right'
			{...props}
		/>
	);
};

export default ZpDatePicker;
