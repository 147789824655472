import { Tooltip } from 'antd';
import { TooltipPropsWithTitle } from 'antd/es/tooltip';
import { FC, ReactNode } from 'react';

interface IZpTooltip extends Omit<TooltipPropsWithTitle, 'title'> {
  title: string | ReactNode;
}

const ZpTooltip: FC<IZpTooltip> = ({ children, title, ...props }) => {
  return (
    <Tooltip title={title} {...props}>
      {children}
    </Tooltip>
  );
};

export default ZpTooltip;
