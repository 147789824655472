import { RootState } from 'core/store';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

const ToastProvider: FC = () => {
  const { theme, lang } = useSelector((store: RootState) => store.settingStore);

  return <ToastContainer rtl={lang === 'en' ? false : true} limit={3} theme={theme === 'light' ? 'light' : 'dark'} />;
};

export default ToastProvider;
