import { useDeleteGatewayLinkQuery } from 'components/Gateway/GatewayLinks/List/hooks/react-query/useDeleteGatewayLinkQuery';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { useNavigate } from 'react-router-dom';
import useRoute from 'core/hooks/useRoute';

const useGatewayLinkLayout = () => {
  // hooks
  const { query } = useQueryUrlParam();
  const navigate = useNavigate();
  const { routes } = useRoute();

  // query
  const { mutate: deleteGatewayLink, isLoading: isLoadingDeleteGatewayLink } = useDeleteGatewayLinkQuery();

  const deleteGatewayLinkHandler = () => {
    query?.gatewayLinkId ? deleteGatewayLink({ gatewayLinkId: query?.gatewayLinkId }, { onSuccess: () => navigate(routes.PANEL_HOME.link()) }) : navigate(-1);
  };

  return { isLoadingDeleteGatewayLink, deleteGatewayLinkHandler };
};

export default useGatewayLinkLayout;
