import { createSlice } from '@reduxjs/toolkit';
import { IBlock } from 'core/types/Models/linkType';

export interface linkDragAndDropType {
  order: number;
  value: number;
  children: { order: number; value: number }[] | [];
}

const initialState: {
  linkDragAndDropMasterDetail: IBlock[] | [];
  linkDragAndDropPayload: linkDragAndDropType[] | [];
} = {
  linkDragAndDropMasterDetail: [],
  linkDragAndDropPayload: [],
};

export const linkDragAndDropSlice = createSlice({
  name: 'linkDragAndDrop',
  initialState: initialState,
  reducers: {
    setLinkDragAndDropPayload: (state, { payload }: { payload: linkDragAndDropType[] }) => {
      state.linkDragAndDropPayload = payload;
    },
    setLinkDragAndDropMasterDetail: (state, { payload }: { payload: IBlock[] }) => {
      state.linkDragAndDropMasterDetail = payload;
    },
    resetLinkDragAndDropData: (state) => {
      state.linkDragAndDropMasterDetail = [];
      state.linkDragAndDropPayload = [];
    },
  },
});

export const { setLinkDragAndDropMasterDetail, resetLinkDragAndDropData, setLinkDragAndDropPayload } = linkDragAndDropSlice.actions;
export default linkDragAndDropSlice.reducer;
