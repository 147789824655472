import { useEffect, useState } from 'react';
import { useUpdateGatewayLinkQuery } from 'components/Gateway/GatewayLinks/Create/Professional/StepTwo/hooks/react-query/useUpdateGatewayLinkQuery';
import { useGatewayLinkDetailQuery } from 'core/hooks/react-query/useGatewayLinkDetailQuery';
import { Form } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { IGatewayLinkPayload } from 'core/types/Models/gatewayLinkType';
import { convertEnglishDateToJalali, convertJalaliDateToEnglish, extractNumberFromString } from 'core/utils/utils';
import { useCreateGatewayLinkBlockQuery } from 'components/BlocksComponents/GatewayBlock/hooks/react-query/useCreateGatewayLinkBlockQuery';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import useModal from 'core/hooks/useModal';

const useStepThree = () => {
  // hooks
  const [gatewayLinkStepThreeForm] = Form.useForm();
  const { query } = useQueryUrlParam();
  const { modal, showModal } = useModal();

  // query
  const { mutate: updateGatewayLink, isLoading: isLoadingUpdateGatewayLinkStepTwo } = useUpdateGatewayLinkQuery(undefined, showModal);
  const { data: gatewayLinkDetail, isFetching: isLoadingLinkDetail } = useGatewayLinkDetailQuery(true);
  const { mutate: createGatewayLinkBlock, isLoading: isLoadingCreateGatewayLinkBlock } = useCreateGatewayLinkBlockQuery(false);

  // store
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  // state
  const [limitAmount, setLimitAmount] = useState(false);
  const [limitDate, setLimitDate] = useState(false);
  const [limitTransaction, setLimitTransaction] = useState(false);

  const onFinishUpdateLinkStepThreeHandler = (values: IGatewayLinkPayload) => {
    updateGatewayLink(
      {
        ...values,
        is_show_process_bar: limitAmount ? values?.is_show_process_bar : false,
        payment_ceiling: limitAmount ? extractNumberFromString(values.payment_ceiling + '') : null,
        expired_at: limitDate ? convertJalaliDateToEnglish(values.expired_at) : null,
        time_expired_at: limitDate ? values.time_expired_at : null,
        deposit_min: limitTransaction ? extractNumberFromString(values.deposit_min + '') : null,
        deposit_max: limitTransaction ? extractNumberFromString(values.deposit_max + '') : null,
      },
      {
        onSuccess: () => {
          return createGatewayLinkBlock({ gatewayLinkId: query?.gatewayLinkId, title: 'لینک پرداخت' });
        },
      }
    );
  };

  useEffect(() => {
    setLimitAmount(!!gatewayLinkDetail?.data?.payment_ceiling || !!gatewayLinkDetail?.data?.is_show_process_bar);
    setLimitDate(!!gatewayLinkDetail?.data?.expired_at || !!gatewayLinkDetail?.data?.time_expired_at);
    setLimitTransaction(!!gatewayLinkDetail?.data?.deposit_min || !!gatewayLinkDetail?.data?.deposit_max);
    gatewayLinkStepThreeForm.setFieldsValue({
      ...gatewayLinkDetail?.data,
      ...(gatewayLinkDetail?.data?.expired_at ? { expired_at: convertEnglishDateToJalali(gatewayLinkDetail?.data?.expired_at) } : {}),
      ...(gatewayLinkDetail?.data?.payment_ceiling !== 0 ? { payment_ceiling: gatewayLinkDetail?.data?.payment_ceiling } : { payment_ceiling: undefined }),
      ...(gatewayLinkDetail?.data?.deposit_max !== 0 ? { deposit_max: gatewayLinkDetail?.data?.deposit_max } : { deposit_max: undefined }),
      ...(gatewayLinkDetail?.data?.deposit_min !== 0 ? { deposit_min: gatewayLinkDetail?.data?.deposit_min } : { deposit_min: undefined }),
    });
  }, [gatewayLinkDetail?.data]);

  useEffect(() => {
    gatewayLinkStepThreeForm.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors.length]);

  return {
    isLoading: isLoadingLinkDetail,
    onFinishUpdateLinkStepThreeHandler,
    isLoadingUpdateGatewayLinkStepTwo: isLoadingCreateGatewayLinkBlock || isLoadingUpdateGatewayLinkStepTwo,
    limitAmount,
    setLimitAmount,
    limitTransaction,
    setLimitTransaction,
    gatewayLinkStepThreeForm,
    limitDate,
    setLimitDate,
    modal,
  };
};

export default useStepThree;
