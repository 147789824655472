import { useEffect, useState } from 'react';
import useQueryUrlParam from './useQueryUrlParam';
import { removeArrayObject } from 'core/utils/utils';

const useSelected = (active?: string | number, hasUrlParam = true, deSelected = false, selectedTypeKeepActive?: { index: number | string; data?: any }[]) => {
  const { query, setUrlParam, search } = useQueryUrlParam();
  const [selectedType, setSelectedType] = useState<{ index: number | string; data: any }>({ index: hasUrlParam ? query?.tab : active, data: {} });
  const [selectedTypeKeep, setSelectedTypeKeep] = useState<{ index: number | string; data?: any }[]>(selectedTypeKeepActive || []);

  const selectedTypeHandler = (index: string | number, data?: any) => {
    setSelectedType({ index: deSelected ? (index === selectedType.index ? -1 : index) : index, data: deSelected ? (index === selectedType.index ? {} : data) : data });
  };

  const selectedTypeKeepHandler = (index: string | number, data?: any) => {
    if (selectedTypeKeep.some((item) => item.index === index)) {
      setSelectedTypeKeep([...removeArrayObject(selectedTypeKeep, [index], 'index')]);
    } else {
      setSelectedTypeKeep((prevState) => [...prevState, { index, data }]);
    }
  };

  useEffect(() => {
    if (hasUrlParam) {
      const newQueryParam = {
        tab: query?.tab || active,
      };

      setSelectedType({ index: query?.tab || active, data: {} });
      hasUrlParam && setUrlParam(newQueryParam);
    }
  }, [search]);

  return { selectedType, selectedTypeHandler, setUrlParam, selectedTypeKeepHandler, selectedTypeKeep, setSelectedTypeKeep };
};

export default useSelected;
