import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { stringToBoolean } from 'core/utils/utils';

const initialState: { apiCatcherFormErrors: any[]; isAuthenticated: boolean } = {
  apiCatcherFormErrors: [],
  isAuthenticated: stringToBoolean(process.env.REACT_APP_LOGIN_LOCAL) || !!Cookies.get('jwt'),
};

export const mainInfoSlice = createSlice({
  name: 'mainInfo',
  initialState: initialState,
  reducers: {
    setApiCatcherFormErrors: (state, { payload }) => {
      state.apiCatcherFormErrors = payload;
    },
    setAuthenticated: (state, { payload }) => {
      state.isAuthenticated = payload;
    },
  },
});

export const { setApiCatcherFormErrors, setAuthenticated } = mainInfoSlice.actions;
export default mainInfoSlice.reducer;
