import ZarinPalIcon from 'assets/images/zarinPalIcon.svg';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { FC } from 'react';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { useNavigate } from 'react-router-dom';
import useRoute from 'core/hooks/useRoute';
import { IGateway } from 'core/types/Models/gatewayType';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

interface IPaymentLinkListEmpty {
  gateway: IGateway | undefined;
}

const PaymentLinkListEmpty: FC<IPaymentLinkListEmpty> = ({ gateway }) => {
  const navigate = useNavigate();
  const { routes } = useRoute();
  const { linkGatewayDetail } = useSelector((store: RootState) => store.linkStore);

  if (gateway?.payment_links?.length !== 0) return <></>;

  return (
    <div className='bg-blue-light-color px-4 py-7 text-center rounded-lg space-y-3'>
      <ZpImage src={ZarinPalIcon} width={50} />
      <div>
        <p>لینک پرداخت {process.env.REACT_APP_BRAND_NAME_FA}</p>
        <p className='text-color-placeholder'>در حال حاظر بلوک لینک پرداختی وجود ندارد!</p>
      </div>

      <ZpButton type='primary' ghost buttonSize='xs' onClick={() => navigate(routes.GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_1.link(`gatewayId=${linkGatewayDetail?.id}`))}>
        ساخت لینک پرداخت جدید
      </ZpButton>
    </div>
  );
};

export default PaymentLinkListEmpty;
