import { Dropdown, DropDownProps } from 'antd';
import useModal from 'core/hooks/useModal';
import { FC, useEffect } from 'react';

interface IZpDropDown extends DropDownProps {
  trigger?: ('click' | 'hover' | 'contextMenu')[] | undefined;
  getDropdownStatus?: (open: any, hide: any, show: any) => void;
}

const ZpDropDown: FC<IZpDropDown> = ({ getDropdownStatus, trigger = ['click'], children, ...props }) => {
  const { modal: openDropdown, showModal: showDropdown, hideModal: hideDropdown } = useModal();

  useEffect(() => {
    openDropdown && getDropdownStatus?.(openDropdown, hideDropdown, showDropdown);
  }, [openDropdown]);

  const onOpenChange = () => {
    openDropdown ? hideDropdown() : showDropdown();
  };

  return (
    <Dropdown open={openDropdown} onOpenChange={onOpenChange} trigger={trigger} {...props}>
      {children}
    </Dropdown>
  );
};

export default ZpDropDown;
