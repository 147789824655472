import { Select, SelectProps } from 'antd';
import { FC, memo } from 'react';

export interface IZpSelect extends SelectProps {
  isSort?: boolean;
  placeholderTitle?: string;
  getAllDataForFilter?: boolean;
  customOnChange?: (value?: any, allProperty?: any) => void;
  setValue?: (val: any) => void;
}

const ZpSelect: FC<IZpSelect> = ({ getAllDataForFilter = false, onChange = () => {}, setValue, isSort = false, options = [], customOnChange, ...props }) => {
  const newItems = isSort ? options?.sort((a: any, b: any) => (a.label > b.label ? 1 : -1)) : options;

  return (
    <Select
      showSearch
      // notFoundContent={<DgEmpty />}
      options={newItems}
      optionFilterProp='filter'
      filterOption={(input: any, option: any) => (option?.filter as string).toLowerCase().includes(input.toLowerCase())}
      onSelect={(value, allProperty) => {
        setValue && setValue(value);
        customOnChange && customOnChange(value, allProperty);
        return onChange(getAllDataForFilter ? allProperty : value, []);
      }}
      {...props}
    />
  );
};

export default memo(ZpSelect);
