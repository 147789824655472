import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import api from 'core/services/fetch-api/useApi';
import { ITemplateCategoryList } from 'core/types/Models/templateType';

export const useTemplateListQuery = () => {
  return useQueryHook(
    [endPointUrls.TEMPLATE_LIST],
    () => api.get(endPointUrls.TEMPLATE_LIST),
    {
      select: (res: any) => res.data,
    },
    false
  ) as UseQueryResult<ITemplateCategoryList, Error>;
};
