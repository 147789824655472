import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryOnSuccessHandler from 'core/helpers/queryOnSuccessHandler/useQueryOnSuccessHandler';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';

export const useDeleteLinkQuery = () => {
  const { onSuccessHandler } = useQueryOnSuccessHandler();

  return useMutationHook([endPointUrls.LINK('delete')], (data: any) => api.delete(endPointUrls.LINK(data?.linkId)), {
    onSuccess: (res: any) => {
      onSuccessHandler(res);
    },
  }) as UseMutationResult;
};
