import ZpImage from 'components/_Core/UI/Image/ZpImage';
import EmptyGatewayLinkIcon from 'assets/images/empty-gateway-link.svg';

function GatewayInvoicesEmpty() {
  return (
    <div className='space-y-5 text-center text-color-paragraph relative top-20'>
      <ZpImage src={EmptyGatewayLinkIcon} />

      <p>تراکنشی وجود ندارد.</p>
    </div>
  );
}

export default GatewayInvoicesEmpty;
