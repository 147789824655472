import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryOnSuccessHandler from 'core/helpers/queryOnSuccessHandler/useQueryOnSuccessHandler';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';

export const useAccountBankCreateQuery = () => {
  const { onSuccessHandler } = useQueryOnSuccessHandler();

  return useMutationHook([endPointUrls.LINK_ACCOUNT_BANK], (data) => api.post(endPointUrls.LINK_ACCOUNT_BANK, data), {
    onSuccess: (res: any) => {
      onSuccessHandler(res);
    },
  }) as UseMutationResult;
};
