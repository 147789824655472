import { FC, memo, ReactNode } from 'react';
import MainLayout from 'components/_Core/Layouts/Main/MainLayout';
import MainHeaderLayout from 'components/_Core/Layouts/Main/MainHeader/MainHeaderLayout';
import useRoute from 'core/hooks/useRoute';

interface IProfileLayout {
  children: ReactNode;
}

const ProfileLayout: FC<IProfileLayout> = ({ children }) => {
  const { routes } = useRoute();

  return (
    <MainLayout>
      <MainHeaderLayout headerTitle='پروفایل من' backUrlArrow={routes.LINKS.link} />

      <>{children}</>
    </MainLayout>
  );
};

export default memo(ProfileLayout);
