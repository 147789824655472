import GatewayInvoiceSearchText from 'components/Gateway/components/GatewayInvoiceFilter/components/GatewayInvoiceSearchText';
import GatewayInvoiceDate from 'components/Gateway/components/GatewayInvoiceFilter/components/GatewayInvoiceDate';
import GatewayInvoiceStatus from 'components/Gateway/components/GatewayInvoiceFilter/components/GatewayInvoiceStatus';
import GatewayInvoiceAmount from 'components/Gateway/components/GatewayInvoiceFilter/components/GatewayInvoiceAmount';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';

const GatewayInvoiceFilter = () => {
  const { filterParams } = useQueryUrlParam();

  return (
    <div className='space-y-6'>
      <div className={filterParams ? 'flex items-center flex-wrap gap-5' : 'grid grid-cols-3 space-x-3 space-x-reverse'}>
        <GatewayInvoiceStatus />
        <GatewayInvoiceAmount />
        <GatewayInvoiceDate />
      </div>
      <GatewayInvoiceSearchText />
    </div>
  );
};

export default GatewayInvoiceFilter;
