import PaymentLinkIconDark from 'assets/images/icon/payment-dark.svg';
import PaymentLinkIconLight from 'assets/images/icon/payment-light.svg';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { FC } from 'react';
import PaymentLinkListEmpty from 'components/_Core/UI/BlockCreate/components/PaymentLinkListEmpty';
import { IGateway } from 'core/types/Models/gatewayType';
import { getImageUrl } from 'core/utils/utils';
import { BsArrowLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import useRoute from 'core/hooks/useRoute';

interface IPaymentLinkList {
  blockTypeTitle: string;
  blockTypeDescription: string;
  gateway: IGateway | undefined;
  onFinishCreateGatewayLinkHandler: ((gatewayLinkId: string | number) => void) | undefined;
}

const PaymentLinkList: FC<IPaymentLinkList> = ({ blockTypeTitle, blockTypeDescription, gateway, onFinishCreateGatewayLinkHandler }) => {
  const { theme } = useSelector((store: RootState) => store.settingStore);
  const navigate = useNavigate();
  const { routes } = useRoute();
  const { hasGateway, linkGatewayDetail } = useSelector((store: RootState) => store.linkStore);

  return (
    <>
      <div className='flex space-x-3 space-x-reverse mb-5'>
        <ZpImage src={theme === 'light' ? PaymentLinkIconLight : PaymentLinkIconDark} />
        <div>
          <h2 className='text-lg font-bold'>{blockTypeTitle}</h2>
          <p className='text-color-paragraph'>{blockTypeDescription}</p>
        </div>
      </div>

      <PaymentLinkListEmpty gateway={gateway} />

      <div className='grid grid-cols-1 gap-5'>
        {gateway?.payment_links?.map((link) => (
          <div
            className={`bg-blue-light-color ${
              link.is_use ? 'bg-natural-color-300 cursor-not-allowed' : ''
            } hover:bg-natural-color-300 p-4 cursor-pointer flex items-center space-x-reverse space-x-5 rounded-lg`}
            key={link.id}
            onClick={() => onFinishCreateGatewayLinkHandler && !link.is_use && onFinishCreateGatewayLinkHandler(link.id)}
          >
            <ZpImage src={getImageUrl(link.icon?.url)} width={40} height={40} className='rounded-full' />
            <p className='text-base'>
              {link.title} {link.is_use ? <span className='text-color-placeholder block'>لینک پرداخت در بلوک استفاده شده است</span> : ''}
            </p>
          </div>
        ))}

        {gateway?.payment_links?.length !== 0 && (
          <div
            onClick={() =>
              hasGateway
                ? navigate(routes.GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_1.link(`gatewayId=${linkGatewayDetail?.id}`))
                : navigate(routes.GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_1.link())
            }
            className='border border-primary-color-main cursor-pointer rounded-lg py-4'
          >
            <div className='flex items-center space-x-3 space-x-reverse w-full justify-center text-primary-color-main'>
              <span className='font-bold text-lg'>ساخت لینک پرداخت جدید</span>
              <BsArrowLeft size={23} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentLinkList;
