import { RootState } from 'core/store';
import BlockDraggableBox from './components/BlockDraggableBox';
import { useSelector } from 'react-redux';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import LinkPreviewSkeleton from 'components/_Core/UI/LinkPreviewSkeleton/LinkPreviewSkeleton';

const BlockDraggable = () => {
  const { linkDetail } = useSelector((store: RootState) => store.linkStore);
  const { isFetching: isFetchingLinkDetail } = useLinkDetailQuery();

  if (isFetchingLinkDetail) return <LinkPreviewSkeleton />;
  if (!linkDetail?.data) return <></>;

  return <BlockDraggableBox link={linkDetail.data} />;
};

export default BlockDraggable;
