import { useGatewayLinkListQuery } from 'components/Gateway/GatewayLinks/List/hooks/react-query/useGatewayLinkListQuery';

const useGatewayLinkList = () => {
  // query
  const { data: gatewayLinkList, isFetching: isLoadingGatewayLinkList } = useGatewayLinkListQuery();

  return { gatewayLinkList, isLoadingGatewayLinkList };
};

export default useGatewayLinkList;
