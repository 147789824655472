import React, { FC } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { LAT_INITIAL, LNG_INITIAL } from 'core/constants/constant';
import { IBlockDetail } from 'core/types/Models/linkType';
import useCopyToClipboard from 'core/hooks/useCopyToClipboard';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

interface IMapNavigationButtons {
  block: IBlockDetail;
}

const MapNavigationButtons: FC<IMapNavigationButtons> = ({ block }) => {
  const { copyHandler } = useCopyToClipboard();
  const { templateDetail } = useSelector((store: RootState) => store.templateStore);
  const { styles } = templateDetail || {};
  const { block_border_radius } = styles || {};

  return (
    <div className='flex items-center space-x-5 space-x-reverse'>
      <ZpButton
        style={{ borderRadius: block_border_radius || '0' }}
        ghost
        onClick={() => copyHandler(`https://maps.google.com/?q=${block?.lat || LAT_INITIAL},${block?.lng || LNG_INITIAL}`)}
        type='primary'
        className='w-full text-ghost_button_color border-ghost_button_color'
      >
        اشتراک گذاری
      </ZpButton>

      <MobileView className='w-full'>
        <a href={`geo:${block?.lat || LAT_INITIAL},${block?.lng || LNG_INITIAL}?q=${block?.lat || LAT_INITIAL},${block?.lng || LNG_INITIAL}`}>
          <ZpButton style={{ borderRadius: block_border_radius || '0' }} type='primary' className='w-full bg-primary_button_color'>
            مسیریابی
          </ZpButton>
        </a>
      </MobileView>

      <BrowserView className='w-full'>
        <a rel='noreferrer' target='_blank' href={`https://maps.google.com/?q=${block?.lat || LAT_INITIAL},${block?.lng || LNG_INITIAL}`}>
          <ZpButton style={{ borderRadius: block_border_radius || '0' }} type='primary' className='w-full bg-primary_button_color'>
            مسیریابی
          </ZpButton>
        </a>
      </BrowserView>
    </div>
  );
};

export default MapNavigationButtons;
