import { Image, ImageProps } from 'antd';
import { FC, memo, SyntheticEvent } from 'react';
import { twMerge } from 'tailwind-merge';

interface IZpImage extends ImageProps {
  className?: string;
}

const ZpImage: FC<IZpImage> = ({ className, src, ...props }) => {
  const onErrorHandler = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = require('assets/images/default.png');
  };
  return (
    <Image src={!src ? '../../../assets/images/default.png' : src} preview={false} onError={onErrorHandler} className={twMerge(`w-full object-cover ${className}`)} {...props} />
  );
};

export default memo(ZpImage);
