import useRoute from 'core/hooks/useRoute';
import LinkCategoryItems from './components/LinkCategoryItems';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { useNavigate } from 'react-router-dom';
import PanelCreateLinkLayoutFooter from 'components/_Core/Layouts/Panel/PanelCreateLinkLayout/PanelCreateLinkLayoutFooter';
import useStepThree from './hooks/useStepThree';
import CreateLinkStepThreeSkeleton from './components/CreateLinkStepThreeSkeleton';
import MainContentLayout from 'components/_Core/Layouts/Main/MainContentLayout/MainContentLayout';

const CreateLinkStepThree = () => {
  const { isLoadingUpdateLinkStepTwo, onFinishUpdateLinkStepThreeHandler, isLoading, linkCategories, selectedTypeKeep, selectedTypeKeepHandler } = useStepThree();
  const { routes } = useRoute();
  const { query } = useQueryUrlParam();
  const navigate = useNavigate();

  if (isLoading)
    return (
      <MainContentLayout>
        <CreateLinkStepThreeSkeleton />
      </MainContentLayout>
    );

  return (
    <>
      <MainContentLayout>
        <div className='flex flex-col justify-between h-full'>
          <div className='mb-10'>
            <p className='mb-5'>یک یا چند حوزه فعالیت را انتخاب کنید :</p>
            <LinkCategoryItems linkCategories={linkCategories} selectedTypeKeep={selectedTypeKeep} selectedTypeKeepHandler={selectedTypeKeepHandler} />
          </div>
        </div>
      </MainContentLayout>

      <PanelCreateLinkLayoutFooter
        backStep={() => navigate(routes.CREATE_LINK_STEP_2.link(`id=${query?.id}&username=${query?.username}`))}
        isLoading={isLoadingUpdateLinkStepTwo}
        onClick={onFinishUpdateLinkStepThreeHandler}
      />
    </>
  );
};

export default CreateLinkStepThree;
