import { RxHamburgerMenu } from 'react-icons/rx';
import { AiFillPlusCircle } from 'react-icons/ai';
import ZpLink from 'components/_Core/UI/Link/ZpLink';
import { IPanelFooterItem } from 'core/types/Components/PanelFooterItem/PanelHomeFooterItemType';
import MainFooterLayout from 'components/_Core/Layouts/Main/MainFooter/MainFooterLayout';
import { IMenuItem } from 'core/types/Components/MenuItem/PanelHomeFooterNavbarItemType';
import { ReactComponent as ActivePlan } from 'assets/images/icon/menu-icon/active-plan.svg';
import { ReactComponent as Transaction } from 'assets/images/icon/menu-icon/transaction.svg';
import { ReactComponent as Logout } from 'assets/images/icon/menu-icon/logout.svg';
import { ReactComponent as Phone } from 'assets/images/icon/phone-r.svg';
import { ReactComponent as Rules } from 'assets/images/icon/menu-icon/rules.svg';
import { ReactComponent as Plan } from 'assets/images/icon/menu-icon/plan.svg';
import { ReactComponent as Info } from 'assets/images/icon/menu-icon/info.svg';
import { ReactComponent as DashboardSvg } from 'assets/images/icon/menu-icon/dashboard.svg';
import PanelMenu from 'components/_Core/UI/Menu/PanelMenu';
import usePanelMasterFooterNavbar from 'components/_Core/Layouts/PanelMaster/PanelMasterIndex/PanelMasterIndexFooterNavbar/hooks/usePanelMasterFooterNavbar';
import { FC } from 'react';
import PanelMasterFooterNavbarImage from 'components/_Core/Layouts/PanelMaster/PanelMasterIndex/PanelMasterIndexFooterNavbar/components/PanelMasterFooterNavbarImage';

interface IPanelMasterIndexFooterNavbar {
  showQuickAccessButton?: boolean;
}

const PanelMasterIndexFooterNavbar: FC<IPanelMasterIndexFooterNavbar> = ({ showQuickAccessButton = true }) => {
  const { routes, showModalMenu, logout, navigate } = usePanelMasterFooterNavbar();

  const navigators: IPanelFooterItem[] = [
    { id: 1, icon: <RxHamburgerMenu size={30} />, onClick: showModalMenu },
    { id: 2, to: routes.PROFILE.link, icon: <PanelMasterFooterNavbarImage /> },
  ];

  const menuItems: IMenuItem[] = [
    { title: 'داشبورد اصلی', icon: <DashboardSvg />, to: routes.LINKS.link },
    { title: 'پلن فعال من', icon: <ActivePlan />, to: routes.ACTIVE_PLAN.link() },
    { title: `پلن‌های ${process.env.REACT_APP_BRAND_NAME_FA}`, icon: <Plan />, to: routes.PLANS_LIST.link() },
    { title: 'تراکنس‌های من', icon: <Transaction />, to: routes.TRANSACTIONS_LIST.link() },
    // { title: 'قوانین و مقررات', icon: <Rules /> },
    // { title: 'درباره زرین لینک', icon: <Info /> },
    { title: 'پشتیبانی', icon: <Phone />, to: routes.SUPPORT.link },
    { title: 'خروج', icon: <Logout />, onClick: logout },
  ];

  return (
    <>
      <PanelMenu menuItems={menuItems} />

      <MainFooterLayout className='flex items-center justify-between sm:px-10'>
        {navigators.map((nav) => (
          <PanelMasterFooterNavbarItem key={nav.id} {...nav} />
        ))}

        {showQuickAccessButton && (
          <>
            <div className='bg-white absolute -top-4 left-1/2 -translate-x-1/2 w-10 h-10 rounded-full shadow-xl' />
            <AiFillPlusCircle
              onClick={() => navigate(routes.CREATE_LINK_STEP_1.link())}
              stroke='var(--white-color)'
              size={65}
              className='absolute -top-8 left-1/2 -translate-x-1/2 cursor-pointer'
              color='var(--primary-color-main)'
            />
          </>
        )}
      </MainFooterLayout>
    </>
  );
};

const PanelMasterFooterNavbarItem = ({ icon, to, onClick }: IPanelFooterItem) => {
  const Item = (
    <div className='flex flex-col space-y-2 items-center relative cursor-pointer' onClick={onClick}>
      {icon}
    </div>
  );

  return to ? <ZpLink to={to}>{Item}</ZpLink> : Item;
};

export default PanelMasterIndexFooterNavbar;
