import { useEffect } from 'react';
import { Form } from 'antd';
import { IGatewayDetail, IGatewayPayload } from 'core/types/Models/gatewayType';
import { convertAllPropertyToEnNumber, convertEnglishToPersian, convertJalaliDateToEnglish } from 'core/utils/utils';
import { useGatewayCreateQuery } from 'components/Gateway/hooks/react-query/useGatewayCreateQuery';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useGatewayDetailQuery } from 'core/hooks/react-query/useGatewayDetailQuery';
import { useGatewayUpdateQuery } from 'components/Gateway/hooks/react-query/useGatewayUpdateQuery';
import useCreateGatewayLinkStepOne from 'components/Gateway/GatewayLinks/Create/Professional/StepOne/hooks/useCreateGatewayLinkStepOne';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import useGatewayLinkCreateSimple from 'components/Gateway/GatewayLinks/Create/Simple/hooks/useGatewayLinkCreateSimple';
import useRoute from 'core/hooks/useRoute';

const useGatewayCreate = () => {
  //hooks
  const [form] = Form.useForm();
  const { routes } = useRoute();
  const { query, pathname } = useQueryUrlParam(false);
  const { isLoadingCreateGatewayLinkStepOne, onFinishCreateGatewayLinkStepOneHandler } = useCreateGatewayLinkStepOne(form);
  const { isLoadingCreateGatewayLinkSimple, onFinishCreateGatewayLinkSimpleHandler, modal } = useGatewayLinkCreateSimple(form);

  const isLoadingCreateGatewayLink = pathname === routes.GATEWAY_CREATE_SIMPLE.link() ? isLoadingCreateGatewayLinkSimple : isLoadingCreateGatewayLinkStepOne;
  const onFinishCreateGatewayLink = pathname === routes.GATEWAY_CREATE_SIMPLE.link() ? onFinishCreateGatewayLinkSimpleHandler : onFinishCreateGatewayLinkStepOneHandler;

  // store
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);
  const { linkDetail, hasMoreThanOneGatewayLink } = useSelector((store: RootState) => store.linkStore);
  const { userInfo } = useSelector((store: RootState) => store.userStore);

  // query
  const { mutate: createGateway, isLoading: isLoadingCreateGateway } = useGatewayCreateQuery();
  const { mutate: updateGateway, isLoading: isLoadingUpdateGateway } = useGatewayUpdateQuery();
  const { isFetching: isLoadingLinkDetail } = useLinkDetailQuery();
  const { data: gatewayDetail, isFetching: isLoadingGatewayLinkDetail } = useGatewayDetailQuery(true);

  const onCreateOrUpdateGatewayHandler = (values: IGatewayPayload, title: string, description: string) => {
    if (hasMoreThanOneGatewayLink) {
      return onFinishCreateGatewayLink({ title, description });
    }

    if (gatewayDetail?.data?.id && query?.gatewayId) {
      updateGateway(values, { onSuccess: () => onFinishCreateGatewayLink({ title, description }) });
    } else {
      createGateway(values, { onSuccess: (res: any) => onFinishCreateGatewayLink({ title, description }, res?.data as IGatewayDetail) });
    }
  };

  const onFinishCreateGatewayHandler = (values: IGatewayPayload) => {
    const { ssn, birthday_convert } = userInfo?.data || {};
    const formattedValuesToEnglish = convertAllPropertyToEnNumber(
      { ssn: values.ssn || ssn, birthday: convertJalaliDateToEnglish(values.birthday || birthday_convert + ''), bank_account_id: values.bank_account_id },
      false
    );
    const formattedValues = { ...formattedValuesToEnglish, title: convertEnglishToPersian(linkDetail?.data?.title_fa + '') };

    onCreateOrUpdateGatewayHandler(formattedValues, values.linkTitle, values.description);
  };

  useEffect(() => {
    form.setFields(apiCatcherFormErrors);
  }, [JSON.stringify(apiCatcherFormErrors)]);

  useEffect(() => {
    if (gatewayDetail?.data?.bank_account_id) {
      form.setFieldsValue({ bank_account_id: +gatewayDetail?.data?.bank_account_id });
    }
  }, [gatewayDetail]);

  return {
    modal,
    createGatewayForm: form,
    onFinishCreateGatewayHandler,
    isLoadingGatewayLinkDetail: isLoadingGatewayLinkDetail || isLoadingLinkDetail,
    isLoadingCreateGateway: isLoadingCreateGateway || isLoadingUpdateGateway || isLoadingCreateGateway || isLoadingCreateGatewayLink,
  };
};

export default useGatewayCreate;
