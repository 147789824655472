import DividerBlock from 'components/BlocksComponents/DividerBlock/DividerBlock';
import BlockLayoutCreate from 'components/_Core/Layouts/Panel/PanelHomeBlockLayout/Create/PanelHomeBlockLayoutCreate';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';

const DividerBlockPage = () => {
  return (
    <BlockLayoutCreate>
      <HeadMeta tabTitle='جداکننده‌ها' />
      <DividerBlock />
    </BlockLayoutCreate>
  );
};

export default DividerBlockPage;
