import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import Container from 'components/_Core/Layouts/Container/Container';
import { FiArrowRight } from 'react-icons/fi';
import { disableBackToUrl } from 'core/utils/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClipLoader, PulseLoader } from 'react-spinners';
import { SlClose } from 'react-icons/sl';

interface IMainHeaderLayout {
  headerTitle?: string | ReactNode;
  backUrlArrow?: string;
  backUrlClose?: string;
  className?: string;
  closeLading?: boolean;
  headerLading?: boolean;
  onCloseHandler?: () => void;
  children?: ReactNode;
}

const MainHeaderLayout: FC<IMainHeaderLayout> = ({ className = '', headerTitle = '', backUrlArrow, backUrlClose, closeLading, onCloseHandler, headerLading, children }) => {
  const navigate = useNavigate();
  const { key, state } = useLocation();

  return (
    <div className={twMerge(`shadow-md py-5 h-[71px] sticky top-0 bg-white-color z-50 ${className}`)}>
      <Container className='h-full'>
        {children ? (
          children
        ) : (
          <div className='flex items-center justify-between h-full'>
            <div className='flex items-center space-x-3 space-x-reverse h-full'>
              {backUrlArrow && (
                <FiArrowRight
                  size={30}
                  className='cursor-pointer'
                  onClick={() => (key === 'default' || state?.disableBackToUrl ? navigate(backUrlArrow, { state: disableBackToUrl }) : navigate(-1))}
                />
              )}
              <h2 className='font-bold text-xl'>{headerLading ? <PulseLoader className='relative top-1' color='var(--primary-color-400)' size={10} /> : headerTitle}</h2>
            </div>

            {(backUrlClose || onCloseHandler) && (
              <>
                {closeLading ? (
                  <ClipLoader color='var(--primary-color-main)' size={18} />
                ) : (
                  <SlClose
                    size={22}
                    className='cursor-pointer'
                    onClick={() =>
                      onCloseHandler?.() ||
                      (backUrlClose && (key === 'default' || state?.disableBackToUrl ? navigate(backUrlClose + '', { state: disableBackToUrl }) : navigate(-1)))
                    }
                  />
                )}
              </>
            )}
          </div>
        )}
      </Container>
    </div>
  );
};

export default MainHeaderLayout;
