import './assets/css/global.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import { BrowserRouter as Router } from 'react-router-dom';
import MainRoute from 'core/MainRoute/MainRoute';
import IndexProvider from './core/providers/IndexProvider';
import * as Sentry from '@sentry/react';

function App() {
  return (
    <Router>
      <IndexProvider>
        <MainRoute />
      </IndexProvider>
    </Router>
  );
}

export default Sentry.withProfiler(App);
