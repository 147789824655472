import GatewayLayout from 'components/_Core/Layouts/Panel/PanelHomeGatewayLout/PanelHomeGatewayLayout';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';
import useRoute from 'core/hooks/useRoute';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import GatewayLinkInvoices from 'components/Gateway/GatewayLinkInvoices/GatewayLinkInvoices';

const GatewayLinkInvoicesPage = () => {
  const { routes } = useRoute();
  const { gatewayLinkDetail } = useSelector((store: RootState) => store.gatewayStore);
  const headerTitle = `تراکنش‌های لینک پرداخت (${gatewayLinkDetail?.data?.title || '--'})`;

  return (
    <GatewayLayout headerTitle={headerTitle} backUrl={routes.LINKS.link} enableGatewayDetail={false}>
      <HeadMeta tabTitle={headerTitle} />
      <GatewayLinkInvoices />
    </GatewayLayout>
  );
};

export default GatewayLinkInvoicesPage;
