import { FC, ReactNode } from 'react';
import AntProvider from './AntProvider/AntProvider';
import AppProvider from './AppProvider/AppProvider';
import ReactQueryProvider from './ReactQueryProvider/ReactQueryProvider';
import ReduxProvider from './ReduxProvider/ReduxProvider';
import ThemeProvider from './ThemeProvider/ThemeProvider';
import ToastProvider from './ToastProvider/ToastProvider';
import HelmetProviders from './HelmetProvider/HelmetProviders';

interface IIndexProvider {
  children: ReactNode;
}

const IndexProvider: FC<IIndexProvider> = ({ children }) => {
  return (
    <ReduxProvider>
      <AntProvider>
        <ThemeProvider>
          <ReactQueryProvider>
            <HelmetProviders>
              <AppProvider>{children}</AppProvider>
            </HelmetProviders>
          </ReactQueryProvider>
          <ToastProvider />
        </ThemeProvider>
      </AntProvider>
    </ReduxProvider>
  );
};

export default IndexProvider;
