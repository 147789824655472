import { FormInstance } from 'antd';
import { IBlockDetail } from 'core/types/Models/linkType';
import { useEffect } from 'react';
import { useUpdateExternalLinkQuery } from './react-query/useUpdateExternalLinkQuery';
import { useDeleteExternalLinkQuery } from './react-query/useDeleteExternalLinkQuery';
import useModal from 'core/hooks/useModal';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';

const useUpdateExternalLink = (form: FormInstance, block: IBlockDetail | undefined) => {
  // hooks
  const { hideModal, modal, showModal } = useModal();

  // query
  const { mutate: updateExternalLink, isLoading: isLoadingUpdateExternalLink } = useUpdateExternalLinkQuery();
  const { mutate: deleteExternalLink, isLoading: isLoadingDeleteExternalLink } = useDeleteExternalLinkQuery();

  // store
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  useEffect(() => {
    block && form.setFieldsValue({ ...block });
  }, []);

  useEffect(() => {
    const hasError = apiCatcherFormErrors.every((item) => item?.payload?.id === block?.id);
    hasError && form.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors]);

  const onFinishUpdateHandler = (values: { title: string; username: string }) => {
    updateExternalLink({ ...values, order: block?.order, id: block?.id });
  };

  const onDeleteHandler = () => {
    deleteExternalLink({ id: block?.id });
  };

  return { onFinishUpdateHandler, isLoadingUpdateExternalLink, isLoadingDeleteExternalLink, onDeleteHandler, hideModal, modal, showModal };
};

export default useUpdateExternalLink;
