import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryOnSuccessHandler from 'core/helpers/queryOnSuccessHandler/useQueryOnSuccessHandler';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import useRoute from 'core/hooks/useRoute';
import api from 'core/services/fetch-api/useApi';
import { useNavigate } from 'react-router-dom';

export const useUpdateLinkQuery = (step?: 'CREATE_LINK_STEP_3' | 'CREATE_LINK_STEP_2', finalNavigate?: boolean, linkIdParam?: number | string) => {
  const { onSuccessHandler } = useQueryOnSuccessHandler();
  const { routes, linkId } = useRoute();
  const { query } = useQueryUrlParam();
  const navigate = useNavigate();

  return useMutationHook([endPointUrls.LINK('update')], (data: any) => api.put(endPointUrls.LINK(query?.id || data?.linkId || linkId || linkIdParam), data), {
    onSuccess: (res: any) => {
      if (res.status === 200) {
        onSuccessHandler(res);
        step && navigate(routes[step].link(`id=${res.data?.data?.id}&username=${res.data?.data?.username}`));
        if (finalNavigate) {
          navigate(routes.PANEL_HOME.link(res.data?.data?.username));
        }
      }
    },
  }) as UseMutationResult;
};
