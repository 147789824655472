import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { useDispatch } from 'react-redux';
import toastHandler from '../toast/toast';

const useQueryOnSuccessHandler = () => {
  const dispatch = useDispatch();

  const onSuccessHandler = (res: any, showToast = true) => {
    if (res.status === 200) {
      showToast && toastHandler('success', res.data.message);
      dispatch(setApiCatcherFormErrors([]));
    }
  };

  return { onSuccessHandler };
};

export default useQueryOnSuccessHandler;
