import { MenuProps } from 'antd';
import ZpDropDown from 'components/_Core/UI/DropDown/ZpDropDown';
import { FiAlertTriangle, FiMoreHorizontal } from 'react-icons/fi';
import ZpLink from 'components/_Core/UI/Link/ZpLink';
import useRoute from 'core/hooks/useRoute';
import { AiOutlineCheckCircle, AiOutlineDelete, AiTwotoneEdit } from 'react-icons/ai';
import ZpDrawer from 'components/_Core/UI/Drawer/ZpDrawer';
import useLinkAction from '../hooks/useLinkAction';
import LinkStatus from './LinkStatus';

const LinkAction = ({ username, status, linkId }: { username: string; status: number; linkId: number }) => {
  // hooks
  const { hideModal, modal, showModalHandler, openDropDown, isDeleted, onOpenChange, isLoadingUpdateLink, onChangeStatusHandler } = useLinkAction();
  const { routes } = useRoute();

  const items: MenuProps['items'] = [
    {
      label: (
        <ZpLink to={routes.LINK_UPDATE.link(username + '')} className='flex items-center'>
          <AiTwotoneEdit size={16} className='ml-1' />
          <span>ویرایش لینک</span>
        </ZpLink>
      ),
      key: '0',
    },
    {
      label:
        status === 0 ? (
          <p className='flex items-center text-success-color-300' onClick={() => showModalHandler()}>
            <AiOutlineCheckCircle size={16} className='ml-1' color='var(--success-color-300)' />
            <span>فعال سازی لینک</span>
          </p>
        ) : (
          <p className='flex items-center text-warning-color-300' onClick={() => showModalHandler()}>
            <FiAlertTriangle size={16} className='ml-1' color='var(--warning-color-300)' />
            <span>غیر فعال کردن لینک</span>
          </p>
        ),
      key: '1',
    },
    {
      label: (
        <p className='flex items-center text-error-color-300' onClick={() => showModalHandler(true)}>
          <AiOutlineDelete size={16} className='ml-1' color='var(--error-color-300)' />
          <span>حذف لینک</span>
        </p>
      ),
      key: '2',
    },
  ];

  return (
    <>
      <ZpDrawer height='auto' placement='bottom' closable={false} open={modal} onClose={hideModal}>
        <LinkStatus isDeleted={isDeleted} hideModal={hideModal} linkId={linkId} status={status} isLoadingUpdateLink={isLoadingUpdateLink} onSubmitHandler={onChangeStatusHandler} />
      </ZpDrawer>
      <ZpDropDown menu={{ items }} open={openDropDown} onOpenChange={onOpenChange}>
        <FiMoreHorizontal size={25} className='absolute cursor-pointer top-6 left-6' />
      </ZpDropDown>
    </>
  );
};

export default LinkAction;
