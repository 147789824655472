import Container from 'components/_Core/Layouts/Container/Container';
import ZpSkeleton from 'components/_Core/UI/Skeleton/ZpSkeleton';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';

const CreateLinkStepTwoSkeleton = () => {
  return (
    <Container className='h-full'>
      <div className='flex flex-col justify-between h-full'>
        <ZpWrapper singleColumn>
          <div className='text-center'>
            <ZpSkeleton height={250} />
          </div>

          <div>
            <ZpSkeleton height={40} />
          </div>
        </ZpWrapper>
      </div>
    </Container>
  );
};

export default CreateLinkStepTwoSkeleton;
