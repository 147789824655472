import ZpSkeleton from 'components/_Core/UI/Skeleton/ZpSkeleton';

const PlanItemSkeleton = () => {
  return (
    <>
      <div className='px-10'>
        <ZpSkeleton height={100} />
      </div>
      <div className='px-7 py-6 mt-8 rounded-2xl shadow-lg text-center bg-block-item-bg max-w-xs w-full mx-auto relative space-y-6'>
        <div className='space-y-5'>
          <ZpSkeleton circle width={90} height={90} />

          <div>
            <ZpSkeleton width={100} height={35} />
          </div>

          <div>
            <ZpSkeleton width={100} height={35} />
          </div>
        </div>

        <div className='p-4 space-y-5'>
          <div>
            <ZpSkeleton height={35} />
          </div>

          <div>
            <ZpSkeleton height={35} />
          </div>

          <div>
            <ZpSkeleton height={35} />
          </div>
        </div>

        <div>
          <ZpSkeleton width={200} height={45} />
        </div>
      </div>
    </>
  );
};

export default PlanItemSkeleton;
