import { RiArrowLeftSLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import useRoute from 'core/hooks/useRoute';
import GatewayItem from 'components/Gateway/List/components/GatewayItem';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import ZpSpinner from 'components/_Core/UI/Spinner/ZpSpinner';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import logo from 'assets/images/logo/logo-text.svg';

interface IItems {
  title: string;
  link: string;
  external?: boolean;
}

const GatewayDetail = () => {
  // hooks
  const { routes } = useRoute();

  // query
  const { isFetching } = useLinkDetailQuery();

  // store
  const { linkDetail } = useSelector((store: RootState) => store.linkStore);

  const items: IItems[] = [
    { title: 'مدیریت لینک‌های پرداخت', link: routes.GATEWAY_LINKS.link() },
    { title: 'لیست تراکنش‌ها', link: routes.GATEWAY_INVOICES.link() },
    // { title: 'ویرایش درگاه پرداخت', link: routes.GATEWAY_UPDATE.link() },
    { title: 'تسویه حساب', link: routes.ZARINPAL, external: true },
  ];

  return (
    <div className='space-y-5'>
      <div className='p-4 bg-primary-color-50 dark:bg-natural-color-50 rounded-lg border-border-color border space-y-5'>
        <ZpImage src={logo} width={100} />

        {isFetching && (!linkDetail?.data || linkDetail?.data?.gateways?.length === 0) && (
          <div className='text-center'>
            <ZpSpinner />
          </div>
        )}

        {linkDetail?.data?.gateways.map((item) => (
          <GatewayItem isFetching={isFetching} key={item.id} {...item} />
        ))}
      </div>

      {items.map((item) => (
        <GatewayDetailItem key={item.title} {...item} />
      ))}
    </div>
  );
};

const GatewayDetailItem = ({ title, link, external }: IItems) => {
  const navigate = useNavigate();

  return external ? (
    <a href={link} target='_blank' rel='noreferrer' className='py-4 px-3 cursor-pointer bg-white-color border border-border-color rounded-lg flex items-center justify-between'>
      <h2 className='font-bold text-lg'>{title}</h2>

      <RiArrowLeftSLine size={22} />
    </a>
  ) : (
    <div onClick={() => navigate(link)} className='py-4 px-3 cursor-pointer bg-white-color border border-border-color rounded-lg flex items-center justify-between'>
      <h2 className='font-bold text-lg'>{title}</h2>

      <RiArrowLeftSLine size={22} />
    </div>
  );
};

export default GatewayDetail;
