import { FormInstance } from 'antd';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import faq from 'assets/images/icon/faq.svg';
import { FC } from 'react';
import ZpTextAreaLabel from '../../../../_Core/UI/TextArea/ZpTextAreaLabel';

interface IFaqBlockCreate {
  createForm: FormInstance;
  isLoadingCreateFaq: boolean;
  onFinishCreateLinkHandler: (val: any) => void;
  hideChildModalHandler: () => void;
}

const FaqBlockCreate: FC<IFaqBlockCreate> = ({ createForm, isLoadingCreateFaq, hideChildModalHandler, onFinishCreateLinkHandler }) => {
  return (
    <>
      <div className='flex items-center space-x-reverse space-x-5 mb-5'>
        <ZpImage className='rounded-full' src={faq} width={45} height={45} />
        <h2 className='text-lg font-bold'>سوالات متداول</h2>
      </div>

      <ZpForm form={createForm} onFinish={onFinishCreateLinkHandler}>
        <ZpWrapper singleColumn>
          <ZpFormItem rules={[{ required: true, message: 'سوال را وارد کنید' }]} name='question'>
            <ZpInputLabel required label='سوال' />
          </ZpFormItem>

          <ZpFormItem rules={[{ required: true, message: 'جواب را وارد کنید' }]} name='answer'>
            <ZpTextAreaLabel required label='جواب' />
          </ZpFormItem>

          <ZpFormItem button>
            <div className='flex items-center space-x-reverse space-x-5 justify-between'>
              <ZpButton className='w-full' buttonSize='xs' htmlType='button' ghost type='primary' onClick={hideChildModalHandler}>
                انصراف
              </ZpButton>
              <ZpButton className='w-full' buttonSize='xs' htmlType='submit' type='primary' isLoading={isLoadingCreateFaq}>
                ساخت بلوک
              </ZpButton>
            </div>
          </ZpFormItem>
        </ZpWrapper>
      </ZpForm>
    </>
  );
};

export default FaqBlockCreate;
