import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { IBlockDetail } from 'core/types/Models/linkType';
import { FC, Fragment } from 'react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import ZpBlockDelete from 'components/_Core/UI/BlockDelete/ZpBlockDelete';
import { IBlockTypeDetail } from 'core/types/Models/blockType';
import useDashboardChild from '../hooks/useDashboardChild';
import { getImageUrl } from 'core/utils/utils';
import { useNavigate } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';
import useRoute from 'core/hooks/useRoute';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import zarinpalIcon from 'assets/images/zarinPalIcon.svg';

interface IDashboardGatewayLinkBlock {
  blocks: IBlockDetail[];
  blockType: IBlockTypeDetail;
}

const DashboardGatewayLinkBlock: FC<IDashboardGatewayLinkBlock> = ({ blocks, blockType }) => {
  // hooks
  const navigate = useNavigate();
  const { routes } = useRoute();

  // store
  const { linkGatewayDetail, hasActiveGateway, gatewayStatus, linkDetail } = useSelector((store: RootState) => store.linkStore);

  return (
    <div className='w-full space-y-3'>
      <p className='text-lg font-bold text-center text-gray-color-700'>{blockType.title}</p>

      {blocks?.map((block) => {
        const { modal, hideModal, showModal, isLoadingDelete, onDeleteHandler } = useDashboardChild(blockType, block.id);
        const gatewayIcon = !hasActiveGateway ? gatewayStatus?.icon : block?.icon?.url ? getImageUrl(block?.icon?.url) : zarinpalIcon;

        return (
          <Fragment key={block.id}>
            <ZpBlockDelete modal={modal} hideModal={hideModal} isLoadingDelete={isLoadingDelete} onDeleteHandler={onDeleteHandler} blockId={block?.id} />
            <div className='bg-[var(--block-item-bg)] border border-gray-color-300 rounded-lg w-full p-2 space-x-3 space-x-reverse flex items-center justify-between'>
              <div className='flex items-center space-x-4 space-x-reverse flex-1'>
                <div className='max-w-[40px] w-full flex'>
                  <ZpImage className='rounded-full' width={40} height={40} src={gatewayIcon} />
                </div>

                <div className='flex flex-col'>
                  <span className='line-clamp-2'>{block.title}</span>

                  {!hasActiveGateway && (
                    <p className='text-color-placeholder text-xs max-w-[170px] w-full'>
                      حساب بانکی شما برای این لینک پرداخت <span style={{ color: gatewayStatus?.color }}>{gatewayStatus?.message}</span> است!
                    </p>
                  )}
                </div>
              </div>

              <div className='space-x-3 space-x-reverse max-w-[60px] w-full flex'>
                <FaRegEdit
                  className='cursor-pointer'
                  size={22}
                  onClick={() =>
                    !hasActiveGateway
                      ? navigate(routes.GATEWAY_DETAIL.link(linkDetail?.data?.gateways?.[0]?.id))
                      : block?.icon
                      ? navigate(routes.GATEWAY_LINK_PROFESSIONAL_UPDATE.link(block?.id, linkGatewayDetail?.id))
                      : navigate(routes.GATEWAY_LINK_SIMPLE_UPDATE.link(block?.id, linkGatewayDetail?.id))
                  }
                />
                <RiDeleteBin6Line className='cursor-pointer' size={22} color='var(--error-color-300)' onClick={showModal} />
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default DashboardGatewayLinkBlock;
