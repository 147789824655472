import { Form, FormInstance, FormProps } from 'antd';
import { ReactNode, forwardRef, memo } from 'react';
import { twMerge } from 'tailwind-merge';

interface IZpForm extends FormProps {
  children: ReactNode;
  className?: string;
}

const ZpForm = forwardRef<FormInstance, IZpForm>(({ children, layout = 'vertical', className, ...props }, ref) => (
  <Form layout={layout} className={twMerge(`mb-4 ${className}`)} ref={ref} {...props}>
    {children}
  </Form>
));

ZpForm.displayName = 'ZpForm';

export default memo(ZpForm);
