import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';
import PanelCreateGatewayLinkLayout from 'components/_Core/Layouts/Panel/PanelCreateGatewayLinkLayout/PanelCreateGatewayLinkLayout';
import GatewayLinkCreateProfessionalStepOne from 'components/Gateway/GatewayLinks/Create/Professional/StepOne/GatewayLinkCreateProfessionalStepOne';

const GatewayLinkCreateProfessionalStepOnePage = () => {
  return (
    <PanelCreateGatewayLinkLayout>
      <HeadMeta tabTitle='ایجاد لینک پرداخت جدید - مرحله 1' />
      <GatewayLinkCreateProfessionalStepOne />
    </PanelCreateGatewayLinkLayout>
  );
};

export default GatewayLinkCreateProfessionalStepOnePage;
