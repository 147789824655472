import { ReactComponent as FailedPaymentSVG } from 'assets/images/plan/failed-payment.svg';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { FiArrowLeft } from 'react-icons/fi';
import Container from 'components/_Core/Layouts/Container/Container';
import ZpLink from 'components/_Core/UI/Link/ZpLink';
import ZpSpinner from 'components/_Core/UI/Spinner/ZpSpinner';
import usePaymentReturnFailed from 'components/PaymentReturnFailed/hooks/usePaymentReturnFailed';

const PaymentReturnFailed = () => {
  const { routes, isLoadingPlanPay, isLoadingUserInfo, userInfo, onPlanPayHandler } = usePaymentReturnFailed();
  const userDetail = isLoadingUserInfo ? <ZpSpinner /> : userInfo?.data?.firstname;

  return (
    <Container>
      <div className='flex flex-col items-center justify-center space-y-8'>
        <FailedPaymentSVG />

        <div className='space-y-5 text-center'>
          <h1 className='font-bold text-2xl'>پرداخت شما ناموفق بود!</h1>

          <p className='text-color-paragraph'>
            <span className='text-secondary-color-main'>{userDetail} عزیز</span> متاسفانه حساب کاربری شما به حرفه ای ارتقا پیدا نکرد.میتوانید دوباره اقدام نمایید.
          </p>
        </div>

        <ZpButton onClick={onPlanPayHandler} isLoading={isLoadingPlanPay} type='primary' className='w-full' buttonIcon={<FiArrowLeft size={22} />}>
          پرداخت دوباره
        </ZpButton>

        <ZpLink to={routes.LINKS.link} className='font-bold text-primary-color-main'>
          بازگشت به داشبورد
        </ZpLink>
      </div>
    </Container>
  );
};

export default PaymentReturnFailed;
