import { IBlock } from 'core/types/Models/linkType';
import { FC } from 'react';
import LinkDetailBlockChild from './LinkDetailBlockChild';
import LinkDetailDeviderBlock from 'components/LinkPreview/components/Divider/LinkDetailDeviderBlock';
import LinkDetailFaqBlockChild from 'components/LinkPreview/components/Faq/LinkDetailFaqBlockChild';
import { getBlockTypeDetail } from 'core/utils/utils';
import LinkDetailGatewayBlock from 'components/LinkPreview/components/Gateway/LinkDetailGatewayBlock';
import LinkDetailNavigationBlock from 'components/LinkPreview/components/Navigation/LinkDetailNavigationBlock';

interface ILinkDetailBlockMaster {
  blocks: IBlock[];
  username: string;
}

const LinkDetailBlockMaster: FC<ILinkDetailBlockMaster> = ({ blocks, username }) => {
  return (
    <div className='flex flex-col items-center space-y-8'>
      {blocks?.map((block) => {
        const blockType = getBlockTypeDetail(block);

        return block.type === 'App\\Models\\LinkApp\\Divider' ? (
          block.data?.length > 0 && <LinkDetailDeviderBlock key={block.id} blocks={block.data} />
        ) : block.type === 'App\\Models\\LinkApp\\FAQ' ? (
          block.data?.length > 0 && <LinkDetailFaqBlockChild key={block.id} blockType={blockType} blocks={block.data} />
        ) : block.type === 'App\\Models\\LinkApp\\PaymentLink' ? (
          block.data?.length > 0 && <LinkDetailGatewayBlock username={username} key={block.id} blockType={blockType} blocks={block.data} />
        ) : block.type === 'App\\Models\\LinkApp\\Navigation' ? (
          block.data?.length > 0 && <LinkDetailNavigationBlock key={block.id} blockType={blockType} blocks={block.data} />
        ) : (
          <LinkDetailBlockChild key={block.id} blockType={blockType} blocks={block.data} />
        );
      })}
    </div>
  );
};

export default LinkDetailBlockMaster;
