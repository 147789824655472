import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { IBlockDetail } from 'core/types/Models/linkType';
import { FC, Fragment } from 'react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import ZpBlockDelete from 'components/_Core/UI/BlockDelete/ZpBlockDelete';
import { IBlockTypeDetail } from 'core/types/Models/blockType';
import useDashboardChild from '../hooks/useDashboardChild';
import { getImageUrl } from 'core/utils/utils';

interface IDashboardDividerBlock {
  blocks: IBlockDetail[];
  blockType: IBlockTypeDetail;
}

const DashboardDividerBlock: FC<IDashboardDividerBlock> = ({ blocks, blockType }) => {
  return (
    <>
      <div className='w-full'>
        {blocks?.map((block) => {
          const { modal, hideModal, showModal, isLoadingDelete, onDeleteHandler } = useDashboardChild(blockType, block.id);

          return (
            <Fragment key={block.id}>
              <ZpBlockDelete modal={modal} hideModal={hideModal} isLoadingDelete={isLoadingDelete} onDeleteHandler={onDeleteHandler} blockId={block?.id} />
              <div className='bg-[var(--block-item-bg)] border border-gray-color-300 rounded-lg w-full p-3 space-x-3 space-x-reverse flex items-center justify-between'>
                <div className='flex'>
                  <ZpImage src={getImageUrl(block?.image)} />
                </div>

                <div className='flex max-w-[50px] w-full relative left-1 justify-end'>
                  <RiDeleteBin6Line className='cursor-pointer' size={22} color='var(--error-color-300)' onClick={showModal} />
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

export default DashboardDividerBlock;
