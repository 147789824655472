import React from 'react';
import { usePlanPayQuery } from 'components/Plans/components/PlanList/hooks/react-query/usePlanPayQuery';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { useUserInfoQuery } from 'core/hooks/react-query/useUserInfoQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import useRoute from 'core/hooks/useRoute';

const usePaymentReturnFailed = () => {
  // hooks
  const { isFetching: isLoadingUserInfo } = useUserInfoQuery();
  const { routes } = useRoute();
  const { query } = useQueryUrlParam();
  console.log(query);

  // store
  const { userInfo } = useSelector((store: RootState) => store.userStore);

  // query
  const { isLoading: isLoadingPlanPay, mutate: planPay } = usePlanPayQuery();

  const onPlanPayHandler = () => {
    planPay({ plan_id: query?.plan_id });
  };

  return { isLoadingPlanPay, routes, isLoadingUserInfo, userInfo, onPlanPayHandler };
};

export default usePaymentReturnFailed;
