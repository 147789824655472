import { createSlice } from '@reduxjs/toolkit';
import { IUserInfo } from 'core/types/Models/userInfoType';

const initialState: { userInfo: IUserInfo | null } = {
  userInfo: null,
};

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: initialState,
  reducers: {
    setUserInfo: (state, { payload }) => {
      state.userInfo = payload;
    },
  },
});

export const { setUserInfo } = userInfoSlice.actions;
export default userInfoSlice.reducer;
