import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';
import GatewayLinkCreateProfessionalStepTwo from 'components/Gateway/GatewayLinks/Create/Professional/StepTwo/GatewayLinkCreateProfessionalStepTwo';
import PanelCreateGatewayLinkLayout from 'components/_Core/Layouts/Panel/PanelCreateGatewayLinkLayout/PanelCreateGatewayLinkLayout';

const GatewayLinkCreateProfessionalStepTwoPage = () => {
  return (
    <PanelCreateGatewayLinkLayout>
      <HeadMeta tabTitle='ایجاد لینک پرداخت جدید - مرحله 2' />
      <GatewayLinkCreateProfessionalStepTwo />
    </PanelCreateGatewayLinkLayout>
  );
};

export default GatewayLinkCreateProfessionalStepTwoPage;
