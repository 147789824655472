import { FC } from 'react';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import zarinpalIcon from 'assets/images/zarinPalIcon.svg';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import ZpModal from 'components/_Core/UI/Modal/ZpModal';
import useRoute from 'core/hooks/useRoute';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { disableBackToUrl } from 'core/utils/utils';

interface IGatewayCreateLinkSuccess {
  open: boolean;
}

const GatewayCreateLinkSuccess: FC<IGatewayCreateLinkSuccess> = ({ open }) => {
  // hooks
  const { routes } = useRoute();
  const navigate = useNavigate();

  // store
  const { hasActiveGateway, linkDetail } = useSelector((store: RootState) => store.linkStore);

  return (
    <ZpModal
      width={420}
      closable={false}
      open={open}
      closeIcon={<></>}
      className='border-t-[3px] box-content border-l-[3px] border-r-[3px] border-primary-color-main border-b-[6px] rounded-xl'
    >
      <div className='text-center space-y-5'>
        <ZpImage src={zarinpalIcon} width={35} />

        <h2 className='text-lg'>ساخت لینک پرداخت شما موفقیت آمیز بود!</h2>
        {!hasActiveGateway && (
          <p className='text-color-paragraph'>در حال حاضر لینک پرداخت شما برای کاربران شما قابل دسترسی نیست، بعد از تایید و فعال شدن درگاه پرداخت شما لینک شما قابل دسترس است.</p>
        )}

        <div className='flex items-center space-x-4 space-x-reverse justify-between'>
          <ZpButton className='border-gray-200 text-color-main w-full' ghost onClick={() => navigate(routes.PANEL_HOME.link())}>
            صفحه اصلی
          </ZpButton>
          <ZpButton type='primary' className='w-full' onClick={() => navigate(routes.GATEWAY_DETAIL.link(linkDetail?.data?.gateways?.[0]?.id), { state: disableBackToUrl })}>
            مدیریت لینک‌ پرداخت
          </ZpButton>
        </div>
      </div>
    </ZpModal>
  );
};

export default GatewayCreateLinkSuccess;
