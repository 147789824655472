import { combineReducers } from 'redux';

import mainInfoStore from 'core/store/slice/mainInfo/mainInfoSlice';
import settingStore from 'core/store/slice/setting/settingSlice';
import linkStore from 'core/store/slice/link/linkSlice';
import userStore from 'core/store/slice/userInfo/userInfoSlice';
import linkBlockStore from 'core/store/slice/linkBlock/linkBlockSlice';
import linkDragAndDropStore from 'core/store/slice/linkDragAndDrop/linkDragAndDropSlice';
import gatewayStore from 'core/store/slice/gateway/gatewaySlice';
import userPlanStore from 'core/store/slice/userPlan/userPlanSlice';
import templateStore from 'core/store/slice/template/templateSlice';

const rootReducer = combineReducers({ mainInfoStore, settingStore, linkStore, userStore, linkBlockStore, linkDragAndDropStore, gatewayStore, userPlanStore, templateStore });

export default rootReducer;
