import CreateLinkStepOne from 'components/CreateLink/StepOne/CreateLinkStepOne';
import PanelCreateLinkLayout from 'components/_Core/Layouts/Panel/PanelCreateLinkLayout/PanelCreateLinkLayout';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';

const CreateLinkStepOnePage = () => {
  return (
    <PanelCreateLinkLayout>
      <HeadMeta tabTitle='ایجاد لینک - مرحله 1' />
      <CreateLinkStepOne />
    </PanelCreateLinkLayout>
  );
};

export default CreateLinkStepOnePage;
