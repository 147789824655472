import { ReactComponent as ActivePlanSVG } from 'assets/images/plan/active-plan.svg';
import { ReactComponent as PremiumLabelSVG } from 'assets/images/plan/premium-label-active-4.svg';
import PlanSubscriptionButton from 'components/Plans/components/PlanList/components/PlanSubscriptionButton';
import { FC } from 'react';
import { IPlanFeature, planCodeType } from 'core/types/Models/planType';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { getImageUrl, separator } from 'core/utils/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

interface IPlanItem {
  icon: string;
  name: string;
  price: number;
  features: IPlanFeature[];
  code: planCodeType;
  isLoadingPlanPay: boolean;
  onPlanPayHandler: (val: number) => void;
  plan_id: number;
  trialDays: number;
}

const PlanItem: FC<IPlanItem> = ({ name = '', icon, price, features, code, plan_id, onPlanPayHandler, isLoadingPlanPay, trialDays }) => {
  const { activePlan, canBuy, renewal } = useSelector((store: RootState) => store.userPlanStore);
  const isPro = code === 'pro';

  return (
    <div className='px-7 py-6 rounded-2xl shadow-lg text-center bg-block-item-bg max-w-xs w-full mx-auto relative space-y-6'>
      {activePlan?.code === code && <ActivePlanSVG className='absolute right-0 top-0' />}
      {isPro && <PremiumLabelSVG className='absolute -left-5 -top-12' />}

      <div className='space-y-5'>
        <ZpImage src={getImageUrl(icon)} />
        <h2 className='font-bold text-2xl'>{name}</h2>
        <h2 className='font-bold text-3xl text-secondary-color-main'>{price ? `${separator(price || 0)} تومان` : 'رایگان'}</h2>
      </div>

      <div className='p-4 space-y-1'>
        {features.map((feature) => (
          <p key={feature.id} className='text-color-paragraph border-b border-natural-color-200 dark:border-border-color py-3 last:border-0'>
            {feature.name}
          </p>
        ))}
      </div>

      {isPro && canBuy && <PlanSubscriptionButton canBuyPlan={renewal} isLoadingPlanPay={isLoadingPlanPay} onPlanPayHandler={onPlanPayHandler} plan_id={plan_id} />}
    </div>
  );
};

export default PlanItem;
