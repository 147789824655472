import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryOnSuccessHandler from 'core/helpers/queryOnSuccessHandler/useQueryOnSuccessHandler';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import useRoute from 'core/hooks/useRoute';
import api from 'core/services/fetch-api/useApi';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';

export const useCreateGatewayLinkQuery = () => {
  const { onSuccessHandler } = useQueryOnSuccessHandler();
  const { gatewayId } = useRoute();
  const { query } = useQueryUrlParam();

  return useMutationHook(
    [endPointUrls.GATEWAY_LINK(query?.gatewayId || gatewayId)],
    (data: any) => api.post(endPointUrls.GATEWAY_LINK(data?.gatewayId || query?.gatewayId || gatewayId), data?.data),
    {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          onSuccessHandler(res);
        }
      },
    }
  ) as UseMutationResult;
};
