import { RxReload } from 'react-icons/rx';
import { FC } from 'react';

interface IReload {
  onReloadHandler: () => void;
  isLoading: boolean;
}

const Reload: FC<IReload> = ({ onReloadHandler, isLoading }) => {
  return (
    <div className='space-x-2 space-x-reverse flex items-center cursor-pointer text-color-placeholder' onClick={onReloadHandler}>
      <span>بروز رسانی</span>
      <span className='flex'>
        <RxReload size={14} className={`${isLoading ? 'animate-spin' : ''}`} />
      </span>
    </div>
  );
};

export default Reload;
