import { ScaleLoader } from 'react-spinners';
import { FC, memo } from 'react';
import ReactDOM from 'react-dom';

const ZpGlobalLoading: FC = () => {
  return ReactDOM.createPortal(
    <div className='w-full min-h-screen fixed flex justify-center items-center bg-[var(--white-natural-color)] z-[9999]'>
      <ScaleLoader height={26} width={3} color='var(--primary-color-main)' />
    </div>,
    document.getElementById('global-loading') as HTMLElement
  );
};

export default memo(ZpGlobalLoading);
