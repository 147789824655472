import { FC } from 'react';

interface IPreviewTemplateBlockTitle {
  title: string;
}

const PreviewTemplateBlockTitle: FC<IPreviewTemplateBlockTitle> = ({ title = '' }) => {
  return <p className='text-xl font-bold text-center text-block_title_color'>{title}</p>;
};

export default PreviewTemplateBlockTitle;
