import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

const useRoute = () => {
  // hooks
  const { username, gatewayId, gatewayLinkId } = useParams();

  // store
  const { linkDetail } = useSelector((store: RootState) => store.linkStore);
  const { hasGateway, linkGatewayDetail } = useSelector((store: RootState) => store.linkStore);

  const linkId = linkDetail?.data?.id;

  const routes = Object.freeze({
    // landing
    LANDING_RULES: `${process.env.REACT_APP_API_BASE_DOMAIN}/rules`,
    LANDING_PRIVACY: `${process.env.REACT_APP_API_BASE_DOMAIN}/privacy`,

    // development
    DEV: '/dev',

    // blog panel
    BLOG_PANEL: `${process.env.REACT_APP_API_BLOG_BASE_URL}/panel/blogs/${username}/posts`,

    // zarinPal
    ZARINPAL: 'https://next.zarinpal.com/auth/login',

    // root
    ROOT: '/',
    NOTFOUND: '/404',

    // auth
    LOGIN: { route: '/login', link: (query?: string) => `/login${query ? `?${query}` : ''}` },
    VERIFY_CODE: { route: '/verify', link: (query?: string) => `/verify${query ? `?${query}` : ''}` },
    REGISTER: { route: '/register', link: (query?: string) => `/register${query ? `?${query}` : ''}` },

    // payment success and failed page
    PAYMENT_RETURN_SUCCESS: { route: '/panel/payment-success', link: '/panel/payment-success' },
    PAYMENT_RETURN_FAILED: { route: '/panel/payment-failed', link: '/panel/payment-failed' },

    // profile
    PROFILE: { route: '/panel/profile', link: '/panel/profile' },

    // support
    SUPPORT: { route: '/panel/support', link: '/panel/support' },

    // links
    LINKS: { route: '/panel/links', link: '/panel/links' },
    LINK_UPDATE: { route: '/panel/link/update/:username', link: (username: string) => `/panel/link/update/${username}` },
    CREATE_LINK_STEP_1: { route: '/panel/links/create-link/step/1', link: (query?: string) => `/panel/links/create-link/step/1${query ? `?${query}` : ''}` },
    CREATE_LINK_STEP_2: { route: '/panel/links/create-link/step/2', link: (query?: string) => `/panel/links/create-link/step/2${query ? `?${query}` : ''}` },
    CREATE_LINK_STEP_3: { route: '/panel/links/create-link/step/3', link: (query?: string) => `/panel/links/create-link/step/3${query ? `?${query}` : ''}` },

    // panel dashboard
    PANEL_HOME: {
      route: '/panel/:username',
      link: (usernameParam?: string, query?: string) => `/panel/${usernameParam || username}${query ? `?${query}` : ''}`,
    },

    // panel setting
    PANEL_SETTING: { route: '/panel/:username/setting', link: (query?: string) => `/panel/${username}/setting${query ? `?${query}` : ''}` },

    // panel blocks
    PANEL_CREATE_BLOCK_MESSENGER: {
      route: '/panel/:username/blocks/create-block/messenger',
      link: (query?: string) => `/panel/${username}/blocks/create-block/messenger${query ? `?${query}` : ''}`,
    },
    PANEL_CREATE_BLOCK_SOCIALMEDIA: {
      route: '/panel/:username/blocks/create-block/socialMedia',
      link: (query?: string) => `/panel/${username}/blocks/create-block/socialMedia${query ? `?${query}` : ''}`,
    },
    PANEL_CREATE_EXTERNAL_LINK: {
      route: '/panel/:username/blocks/create-block/externalLink',
      link: (query?: string) => `/panel/${username}/blocks/create-block/externalLink${query ? `?${query}` : ''}`,
    },
    PANEL_CREATE_BLOCK_NAVIGATION: {
      route: '/panel/:username/blocks/create-block/navigation',
      link: (query?: string) => `/panel/${username}/blocks/create-block/navigation${query ? `?${query}` : ''}`,
    },
    PANEL_CREATE_BLOCK_FAQ: {
      route: '/panel/:username/blocks/create-block/faq',
      link: (query?: string) => `/panel/${username}/blocks/create-block/faq${query ? `?${query}` : ''}`,
    },
    PANEL_CREATE_BLOCK_CONNECTION: {
      route: '/panel/:username/blocks/create-block/connection',
      link: (query?: string) => `/panel/${username}/blocks/create-block/connection${query ? `?${query}` : ''}`,
    },
    PANEL_CREATE_BLOCK_DIVIDER: {
      route: '/panel/:username/blocks/create-block/divider',
      link: (query?: string) => `/panel/${username}/blocks/create-block/divider${query ? `?${query}` : ''}`,
    },
    PANEL_CREATE_BLOCK_GATEWAY: {
      route: '/panel/:username/blocks/create-block/gateway',
      link: (usernameParam?: string, linkIdParam?: string | number, query?: string) => `/panel/${usernameParam || username}/blocks/create-block/gateway${query ? `?${query}` : ''}`,
    },

    // panel blocks draggable
    PANEL_BLOCK_DRAGGABLE: {
      route: '/panel/:username/block-draggable',
      link: (query?: string) => `/panel/${username}/block-draggable${query ? `?${query}` : ''}`,
    },

    // panel link preview
    PANEL_PREVIEW: {
      route: '/panel/:username/preview',
      link: (usernameParam?: string, linkIdParam?: string | number, query?: string) => `/panel/${usernameParam || username}/preview${query ? `?${query}` : ''}`,
    },

    // panel link share
    PANEL_SHARE: {
      route: '/panel/:username/share',
      link: (usernameParam?: string, query?: string) => `/panel/${usernameParam || username}/share${query ? `?${query}` : ''}`,
    },

    // panel template
    PANEL_TEMPLATE: {
      route: '/panel/:username/template',
      link: (usernameParam?: string, query?: string) => `/panel/${usernameParam || username}/template${query ? `?${query}` : ''}`,
    },

    // gateway
    PANEL_LINK_GATEWAY: {
      route: '/panel/:username/gateway',
      link: (query?: string) => `/panel/${username}/gateway${query ? `?${query}` : ''}`,
    },
    GATEWAY_CREATE: {
      route: '/panel/:username/gateway/create',
      link: (query?: string) => `/panel/${username}/gateway/create${query ? `?${query}` : ''}`,
    },
    GATEWAY_CREATE_EMPTY: {
      route: '/panel/:username/gateway/empty',
      link: `/panel/${username}/gateway/empty`,
    },
    GATEWAY_DETAIL: {
      route: '/panel/:username/gateway/:gatewayId',
      link: (gatewayIdParam?: string | number, query?: string) => `/panel/${username}/gateway/${gatewayIdParam || gatewayId}${query ? `?${query}` : ''}`,
    },
    GATEWAY_UPDATE: {
      route: '/panel/:username/gateway/update/:gatewayId',
      link: (gatewayIdParam?: string | number, query?: string) => `/panel/${username}/gateway/update/${gatewayIdParam || gatewayId}${query ? `?${query}` : ''}`,
    },
    GATEWAY_LINKS: {
      route: '/panel/:username/gateway/:gatewayId/links',
      link: (gatewayIdParam?: string | number, query?: string) => `/panel/${username}/gateway/${gatewayIdParam || gatewayId}/links${query ? `?${query}` : ''}`,
    },
    GATEWAY_CREATE_SIMPLE: {
      route: '/panel/:username/gateway/create-simple',
      link: (query?: string) => `/panel/${username}/gateway/create-simple${query ? `?${query}` : ''}`,
    },
    GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_1: {
      route: '/panel/:username/gateway/create-professional/step/1',
      link: (query?: string) => `/panel/${username}/gateway/create-professional/step/1${query ? `?${query}` : ''}`,
    },
    GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_2: {
      route: '/panel/:username/gateway/create-professional/step/2',
      link: (query?: string) => `/panel/${username}/gateway/create-professional/step/2${query ? `?${query}` : ''}`,
    },
    GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_3: {
      route: '/panel/:username/gateway/create-professional/step/3',
      link: (query?: string) => `/panel/${username}/gateway/create-professional/step/3${query ? `?${query}` : ''}`,
    },
    GATEWAY_LINK_PROFESSIONAL_UPDATE: {
      route: '/panel/:username/gateway/:gatewayId/links/:gatewayLinkId/update-professional',
      link: (gatewayLinkIdParam?: string | number, gatewayIdParam?: string | number, query?: string) =>
        `/panel/${username}/gateway/${gatewayIdParam || gatewayId}/links/${gatewayLinkIdParam || gatewayLinkId}/update-professional${query ? `?${query}` : ''}`,
    },
    GATEWAY_LINK_SIMPLE_UPDATE: {
      route: '/panel/:username/gateway/:gatewayId/links/:gatewayLinkId/update-simple',
      link: (gatewayLinkIdParam?: string | number, gatewayIdParam?: string | number, query?: string) =>
        `/panel/${username}/gateway/${gatewayIdParam || gatewayId}/links/${gatewayLinkIdParam || gatewayLinkId}/update-simple${query ? `?${query}` : ''}`,
    },
    GATEWAY_LINK_INVOICES: {
      route: '/panel/:username/gateway/:gatewayId/links/:gatewayLinkId/invoices',
      link: (gatewayLinkIdParam?: string | number, query?: string) =>
        `/panel/${username}/gateway/${gatewayId}/links/${gatewayLinkIdParam || gatewayLinkId}/invoices${query ? `?${query}` : ''}`,
    },
    GATEWAY_INVOICES: {
      route: '/panel/:username/gateway/:gatewayId/invoices',
      link: (query?: string) => `/panel/${username}/gateway/${gatewayId}/invoices${query ? `?${query}` : ''}`,
    },

    // plan
    PLANS_LIST: {
      route: '/panel/plans',
      link: (query?: string) => `/panel/plans${query ? `?${query}` : ''}`,
    },
    ACTIVE_PLAN: {
      route: '/panel/active-plan',
      link: (query?: string) => `/panel/active-plan${query ? `?${query}` : ''}`,
    },

    // transaction
    TRANSACTIONS_LIST: {
      route: '/panel/transactions',
      link: (query?: string) => `/panel/transactions${query ? `?${query}` : ''}`,
    },
  });

  const gatewayCreateProfessionalDynamicRoute = hasGateway
    ? routes.GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_1.link(`gatewayId=${linkGatewayDetail?.id}`)
    : routes.GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_1.link();

  const gatewayCreateSimpleDynamicRoute = hasGateway ? routes.GATEWAY_CREATE_SIMPLE.link(`gatewayId=${linkGatewayDetail?.id}`) : routes.GATEWAY_CREATE_SIMPLE.link();

  return { routes, username, linkId, gatewayId, gatewayLinkId, gatewayCreateProfessionalDynamicRoute, gatewayCreateSimpleDynamicRoute };
};

export default useRoute;
