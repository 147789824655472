import { FC, memo } from 'react';
import { twMerge } from 'tailwind-merge';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

export interface IInput extends NumericFormatProps {
  className?: string;
  value?: any;
  setValue?: (val: any) => void;
}

const ZpInputNumber: FC<IInput> = ({ className = '', value, setValue, ...props }) => {
  return (
    <NumericFormat
      value={value}
      onChange={setValue ? (e) => setValue(e.currentTarget.value) : () => {}}
      className={twMerge(
        `disabled:bg-[var(--input-disable-bg)] bg-white-natural-color text-color-main py-3 px-4 w-full border border-border-color rounded-md hover:border-primary-color-300 ${className}`
      )}
      {...props}
    />
  );
};

ZpInputNumber.displayName = 'ZpInputNumber';

export default memo(ZpInputNumber);
