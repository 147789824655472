import { StepProps } from 'antd';
import ZpSteps from 'components/_Core/UI/Steps/ZpSteps';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import PanelCreateGatewayLinksStepsSkeleton from 'components/_Core/Layouts/Panel/PanelCreateGatewayLinkLayout/PanelCreateGatewayLinksStepsSkeleton';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';

const PanelCreateGatewayLinksSteps = () => {
  // query
  const { isFetching } = useLinkDetailQuery();

  const steps: StepProps[] = [
    { title: <span className='text-sm'>اطلاعات پایه</span> },
    { title: <span className='text-sm'>تصویر و آیکون</span> },
    { title: <span className='text-sm'>محدودیت‌های لینک</span> },
  ];
  const { pathnameLastPart } = useQueryUrlParam();

  if (isFetching) return <PanelCreateGatewayLinksStepsSkeleton />;

  return (
    <div className='py-5 mx-2 flex justify-center max-w-full'>
      <ZpSteps items={steps} labelPlacement='vertical' className='justify-center' responsive={false} current={pathnameLastPart ? +pathnameLastPart - 1 : 0} />
    </div>
  );
};

export default PanelCreateGatewayLinksSteps;
