import { Form } from 'antd';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import { IBlockDetail } from 'core/types/Models/linkType';
import useUpdateNavigation from 'components/BlocksComponents/NavigationBlock/hooks/useUpdateNavigation';
import ZpBlockDelete from 'components/_Core/UI/BlockDelete/ZpBlockDelete';
import ZpTextAreaLabel from 'components/_Core/UI/TextArea/ZpTextAreaLabel';
import ZpMap from 'components/_Core/UI/Map/ZpMap';
import { Marker } from 'react-leaflet';
import { pinIcon } from 'core/constants/pinIcon';
import useValidationFormMessages from 'core/hooks/useValidationFormMessages';
import { FC } from 'react';

interface INavigationBlockUpdate {
  block: IBlockDetail | undefined;
}

const NavigationBlockUpdate: FC<INavigationBlockUpdate> = ({ block }) => {
  const [formUpdate] = Form.useForm();
  const { required } = useValidationFormMessages();
  const {
    onFinishUpdateHandler,
    hideModal,
    modal,
    showModal,
    isLoadingUpdateNavigation,
    isLoadingDeleteNavigation,
    onDeleteHandler,
    latAndLng,
    setLatAndLng,
    zoomLevel,
    setZoomLevel,
  } = useUpdateNavigation(formUpdate, block);

  console.log(latAndLng, block?.lat, block?.lng);

  return (
    <>
      <ZpBlockDelete modal={modal} hideModal={hideModal} isLoadingDelete={isLoadingDeleteNavigation} onDeleteHandler={onDeleteHandler} blockId={block?.id} />

      <ZpForm form={formUpdate} onFinish={onFinishUpdateHandler}>
        <ZpWrapper singleColumn>
          <ZpFormItem rules={[required('عنوان آدرس')]} name='title'>
            <ZpInputLabel required label='عنوان آدرس' />
          </ZpFormItem>

          <ZpMap
            height='h-[200px]'
            firstRender={!(block?.lng && block?.lat)}
            setZoomLevel={setZoomLevel}
            setLatAndLng={setLatAndLng}
            initialCenter={latAndLng}
            zoomLevel={zoomLevel}
          >
            <Marker icon={pinIcon} position={[latAndLng?.[0], latAndLng?.[1]]} />
          </ZpMap>

          <ZpFormItem name='description'>
            <ZpTextAreaLabel rows={2} label='جزئیات آدرس' />
          </ZpFormItem>

          <ZpFormItem button>
            <div className='flex items-center space-x-reverse space-x-5 justify-between'>
              <ZpButton buttonSize='xs' className='w-full' htmlType='button' danger ghost type='primary' isLoading={isLoadingDeleteNavigation} onClick={showModal}>
                حذف
              </ZpButton>
              <ZpButton buttonSize='xs' className='w-full' htmlType='submit' type='primary' isLoading={isLoadingUpdateNavigation}>
                ثبت تغییرات
              </ZpButton>
            </div>
          </ZpFormItem>
        </ZpWrapper>
      </ZpForm>
    </>
  );
};

export default NavigationBlockUpdate;
