import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryOnSuccessHandler from 'core/helpers/queryOnSuccessHandler/useQueryOnSuccessHandler';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import useRoute from 'core/hooks/useRoute';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';

export const useDeleteGatewayLinkQuery = (refetchProfile?: boolean) => {
  const { onSuccessHandler } = useQueryOnSuccessHandler();
  const { gatewayId, username } = useRoute();
  const { query } = useQueryUrlParam();
  const queryClient = useQueryClient();

  return useMutationHook(
    [endPointUrls.GATEWAY_LINK(query?.gatewayId || gatewayId + '', 'delete')],
    (data: any) => api.delete(endPointUrls.GATEWAY_LINK(data?.gatewayId || query?.gatewayId || gatewayId + '', data?.gatewayLinkId)),
    {
      onSuccess: (res: any) => {
        onSuccessHandler(res);
        refetchProfile && queryClient.invalidateQueries([endPointUrls.LINK_GET_BY_USERNAME(username + '')]);
      },
    }
  ) as UseMutationResult;
};
