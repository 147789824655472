import React, { FC } from 'react';
import { IGatewayLinkBlockMaster } from 'core/types/Components/Gateway/GatewayLinkBlock';
import PreviewTemplateSimpleGatewayLink from 'components/_Core/UI/PreviewTemplate/GatewayLink/PreviewTemplateSimpleGatewayLink';
import PreviewTemplateProfessionalGatewayLink from 'components/_Core/UI/PreviewTemplate/GatewayLink/PreviewTemplateProfessionalGatewayLink';
import PreviewTemplateBlockTitle from 'components/_Core/UI/PreviewTemplate/BlockTitle/PreviewTemplateBlockTitle';

const LinkDetailGatewayBlock: FC<IGatewayLinkBlockMaster> = ({ blocks, blockType }) => {
  return (
    <div className='w-full space-y-5'>
      <PreviewTemplateBlockTitle title={blockType.title} />

      {blocks.map((block) =>
        block?.icon ? <PreviewTemplateProfessionalGatewayLink key={block.id} block={block} /> : <PreviewTemplateSimpleGatewayLink key={block.id} block={block} />
      )}
    </div>
  );
};

export default LinkDetailGatewayBlock;
