import { usePlanListQuery } from 'components/Plans/components/PlanList/hooks/react-query/usePlanListQuery';
import useModal from 'core/hooks/useModal';
import { usePlanPayQuery } from 'components/Plans/components/PlanList/hooks/react-query/usePlanPayQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

const usePlanList = () => {
  // hooks
  const { modal: modalActivateTrial, hideModal: hideModalActivateTrial, showModal: showModalActivateTrial } = useModal();

  // query
  const { isFetching: isLoadingPlanList, data: planList } = usePlanListQuery();
  const { isLoading: isLoadingPlanPay, mutate: planPay } = usePlanPayQuery(showModalActivateTrial);

  // store
  const { trialDays } = useSelector((store: RootState) => store.userPlanStore);

  const onPlanPayHandler = (plan_id: number) => {
    planPay({ plan_id });
  };

  return { planList, isLoadingPlanList, modalActivateTrial, hideModalActivateTrial, onPlanPayHandler, isLoadingPlanPay, trialDays };
};

export default usePlanList;
