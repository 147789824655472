import PanelCreateLinkLayoutFooter from 'components/_Core/Layouts/Panel/PanelCreateLinkLayout/PanelCreateLinkLayoutFooter';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpInformation from 'components/_Core/UI/Information/ZpInformation';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import ZpUploadImage from 'components/_Core/UI/UploadImage/ZpUploadImage';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import useCreateLinkStepTwo from './hooks/useCreateLinkStepTwo';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import { useNavigate } from 'react-router-dom';
import useRoute from 'core/hooks/useRoute';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import CreateLinkStepTwoSkeleton from './components/CreateLinkStepTwoSkeleton';
import MainContentLayout from 'components/_Core/Layouts/Main/MainContentLayout/MainContentLayout';

const CreateLinkStepTwo = () => {
  const {
    updateLinkStepTwoForm,
    imageRef,
    linkDetail,
    onUpdateImageHandler,
    onFinishUpdateLinkStepTwoHandler,
    isLoadingUploadProfileImage,
    isLoadingLinkDetail,
    isLoadingUpdateLinkStepTwo,
    imageUploadData,
  } = useCreateLinkStepTwo();
  const { routes } = useRoute();
  const { query } = useQueryUrlParam();
  const navigate = useNavigate();

  if (isLoadingLinkDetail)
    return (
      <MainContentLayout>
        <CreateLinkStepTwoSkeleton />
      </MainContentLayout>
    );

  return (
    <>
      <MainContentLayout>
        <div className='flex flex-col justify-between h-full'>
          <ZpForm form={updateLinkStepTwoForm} onFinish={onFinishUpdateLinkStepTwoHandler} className='flex-1'>
            <ZpWrapper singleColumn>
              <div>
                <ZpUploadImage
                  isLoading={isLoadingUploadProfileImage}
                  linkDetail={imageUploadData?.data || linkDetail}
                  imageRef={imageRef}
                  onUpdateImageHandler={onUpdateImageHandler}
                  title={`آپلود تصویر ${process.env.REACT_APP_BRAND_NAME_FA}`}
                />
                {!linkDetail?.data?.image_profile && <ZpInformation>تصویر پروفایل {process.env.REACT_APP_BRAND_NAME_FA} را آپلود کنید.</ZpInformation>}
              </div>

              <div>
                <ZpFormItem noMargin name='description'>
                  <ZpInputLabel label='توضیحات عنوان لینک' />
                </ZpFormItem>
                <ZpInformation>برای عنوان خود توضیحاتی بنویسید. برای مثال: پروداکت دیزاینر یا فروش محصولات دیجیتال</ZpInformation>
              </div>
            </ZpWrapper>
          </ZpForm>
        </div>
      </MainContentLayout>

      <PanelCreateLinkLayoutFooter
        backStep={() => navigate(routes.CREATE_LINK_STEP_1.link(`id=${query?.id}&username=${query?.username}`))}
        isLoading={isLoadingUpdateLinkStepTwo}
        createLinkForm={updateLinkStepTwoForm}
      />
    </>
  );
};

export default CreateLinkStepTwo;
