import { IBlock, IBlockDetail } from 'core/types/Models/linkType';
import { FC } from 'react';
import { ReactComponent as DragLight } from 'assets/images/icon/drag-loght.svg';
import { ReactComponent as DragDark } from 'assets/images/icon/drag-dark.svg';
import { IBlockTypeDetail } from 'core/types/Models/blockType';
import BlockDraggableChildItem from './BlockDraggableChildItem';
import { DndContext, closestCenter, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import useBlockDraggableChild from '../hooks/useBlockDraggableChild';
import { CSS } from '@dnd-kit/utilities';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

interface IBlockDraggableChild {
  childBlocks: IBlockDetail[];
  masterBlocks: IBlock;
  blockType: IBlockTypeDetail;
  links: IBlock[];
}

const BlockDraggableChild: FC<IBlockDraggableChild> = ({ childBlocks, links, masterBlocks, blockType }) => {
  const { childBlocksState, handleDrag } = useBlockDraggableChild(childBlocks, blockType, links, masterBlocks.id);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: masterBlocks.id });
  const { theme } = useSelector((store: RootState) => store.settingStore);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div style={style} className='w-full space-y-3'>
      <div className='flex items-center justify-center space-x-reverse space-x-3'>
        <div className='relative top-0.5' ref={setNodeRef} {...attributes} {...listeners}>
          {theme === 'light' ? <DragDark className='cursor-move' /> : <DragLight className='cursor-move' />}
        </div>
        <p className='text-lg font-bold text-center text-gray-color-700 disable-text-selection'>{blockType.title}</p>
      </div>

      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDrag}>
        <SortableContext items={childBlocks} strategy={verticalListSortingStrategy}>
          {childBlocksState?.map((block) => {
            return <BlockDraggableChildItem key={block.id} blockType={blockType} childBlock={block} />;
          })}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default BlockDraggableChild;
