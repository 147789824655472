import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { IBlockDetail } from 'core/types/Models/linkType';
import { FC } from 'react';
import externalLink from 'assets/images/externalLink.svg';
import { ReactComponent as DragLight } from 'assets/images/icon/drag-loght.svg';
import { ReactComponent as DragDark } from 'assets/images/icon/drag-dark.svg';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IBlockTypeDetail } from 'core/types/Models/blockType';
import faq from 'assets/images/icon/faq.svg';
import navigation from 'assets/images/icon/navigation.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { getImageUrl } from 'core/utils/utils';
import zarinpalIcon from 'assets/images/zarinPalIcon.svg';

interface IBlockDraggableChildItem {
  childBlock: IBlockDetail;
  blockType: IBlockTypeDetail;
}

const BlockDraggableChildItem: FC<IBlockDraggableChildItem> = ({ childBlock, blockType }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: childBlock.id,
    data: childBlock,
  });
  const staticIcon = blockType.name === 'externalLink' ? externalLink : blockType.name === 'faq' ? faq : blockType.name === 'navigation' ? navigation : zarinpalIcon;
  const blockIcon = (childBlock?.type?.icon_url || childBlock?.icon?.url ? getImageUrl(childBlock?.type?.icon_url || childBlock?.icon?.url) : staticIcon) || undefined;
  const { theme } = useSelector((store: RootState) => store.settingStore);

  console.log(blockType);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div style={style} className='bg-[var(--block-item-bg)] border-2 border-dashed border-gray-color-300 rounded-lg w-full p-2 space-x-3 space-x-reverse'>
      <div className='flex items-center flex-1 space-x-4 space-x-reverse'>
        <div className='relative top-1' ref={setNodeRef} {...attributes} {...listeners}>
          {theme === 'light' ? <DragDark className='cursor-move' /> : <DragLight className='cursor-move' />}
        </div>

        <div className='max-w-[40px] w-full flex'>
          <ZpImage className='rounded-full' width={40} height={40} src={blockIcon} />
        </div>

        <span className='break-all max-w-[25ch]'>{childBlock.title || childBlock.question}</span>
      </div>
    </div>
  );
};

export default BlockDraggableChildItem;
