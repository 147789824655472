import { IBlock } from 'core/types/Models/linkType';
import { useState } from 'react';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { arrayMove } from '@dnd-kit/sortable';
import { dragAndDropHandler } from 'core/utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';

const useBlockDraggableMaster = (masterBlocks: IBlock[]) => {
  // state
  const [masterBlocksState, setMasterBlocksState] = useState(masterBlocks);

  // store
  const { linkDragAndDropPayload, linkDragAndDropMasterDetail } = useSelector((store: RootState) => store.linkDragAndDropStore);

  // hooks
  const dispatch = useDispatch();

  const handleDrag = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      setMasterBlocksState((prevState) => {
        const activeIndex = prevState.map((item) => item.id).indexOf(+active.id);
        const overIndex = prevState.map((item) => item.id).indexOf(over!.id as number);

        const blocksModify = arrayMove(prevState, activeIndex, overIndex);

        dragAndDropHandler(linkDragAndDropMasterDetail, linkDragAndDropPayload, dispatch, blocksModify);

        return blocksModify;
      });
    }
  };

  return { handleDrag, masterBlocksState };
};

export default useBlockDraggableMaster;
