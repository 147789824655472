import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import { useUserInfoQuery } from 'core/hooks/react-query/useUserInfoQuery';
import { FC, ReactNode } from 'react';
import { useLinksListQuery } from 'components/DashboardLinkList/components/Links/hooks/react-query/useLinksListQuery';

interface IPrivateLayout {
  children: ReactNode;
}

const PrivateLayout: FC<IPrivateLayout> = ({ children }) => {
  useUserInfoQuery();
  useLinkDetailQuery();
  useLinksListQuery();

  return <>{children}</>;
};

export default PrivateLayout;
