import { FC } from 'react';
import ZpSkeleton from 'components/_Core/UI/Skeleton/ZpSkeleton';

const PlanTransactionItemSkeleton: FC = () => {
  return (
    <div className='space-y-5'>
      <div className='flex items-center justify-between'>
        <ZpSkeleton height={25} width={200} />
      </div>

      {[1, 2].map((item) => (
        <SkeletonItem key={item} />
      ))}
    </div>
  );
};

const SkeletonItem = () => {
  return (
    <div className='bg-white-color border border-border-color rounded-lg px-4 py-3 flex items-start space-x-5 space-x-reverse cursor-pointer'>
      <div className='flex-1 pt-1 flex items-start justify-between w-full'>
        <div className='space-y-4 w-full'>
          <div>
            <ZpSkeleton height={45} />
          </div>

          <div>
            <ZpSkeleton height={45} />
          </div>

          <div>
            <ZpSkeleton height={45} />
          </div>

          <div>
            <ZpSkeleton height={45} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanTransactionItemSkeleton;
