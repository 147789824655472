import useModal from 'core/hooks/useModal';
import { Form } from 'antd';
import { useCreateFaqQuery } from './react-query/useCreateFaqQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import useRoute from 'core/hooks/useRoute';

const useFaqBlock = () => {
  // hooks
  const { modal: childModal, hideModal: hideChildModal, showModal: showChildModal } = useModal();
  const [createForm] = Form.useForm();
  const navigate = useNavigate();
  const { routes } = useRoute();

  // store
  const { linkDetail } = useSelector((store: RootState) => store.linkStore);
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  // query
  const { isFetching: isLoadingLinkDetail } = useLinkDetailQuery();
  const { mutate: createFaq, isLoading: isLoadingCreateFaq } = useCreateFaqQuery();

  const FaqList = linkDetail?.data?.blocks?.filter((block) => block.type.toLowerCase().includes('faq'));
  const hasBlock = !!FaqList?.[0]?.data?.length;

  const hideChildModalHandler = () => {
    createForm.resetFields();
    hideChildModal();
  };

  const onFinishCreateLinkHandler = (values: { username: string; title: string }) => {
    createFaq(
      { ...values, order: hasBlock ? FaqList?.[0]?.data?.[FaqList?.[0]?.data?.length - 1]?.order + 1 : 1 },
      {
        onSuccess: () => {
          hideChildModalHandler();
          navigate(routes.PANEL_HOME.link());
        },
      }
    );
  };

  useEffect(() => {
    createForm.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors.length]);

  return {
    onFinishCreateLinkHandler,
    createForm,
    childModal,
    hideChildModalHandler,
    isLoadingCreateFaq,
    showChildModal,
    isLoadingLinkDetail,
    FaqList,
    hasBlock,
  };
};

export default useFaqBlock;
