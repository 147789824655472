import { FC } from 'react';
import { ITemplate } from 'core/types/Models/templateType';
import { ReactComponent as TemplateUserSVG } from 'assets/images/icon/template-user.svg';
import { ReactComponent as VerifySVG } from 'assets/images/icon/verify.svg';
import TemplateItemBlock from 'components/Template/components/TemplateList/components/TemplateItem/TemplateItemBlock';
import TemplatePreviewModal from 'components/Template/components/PreviewModal/TemplatePreviewModal';
import useTemplateItem from 'components/Template/components/TemplateList/hooks/useTemplateItem';
import PlanDrawer from 'components/Plans/components/PlanDrawer/PlanDrawer';

interface ITemplateItem {
  template: ITemplate;
}

const TemplateItem: FC<ITemplateItem> = ({ template }) => {
  const {
    name,
    backgroundStyle,
    block_background_image,
    block_box_shadow,
    block_border_radius,
    has_block_border,
    has_block_blur,
    block_border_color,
    block_background_color,
    hideModalPlan,
    modalPlan,
    modalPreview,
    hideModalPreview,
    onShowTemplatePreviewHandler,
    isSelectedTheme,
    onUpdateThemeHandler,
    isLoadingUpdateTheme,
  } = useTemplateItem(template);

  const SelectedTheme = isSelectedTheme ? (
    <>
      <VerifySVG className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20' />
      <div className='bg-primary-color-100 opacity-70 absolute top-0 left-0 w-full h-full z-10 rounded-2xl border-4 border-primary-color-main' />
    </>
  ) : null;

  return (
    <>
      <TemplatePreviewModal isLoadingUpdateTheme={isLoadingUpdateTheme} onUpdateThemeHandler={onUpdateThemeHandler} modal={modalPreview} hideModal={hideModalPreview} />
      <PlanDrawer open={modalPlan} hideDrawer={hideModalPlan} />

      <div className='space-y-3 flex flex-col justify-between'>
        <div className='flex-1 relative cursor-pointer' onClick={onShowTemplatePreviewHandler}>
          <div className='h-[260px]'>
            <div className='rounded-2xl' style={backgroundStyle} />
            {SelectedTheme}

            <div className='absolute top-5 left-1/2 -translate-x-1/2 space-y-10 text-center'>
              <TemplateUserSVG />

              <div className='space-y-3'>
                {[1, 2, 3].map((item) => (
                  <TemplateItemBlock
                    key={item}
                    block_background_color={block_background_color}
                    block_border_color={block_border_color}
                    has_block_border={has_block_border}
                    block_border_radius={block_border_radius}
                    block_box_shadow={block_box_shadow}
                    block_background_image={block_background_image}
                    has_block_blur={has_block_blur}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <p className='text-center'>{name}</p>
      </div>
    </>
  );
};

export default TemplateItem;
