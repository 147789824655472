import useModal from 'core/hooks/useModal';
import { useDividerListQuery } from './react-query/useDividerListQuery';
import { useEffect } from 'react';
import { Form } from 'antd';
import { useCreateDividerQuery } from './react-query/useCreateDividerQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import { endPointUrls } from 'core/constants/endPointUrls';
import useRoute from 'core/hooks/useRoute';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

const useDividerBlock = () => {
  // hooks
  const { modal: masterModal, hideModal: hideMasterModal, showModal: showMasterModal } = useModal();
  const { modal: childModal, hideModal: hideChildModal, showModal: showChildModal } = useModal();
  const [createForm] = Form.useForm();
  const { linkId, routes } = useRoute();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // store
  const { linkDetail } = useSelector((store: RootState) => store.linkStore);
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  // query
  const { isFetching: isLoadingDividerList, data: DividersList } = useDividerListQuery();
  const { isFetching: isLoadingLinkDetail } = useLinkDetailQuery();
  const { mutate: createDivider, isLoading: isLoadingCreateDivider } = useCreateDividerQuery();

  const DividerList = linkDetail?.data?.blocks?.filter((block) => block.type.toLowerCase().includes('divider'));
  const hasBlock = !!DividerList?.[0]?.data?.length;

  const hideChildModalHandler = () => {
    createForm.resetFields();
    hideChildModal();
  };

  const onFinishCreateDividerHandler = (dividerId: number | string) => {
    createDivider(
      { dividerId },
      {
        onSuccess: () => {
          hideMasterModal();
          navigate(routes.PANEL_HOME.link());
          return queryClient.invalidateQueries([endPointUrls.GATEWAY(linkId || 0)]);
        },
      }
    );
  };

  useEffect(() => {
    createForm.setFields(apiCatcherFormErrors);
  }, [apiCatcherFormErrors.length]);

  return {
    hideMasterModal,
    onFinishCreateDividerHandler,
    childModal,
    hideChildModalHandler,
    isLoadingCreateDivider,
    showChildModal,
    masterModal,
    isLoadingDividerList,
    showMasterModal,
    isLoadingLinkDetail,
    DividerList,
    hasBlock,
    DividersList,
  };
};

export default useDividerBlock;
