import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { FC } from 'react';

interface IPlanSubscriptionButton {
  isLoadingPlanPay: boolean;
  canBuyPlan: boolean;
  onPlanPayHandler: (val: number) => void;
  plan_id: number;
}

const PlanSubscriptionButton: FC<IPlanSubscriptionButton> = ({ onPlanPayHandler, isLoadingPlanPay, plan_id, canBuyPlan }) => {
  return (
    <ZpButton isLoading={isLoadingPlanPay} onClick={() => onPlanPayHandler(plan_id)} type='primary' className='w-full'>
      {canBuyPlan ? 'تمدید پلن حرفه‌ای' : 'خرید پلن حرفه‌ای'}
    </ZpButton>
  );
};

export default PlanSubscriptionButton;
