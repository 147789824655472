import { lazy, memo, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import ZpGlobalLoading from 'components/_Core/UI/GlobalLoading/ZpGlobalLoading';
import PrivateRoute from 'components/_Core/Route/PrivateRoute';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import PublicRoute from 'components/_Core/Route/PublicRoute';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import useRoute from 'core/hooks/useRoute';
import useCheckEndScroll from 'core/hooks/useCheckEndScroll';
import { disableBackToUrl, scrollToSection } from 'core/utils/utils';
import { setEnableScroll, setOpenBlockListHandler, setOpenMenuHandler } from 'core/store/slice/setting/settingSlice';

// other route
const Page404 = lazy(() => import('components/_Core/Page404/Page404'));

// public route
const LoginPage = lazy(() => import('pages/Login/LoginPage'));
const VerifyCodePage = lazy(() => import('pages/VerifyCode/VerifyCodePage'));
const RegisterPage = lazy(() => import('pages/Register/RegisterPage'));
const DevelopmentPage = lazy(() => import('pages/_Development/DevelopmentPage'));

// private
import DashboardPage from 'pages/Dashboard/DashboardPage';
import DashboardLinkListPage from 'pages/DashboardLinkList/DashboardLinkListPage';
import CreateLinkStepOnePage from 'pages/CreateLink/StepOne/CreateLinkStepOnePage';
import CreateLinkStepTwoPage from 'pages/CreateLink/StepTwo/CreateLinkStepTwoPage';
import CreateLinkStepThreePage from 'pages/CreateLink/StepThree/CreateLinkStepThreePage';
import UpdateLinkPage from 'pages/UpdateLink/UpdateLinkPage';
import MessengerBlockPage from 'pages/BlocksComponents/MessengerBlock/MessengerBlockPage';
import ExternalLinkBlockPage from 'pages/BlocksComponents/ExternalLinkBlock/ExternalLinkBlockPage';
import SocialMediaBlockPage from 'pages/BlocksComponents/SocialMediaBlock/SocialMediaBlockPage';
import NavigationBlockPage from 'pages/BlocksComponents/NavigationBlock/NavigationBlockPage';
import ConnectionBlockPage from 'pages/BlocksComponents/ConnectionBlock/ConnectionBlockPage';
import FaqBlockPage from 'pages/BlocksComponents/FaqBlock/FaqBlockPage';
import DeviderBlockPage from 'pages/BlocksComponents/DeviderBlock/DeviderBlockPage';
import GatewayBlockPage from 'pages/BlocksComponents/GatewayBlock/GatewayBlockPage';
import ProfilePage from 'pages/Profile/ProfilePage';
import BlockDraggablePage from 'pages/BlockDraggable/BlockDraggablePage';
import LinkPreviewPage from 'pages/LinkPreview/LinkPreviewPage';
import LinkSharePage from 'pages/LinkShare/LinkSharePage';
import SupportPage from 'pages/Support/SupportPage';
import GatewayListPage from 'pages/Gateway/GatewayListPage';
import GatewayDetailPage from 'pages/Gateway/GatewayDetailPage';
import GatewayLinkCreateSimplePage from 'pages/GatewayLink/Create/Simple/GatewayLinkCreateSimplePage';
import GatewayLinkCreateProfessionalStepOnePage from 'pages/GatewayLink/Create/Professional/StepOne/GatewayLinkCreateProfessionalStepOnePage';
import GatewayLinkCreateProfessionalStepTwoPage from 'pages/GatewayLink/Create/Professional/StepTwo/GatewayLinkCreateProfessionalStepTwoPage';
import GatewayLinkCreateProfessionalStepThreePage from 'pages/GatewayLink/Create/Professional/StepThree/GatewayLinkCreateProfessionalStepThreePage';
import GatewayLinksListPage from 'pages/GatewayLink/List/GatewayLinksListPage';
import GatewayInvoicesPage from 'pages/Gateway/GatewayInvoicesPage';
import GatewayLinkInvoicesPage from 'pages/Gateway/GatewayLinkInvoicesPage';
import GatewayUpdatePage from 'pages/Gateway/GatewayUpdatePage';
import GatewayCreateEmptyPage from 'pages/GatewayLink/Create/GatewayCreateEmpty/GatewayCreateEmptyPage';
import GatewayLinkProfessionalUpdatePage from 'pages/GatewayLink/Update/GatewayLinkProfessionalUpdatePage';
import GatewayLinkSimpleUpdatePage from 'pages/GatewayLink/Update/GatewayLinkSimpleUpdatePage';
import PlansPage from 'pages/Plans/PlansPage';
import ActivePlanPage from 'pages/ActivePlan/ActivePlanPage';
import PaymentReturnSuccessPage from 'pages/PaymentReturn/PaymentReturnSuccessPage';
import PaymentReturnFailedPage from 'pages/PaymentReturn/PaymentReturnFailedPage';
import PlanTransactionsPage from 'pages/PlanTransactions/PlanTransactionsPage';
import TemplatePage from 'pages/Template/TemplatePage';

const MainRoute = () => {
  //store
  const { isAuthenticated } = useSelector((store: RootState) => store.mainInfoStore);
  const { enableScroll, isOpenBlockList, isOpenMenu } = useSelector((store: RootState) => store.settingStore);

  // hooks
  const navigate = useNavigate();
  const { checkEndScroll } = useCheckEndScroll();
  const { routes } = useRoute();
  const { pathname, pathnameArray, query } = useQueryUrlParam();
  const dispatch = useDispatch();

  // routes
  const privateRoute = [
    {
      path: routes.LINKS.route,
      element: <DashboardLinkListPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.PANEL_HOME.route,
      element: <DashboardPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.CREATE_LINK_STEP_1.route,
      element: <CreateLinkStepOnePage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.CREATE_LINK_STEP_2.route,
      element: <CreateLinkStepTwoPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.CREATE_LINK_STEP_3.route,
      element: <CreateLinkStepThreePage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.LINK_UPDATE.route,
      element: <UpdateLinkPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.PANEL_CREATE_BLOCK_MESSENGER.route,
      element: <MessengerBlockPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.PANEL_CREATE_BLOCK_SOCIALMEDIA.route,
      element: <SocialMediaBlockPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.PANEL_CREATE_EXTERNAL_LINK.route,
      element: <ExternalLinkBlockPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.PANEL_CREATE_BLOCK_NAVIGATION.route,
      element: <NavigationBlockPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.PANEL_CREATE_BLOCK_FAQ.route,
      element: <FaqBlockPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.PANEL_CREATE_BLOCK_DIVIDER.route,
      element: <DeviderBlockPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.PANEL_CREATE_BLOCK_CONNECTION.route,
      element: <ConnectionBlockPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.PROFILE.route,
      element: <ProfilePage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.PANEL_BLOCK_DRAGGABLE.route,
      element: <BlockDraggablePage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.PANEL_PREVIEW.route,
      element: <LinkPreviewPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.PANEL_SHARE.route,
      element: <LinkSharePage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.SUPPORT.route,
      element: <SupportPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.PANEL_LINK_GATEWAY.route,
      element: <GatewayListPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.PANEL_CREATE_BLOCK_GATEWAY.route,
      element: <GatewayBlockPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.GATEWAY_DETAIL.route,
      element: <GatewayDetailPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.GATEWAY_CREATE_SIMPLE.route,
      element: <GatewayLinkCreateSimplePage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_1.route,
      element: <GatewayLinkCreateProfessionalStepOnePage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_2.route,
      element: <GatewayLinkCreateProfessionalStepTwoPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_3.route,
      element: <GatewayLinkCreateProfessionalStepThreePage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.GATEWAY_LINKS.route,
      element: <GatewayLinksListPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.GATEWAY_INVOICES.route,
      element: <GatewayInvoicesPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.GATEWAY_LINK_INVOICES.route,
      element: <GatewayLinkInvoicesPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.GATEWAY_UPDATE.route,
      element: <GatewayUpdatePage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.GATEWAY_CREATE_EMPTY.route,
      element: <GatewayCreateEmptyPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.GATEWAY_LINK_PROFESSIONAL_UPDATE.route,
      element: <GatewayLinkProfessionalUpdatePage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.GATEWAY_LINK_SIMPLE_UPDATE.route,
      element: <GatewayLinkSimpleUpdatePage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.PLANS_LIST.route,
      element: <PlansPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.PAYMENT_RETURN_SUCCESS.route,
      element: <PaymentReturnSuccessPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.PAYMENT_RETURN_FAILED.route,
      element: <PaymentReturnFailedPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.ACTIVE_PLAN.route,
      element: <ActivePlanPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.TRANSACTIONS_LIST.route,
      element: <PlanTransactionsPage />,
      role: true,
      isAuthenticated,
    },
    {
      path: routes.PANEL_TEMPLATE.route,
      element: <TemplatePage />,
      role: true,
      isAuthenticated,
    },
  ];

  const authenticationRoutes = [
    {
      path: routes.DEV,
      element: <DevelopmentPage />,
    },
    {
      path: routes.LOGIN.route,
      element: <LoginPage />,
    },
    {
      path: routes.VERIFY_CODE.route,
      element: <VerifyCodePage />,
    },
    {
      path: routes.REGISTER.route,
      element: <RegisterPage />,
    },
  ];

  useEffect(() => {
    if (query?.redirect && isAuthenticated) {
      navigate(query?.redirect, { state: disableBackToUrl });
    } else {
      if (!isAuthenticated) {
        const isPrivate = privateRoute.some((item) => item.path.startsWith('/' + pathnameArray?.[1]));
        isPrivate && navigate(routes.LOGIN.link());
      } else {
        const isAuthenticate = authenticationRoutes.some((item) => item.path === pathname);
        isAuthenticate && navigate(routes.LINKS.link);
      }
    }

    dispatch(setEnableScroll(true));
    scrollToSection('main-section', undefined, 0);
    isOpenBlockList && dispatch(setOpenBlockListHandler(false));
    isOpenMenu && dispatch(setOpenMenuHandler(false));
  }, [pathname]);

  return (
    <div
      id='main-section'
      onScroll={checkEndScroll}
      className={`max-w-md w-full border mx-auto border-gray-color-300 hiddenScroll h-screen bg-white-natural-color relative ${
        enableScroll ? 'overflow-y-scroll' : 'overflow-y-hidden'
      }`}
    >
      <Suspense fallback={<ZpGlobalLoading />}>
        {isAuthenticated ? (
          <Routes>
            {privateRoute.map((route) => (
              <Route key={route.path} path={route.path} element={<PrivateRoute isAuthenticated={route.isAuthenticated} role={route.role} />}>
                <Route {...route} />
              </Route>
            ))}

            <Route path='*' element={<Page404 />} />
          </Routes>
        ) : (
          <Routes>
            {authenticationRoutes.map((route) => (
              <Route key={route.path} path={route.path} element={<PublicRoute isAuthenticated={isAuthenticated} />}>
                <Route {...route} />
              </Route>
            ))}

            <Route path='*' element={<Navigate to={routes.LOGIN.link()} replace />} />
          </Routes>
        )}
      </Suspense>
    </div>
  );
};

export default memo(MainRoute);
