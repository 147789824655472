import ZpCollapse from 'components/_Core/UI/Collapse/ZpCollapse';
import ZpPanel from 'components/_Core/UI/Collapse/ZpPanel';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { CSSProperties, FC, ReactNode } from 'react';
import { IBlockDetail } from 'core/types/Models/linkType';
import 'components/_Core/UI/BlockList/style/blockList.scss';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { twMerge } from 'tailwind-merge';
import { getImageUrl } from 'core/utils/utils';
import { useLocation } from 'react-router-dom';

interface IZpBlockItem {
  children: ReactNode;
  block: IBlockDetail;
  customIconUrl?: string;
  panelClassName?: string;
  arrowIconColor?: string;
  blockNameColor?: string;
  forceRender?: boolean;
  hasBlockIcon?: boolean;
  hasBlockArrow?: boolean;
  bordered?: boolean;
  hasBlockBorder?: boolean;
  hasBlockImage?: boolean;
  centerContent?: string;
  panelStyleObject?: CSSProperties;
}

const ZpBlockItem: FC<IZpBlockItem> = ({
  children,
  hasBlockArrow = true,
  hasBlockIcon = true,
  block,
  customIconUrl,
  arrowIconColor,
  forceRender = true,
  bordered = true,
  hasBlockBorder,
  hasBlockImage,
  blockNameColor,
  centerContent,
  panelClassName = 'bg-white-natural-color',
  panelStyleObject,
}) => {
  const { state } = useLocation();
  const header = (
    <div className={`flex items-center space-x-5 space-x-reverse ${centerContent}`}>
      {hasBlockIcon && (
        <div className='max-w-[45px] w-full flex'>
          <ZpImage className='rounded-full' src={customIconUrl || getImageUrl(block?.type?.icon_url || block?.icon?.url) || undefined} width={40} height={40} />
        </div>
      )}
      <span className={`text-lg font-bold ${blockNameColor ? blockNameColor : ''}`}>{block?.title || block?.question}</span>
    </div>
  );

  return (
    <ZpCollapse
      defaultActiveKey={state?.blockId}
      expandIcon={({ isActive }) => <MdKeyboardArrowDown color={arrowIconColor} className={`${isActive ? 'rotate-180' : ''}`} size={20} />}
      key={block?.id}
      bordered={bordered}
    >
      <ZpPanel
        showArrow={hasBlockArrow}
        forceRender={forceRender}
        className={twMerge(
          `blockList block-collapse overflow-hidden ${bordered ? '' : hasBlockBorder ? 'template-preview' : ''} ${hasBlockImage ? '' : 'blockList-bg'} ${panelClassName}`
        )}
        key={block?.id}
        header={header}
        style={panelStyleObject}
      >
        {children}
      </ZpPanel>
    </ZpCollapse>
  );
};

export default ZpBlockItem;
