import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import ZpDatePickerLabel from 'components/_Core/UI/DatePicker/ZpDatePickerLabel';
import AccountBank from 'components/Gateway/components/AccountBank/AccountBank';
import { numberRegex, shaparakTitleRegex, validateAge } from 'core/utils/utils';
import GatewayFooter from 'components/_Core/Layouts/Panel/PanelHomeGatewayLout/PanelHomeGatewayFormFooter';
import useGatewayUpdate from 'components/Gateway/hooks/useGatewayUpdate';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import ZpInformation from 'components/_Core/UI/Information/ZpInformation';
import MainContentLayout from 'components/_Core/Layouts/Main/MainContentLayout/MainContentLayout';

function GatewayUpdate() {
  const { form, onFinishCreateGatewayHandler, isLoadingUpdateGateway, isLoadingAccountBanks, accountBanks } = useGatewayUpdate();
  const { userInfo } = useSelector((store: RootState) => store.userStore);

  return (
    <>
      <MainContentLayout>
        <ZpForm form={form} onFinish={onFinishCreateGatewayHandler}>
          <ZpWrapper singleColumn>
            <div>
              <ZpFormItem
                rules={[
                  {
                    required: true,
                    message: 'نام پذیزنده را وارد کنید',
                  },
                  {
                    pattern: shaparakTitleRegex,
                    message: 'نام پذیرنده فقط شامل حروف فارسی می‌باشد',
                  },
                ]}
                noMargin
                name='title'
              >
                <ZpInputLabel required label='نام پذیرنده' placeholder='برای مثال : دیجیکالا یا آقای علی محمدی' />
              </ZpFormItem>
              <ZpInformation> این نام در صفحه واریز شاپرک با عنوان نام پذیرنده به کاربران شما نمایش داده می شود.برای مثال : شرکت دیجی کالا یا علیرضا سهرابی</ZpInformation>
            </div>

            {userInfo && !userInfo?.data?.ssn && (
              <ZpFormItem
                name='ssn'
                rules={[
                  {
                    required: true,
                    message: 'کدملی خود را وارد کنید',
                  },
                  {
                    pattern: numberRegex,
                    message: 'کدملی شامل اعداد می‌باشد',
                  },
                  {
                    whitespace: true,
                    message: 'فاصله مجاز نیست',
                  },
                  {
                    max: 10,
                    message: 'کدملی حداکثر 10 رقم می‌باشد',
                  },
                  {
                    min: 10,
                    message: 'کدملی حداقل 10 رقم می‌باشد',
                  },
                ]}
              >
                <ZpInputLabel disabled={!!userInfo?.data?.ssn} required label='کدملی' />
              </ZpFormItem>
            )}

            {userInfo && !userInfo?.data?.birthday && (
              <ZpFormItem
                name='birthday'
                rules={[
                  {
                    required: true,
                    message: 'تاریخ تولد خود را وارد کنید',
                  },
                  () => ({
                    validator(_, value) {
                      const isValid = validateAge(value);
                      if (isValid) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error('حداقل سن مجاز، بالای 18 سال باشد'));
                      }
                    },
                  }),
                ]}
              >
                <ZpDatePickerLabel disabled={!!userInfo?.data?.birthday_convert} required label='انتخاب تاریخ تولد' />
              </ZpFormItem>
            )}

            <ZpFormItem
              rules={[
                {
                  required: true,
                  message: 'شماره شبا را وارد کنید',
                },
              ]}
              name='bank_account_id'
            >
              <AccountBank accountBanks={accountBanks || []} isLoadingAccountBanks={isLoadingAccountBanks} />
            </ZpFormItem>
          </ZpWrapper>
        </ZpForm>
      </MainContentLayout>

      <GatewayFooter isLoading={isLoadingUpdateGateway} form={form} />
    </>
  );
}

export default GatewayUpdate;
