import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import ZpDrawer from 'components/_Core/UI/Drawer/ZpDrawer';
import useModal from 'core/hooks/useModal';
import ZpRadioButtonGroup from 'components/_Core/UI/RadioButton/ZpRadioButtonGroup';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const GatewayInvoiceStatus = () => {
  // hooks
  const { modal, showModal, hideModal } = useModal();
  const { query, setUrlParam, deleteOneQueryParam } = useQueryUrlParam();

  return (
    <>
      <ZpDrawer
        height='auto'
        roundedTop
        className='bg-white-natural-color'
        placement='bottom'
        open={modal}
        onClose={hideModal}
        title={<span className='text-lg'>وضعیت تراکنش</span>}
      >
        <ZpRadioButtonGroup
          className='flex flex-col space-y-7'
          onChange={(e) => (e.target.value === 'all' ? deleteOneQueryParam('filter[status]') : setUrlParam({ ...query, 'filter[status]': e.target.value }))}
          value={query['filter[status]'] || 'all'}
          options={[
            { label: <span className='text-lg'>همه</span>, value: 'all' },
            { label: <span className='text-lg'>موفق</span>, value: 1 },
            { label: <span className='text-lg'>ناموفق</span>, value: 2 },
          ]}
        />
      </ZpDrawer>

      {query['filter[status]'] ? (
        <div className='border rounded-lg py-3 px-4 cursor-pointer border-primary-color-main' onClick={showModal}>
          <span className='flex items-center space-x-5 space-x-reverse justify-between text-primary-color-main'>
            <span>{query['filter[status]'] === 1 ? 'موفق' : 'ناموفق'}</span>
            <AiOutlineCloseCircle
              size={20}
              onClick={(e) => {
                e.stopPropagation();
                deleteOneQueryParam('filter[status]');
              }}
            />
          </span>
        </div>
      ) : (
        <div className='border border-border-color rounded-lg py-3 px-4 cursor-pointer hover:border-primary-color-main' onClick={showModal}>
          <span className='flex items-center space-x-5 space-x-reverse justify-center'>
            <span>وضعیت</span>
            <MdOutlineKeyboardArrowDown size={20} />
          </span>
        </div>
      )}
    </>
  );
};

export default GatewayInvoiceStatus;
