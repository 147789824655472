import Support from 'components/Support/Support';
import PanelLinkLayout from 'components/_Core/Layouts/PanelMaster/PanelMasterIndex/PanelMasterIndexLayout';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';

const SupportPage = () => {
  return (
    <PanelLinkLayout>
      <HeadMeta tabTitle='پشتیبانی' />
      <Support />
    </PanelLinkLayout>
  );
};

export default SupportPage;
