import ZpDrawer from 'components/_Core/UI/Drawer/ZpDrawer';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { FC } from 'react';

interface IZpBlockDelete {
  blockId: number | undefined;
  hideModal(): void;
  onDeleteHandler(): void;
  isLoadingDelete: boolean;
  modal: boolean;
  deleteTitle?: string;
}

const ZpBlockDelete: FC<IZpBlockDelete> = ({ hideModal, modal, onDeleteHandler, isLoadingDelete, deleteTitle = 'آیا می‌خواهید حذف کنید ؟' }) => {
  return (
    <ZpDrawer height='auto' placement='bottom' closable={false} open={modal} onClose={hideModal}>
      <div className='flex flex-col space-y-8 items-center justify-center h-full'>
        <p className='text-lg'>{deleteTitle}</p>

        <div className='flex space-x-5 space-x-reverse'>
          <ZpButton ghost type='primary' onClick={hideModal}>
            انصراف
          </ZpButton>
          <ZpButton type='primary' danger isLoading={isLoadingDelete} onClick={onDeleteHandler}>
            بله حذف می‌کنم
          </ZpButton>
        </div>
      </div>
    </ZpDrawer>
  );
};

export default ZpBlockDelete;
