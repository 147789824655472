import { getImageUrl } from 'core/utils/utils';
import { ITemplate } from 'core/types/Models/templateType';
import useModal from 'core/hooks/useModal';
import { useDispatch, useSelector } from 'react-redux';
import { setTemplateDetail } from 'core/store/slice/template/templateSlice';
import { RootState } from 'core/store';
import { useUpdateLinkQuery } from 'components/CreateLink/StepTwo/hooks/react-query/useUpdateLinkQuery';
import { useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useRoute from 'core/hooks/useRoute';

const useTemplateItem = (template: ITemplate) => {
  //hooks
  const { modal: modalPreview, showModal: showModalPreview, hideModal: hideModalPreview } = useModal();
  const { showModal: showModalPlan, modal: modalPlan, hideModal: hideModalPlan } = useModal();
  const { username } = useRoute();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  // store
  const { linkDetail } = useSelector((store: RootState) => store.linkStore);
  const { hasPlan } = useSelector((store: RootState) => store.userPlanStore);

  // query
  const { mutate: updateTheme, isLoading: isLoadingUpdateTheme } = useUpdateLinkQuery(undefined, undefined, template.id);

  // template data
  const { name, background, styles } = template;
  const { image, default_color } = background;
  const { block_background_image, block_box_shadow, block_border_radius, has_block_border, has_block_blur, colors } = styles;
  const { block_border_color, block_background_color } = colors;

  // utils
  const backgroundStyle = {
    backgroundImage: `url(${getImageUrl(image)})`,
    backgroundColor: default_color,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
    zIndex: -10,
  };

  const isSelectedTheme = linkDetail?.data?.template?.id === template.id;

  const onShowTemplatePreviewHandler = () => {
    if (!isSelectedTheme && (hasPlan || template?.is_free)) {
      showModalPreview();
      dispatch(setTemplateDetail(template));
    } else {
      !isSelectedTheme && !template?.is_free && showModalPlan();
    }
  };

  const onUpdateThemeHandler = () => {
    updateTheme(
      { template_id: template.id },
      {
        onSuccess: () => {
          hideModalPreview();
          queryClient.prefetchQuery([endPointUrls.LINK_GET_BY_USERNAME(username + '')]);
        },
      }
    );
  };

  return {
    name,
    backgroundStyle,
    block_background_image,
    block_box_shadow,
    block_border_radius,
    has_block_border,
    has_block_blur,
    block_border_color,
    block_background_color,
    modalPreview,
    hideModalPreview,
    hideModalPlan,
    modalPlan,
    onShowTemplatePreviewHandler,
    isSelectedTheme,
    onUpdateThemeHandler,
    isLoadingUpdateTheme,
  };
};

export default useTemplateItem;
