import { Form } from 'antd';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import { IBlockDetail } from 'core/types/Models/linkType';
import useUpdateSocialMedia from '../../hooks/useUpdateSocialMedia';
import ZpBlockDelete from 'components/_Core/UI/BlockDelete/ZpBlockDelete';

const SocialMediaBlockUpdate = ({ block }: { block: IBlockDetail | undefined }) => {
  const [formUpdate] = Form.useForm();
  const { onFinishUpdateHandler, isLoadingUpdateSocialMedia, isLoadingDeleteSocialMedia, onDeleteHandler, hideModal, modal, showModal } = useUpdateSocialMedia(formUpdate, block);

  return (
    <>
      <ZpBlockDelete modal={modal} hideModal={hideModal} isLoadingDelete={isLoadingDeleteSocialMedia} onDeleteHandler={onDeleteHandler} blockId={block?.id} />

      <ZpForm form={formUpdate} onFinish={onFinishUpdateHandler}>
        <ZpWrapper singleColumn>
          <ZpFormItem rules={[{ required: true, message: `عنوان ${block?.type?.fa_name} را وارد کنید` }]} name='title'>
            <ZpInputLabel required label={`عنوان ${block?.type?.fa_name}`} />
          </ZpFormItem>

          <ZpFormItem
            rules={[
              { required: true, message: `آیدی ${block?.type?.fa_name} را وارد کنید` },
              ...(block?.type?.en_name.toLowerCase().includes('youtube')
                ? [
                    {
                      pattern: /^@/,
                      message: `فرمت ${block?.type?.fa_name} اشتباه است`,
                    },
                  ]
                : []),
            ]}
            name='username'
          >
            <ZpInputLabel
              suffix={!block?.type?.en_name.toLowerCase().includes('youtube') && '@'}
              placeholder={block?.type?.en_name.toLowerCase().includes('youtube') ? `برای مثال : @${process.env.REACT_APP_BRAND_NAME_STUCK_EN}` : ''}
              textLeftFull={!block?.type?.en_name.toLowerCase().includes('youtube')}
              textLeft={block?.type?.en_name.toLowerCase().includes('youtube')}
              required
              label={`آیدی ${block?.type?.fa_name}`}
            />
          </ZpFormItem>

          <ZpFormItem button>
            <div className='flex items-center space-x-reverse space-x-5 justify-between'>
              <ZpButton buttonSize='xs' className='w-full' htmlType='button' danger ghost type='primary' isLoading={isLoadingDeleteSocialMedia} onClick={showModal}>
                حذف
              </ZpButton>
              <ZpButton buttonSize='xs' className='w-full' htmlType='submit' type='primary' isLoading={isLoadingUpdateSocialMedia}>
                ثبت تغییرات
              </ZpButton>
            </div>
          </ZpFormItem>
        </ZpWrapper>
      </ZpForm>
    </>
  );
};

export default SocialMediaBlockUpdate;
