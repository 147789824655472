import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { FC } from 'react';

interface ILinkStatus {
  status: number;
  linkId: number;

  hideModal(): void;

  onSubmitHandler(status: number, id: number, hideModal: () => void, isDeleted: boolean): void;

  isLoadingUpdateLink: boolean;
  isDeleted: boolean;
}

const LinkStatus: FC<ILinkStatus> = ({ status, isDeleted, hideModal, onSubmitHandler, isLoadingUpdateLink, linkId }) => {
  return (
    <div className='flex flex-col space-y-8 items-center justify-center h-full'>
      <p className='text-lg'>آیا می‌خواهید {(isDeleted && 'حذف') || (status === 0 ? 'فعال' : 'غیرفعال')} کنید ؟</p>

      <div className='flex space-x-5 space-x-reverse'>
        <ZpButton ghost type='primary' onClick={hideModal}>
          انصراف
        </ZpButton>
        <ZpButton type='primary' danger={isDeleted || status !== 0} isLoading={isLoadingUpdateLink} onClick={() => onSubmitHandler(status, linkId, hideModal, isDeleted)}>
          {(isDeleted && ' بله حذف می‌کنم') || (status === 0 ? 'بله فعال می‌کنم' : 'بله غیرفعال می‌کنم')}
        </ZpButton>
      </div>
    </div>
  );
};

export default LinkStatus;
