import { ReactComponent as PlanTitleLabelSVG } from 'assets/images/plan/plan-title-label.svg';
import { ReactComponent as PlanTitleArrowSVG } from 'assets/images/plan/plan-title-arrow.svg';
import { FC } from 'react';

interface IPlanTitle {
  isModal?: boolean;
}

const PlanTitle: FC<IPlanTitle> = ({ isModal = false }) => {
  return (
    <div className={`sticky z-10 ${isModal ? 'top-0' : 'top-[95px]'}`}>
      <div className='flex flex-col items-center pt-10 pb-8 justify-center space-y-8 bg-white-natural-color relative bottom-6'>
        <div className='relative'>
          <h2 className='text-2xl'>پلن‌های {process.env.REACT_APP_BRAND_NAME_FA}</h2>
          <div className='w-10 h-1 bg-secondary-color-main absolute -bottom-3 right-0' />
          <PlanTitleLabelSVG className='absolute -left-5 -top-5' />
        </div>
        <PlanTitleArrowSVG className='absolute -right-8 -bottom-5' />

        <p className='text-color-paragraph text-center px-5'>
          استفاده از لینک‌ها و ساخت درگاه پرداخت رایگان است. همچنین به مدت 14 روز می‌توانید بدون هیچ هزینه‌ای از تمامی بخش‌های حرفه‌ای {process.env.REACT_APP_BRAND_NAME_FA} استفاده
          کنید و از کارایی آن مطلع شوید.
        </p>
      </div>
    </div>
  );
};

export default PlanTitle;
