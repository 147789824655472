import { Modal, ModalProps } from 'antd';
import { FC, MouseEvent, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';

interface IZpModal extends ModalProps {
  title?: string | ReactNode;
  footerContent?: ReactNode;
}

const ZpModal: FC<IZpModal> = ({ children, title = '', width, footer = null, onCancel, footerContent, ...props }) => {
  //hooks
  const dispatch = useDispatch();

  const onCancelModal = (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
    dispatch(setApiCatcherFormErrors([]));
    onCancel && onCancel(e as any);
  };
  return (
    <Modal width={width} footer={footer} onCancel={(e) => onCancelModal(e)} centered destroyOnClose title={<p className='mb-0 text-inherit'>{title}</p>} {...props}>
      <div>{children}</div>
      {footerContent && <div className='pt-4 border-t border-t-border-color'>{footerContent}</div>}
    </Modal>
  );
};

export default ZpModal;
