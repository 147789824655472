import ZpBlockCreate from 'components/_Core/UI/BlockCreate/ZpBlockCreate';
import useDividerBlock from './hooks/useDividerBlock';
import { HiOutlineUserGroup } from 'react-icons/hi';
import DividerBlockList from './components/List/DividerBlockList';
import BlockListSkeleton from 'components/_Core/UI/BlockListSkeleton/BlockListSkeleton';

const DividerBlock = () => {
  const {
    hideMasterModal,
    onFinishCreateDividerHandler,
    childModal,
    hideChildModalHandler,
    isLoadingDividerList,
    isLoadingCreateDivider,
    showChildModal,
    masterModal,
    showMasterModal,
    isLoadingLinkDetail,
    DividerList,
    hasBlock,
    DividersList,
  } = useDividerBlock();

  if (isLoadingDividerList || isLoadingLinkDetail || isLoadingCreateDivider) return <BlockListSkeleton />;

  return (
    <>
      <ZpBlockCreate
        childModal={childModal}
        hasBlock={hasBlock}
        hideChildModal={hideChildModalHandler}
        showChildModal={showChildModal}
        blockTypes={DividersList}
        masterModal={masterModal}
        hideMasterModal={hideMasterModal}
        onFinishMasterHandler={onFinishCreateDividerHandler}
        showMasterModal={showMasterModal}
        blockTypeIcon={<HiOutlineUserGroup size={26} />}
        blockTypeTitle='جداکننده‌ها'
        blockTypeDescription='یکی از جداکننده‌ها را انتخاب کنید'
        title='جداکننده'
      >
        <></>
      </ZpBlockCreate>

      <DividerBlockList blocks={DividerList} />
    </>
  );
};

export default DividerBlock;
