import ZpDrawer from 'components/_Core/UI/Drawer/ZpDrawer';
import { ReactComponent as ActivateTrialPlanSVG } from 'assets/images/plan/activate-trial-plan.svg';
import { FC } from 'react';
import { CgCloseO } from 'react-icons/cg';
import ZpSpinner from 'components/_Core/UI/Spinner/ZpSpinner';
import ZpLink from 'components/_Core/UI/Link/ZpLink';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { FiArrowLeft } from 'react-icons/fi';
import Container from 'components/_Core/Layouts/Container/Container';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import useRoute from 'core/hooks/useRoute';

interface IActivePlanTrialDrawer {
  open: boolean;
  hideDrawer: () => void;
}

const ActivatePlanTrialDrawer: FC<IActivePlanTrialDrawer> = ({ hideDrawer, open }) => {
  // store
  const { trialDays } = useSelector((store: RootState) => store.userPlanStore);
  const { userInfo } = useSelector((store: RootState) => store.userStore);

  // hooks
  const { routes } = useRoute();

  return (
    <ZpDrawer height='100%' placement='bottom' className='bg-white-natural-color' closable={false} open={open} onClose={hideDrawer}>
      <CgCloseO className='absolute right-5 top-5 z-50 cursor-pointer' onClick={hideDrawer} size={25} />

      <Container>
        <div className='flex flex-col items-center justify-center space-y-8'>
          <ActivateTrialPlanSVG />

          <div className='space-y-5 text-center'>
            <h1 className='font-bold text-2xl'>پلن حرفه‌ای به مدت {trialDays || 0} روز فعال شد!</h1>

            <p className='text-color-paragraph'>
              <span className='text-secondary-color-main'>{userInfo?.data?.firstname} عزیز</span> حساب کاربری شما به مدت 14 روز به پلن حرفه ای ارتقا پیدا کرد.در این مدت می توانید
              از های بخش های حرفه ای {process.env.REACT_APP_BRAND_NAME_FA} استفاده نمایید.
            </p>
          </div>

          <ZpLink to={routes.LINKS.link} className='w-full'>
            <ZpButton type='primary' className='w-full' buttonIcon={<FiArrowLeft size={22} />}>
              شروع مسیر حرفه‌ای
            </ZpButton>
          </ZpLink>
        </div>
      </Container>
    </ZpDrawer>
  );
};

export default ActivatePlanTrialDrawer;
