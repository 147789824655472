import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryOnSuccessHandler from 'core/helpers/queryOnSuccessHandler/useQueryOnSuccessHandler';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import api from 'core/services/fetch-api/useApi';
import useRoute from 'core/hooks/useRoute';
import { IGatewayLinkMutationResponse } from 'core/types/Models/gatewayLinkType';

export const useUpdateGatewayLinkImageQuery = () => {
  const { onSuccessHandler } = useQueryOnSuccessHandler();
  const { query } = useQueryUrlParam();
  const { gatewayLinkId, gatewayId } = useRoute();

  return useMutationHook(
    [endPointUrls.GATEWAY_LINK_UPLOAD_IMAGE(query?.gatewayId + '', query?.gatewayLinkId)],
    (data: any) => api.post(endPointUrls.GATEWAY_LINK_UPLOAD_IMAGE(query?.gatewayId || gatewayId, query?.gatewayLinkId || gatewayLinkId), data),
    {
      onSuccess: (res: any) => {
        onSuccessHandler(res);
      },
    }
  ) as UseMutationResult<IGatewayLinkMutationResponse, Error>;
};
