import { FormInstance } from 'antd';
import { Rule } from 'antd/es/form';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import { IBlockTypes } from 'core/types/Models/blockType';
import { getImageUrl, numberRegex } from 'core/utils/utils';
import { FC } from 'react';

interface IConnectionBlockCreate {
  createForm: FormInstance;
  isLoadingCreateConnection: boolean;
  blockTypeSelected: IBlockTypes | undefined;
  onFinishCreateLinkHandler: (val: any) => void;
  hideChildModalHandler: () => void;
}

const ConnectionBlockCreate: FC<IConnectionBlockCreate> = ({ createForm, isLoadingCreateConnection, blockTypeSelected, hideChildModalHandler, onFinishCreateLinkHandler }) => {
  const isNumber =
    blockTypeSelected?.en_name.toLowerCase().includes('mobile') ||
    blockTypeSelected?.en_name.toLowerCase().includes('phone') ||
    blockTypeSelected?.en_name.toLowerCase().includes('sms');

  const isEmail = blockTypeSelected?.en_name.toLowerCase().includes('email');

  const emailValidation: Rule = isEmail
    ? {
        type: 'email',
        message: `فرمت ${blockTypeSelected?.fa_name} اشتباه است`,
      }
    : {};
  const numberValidation: Rule = isNumber
    ? {
        pattern: numberRegex,
        message: `فرمت ${blockTypeSelected?.fa_name} اشتباه است`,
      }
    : {};

  return (
    <>
      <div className='flex items-center space-x-reverse space-x-5 mb-5'>
        <ZpImage className='rounded-full' src={getImageUrl(blockTypeSelected?.icon_url)} width={45} height={45} />
        <h2 className='text-lg font-bold'>{blockTypeSelected?.fa_name}</h2>
      </div>

      <ZpForm form={createForm} onFinish={onFinishCreateLinkHandler}>
        <ZpWrapper singleColumn>
          <ZpFormItem rules={[{ required: true, message: `عنوان ${blockTypeSelected?.fa_name} را وارد کنید` }]} name='title'>
            <ZpInputLabel required label={`عنوان ${blockTypeSelected?.fa_name}`} />
          </ZpFormItem>

          <ZpFormItem
            rules={[
              { required: true, message: `${blockTypeSelected?.fa_name} را وارد کنید` },
              { ...numberValidation, ...emailValidation },
            ]}
            name='value'
          >
            <ZpInputLabel textLeft required label={`${blockTypeSelected?.fa_name}`} />
          </ZpFormItem>

          <ZpFormItem button>
            <div className='flex items-center space-x-reverse space-x-5 justify-between'>
              <ZpButton buttonSize='xs' className='w-full' htmlType='button' ghost type='primary' onClick={hideChildModalHandler}>
                انصراف
              </ZpButton>
              <ZpButton buttonSize='xs' className='w-full' htmlType='submit' type='primary' isLoading={isLoadingCreateConnection}>
                ساخت بلوک
              </ZpButton>
            </div>
          </ZpFormItem>
        </ZpWrapper>
      </ZpForm>
    </>
  );
};

export default ConnectionBlockCreate;
