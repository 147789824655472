import { MapContainer, TileLayer, ZoomControl, useMap, MapContainerProps } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { CSSProperties, FC, ReactNode, useEffect } from 'react';

interface IZpMap extends MapContainerProps {
  initialCenter?: [number, number];
  children?: ReactNode;
  zoomLevel?: number;
  firstRender?: boolean;
  mapStyle?: CSSProperties;
  setZoomLevel?: (val: any) => void;
  setLatAndLng?: (val: any) => void;
  height?: string;
}

const ZpMap: FC<IZpMap> = ({
  children,
  firstRender = false,
  initialCenter,
  mapStyle = { direction: 'ltr', position: 'relative', zIndex: 0 },
  zoomLevel,
  setLatAndLng,
  setZoomLevel,
  height = 'h-[300px]',
  ...props
}) => {
  if (!initialCenter || (initialCenter && initialCenter?.length !== 2)) return <></>;

  return (
    <div id='map' className={`w-full border border-natural-color-400 rounded-lg ${height}`} style={mapStyle}>
      <MapContainer center={initialCenter} zoom={zoomLevel} scrollWheelZoom={true} zoomControl={false} className='rounded-lg' {...props}>
        <ZpTileLayer firstRender={firstRender} setLatAndLng={setLatAndLng} setZoomLevel={setZoomLevel} initialCenter={initialCenter} />
        <ZoomControl position='bottomright' />
        {children}
      </MapContainer>
    </div>
  );
};

const ZpTileLayer = ({
  setLatAndLng,
  setZoomLevel,
  initialCenter,
  firstRender,
}: {
  initialCenter?: [number, number];
  firstRender?: boolean;
  setZoomLevel?: (val: any) => void;
  setLatAndLng?: (val: any) => void;
}) => {
  const map = useMap();

  useEffect(() => {
    if (setLatAndLng && setZoomLevel) {
      map?.on('move', (e) => {
        setLatAndLng([e.target.getCenter().lat, e.target.getCenter().lng]);
        setZoomLevel(e.target.getZoom());
      });
    }
  }, [map]);

  useEffect(() => {
    initialCenter?.length && map.flyTo([initialCenter?.[0], initialCenter?.[1]], firstRender ? 10 : 17);
  }, []);

  useEffect(() => {
    map.invalidateSize(true);
  }, []);

  return (
    <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' attribution={'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'} />
  );
};

export default ZpMap;
