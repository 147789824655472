import ZpSkeleton from 'components/_Core/UI/Skeleton/ZpSkeleton';

const LinkShareSkeleton = () => {
  return (
    <div className='flex flex-col justify-between h-full'>
      <div className='space-y-10'>
        <div>
          <ZpSkeleton height={50} />
        </div>

        <div>
          <ZpSkeleton height={50} />
        </div>

        <div className='flex items-center justify-center space-x-3 space-x-reverse'>
          <span>
            <ZpSkeleton width={60} circle height={60} />
          </span>
          <span>
            <ZpSkeleton width={60} circle height={60} />
          </span>
          <span>
            <ZpSkeleton width={60} circle height={60} />
          </span>
          <span>
            <ZpSkeleton width={60} circle height={60} />
          </span>
        </div>

        <div>
          <ZpSkeleton height={400} />
        </div>
      </div>
    </div>
  );
};

export default LinkShareSkeleton;
