import ZpSkeleton from 'components/_Core/UI/Skeleton/ZpSkeleton';

const LinkItemSkeleton = () => {
  return (
    <div className='border relative bg-white-color border-natural-color-200 max-w-md rounded-lg mx-auto w-full px-4 pt-14 pb-8 shadow-sm'>
      <div className='text-center space-y-5'>
        <ZpSkeleton className='absolute cursor-pointer top-6 left-6' width={50} height={30} />

        <ZpSkeleton width={100} height={100} circle />

        <div className='space-y-3'>
          <div>
            <ZpSkeleton width={80} height={20} />
          </div>

          <div>
            <ZpSkeleton width={80} height={20} />
          </div>
        </div>

        <div>
          <ZpSkeleton height={40} />
        </div>

        <div className='flex items-center justify-between gap-2'>
          <ZpSkeleton width={120} height={40} />

          <ZpSkeleton width={120} height={40} />
        </div>
      </div>
    </div>
  );
};

export default LinkItemSkeleton;
