import PlanTransactionItem from 'components/PlanTransactions/components/PlanTransactionItem';
import PlanTransactionsEmpty from 'components/PlanTransactions/components/PlanTransactionsEmpty';
import PlanTransactionItemSkeleton from 'components/PlanTransactions/components/PlanTransactionItemSkeleton';
import { usePlanTransactionListQuery } from 'components/PlanTransactions/hooks/react-query/usePlanTransactionListQuery';

const PlanTransactions = () => {
  const { data: planTransaction, isFetching: isLoadingPlanTransaction } = usePlanTransactionListQuery();

  if (isLoadingPlanTransaction) return <PlanTransactionItemSkeleton />;

  return (
    <>
      {(planTransaction === undefined || planTransaction?.data?.length === 0) && <PlanTransactionsEmpty />}
      <div className='space-y-5'>
        {planTransaction?.data?.map((invoice) => (
          <PlanTransactionItem key={invoice.id} {...invoice} />
        ))}
      </div>
    </>
  );
};

export default PlanTransactions;
