import { createSlice } from '@reduxjs/toolkit';

const initialState: { dragMode: boolean } = { dragMode: false };

export const linkBlockSlice = createSlice({
  name: 'linkBlock',
  initialState: initialState,
  reducers: {
    setBlockDragMode: (state, { payload }: { payload: boolean }) => {
      state.dragMode = payload;
    },
  },
});

export const { setBlockDragMode } = linkBlockSlice.actions;
export default linkBlockSlice.reducer;
