import ProfileLayout from 'components/_Core/Layouts/PanelMaster/Profile/ProfileLayout';
import Profile from 'components/Profile/Profile';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';

const ProfilePage = () => {
  return (
    <ProfileLayout>
      <HeadMeta tabTitle='پروفایل من' />
      <Profile />
    </ProfileLayout>
  );
};

export default ProfilePage;
