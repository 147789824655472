import { FC, memo, ReactNode } from 'react';
import PanelCreateLinksSteps from 'components/_Core/Layouts/Panel/PanelCreateLinkLayout/PanelCreateLinksSteps';
import MainLayout from 'components/_Core/Layouts/Main/MainLayout';
import MainHeaderLayout from 'components/_Core/Layouts/Main/MainHeader/MainHeaderLayout';
import useRoute from 'core/hooks/useRoute';

interface IPanelCreateLinkLayout {
  children: ReactNode;
}

const PanelCreateLinkLayout: FC<IPanelCreateLinkLayout> = ({ children }) => {
  const { routes } = useRoute();

  return (
    <MainLayout>
      <MainHeaderLayout headerTitle='ساخت لینک جدید' backUrlArrow={routes.LINKS.link} />

      <PanelCreateLinksSteps />

      <>{children}</>
    </MainLayout>
  );
};

export default memo(PanelCreateLinkLayout);
