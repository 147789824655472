import ZpImage from 'components/_Core/UI/Image/ZpImage';
import { IBlockDetail } from 'core/types/Models/linkType';
import { FC, Fragment } from 'react';
import externalLink from 'assets/images/externalLink.svg';
import { useNavigate } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import ZpBlockDelete from 'components/_Core/UI/BlockDelete/ZpBlockDelete';
import { IBlockTypeDetail } from 'core/types/Models/blockType';
import useDashboardChild from '../hooks/useDashboardChild';
import faq from 'assets/images/icon/faq.svg';
import { getImageUrl } from 'core/utils/utils';

interface IDashboardBlockChild {
  blocks: IBlockDetail[];
  blockType: IBlockTypeDetail;
}

const DashboardBlockChild: FC<IDashboardBlockChild> = ({ blocks, blockType }) => {
  const navigate = useNavigate();
  const staticIcon = blockType.name === 'externalLink' ? externalLink : blockType.name === 'faq' ? faq : undefined;

  return (
    <>
      <div className='w-full space-y-3'>
        <p className='text-lg font-bold text-center text-gray-color-700'>{blockType.title}</p>
        {blocks?.map((block) => {
          const { modal, hideModal, showModal, isLoadingDelete, onDeleteHandler } = useDashboardChild(blockType, block.id);

          return (
            <Fragment key={block.id}>
              <ZpBlockDelete modal={modal} hideModal={hideModal} isLoadingDelete={isLoadingDelete} onDeleteHandler={onDeleteHandler} blockId={block?.id} />
              <div className='bg-[var(--block-item-bg)] border border-gray-color-300 rounded-lg w-full p-2 space-x-3 space-x-reverse flex items-center justify-between'>
                <div className='flex items-center space-x-4 space-x-reverse flex-1'>
                  <div className='max-w-[40px] w-full flex'>
                    <ZpImage
                      className='rounded-full'
                      width={40}
                      height={40}
                      src={(block?.type?.icon_url || block?.icon?.url ? getImageUrl(block?.type?.icon_url || block?.icon?.url) : staticIcon) || undefined}
                    />
                  </div>
                  <span className='line-clamp-2'>{block.title || block.question}</span>
                </div>

                <div className='space-x-3 space-x-reverse max-w-[60px] w-full flex'>
                  <FaRegEdit className='cursor-pointer' size={22} onClick={() => navigate(blockType.route, { state: { blockId: block.id } })} />
                  <RiDeleteBin6Line className='cursor-pointer' size={22} color='var(--error-color-300)' onClick={showModal} />
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

export default DashboardBlockChild;
