import { convertJalaliDateToEnglish, extractNumberFromString } from 'core/utils/utils';
import { ICardToIbanMutationResponse } from 'core/types/Models/cardToIbanType';
import { useConvertToShebaQuery } from 'components/Gateway/hooks/react-query/useConvertToShebaQuery';
import { useAccountBankCreateQuery } from 'components/Gateway/components/AccountBank/hooks/react-query/useAccountBankCreateQuery';
import { Form } from 'antd';
import { IAccountBankPayload } from 'core/types/Models/accountBankType';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useEffect } from 'react';
import { endPointUrls } from 'core/constants/endPointUrls';
import { useQueryClient } from '@tanstack/react-query';

const useConvertCardToBankAccount = (hideModal: () => void) => {
  // hooks
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  // query
  const { mutate: convertCardToIban, isLoading: isLoadingConvertCardToIban } = useConvertToShebaQuery();
  const { mutate: createAccountBank, isLoading: isLoadingAccountBank } = useAccountBankCreateQuery();

  // store
  const { userInfo } = useSelector((store: RootState) => store.userStore);
  const { apiCatcherFormErrors } = useSelector((store: RootState) => store.mainInfoStore);

  const onRequestCreateBankAccount = (payload: IAccountBankPayload) => {
    createAccountBank(payload, {
      onSuccess: () => {
        hideModal();
        queryClient.invalidateQueries([endPointUrls.LINK_ACCOUNT_BANK]);
      },
    });
  };

  const onFinishConvertCardToShebaHandler = (values: IAccountBankPayload) => {
    const cardNumber = extractNumberFromString(values?.card + '');

    convertCardToIban(
      { card: extractNumberFromString(cardNumber) },
      {
        onSuccess: (res: ICardToIbanMutationResponse) => {
          const payload: IAccountBankPayload = {
            ssn: values.ssn || userInfo?.data?.ssn + '',
            birthday: userInfo?.data?.birthday || convertJalaliDateToEnglish(values.birthday),
            first_name: values.first_name,
            last_name: values.last_name,
            iban: res?.data?.data?.IBAN,
          };

          return onRequestCreateBankAccount(payload);
        },
      }
    );
  };

  useEffect(() => {
    form.setFieldsValue({ first_name: userInfo?.data?.firstname, last_name: userInfo?.data?.lastname, ssn: userInfo?.data?.ssn, birthday: userInfo?.data?.birthday_convert });
  }, []);

  useEffect(() => {
    form.setFields(apiCatcherFormErrors);
  }, [JSON.stringify(apiCatcherFormErrors)]);

  return { form, onFinishConvertCardToShebaHandler, isLoading: isLoadingConvertCardToIban || isLoadingAccountBank };
};

export default useConvertCardToBankAccount;
