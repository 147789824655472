import { RadioChangeEvent } from 'antd';
import useRoute from 'core/hooks/useRoute';
import { RootState } from 'core/store';
import { ILink } from 'core/types/Models/linkType';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useProfileSwitch = (hideModal: () => void) => {
  // hooks
  const navigate = useNavigate();
  const { routes } = useRoute();

  // store
  const { linkDetail, linkList } = useSelector((store: RootState) => store.linkStore);

  const onChangeProfileHandler = (e: RadioChangeEvent, link: ILink) => {
    navigate(routes.PANEL_HOME.link(link.username));
    hideModal();
  };

  return { links: linkList, linkDetail, onChangeProfileHandler };
};

export default useProfileSwitch;
