import { useMutation, QueryKey, UseMutationOptions, MutationFunction } from '@tanstack/react-query';
import useQueryOnErrorHandler from 'core/helpers/queryOnErrorHandler/useQueryOnErrorHandler';
import toastHandler from 'core/helpers/toast/toast';

const useMutationHook = (queryKey: QueryKey, queryFn: MutationFunction, queryOptions: UseMutationOptions, showError = true) => {
  const { onErrorHandler } = useQueryOnErrorHandler();

  return useMutation(queryKey, queryFn, {
    ...queryOptions,
    onError: (res: any, payload: any) => {
      if (res?.response?.status >= 500) {
        toastHandler('error', 'لطفا دوباره تلاش کنید');
      }

      onErrorHandler(res, showError, payload);
    },
  });
};

export default useMutationHook;
