import { StepProps } from 'antd';
import ZpSteps from 'components/_Core/UI/Steps/ZpSteps';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import Container from 'components/_Core/Layouts/Container/Container';

const PanelCreateLinksSteps = () => {
  const steps: StepProps[] = [{ title: 'آیدی لینک' }, { title: 'مشخصات لینک' }, { title: 'حوزه فعالیت' }];
  const { pathnameLastPart } = useQueryUrlParam();

  return (
    <Container className='py-5 flex mx-0 justify-center max-w-full'>
      <ZpSteps items={steps} labelPlacement='vertical' className='max-w-xl' responsive={false} current={pathnameLastPart ? +pathnameLastPart - 1 : 0} />
    </Container>
  );
};

export default PanelCreateLinksSteps;
