import { useQueryClient } from '@tanstack/react-query';
import { useUpdateLinkQuery } from 'components/CreateLink/StepTwo/hooks/react-query/useUpdateLinkQuery';
import { endPointUrls } from 'core/constants/endPointUrls';
import useModal from 'core/hooks/useModal';
import { useState } from 'react';
import { useDeleteLinkQuery } from './react-query/useDeleteLinkQuery';
import { scrollToSection } from 'core/utils/utils';

const useLinkAction = () => {
  // hooks
  const { hideModal, modal, showModal } = useModal();
  const { modal: openDropDown, hideModal: hideDropdown, showModal: showDropdown } = useModal();
  const queryClient = useQueryClient();

  // state
  const [isDeleted, setIsDeleted] = useState(false);

  // query
  const { mutate: updateLink, isLoading: isLoadingUpdateLink } = useUpdateLinkQuery();
  const { mutate: deleteLink, isLoading: isLoadingDeleteLink } = useDeleteLinkQuery();

  const onOpenChange = () => {
    if (openDropDown) {
      hideDropdown();
      isDeleted && setIsDeleted(false);
    } else {
      showDropdown();
    }
  };

  const showModalHandler = (deleted?: boolean) => {
    showModal();
    hideDropdown();
    deleted && setIsDeleted(deleted);
  };

  const onChangeStatusHandler = (status: number, linkId: number, hideModal: () => void, isDeleted: boolean) => {
    if (isDeleted) {
      deleteLink(
        { linkId },
        {
          onSuccess: () => {
            hideModal();
            scrollToSection('main-section', undefined, 0);
            queryClient.invalidateQueries([endPointUrls.LINK('list')]);
          },
        }
      );
    } else {
      updateLink(
        { status: status === 0 ? 1 : 0, linkId },
        {
          onSuccess: () => {
            hideModal();
            scrollToSection('main-section', undefined, 0);
            queryClient.invalidateQueries([endPointUrls.LINK('list')]);
          },
        }
      );
    }
  };

  return { hideModal, isDeleted, modal, showModalHandler, openDropDown, onOpenChange, onChangeStatusHandler, isLoadingUpdateLink: isLoadingUpdateLink || isLoadingDeleteLink };
};

export default useLinkAction;
