import NavigationBlock from 'components/BlocksComponents/NavigationBlock/NavigationBlock';
import BlockLayoutCreate from 'components/_Core/Layouts/Panel/PanelHomeBlockLayout/Create/PanelHomeBlockLayoutCreate';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';

const NavigationBlockPage = () => {
  return (
    <BlockLayoutCreate>
      <HeadMeta tabTitle='آدرس‌های من' />
      <NavigationBlock />
    </BlockLayoutCreate>
  );
};

export default NavigationBlockPage;
