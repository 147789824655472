import { FC, memo, useLayoutEffect, useRef, useState } from 'react';
import { TextAreaProps } from 'antd/lib/input';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import ZpTextArea from 'components/_Core/UI/TextArea/ZpTextArea';

interface IZpTextAreaLabel extends TextAreaProps {
  label: string;
  ltr?: boolean;
  className?: string;
  required?: boolean;
  value?: any;
  labelBackground?: string;
}

const ZpTextAreaLabel: FC<IZpTextAreaLabel> = ({ ltr = false, labelBackground, label, className, required, ...props }) => {
  const { lang } = useSelector((store: RootState) => store.settingStore);
  const labelRef = useRef<HTMLLabelElement | null>(null);
  const [value, setValue] = useState<any>('');

  useLayoutEffect(() => {
    if (props.value || value) labelRef.current?.classList?.add('active-float-label');
    else labelRef.current?.classList?.remove('active-float-label');
  }, [props.value, value]);

  return (
    <div className='float-group'>
      <ZpTextArea setValue={setValue} className={`px-3 py-4 ${className}`} {...props} />
      <label ref={labelRef} className={`float-label ${labelBackground} ${lang === 'fa' ? (ltr ? 'left-3' : 'right-3') : 'left-3'}`}>
        {required && <span className='text-error-color-300 inline-block relative top-1 mx-1'>*</span>}
        {label}
      </label>
    </div>
  );
};

export default memo(ZpTextAreaLabel);
