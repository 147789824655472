import PlanTitle from 'components/Plans/components/PlanList/components/PlanTitle';
import PlanItem from 'components/Plans/components/PlanList/components/PlanItem';
import { FC } from 'react';
import usePlanList from 'components/Plans/components/PlanList/hooks/usePlanList';
import ActivatePlanTrialDrawer from 'components/Plans/components/ActivatePlanTrialDrawer/ActivatePlanTrialDrawer';
import PlanItemSkeleton from 'components/Plans/components/PlanList/components/PlanItemSkeleton';

interface IPlansList {
  isModal?: boolean;
}

const PlansList: FC<IPlansList> = ({ isModal = false }) => {
  const { planList, isLoadingPlanList, hideModalActivateTrial, modalActivateTrial, isLoadingPlanPay, onPlanPayHandler, trialDays } = usePlanList();

  if (isLoadingPlanList) return <PlanItemSkeleton />;

  return (
    <>
      <ActivatePlanTrialDrawer hideDrawer={hideModalActivateTrial} open={modalActivateTrial} />

      <div className='space-y-5 pb-10'>
        <PlanTitle isModal={isModal} />

        <div className='space-y-12'>
          {planList?.data?.map((plan) => (
            <PlanItem key={plan.id} {...plan} trialDays={trialDays} code={plan.code} plan_id={plan.id} onPlanPayHandler={onPlanPayHandler} isLoadingPlanPay={isLoadingPlanPay} />
          ))}
        </div>
      </div>
    </>
  );
};

export default PlansList;
