import GatewayLayout from 'components/_Core/Layouts/Panel/PanelHomeGatewayLout/PanelHomeGatewayLayout';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';
import useRoute from 'core/hooks/useRoute';
import GatewayUpdate from 'components/Gateway/Update/GatewayUpdate';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

const GatewayUpdatePage = () => {
  const { routes } = useRoute();
  const { gatewayDetail } = useSelector((store: RootState) => store.gatewayStore);
  const headerTitle = `ویرایش درگاه پرداخت ${gatewayDetail?.data?.title || '--'}`;

  return (
    <GatewayLayout showPanelDashboardFooterNavbar={false} headerTitle={headerTitle} backUrl={routes.PANEL_LINK_GATEWAY.link()}>
      <HeadMeta tabTitle={headerTitle} />
      <GatewayUpdate />
    </GatewayLayout>
  );
};

export default GatewayUpdatePage;
