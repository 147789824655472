import ZpButton from 'components/_Core/UI/Button/ZpButton';
import MainFooterLayout from 'components/_Core/Layouts/Main/MainFooter/MainFooterLayout';
import useBlockDraggableLayoutFooter from 'components/_Core/Layouts/Panel/PanelHomeBlockDraggableLayout/PanelHomeBlockDraggableLayoutFooter/hooks/useBlockDraggableLayoutFooter';

const PanelHomeBlockDraggableLayoutFooter = () => {
  const { linkDragAndDropPayload, reset, isLoadingSorting, onSortHandler } = useBlockDraggableLayoutFooter();

  return (
    <MainFooterLayout className='flex items-center justify-between'>
      <ZpButton type='primary' ghost onClick={reset}>
        انصراف
      </ZpButton>

      <ZpButton isLoading={isLoadingSorting} disabled={linkDragAndDropPayload.length === 0} onClick={onSortHandler} type='primary'>
        ثبت تغییرات
      </ZpButton>
    </MainFooterLayout>
  );
};

export default PanelHomeBlockDraggableLayoutFooter;
