import ZpSkeleton from 'components/_Core/UI/Skeleton/ZpSkeleton';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';

const UpdateLinkSkeleton = () => {
  return (
    <div className='flex flex-col justify-between h-full'>
      <ZpWrapper singleColumn>
        <div className='text-center space-y-5'>
          <div>
            <ZpSkeleton width={150} height={150} circle />
          </div>
          <div>
            <ZpSkeleton width={150} height={30} />
          </div>
        </div>

        <div className='text-center space-y-5'>
          <div>
            <ZpSkeleton height={50} />
          </div>
          <div>
            <ZpSkeleton height={50} />
          </div>
        </div>

        <div className='grid grid-cols-2 gap-5'>
          <div>
            <ZpSkeleton height={50} />
          </div>
          <div>
            <ZpSkeleton height={50} />
          </div>
          <div>
            <ZpSkeleton height={50} />
          </div>
          <div>
            <ZpSkeleton height={50} />
          </div>
          <div>
            <ZpSkeleton height={50} />
          </div>
        </div>
      </ZpWrapper>
    </div>
  );
};

export default UpdateLinkSkeleton;
