import ZpBlockCreate from 'components/_Core/UI/BlockCreate/ZpBlockCreate';
import useGatewayBlock from './hooks/useGatewayBlock';
import { HiOutlineUserGroup } from 'react-icons/hi';
import GatewayLinkBlockList from 'components/BlocksComponents/GatewayBlock/components/List/GatewayLinkBlockList';
import BlockListSkeleton from 'components/_Core/UI/BlockListSkeleton/BlockListSkeleton';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import { FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import useRoute from 'core/hooks/useRoute';
import { getStatus } from 'core/utils/utils';
import Reload from 'components/_Core/UI/Reload';

const GatewayBlock = () => {
  const navigate = useNavigate();
  const { routes } = useRoute();

  const {
    hideMasterModal,
    masterModal,
    showMasterModal,
    isLoadingLinkDetail,
    GatewayLinkList,
    isLoadingProfileGatewayList,
    profileGatewayList,
    hasBlock,
    onReloadHandler,
    gateway,
    onFinishCreateGatewayLinkHandler,
    isLoadingCreateGatewayLinkBlock,
  } = useGatewayBlock();

  if (isLoadingLinkDetail || isLoadingProfileGatewayList || isLoadingCreateGatewayLinkBlock) return <BlockListSkeleton />;

  const createGatewayLinkContent =
    profileGatewayList === undefined ? (
      <>
        <p className='text-color-placeholder text-sm'>در حال حاضر لینک پرداخت فعالی وجود ندارد!</p>
        <ZpButton className='w-full' type='primary' buttonSize='xs' onClick={() => navigate(routes.GATEWAY_CREATE_PROFESSIONAL_LINK_STEP_1.link())}>
          <span className='flex items-center justify-center space-x-3 space-x-reverse'>
            <span>ساخت لینک پرداخت</span>
            <FiArrowLeft size={22} />
          </span>
        </ZpButton>
      </>
    ) : profileGatewayList.data[0]?.status === 'ACTIVE' ? undefined : (
      <>
        <p className='text-color-placeholder'>
          وضعیت درگاه پرداخت : <span style={{ color: getStatus(profileGatewayList.data[0]?.status).color }}>{getStatus(profileGatewayList.data[0]?.status).message}</span>
        </p>
        <Reload onReloadHandler={onReloadHandler} isLoading={isLoadingProfileGatewayList} />
      </>
    );

  return (
    <>
      <ZpBlockCreate
        hasBlock={hasBlock}
        gateway={gateway}
        isPaymentBlock={true}
        masterModal={masterModal}
        hideMasterModal={hideMasterModal}
        showMasterModal={showMasterModal}
        blockTypeIcon={<HiOutlineUserGroup size={26} />}
        blockTypeTitle='لینک‌های پرداخت'
        blockTypeDescription='امکان پرداخت وجه را برای کاربرانتان فراهم کنید.'
        title='لینک پرداخت'
        customContent={createGatewayLinkContent}
        onFinishMasterHandler={onFinishCreateGatewayLinkHandler}
      >
        <></>
      </ZpBlockCreate>

      <GatewayLinkBlockList blocks={GatewayLinkList} />
    </>
  );
};

export default GatewayBlock;
