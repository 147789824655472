import { RootState } from 'core/store';
import { useSelector } from 'react-redux';

const useDashboard = () => {
  // store
  const { linkDetail } = useSelector((store: RootState) => store.linkStore);

  return {
    linkDetail: linkDetail?.data,
  };
};

export default useDashboard;
