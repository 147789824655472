import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import toastHandler from 'core/helpers/toast/toast';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import useRoute from 'core/hooks/useRoute';
import api from 'core/services/fetch-api/useApi';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { useDispatch } from 'react-redux';

export const useUpdateConnectionQuery = () => {
  const dispatch = useDispatch();
  const { linkId, username } = useRoute();
  const queryClient = useQueryClient();

  return useMutationHook([endPointUrls.CONNECTION(linkId + 'update')], (data: any) => api.put(endPointUrls.CONNECTION(linkId, data?.id), data), {
    onSuccess: (res: any) => {
      if (res.status === 200) {
        toastHandler('success', 'با موفقیت آپدیت شد');
        dispatch(setApiCatcherFormErrors([]));
        queryClient.invalidateQueries([endPointUrls.LINK_GET_BY_USERNAME(username + '')]);
      }
    },
  }) as UseMutationResult;
};
