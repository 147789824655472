import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useLinkSortingQuery } from 'components/BlockDraggable/hooks/react-query/useLinkSortingQuery';
import { useNavigate } from 'react-router-dom';
import useRoute from 'core/hooks/useRoute';
import { resetLinkDragAndDropData } from 'core/store/slice/linkDragAndDrop/linkDragAndDropSlice';

const useBlockDraggableLayoutFooter = () => {
  // store
  const { linkDragAndDropPayload } = useSelector((store: RootState) => store.linkDragAndDropStore);

  //query
  const { mutate: sort, isLoading: isLoadingSorting } = useLinkSortingQuery();

  // hooks
  const navigate = useNavigate();
  const { routes } = useRoute();
  const dispatch = useDispatch();

  // utils
  const navigateToHome = () => navigate(routes.PANEL_HOME.link());

  const reset = () => {
    dispatch(resetLinkDragAndDropData());
    navigateToHome();
  };

  const onSortHandler = () => {
    sort(
      { sorting: linkDragAndDropPayload },
      {
        onSuccess: () => reset(),
      }
    );
  };
  return { onSortHandler, isLoadingSorting, linkDragAndDropPayload, reset };
};

export default useBlockDraggableLayoutFooter;
