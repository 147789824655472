import ZpBlockItem from 'components/_Core/UI/BlockList/ZpBlockItem';
import { IBlock } from 'core/types/Models/linkType';
import navigation from 'assets/images/icon/navigation.svg';
import NavigationBlockUpdate from 'components/BlocksComponents/NavigationBlock/components/Update/NavigationBlockUpdate';

const NavigationBlockList = ({ blocks }: { blocks: IBlock[] | undefined }) => {
  return (
    <>
      {blocks?.[0]?.data?.length && blocks?.[0]?.data?.length > 0 ? (
        <div className='grid space-y-5 mt-5'>
          {blocks?.map(
            (block) =>
              block.data?.length > 0 &&
              block.data.map((item) => (
                <ZpBlockItem forceRender={false} customIconUrl={navigation} key={item.id} block={item}>
                  <NavigationBlockUpdate block={item} />
                </ZpBlockItem>
              ))
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default NavigationBlockList;
