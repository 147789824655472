import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';
import GatewayLinkCreateProfessionalStepThree from 'components/Gateway/GatewayLinks/Create/Professional/StepThree/GatewayLinkCreateProfessionalStepThree';
import PanelCreateGatewayLinkLayout from 'components/_Core/Layouts/Panel/PanelCreateGatewayLinkLayout/PanelCreateGatewayLinkLayout';

const GatewayLinkCreateProfessionalStepThreePage = () => {
  return (
    <PanelCreateGatewayLinkLayout>
      <HeadMeta tabTitle='ایجاد لینک پرداخت جدید - مرحله 3' />
      <GatewayLinkCreateProfessionalStepThree />
    </PanelCreateGatewayLinkLayout>
  );
};

export default GatewayLinkCreateProfessionalStepThreePage;
