import ZpDrawer from 'components/_Core/UI/Drawer/ZpDrawer';
import PlansList from 'components/Plans/components/PlanList/PlansList';
import { FC } from 'react';
import { CgCloseO } from 'react-icons/cg';

interface IPlanDrawer {
  open: boolean;
  hideDrawer: () => void;
}

const PlanDrawer: FC<IPlanDrawer> = ({ hideDrawer, open }) => {
  return (
    <ZpDrawer height='100%' placement='bottom' className='bg-white-natural-color' closable={false} open={open} onClose={hideDrawer}>
      <CgCloseO className='absolute right-5 top-5 z-50 cursor-pointer' onClick={hideDrawer} size={25} />
      <PlansList isModal={true} />
    </ZpDrawer>
  );
};

export default PlanDrawer;
