import { useLocation, useNavigate } from 'react-router';
import queryString from 'query-string';
import { FormInstance } from 'antd';
import { convertAllPropertyToEnNumber } from 'core/utils/utils';

interface IQuery {
  [key: string]: any;
}

const useMasterQueryUrlParam = (number: boolean) => {
  const navigate = useNavigate();
  const location = useLocation();

  const query: IQuery = convertAllPropertyToEnNumber(queryString.parse(location.search, { arrayFormat: 'comma' }), number) as object;
  const setUrlParam = (newQuery: any) => navigate(location.pathname + '?' + queryString.stringify(newQuery, { arrayFormat: 'comma' }));

  const resetQuery = (form?: FormInstance, params = {}, filterOptions?: any[]) => {
    navigate(location.pathname + '?' + queryString.stringify({ per_page: 15, page: 1, ...params }));
    form && form.resetFields();

    (filterOptions?.length as number) > 0 &&
      filterOptions?.map((item) => {
        const { formAttribute } = item;
        const [form]: [form: FormInstance] = formAttribute;

        form.resetFields();
      });
  };

  const handlePagination = (page = 1, per_page = 15) => navigate(location.pathname + '?' + queryString.stringify({ ...query, page, per_page }));

  const resetSort = () => {
    const newQuery: any = {};

    for (const key in query) {
      if (Object.hasOwnProperty.call(query, key)) {
        const element = query[key];

        if (key !== 'sort' && key !== 'sort_way') {
          newQuery[key] = element;
        }
      }
    }

    setUrlParam({ ...newQuery, per_page: 15, page: 1 });
  };

  const deleteOneQueryParam = (property: string, params = {}) => {
    delete query[property];

    if (query.filterValue && query.filterValue.includes(property)) {
      const index = query?.filterValue.indexOf(property);
      query.filterValue.splice(index, 2);

      if (query?.filterValue && query?.filterValue?.length === 0) delete query.filterValue;
    }

    setUrlParam({ ...query, ...params });
  };

  const deleteSort = () => {
    delete query?.sort;
    delete query?.sort_way;

    setUrlParam({ ...query, per_page: 15, page: 1 });
  };

  const filterResult = (title: string) => {
    if (!query.filterValue) return;

    let result = null;

    for (let index = 0; index < query.filterValue.length; index++) {
      const element = query.filterValue[index];
      if (element === title) {
        result = query.filterValue[index + 1];
        break;
      }
    }

    return result;
  };

  const filterValuesUpdate = (values: any) => {
    const newValue = { ...values };

    const items = Object.keys(values);

    const array = [...(query.filterValue ? [...query.filterValue] : [])];
    for (const item of items) {
      newValue[item] = values[item]?.value ? values[item]?.value : values[item];

      if (values[item]?.filter) {
        if (array.includes(item)) {
          array.splice(array.indexOf(item), array.indexOf(item) + 2);
        }
        newValue.filterValue = values[item]?.filter ? [...array, `${item}`, values[item]?.filter] : values[item];
      }
    }

    return newValue;
  };

  return {
    query,
    filterQuery: queryString.stringify(query, { arrayFormat: 'comma' }),
    setUrlParam,
    resetQuery,
    resetSort,
    deleteSort,
    filterResult,
    filterValuesUpdate,
    deleteOneQueryParam,
    page: query.page || 1,
    per_page: query.per_page || 15,
    handlePagination,
    search: location.search,
    pathname: location.pathname,
    pathnameArray: location.pathname.split('/'),
    pathnameLastPart: location.pathname.split('/').at(-1),
  };
};

export default useMasterQueryUrlParam;
