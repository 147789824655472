import { Calendar, CalendarProps } from 'react-multi-date-picker';
import persian from 'react-date-object/calendars/persian';
import persian_fa from 'react-date-object/locales/persian_fa';
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

type IZpCalendar = CalendarProps;

const ZpCalendar: FC<IZpCalendar> = ({ children = <></>, className = '', ...props }) => {
  const { theme } = useSelector((store: RootState) => store.settingStore);

  return (
    <Calendar className={`shadow-lg ${theme === 'dark' ? 'bg-dark' : ''} ${className}`} calendar={persian} locale={persian_fa} {...props}>
      {children}
    </Calendar>
  );
};

export default ZpCalendar;
