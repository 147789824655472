import { UseMutationResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryOnSuccessHandler from 'core/helpers/queryOnSuccessHandler/useQueryOnSuccessHandler';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import { ICheckUsernameMutationResponse } from 'core/types/Models/checkUsernameType';

export const useCheckUsernameQuery = () => {
  const { onSuccessHandler } = useQueryOnSuccessHandler();

  return useMutationHook([endPointUrls.LINK_CHECK_USERNAME('create')], (data: any) => api.get(endPointUrls.LINK_CHECK_USERNAME(data?.username)), {
    onSuccess: (res: any) => {
      if (res.status === 200) {
        onSuccessHandler(res);
      }
    },
  }) as UseMutationResult<ICheckUsernameMutationResponse>;
};
