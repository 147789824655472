import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import IconDark from 'assets/images/icon/payment-dark.svg';
import IconLight from 'assets/images/icon/payment-light.svg';
import IconActive from 'assets/images/icon/payment-active.svg';
import ZpImage from 'components/_Core/UI/Image/ZpImage';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import { isSelectedItemRoute } from 'core/utils/utils';

interface IGatewayIcon {
  width?: string;
  route?: string;
  hasBackground?: boolean;
}

const GatewayIcon: FC<IGatewayIcon> = ({ width = 25, route = '', hasBackground }) => {
  const { theme } = useSelector((store: RootState) => store.settingStore);

  const { pathname } = useQueryUrlParam();
  const active = isSelectedItemRoute(route, pathname);

  return hasBackground ? (
    <div className='p-1 rounded-md flex items-center bg-natural-color-200 dark:bg-natural-color-400'>
      <ZpImage src={active ? IconActive : theme === 'light' ? IconLight : IconDark} width={width} />
    </div>
  ) : (
    <ZpImage src={active ? IconActive : theme === 'light' ? IconLight : IconDark} width={width} />
  );
};

export default GatewayIcon;
