import useDashboard from './hooks/useDashboard';
import DashboardBlocksPreview from './components/DashboardBlocksPreview';
import EmptyDashboard from './components/EmptyDashboard';
import { useLinkDetailQuery } from 'core/hooks/react-query/useLinkDetailQuery';
import { useLinksListQuery } from 'components/DashboardLinkList/components/Links/hooks/react-query/useLinksListQuery';
import LinkPreviewSkeleton from 'components/_Core/UI/LinkPreviewSkeleton/LinkPreviewSkeleton';

const Dashboard = () => {
  const { linkDetail } = useDashboard();
  const { isFetching: isFetchingLinkDetail } = useLinkDetailQuery();
  const { isFetching: isFetchingLinkList } = useLinksListQuery();

  if (isFetchingLinkDetail || isFetchingLinkList) return <LinkPreviewSkeleton />;
  if (!linkDetail) return <></>;

  return (
    <>{linkDetail?.blocks?.length === 0 || linkDetail?.blocks?.every((item) => item.data?.length === 0) ? <EmptyDashboard /> : <DashboardBlocksPreview link={linkDetail} />}</>
  );
};

export default Dashboard;
