import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import GatewayFooter from 'components/_Core/Layouts/Panel/PanelHomeGatewayLout/PanelHomeGatewayFormFooter';
import useRoute from 'core/hooks/useRoute';
import GatewayLinkProfessionalUpdateSkeleton from 'components/Gateway/GatewayLinks/Update/Professional/components/GatewayLinkProfessionalUpdateSkeleton';
import useGatewayLinkSimpleUpdate from 'components/Gateway/GatewayLinks/Update/Simple/hooks/useGatewayLinkSimpleUpdate';
import MainContentLayout from 'components/_Core/Layouts/Main/MainContentLayout/MainContentLayout';

const GatewayLinkSimpleUpdate = () => {
  const { isLoadingUpdateGatewayLink, isLoadingGatewayLinkDetail, onFinishUpdateLinkHandler, updateGatewayLinkForm } = useGatewayLinkSimpleUpdate();

  const { routes } = useRoute();

  if (isLoadingGatewayLinkDetail)
    return (
      <MainContentLayout>
        <GatewayLinkProfessionalUpdateSkeleton />
      </MainContentLayout>
    );

  return (
    <>
      <MainContentLayout>
        <ZpForm onFinish={onFinishUpdateLinkHandler} form={updateGatewayLinkForm}>
          <ZpFormItem name='title' rules={[{ required: true, message: 'نام لینک پرداخت را وارد کنید' }]}>
            <ZpInputLabel required label='نام لینک پرداخت' />
          </ZpFormItem>
        </ZpForm>
      </MainContentLayout>

      <GatewayFooter form={updateGatewayLinkForm} backUrl={routes.PANEL_HOME.link()} isLoading={isLoadingUpdateGatewayLink} />
    </>
  );
};

export default GatewayLinkSimpleUpdate;
