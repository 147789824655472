import { ReactComponent as PlanTransactionEmptySVG } from 'assets/images/plan/plan-transaction-empty.svg';

function PlanTransactionsEmpty() {
  return (
    <div className='space-y-5 text-center text-color-paragraph absolute top-1/2 left-1/2 -translate-x-1/2'>
      <PlanTransactionEmptySVG />

      <p>تراکنشی وجود ندارد.</p>
    </div>
  );
}

export default PlanTransactionsEmpty;
