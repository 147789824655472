import { FC, memo, ReactNode } from 'react';
import { ScaleLoader } from 'react-spinners';
import { twMerge } from 'tailwind-merge';

interface IZpSpinnerFull {
  isLoading: boolean;
  children: ReactNode;
  hasHeight?: boolean;
  isBgOpacity?: boolean;
  className?: string;
}

const ZpSpinnerFull: FC<IZpSpinnerFull> = ({ isLoading = false, children, hasHeight = true, className, isBgOpacity = true }) => {
  return isLoading ? (
    <div className='relative h-full'>
      <div
        className={twMerge(
          `flex items-center z-[999] absolute ${isBgOpacity ? ' bg-[var(--natural-color-opacity-200)]' : ' bg-[var(--natural-color-200)]'} justify-center w-full ${
            hasHeight ? 'h-full' : 'h-auto'
          } ${className}`
        )}
      >
        <ScaleLoader height={26} width={3} color='var(--primary-color-main)' />
      </div>
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

export default memo(ZpSpinnerFull);
