import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryOnSuccessHandler from 'core/helpers/queryOnSuccessHandler/useQueryOnSuccessHandler';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import { IPlanPayMutationResponse } from 'core/types/Models/planType';

export const usePlanPayQuery = (callBack?: () => void) => {
  const { onSuccessHandler } = useQueryOnSuccessHandler();
  const queryClient = useQueryClient();

  return useMutationHook([endPointUrls.PLAN_PAY], (data) => api.post(endPointUrls.PLAN_PAY, data), {
    onSuccess: (res: any) => {
      const response = res as IPlanPayMutationResponse;

      if (response?.data?.data?.id) {
        queryClient.invalidateQueries([endPointUrls.USER_INFO]);
        callBack?.();
      } else {
        window.location.href = response?.data?.data;
      }
      onSuccessHandler(response);
    },
  }) as UseMutationResult<IPlanPayMutationResponse>;
};
