import { IBlockDetail } from 'core/types/Models/linkType';
import { FC, Fragment } from 'react';
import PreviewTemplateDivider from 'components/_Core/UI/PreviewTemplate/Divider/PreviewTemplateDivider';

interface ILinkDetailDeviderBlock {
  blocks: IBlockDetail[];
}

const LinkDetailDeviderBlock: FC<ILinkDetailDeviderBlock> = ({ blocks }) => {
  return (
    <>
      <div className='w-full'>
        {blocks?.map((block) => {
          return (
            <Fragment key={block.id}>
              <PreviewTemplateDivider block={block} />
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

export default LinkDetailDeviderBlock;
