import { FC } from 'react';
import { IGateway } from 'core/types/Models/gatewayType';
import GatewayIcon from 'components/_Core/UI/Icon/GatewayIcon';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import ZarinPalLevel from 'components/_Core/UI/ZarinPalLevel';
import useRoute from 'core/hooks/useRoute';
import { getStatus } from 'core/utils/utils';
import ZpLink from 'components/_Core/UI/Link/ZpLink';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import Reload from 'components/_Core/UI/Reload';
import { endPointUrls } from 'core/constants/endPointUrls';
import { useQueryClient } from '@tanstack/react-query';

const GatewayItem: FC<IGateway> = ({ title, status, id, link, isFetching }) => {
  const { routes, username } = useRoute();
  const queryClient = useQueryClient();

  const onReloadHandler = () => {
    queryClient.invalidateQueries([endPointUrls.LINK_GET_BY_USERNAME(username + '')]);
  };

  return (
    <div className='bg-white-color border border-border-color rounded-lg px-4 py-3 flex items-start space-x-5 space-x-reverse'>
      <GatewayIcon hasBackground />

      <div className='flex-1 pt-1 flex items-start justify-between'>
        <div className='space-y-1'>
          <h1 className='font-bold text-lg'>{title}</h1>

          <p className='text-base text-color-placeholder'>{`${window.location.hostname}/${link.username}`}</p>

          {getStatus(status)?.status === 'ACTIVE' ? (
            <p className='flex items-center text-sm text-success-color-300'>
              <AiOutlineCheckCircle size={16} className='ml-1' color='var(--success-color-300)' />
              <span>فعال</span>
            </p>
          ) : (
            <div className='flex flex-col space-y-1'>
              <span style={{ color: getStatus(status).color }}>{getStatus(status).message}</span>
              <Reload onReloadHandler={onReloadHandler} isLoading={isFetching ? isFetching : false} />
            </div>
          )}

          <ZarinPalLevel />
        </div>

        <ZpLink to={routes.GATEWAY_UPDATE.link(id)}>
          <MdOutlineKeyboardArrowLeft size={25} />
        </ZpLink>
      </div>
    </div>
  );
};

export default GatewayItem;
