import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { BsFillMoonStarsFill, BsFillSunFill } from 'react-icons/bs';
import { setTheme } from 'core/store/slice/setting/settingSlice';

const ThemeWidget = ({ showText = true }) => {
  const { theme } = useSelector((store: RootState) => store.settingStore);
  const dispatch = useDispatch();

  const themeIcon = theme === 'light' ? <BsFillMoonStarsFill size={20} /> : <BsFillSunFill color='var(--warning-color-400)' size={20} />;

  return (
    <span
      className='hover:bg-gray-color-200 rounded-full cursor-pointer flex p-2 space-x-4 space-x-reverse'
      onClick={() => dispatch(setTheme(theme === 'light' ? 'dark' : 'light'))}
    >
      {themeIcon}
      {showText && <span>{theme === 'light' ? 'تم تاریک' : 'تم روشن'}</span>}
    </span>
  );
};

export default ThemeWidget;
