import { AxiosResponse } from 'axios';
import useRoute from 'core/hooks/useRoute';
import { IBlockTypeDetail } from 'core/types/Models/blockType';
import { IBlock, IBlockDetail } from 'core/types/Models/linkType';
import { endPointUrls } from '../constants/endPointUrls';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'jalali-moment';
import { RootState } from 'core/store';
import { linkDragAndDropType, setLinkDragAndDropMasterDetail, setLinkDragAndDropPayload } from 'core/store/slice/linkDragAndDrop/linkDragAndDropSlice';
import { Dispatch } from '@reduxjs/toolkit';
import { IStatus, IStatusResult } from 'core/types/Models/accountBankType';
import { DateObject } from 'react-multi-date-picker';
import persian from 'react-date-object/calendars/persian';
import persian_fa from 'react-date-object/locales/persian_fa';
import gatewayPendingStatusIcon from 'assets/images/gatewayPendingStatus.svg';
import gatewayRejectedStatusIcon from 'assets/images/gatewayRejectedStatus.svg';
import { IThemeColors, IThemeColorsString } from 'core/types/Models/templateType';

export const convertPersianNumberToEnglish = (s = '', number?: boolean) => {
  const elem = s
    .toString()
    .replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d) + '')
    .replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d) + '');
  if (number) return +elem;
  return elem;
};

export const convertEnglishToPersian = (n: string | number) => {
  const farsiDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];

  return n.toString().replace(/\d/g, (x) => farsiDigits[+x]);
};

// date picker
export const convertDatePickerWithSlash = (dates: any) => {
  if (dates === null) return undefined;
  return typeof dates === 'object' ? convertPersianNumberToEnglish(dates?.format('YYYY/MM/DD')) : dates;
};

export const convertDatePickerWithDash = (dates: any) => {
  if (dates === null) return undefined;
  return typeof dates === 'object' ? convertPersianNumberToEnglish(dates?.format('YYYY-MM-DD')) : dates;
};

export const convertDatePickerRange = (dates: DateObject[] | null, format = 'YYYY-MM-DD', jalali = false) => {
  if (dates === null) return null;
  return dates.map((date) => (jalali ? convertPersianNumberToEnglish(date?.format(format)) : convertJalaliDateToEnglish(convertPersianNumberToEnglish(date?.format(format)) + '')));
};

export const payload = (res: AxiosResponse) => {
  return JSON.parse(res.config.data);
};

// separator

export const separator = (input: string | number, seperater = ',', seperateLength = 3) => {
  if (input === null || input === undefined) return '';
  input = input.toString();
  let result = '';
  let count = 0;
  for (let i = input.length - 1; i > -1; i--) {
    if (count === seperateLength) {
      result = seperater + result;
      count = 0;
    }
    result = input.charAt(i) + result;
    count++;
  }
  return result;
};

// paginate data
export const paginateDataHandler = (data: any[], page = 1, limit = 15) => data.slice(page === 1 ? 0 : (page - 1) * limit, limit === 1 ? 15 : page * limit);

// selected link by route
export const isSelectedItemRoute = (to: string, currentRoute: string, exact = true) => {
  return exact ? to === currentRoute : currentRoute.includes(to);
};

// remove element array
export const removeArrayElement = (array: any[], element: any) => {
  const index = array.indexOf(element);
  if (index > -1) {
    array.splice(index, 1);
  }

  return array;
};

export const removeArrayObject = (array: any[], values: any[], key: string) => {
  for (let i = 0; i < array.length; i++) {
    const obj = array[i];

    if (values.indexOf(obj[key]) !== -1) {
      array.splice(i, 1);
    }
  }

  return array;
};

export const stringToBoolean = (string: string | undefined) => {
  string = string + '';
  switch (string?.toLowerCase()?.trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return undefined;
  }
};

export const convertTimeStampToDate = (timeStamp: number | string, formattedString: '/' | '-') => {
  const date = new Date(+timeStamp * 1000);

  let formatted_string = date.getFullYear() + '/';

  if (date.getMonth() < 9) {
    formatted_string += '0';
  }
  formatted_string += date.getMonth() + 1;
  formatted_string += formattedString;

  if (date.getDate() < 10) {
    formatted_string += '0';
  }

  formatted_string += date.getDate();

  return {
    date: formatted_string,
    time: date.toLocaleTimeString(),
    dateTime: `${formatted_string} - ${date.toLocaleTimeString()}`,
  };
};

export const persianCalendar = { calendar: persian };

export const hiddenText = (value: string) => {
  return { hiddenValue: value.replace(/^(....)(.*)(....)$/, (a, b, c, d) => b + c.replace(/.{4}/g, '*') + d), value };
};

export const extractNumberFromString = (s: string) => {
  const elem = s
    .toString()
    .replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d) + '')
    .replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d) + '')
    .replace(/\D/g, '');
  return elem;
};

export const convertTimeStampToCountDown = (endTimeStamp: number, startTimeStamp?: number, callBack?: (val: any, percentage?: number) => void) => {
  const countDownDate = new Date(endTimeStamp * 1000).getTime();

  const interval = setInterval(function () {
    const now = new Date().getTime();

    const distance = countDownDate - now;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (distance < 0) {
      clearInterval(interval);
      return callBack?.('EXPIRED', 0);
    }

    const range = endTimeStamp * 1000 - (startTimeStamp || 0) * 1000;
    const diff = Math.max(0, endTimeStamp * 1000 - new Date().getTime());
    const percentage = (100 * diff) / range;

    // console.log('distance', distance);

    // callBack?.((days ? days + 'd ' : '') + (hours || days ? hours + 'h ' : '') + (minutes || hours ? minutes + 'm ' : '') + seconds + 's ', percentage);
    callBack?.((days ? days + ' : ' : '') + (hours || days ? hours + ' : ' : '') + (minutes || hours ? minutes + ' : ' : '00 : ') + seconds, percentage);
    // callBack?.(days ? days + ' days' : hours || minutes ? ' 1 day' : '', percentage);
  }, 1000);
};

export const convertSvgToUrl = (svg: string) => {
  const blob = new Blob([svg || ''], { type: 'image/svg+xml' });
  return URL.createObjectURL(blob);
};

const toPlainString = (num: string | number) => {
  return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, function (a, b, c, d, e) {
    return e < 0 ? b + '0.' + Array(1 - e - c.length).join(0 + '') + c + d : b + c + d + Array(e - d.length + 1).join(0 + '');
  });
};

export const mainFormatNumber = (num: string | number, { point = 0, type = '' } = {}) => {
  if (!num) {
    return 0;
  }
  // const float = parseFloat(num)

  const number = toPlainString(num);
  const numParts = number.split('.');

  const firstPart = numParts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (type === 'irt') return firstPart;

  let secondPart = null;
  if (numParts.length > 1) {
    secondPart = numParts[1].toString().substring(0, point ? point : type === 'usdt' ? 2 : 6);
  }

  let formattedNumber = firstPart;
  if (secondPart) formattedNumber = `${firstPart}.${secondPart}`;
  if (!secondPart && point) formattedNumber += '.00';
  if (point && secondPart) {
    for (let i = 0; i < point - secondPart.length; i++) {
      formattedNumber += '0';
    }
  }

  if (!point && secondPart) {
    for (let i = formattedNumber.length - 1; i > 0; i--) {
      if (formattedNumber[i] === '0') {
        formattedNumber = formattedNumber.substring(0, i);
      } else if (formattedNumber[i] === '.') {
        formattedNumber = formattedNumber.substring(0, i);
        break;
      } else {
        break;
      }
    }
  }

  return formattedNumber;
};

export const convertAllPropertyToEnNumber = (values: any, number = true) => {
  const newValues: any = {};
  for (const key in values) {
    if (Object.hasOwnProperty.call(values, key)) {
      const element = values[key];

      if (element && !isNaN(element)) {
        newValues[key] = convertPersianNumberToEnglish(element, number);
      } else if (Array.isArray(element)) {
        newValues[key] = element.map((item) => {
          convertPersianNumberToEnglish(item);
          if (item && !isNaN(item)) return convertPersianNumberToEnglish(item, number);
          else return convertPersianNumberToEnglish(item);
        });
      } else {
        newValues[key] = convertPersianNumberToEnglish(element);
      }
    }
  }

  return newValues;
};

// sum object values
export const sumObjectValues = (obj: any) => {
  return Object.keys(obj).reduce((sum, key) => sum + parseFloat(obj[key] || 0), 0);
};

// get block name
export const getBlockTypeDetail = (block: IBlock): IBlockTypeDetail => {
  const { routes, linkId } = useRoute();

  switch (block.type) {
    case 'App\\Models\\LinkApp\\ExternalLink':
      return {
        title: 'لینک‌ها',
        route: routes.PANEL_CREATE_EXTERNAL_LINK.link(),
        name: 'externalLink',
        sorting_endPoint: endPointUrls.EXTERNAL_LINK_SORTING(linkId),
      };

    case 'App\\Models\\LinkApp\\Messenger':
      return {
        title: 'پیام رسان ها',
        route: routes.PANEL_CREATE_BLOCK_MESSENGER.link(),
        name: 'messenger',
        sorting_endPoint: endPointUrls.MESSENGER_SORTING(linkId),
      };

    case 'App\\Models\\LinkApp\\Navigation':
      return {
        title: 'آدرس‌های من',
        route: routes.PANEL_CREATE_BLOCK_NAVIGATION.link(),
        name: 'navigation',
        sorting_endPoint: endPointUrls.NAVIGATION_SORTING(linkId),
      };

    case 'App\\Models\\LinkApp\\SocialMedia':
      return {
        title: 'شبکه‌های اجتماعی',
        route: routes.PANEL_CREATE_BLOCK_SOCIALMEDIA.link(),
        name: 'socialMedia',
        sorting_endPoint: endPointUrls.SOCIAL_MEDIA_SORTING(linkId),
      };

    case 'App\\Models\\LinkApp\\FAQ':
      return {
        title: 'سوالات متداول',
        route: routes.PANEL_CREATE_BLOCK_FAQ.link(),
        name: 'faq',
        sorting_endPoint: endPointUrls.FAQ_SORTING(linkId),
      };

    case 'App\\Models\\LinkApp\\Connection':
      return {
        title: 'راه‌های ارتباطی',
        route: routes.PANEL_CREATE_BLOCK_CONNECTION.link(),
        name: 'connection',
        sorting_endPoint: endPointUrls.CONNECTION_SORTING(linkId),
      };

    case 'App\\Models\\LinkApp\\Divider':
      return {
        title: 'جداکننده‌ها',
        route: routes.PANEL_CREATE_BLOCK_DIVIDER.link(),
        name: 'divider',
        sorting_endPoint: '',
      };

    case 'App\\Models\\LinkApp\\PaymentLink':
      return {
        title: 'لینک‌های پرداخت',
        route: routes.PANEL_CREATE_BLOCK_GATEWAY.link(),
        name: 'paymentLink',
        sorting_endPoint: '',
      };
    default:
      return { title: '', route: '#', name: '', sorting_endPoint: '' };
  }
};

export const getBlockLink = (block: IBlockDetail): { blockLink: string } => {
  const isPhone = block?.type?.en_name.toLowerCase().includes('mobile') || block?.type?.en_name.toLowerCase().includes('phone');
  const isSms = block?.type?.en_name.toLowerCase().includes('sms');
  const isEmail = block?.type?.en_name.toLowerCase().includes('email');

  const blockLink = isPhone ? 'tel:' : isSms ? 'sms:' : isEmail ? 'mailto:' : '';

  return { blockLink };
};

export const scrollToSection = (id?: string, queryParam?: string, y?: number) => {
  const element = id && document.getElementById(id);

  if ((y === 0 || y) && element) return element.scrollTo({ behavior: 'smooth', top: y });

  if (y === 0 || y) return window.scrollBy({ behavior: 'smooth', top: y });

  if (queryParam && Object.keys(queryParam).length !== 0 && Object.keys(queryParam).length > 0) {
    element && element?.scrollIntoView({ behavior: 'smooth' });
  } else {
    element && element?.scrollIntoView({ behavior: 'smooth' });
  }
};

// check two array of object same
export const checkObjectsAreSame = (arr1: any[], arr2: any[]) => {
  let objectsAreSame = true;
  for (const propertyName in arr1) {
    if (JSON.stringify(arr1[propertyName]) !== JSON.stringify(arr2[propertyName])) {
      objectsAreSame = false;
      break;
    }
  }
  return objectsAreSame;
};

// regex
export const numberRegex = /^[0-9۰-۹]*$/;
export const enNumberRegex = /^[0-9]*$/;
export const shaparakTitleRegex = /^[۰-۹0-9\u0600-\u06FF\s]+$/;
export const numberRegexWithCommaFormat = /^[1-9۱-۹][0-9۰-۹,]*$/;
export const numberRegexWithSpaceFormat = /^[0-9۰-۹ ]*$/;
export const mobileNumberRegex = /^(09|۰۹)[0-9۰-۹]{9}$/;
export const phoneNumberRegex = /^(0|۰)[0-9۰-۹]{9}$/;

// get css variable
export const getCssVariable = (variable: string) => {
  const { theme } = useSelector((store: RootState) => store.settingStore);
  const rootEl = document.querySelector(':root');
  const [color, setColor] = useState('');

  useEffect(() => {
    rootEl && setColor(getComputedStyle(rootEl).getPropertyValue(variable));
  }, [rootEl, theme]);

  return color;
};

// drag and drop
const getMasterBlockSort = (linkDragAndDropPayload: linkDragAndDropType[] | [], masterBlocksModify: IBlock[]) => {
  console.log(linkDragAndDropPayload, 9999);
  const getBlockModifyChildren = linkDragAndDropPayload.map((block) => ({
    id: block.value,
    children: block.children === undefined || block.children.length === 0 ? [] : block.children.map((item) => ({ value: item.value, order: item.order })),
  }));

  const blockSort = masterBlocksModify.map((block, index) => {
    return {
      value: block.id,
      order: index + 1,
      children: getBlockModifyChildren.filter((item) => item.id === block.id)[0]?.children,
    };
  });

  return blockSort;
};

export const dragAndDropHandler = (
  linkDragAndDropMasterDetail: IBlock[] | [],
  linkDragAndDropPayload: linkDragAndDropType[] | [],
  dispatch: Dispatch,
  masterBlocksModify?: IBlock[],
  childBlocksModify?: IBlockDetail[],
  masterBlocks?: IBlock[],
  masterId?: number
) => {
  masterBlocksModify && dispatch(setLinkDragAndDropMasterDetail(masterBlocksModify));

  if (masterBlocksModify) {
    let blockSort;

    if (linkDragAndDropPayload.length === 0) {
      const hasBlockData = masterBlocksModify.filter((block) => block.data.length > 0);
      const getBlockModifyChildren = hasBlockData.map((block) => ({
        id: block.id,
        children:
          block.type === 'App\\Models\\LinkApp\\Divider' || block.type === 'App\\Models\\LinkApp\\PaymentLink'
            ? []
            : block.data.map((item) => ({ value: item.id, order: item.order })),
      }));

      blockSort = hasBlockData.map((block, index) => ({
        value: block.id,
        order: index + 1,
        children: getBlockModifyChildren.filter((item) => item.id === block.id)[0].children,
      }));
    } else {
      const hasBlockData = masterBlocksModify.filter((block) => block.data.length > 0);
      blockSort = getMasterBlockSort(linkDragAndDropPayload, hasBlockData);
    }

    dispatch(setLinkDragAndDropPayload(blockSort));
  } else {
    const childBlockSort = childBlocksModify?.map((block, index) => ({ value: block.id, order: index + 1 }));

    if (linkDragAndDropPayload.length === 0) {
      const hasBlockData = masterBlocks?.filter((block) => block.data.length > 0);

      const getBlockModifyChildren = hasBlockData?.map((block) => ({
        id: block.id,
        children:
          block.type === 'App\\Models\\LinkApp\\Divider' || block.type === 'App\\Models\\LinkApp\\PaymentLink'
            ? []
            : block.data.map((item) => ({ value: item.id, order: item.order })),
      }));

      const masterBlockSort = hasBlockData?.map((block, index) => ({
        value: block.id,
        order: index + 1,
        children: getBlockModifyChildren?.filter((item) => item.id === block.id)[0].children,
      }));
      const finalSortBlock = masterBlockSort?.map((block) => (block.value === masterId ? { ...block, children: childBlockSort } : block));

      dispatch(setLinkDragAndDropPayload(finalSortBlock as linkDragAndDropType[]));
      dispatch(setLinkDragAndDropMasterDetail(hasBlockData as IBlock[]));
    } else {
      const masterBlockSort = getMasterBlockSort(linkDragAndDropPayload, linkDragAndDropMasterDetail);
      const finalSortBlock = masterBlockSort.map((block) => (block.value === masterId ? { ...block, children: childBlockSort } : block));

      dispatch(setLinkDragAndDropPayload(finalSortBlock as linkDragAndDropType[]));
    }
  }
};

export const disableBackToUrl = { disableBackToUrl: true };

export const getStatus = (status: IStatus): IStatusResult => {
  switch (status) {
    case 'PENDING':
      return { color: 'var(--warning-color-400)', message: 'در حال بررسی', disabled: true, status: 'PENDING', icon: gatewayPendingStatusIcon };

    case 'ACTIVE':
      return { color: 'var(--success-color-300)', message: 'فعال', disabled: false, status: 'ACTIVE', icon: '' };

    case 'REJECTED_SHAPARAK':
      return { color: 'var(--error-color-300)', message: 'توسط شاپرک تایید نشده', disabled: true, status: 'REJECTED_SHAPARAK', icon: gatewayRejectedStatusIcon };

    case 'REJECTED':
      return { color: 'var(--error-color-300)', message: 'تایید نشده', disabled: true, status: 'REJECTED', icon: gatewayRejectedStatusIcon };

    case 'PENDING_SHAPARAK':
      return { color: 'var(--warning-color-400)', message: 'در حال بررسی توسط شاپرک', disabled: true, status: 'PENDING_SHAPARAK', icon: gatewayPendingStatusIcon };

    case 'INACTIVE':
      return { color: 'var(--gray-color-400)', message: 'غیر فعال', disabled: true, status: 'INACTIVE', icon: gatewayRejectedStatusIcon };

    case 'ZARIN_CARD_PENDING':
      return { color: 'var(--warning-color-400)', message: 'در حال بررسی توسط زرین کارت', disabled: true, status: 'ZARIN_CARD_PENDING', icon: gatewayPendingStatusIcon };
  }
};

// validate 18 years old
type dateValue = DateObject & '';
export const validateAge = (value: dateValue, age = 18) => {
  const persianDate = moment.from(convertPersianNumberToEnglish(new Date().toLocaleDateString('fa-IR')) + '', 'fa', 'jYYYY/jM/jD');
  const { year, day, month } = jalaliDateDetail(typeof value === 'string' ? value : '');

  const currentYear = persianDate.jYear();
  const currentMonth = persianDate.jMonth() + 1;
  const currentDay = persianDate.jDate();

  if (
    currentYear - (year || value?.year) > age ||
    (currentYear - (year || value?.year) >= age && (month || value?.month.number) < currentMonth) ||
    (currentYear - (year || value?.year) >= age && (month || value?.month.number) <= currentMonth && (day || value?.day) < currentDay)
  ) {
    return true;
  }
  return false;
};

// convert jalali date to english
export const convertJalaliDateToEnglish = (date: string, formatJalali = 'YYYY-MM-DD', formatEnglish = 'YYYY-MM-DD') => {
  const formattedDate = convertDatePickerWithDash(date);
  return moment.from(formattedDate, 'fa', formatJalali).format(formatEnglish);
};

// jalali date detail
export const jalaliDateDetail = (date: string) => {
  const detailArr = date.split('-');

  return { year: +detailArr[0], month: +detailArr[1], day: +detailArr[2] };
};

// convert english date to jalali
export const convertEnglishDateToJalali = (date: string, formatJalali = 'YYYY-MM-DD') => {
  return moment(date).locale('fa').format(formatJalali);
};

// get image url
export const getImageUrl = (url: string | undefined | null) => {
  return url ? (process.env.REACT_APP_API_FILE_BASE_URL as string) + url : undefined;
};

// date validate min and max
export const isDateTodayOrGreater = (date: any, splitType: '-' | '/') => {
  if (!date) return;
  const valueDate = convertDatePickerWithDash(date).split(splitType);
  const todayDate = convertDatePickerWithDash(new DateObject({ calendar: persian, locale: persian_fa })).split(splitType);
  let isGreater = true;
  for (let index = 0; index < valueDate.length; index++) {
    if (valueDate[index] > todayDate[index]) break;
    if (valueDate[index] < todayDate[index]) isGreater = false;
  }
  return isGreater;
};

export const isDateTodayOrLess = (date: any, splitType: '-' | '/') => {
  if (!date) return;
  const valueDate = convertDatePickerWithDash(date).split(splitType);
  const todayDate = convertDatePickerWithDash(new DateObject({ calendar: persian, locale: persian_fa })).split(splitType);
  let isLess = true;
  for (let index = 0; index < valueDate.length; index++) {
    if (valueDate[index] < todayDate[index]) break;
    if (valueDate[index] > todayDate[index]) isLess = false;
  }
  return isLess;
};

export const minDateToday = (splitType: '-' | '/') => ({
  validator(_: any, value: any) {
    if (!value || isDateTodayOrGreater(value, splitType)) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error('تاریخ گذشته قابل انتخاب نیست.'));
    }
  },
});

export const maxDateToday = (splitType: '-' | '/') => ({
  validator(_: any, value: any) {
    if (!value || isDateTodayOrLess(value, splitType)) return Promise.resolve();
    else return Promise.reject(new Error('تاریخ آینده قابل انتخاب نیست.'));
  },
});

// create time array
export const timeGenerator = () => {
  const locale = 'en'; // or whatever you want...
  const hours = [];

  moment.locale(locale); // optional - can remove if you are only dealing with one locale

  for (let hour = 0; hour < 24; hour++) {
    hours.push(moment({ hour }).format('HH:mm'));
    hours.push(
      moment({
        hour,
        minute: 30,
      }).format('HH:mm')
    );
  }

  return hours;
};

export const convertArrayToString = (s: [string]): string => {
  return s[0];
};

export const blockBackgroundImageConfig = (image?: string, backgroundSize: 'contain' | string = 'contain') => {
  return {
    object: { backgroundImage: `url(${image})`, backgroundRepeat: 'repeat-y', backgroundPosition: 'center', backgroundSize },
    cssText: `background-image: url(${image});` + 'background-repeat: repeat-y;' + 'background-position: center;' + `background-size: ${backgroundSize};`,
  };
};

// theme color

export const setRootColor = (colors: IThemeColors) => {
  Object.keys(colors).map((color) => document.documentElement.style.setProperty(`--${color}`, colors[color as IThemeColorsString]));
};
