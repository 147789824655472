import { ILinkCategoryList } from 'core/types/Models/linkCategoryType';
import LinkCategoryItem from './LinkCategoryItem';
import { FC } from 'react';

interface ILinkCategoryItems {
  selectedTypeKeep: {
    index: string | number;
    data?: any;
  }[];
  linkCategories?: ILinkCategoryList;
  selectedTypeKeepHandler(val: any): void;
}

const LinkCategoryItems: FC<ILinkCategoryItems> = ({ selectedTypeKeep, selectedTypeKeepHandler, linkCategories }) => {
  return (
    <div className='grid grid-cols-2 gap-5'>
      {linkCategories?.data?.map(
        (category) =>
          category.is_publish && (
            <LinkCategoryItem
              key={category.id}
              title={category.fa_title}
              isSelected={selectedTypeKeep.some((item) => item.index === category.id)}
              onClick={() => selectedTypeKeepHandler(category.id)}
            />
          )
      )}
    </div>
  );
};

export default LinkCategoryItems;
