import { Form } from 'antd';
import ZpButton from 'components/_Core/UI/Button/ZpButton';
import ZpForm from 'components/_Core/UI/Form/ZpForm';
import ZpFormItem from 'components/_Core/UI/FormItem/ZpFormItem';
import ZpInputLabel from 'components/_Core/UI/Input/ZpInputLabel';
import ZpWrapper from 'components/_Core/UI/Wrapper/ZpWrapper';
import { IBlockDetail } from 'core/types/Models/linkType';
import useUpdateExternalLink from '../../hooks/useUpdateExternalLink';
import ZpBlockDelete from 'components/_Core/UI/BlockDelete/ZpBlockDelete';

const ExternalLinkBlockUpdate = ({ block }: { block: IBlockDetail | undefined }) => {
  const [formUpdate] = Form.useForm();
  const { onFinishUpdateHandler, hideModal, modal, showModal, isLoadingUpdateExternalLink, isLoadingDeleteExternalLink, onDeleteHandler } = useUpdateExternalLink(
    formUpdate,
    block
  );

  return (
    <>
      <ZpBlockDelete modal={modal} hideModal={hideModal} isLoadingDelete={isLoadingDeleteExternalLink} onDeleteHandler={onDeleteHandler} blockId={block?.id} />

      <ZpForm form={formUpdate} onFinish={onFinishUpdateHandler}>
        <ZpWrapper singleColumn>
          <ZpFormItem rules={[{ required: true, message: 'عنوان لینک را وارد کنید' }]} name='title'>
            <ZpInputLabel required label='عنوان لینک' />
          </ZpFormItem>

          <ZpFormItem
            rules={[
              { required: true, message: 'آدرس لینک را وارد کنید' },
              { type: 'url', message: 'فرمت آدرس لینک اشتباه است' },
            ]}
            name='url'
          >
            <ZpInputLabel required textLeft label='آدرس لینک' />
          </ZpFormItem>

          <ZpFormItem button>
            <div className='flex items-center space-x-reverse space-x-5 justify-between'>
              <ZpButton buttonSize='xs' className='w-full' htmlType='button' danger ghost type='primary' isLoading={isLoadingDeleteExternalLink} onClick={showModal}>
                حذف
              </ZpButton>
              <ZpButton buttonSize='xs' className='w-full' htmlType='submit' type='primary' isLoading={isLoadingUpdateExternalLink}>
                ثبت تغییرات
              </ZpButton>
            </div>
          </ZpFormItem>
        </ZpWrapper>
      </ZpForm>
    </>
  );
};

export default ExternalLinkBlockUpdate;
