import CreateLinkStepThree from 'components/CreateLink/StepThree/CreateLinkStepThree';
import PanelCreateLinkLayout from 'components/_Core/Layouts/Panel/PanelCreateLinkLayout/PanelCreateLinkLayout';
import { HeadMeta } from 'components/_Core/UI/HeadMeta/HeadMeta';

const CreateLinkStepThreePage = () => {
  return (
    <PanelCreateLinkLayout>
      <HeadMeta tabTitle='ایجاد لینک - مرحله 3' />
      <CreateLinkStepThree />
    </PanelCreateLinkLayout>
  );
};

export default CreateLinkStepThreePage;
