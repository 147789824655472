import { IBlock, IBlockDetail } from 'core/types/Models/linkType';
import { useState } from 'react';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { arrayMove } from '@dnd-kit/sortable';
import { IBlockTypeDetail } from 'core/types/Models/blockType';
import { dragAndDropHandler } from 'core/utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';

const useBlockDraggableChild = (childBlocks: IBlockDetail[], blockType: IBlockTypeDetail, masterBlocks: IBlock[], masterId: number) => {
  // state
  const [childBlocksState, setChildBlocksState] = useState(childBlocks);

  // store
  const { linkDragAndDropPayload, linkDragAndDropMasterDetail } = useSelector((store: RootState) => store.linkDragAndDropStore);

  // hooks
  const dispatch = useDispatch();

  const handleDrag = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      setChildBlocksState((prevState) => {
        const activeIndex = prevState.map((item) => item.id).indexOf(+active.id);
        const overIndex = prevState.map((item) => item.id).indexOf(over!.id as number);

        const blocksModify = arrayMove(prevState, activeIndex, overIndex);

        dragAndDropHandler(linkDragAndDropMasterDetail, linkDragAndDropPayload, dispatch, undefined, blocksModify, masterBlocks, masterId);

        return blocksModify;
      });
    }
  };

  return { handleDrag, childBlocksState };
};

export default useBlockDraggableChild;
