import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import api from 'core/services/fetch-api/useApi';
import useQueryUrlParam from '../useQueryUrlParam';
import { ILinkDetail } from 'core/types/Models/linkType';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLinkDetail } from 'core/store/slice/link/linkSlice';
import useQueryOnErrorHandler from 'core/helpers/queryOnErrorHandler/useQueryOnErrorHandler';
import useRoute from '../useRoute';
import { RootState } from 'core/store';

export const useLinkDetailQuery = () => {
  // query
  const { query, pathname } = useQueryUrlParam();
  const dispatch = useDispatch();
  const { onErrorHandler } = useQueryOnErrorHandler();
  const navigate = useNavigate();
  const { routes, username } = useRoute();

  // store
  const { hasActiveGateway, hasGateway, linkDetail } = useSelector((store: RootState) => store.linkStore);

  return useQueryHook([endPointUrls.LINK_GET_BY_USERNAME(username || query?.username)], () => api.get(endPointUrls.LINK_GET_BY_USERNAME(username || query?.username)), {
    select: (res: any) => res.data,
    // staleTime: 30 * 60 * 1000, // minutes * seconds * 1000 = milliseconds
    // cacheTime: 30 * 60 * 1000,
    refetchInterval: pathname === routes.GATEWAY_DETAIL.link() && hasGateway && !hasActiveGateway && 60000,
    enabled: (!!query?.username || !!username) && !(pathname === routes.PANEL_TEMPLATE.link() && !!linkDetail),
    onSuccess(res) {
      const response = res as ILinkDetail;

      dispatch(setLinkDetail(response));
    },
    onError: async (err: any) => {
      onErrorHandler(err, true);

      if (err.response.status === 404) {
        navigate(routes.NOTFOUND);
      }
    },
  }) as UseQueryResult<ILinkDetail, Error>;
};
