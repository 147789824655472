import { FC, memo, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import { PatternFormatProps } from 'react-number-format';
import ZpInputPatternNumber from 'components/_Core/UI/Input/ZpInputPatternNumber';

export interface IZpInputPatternNumberLabel extends PatternFormatProps {
  label: string;
  labelBackground?: string;
  ltr?: boolean;
  className?: string;
  suffixLabel?: string | ReactNode;
  required?: boolean;
  value?: any;
  noLeftRound?: boolean;
  noRightRound?: boolean;
  textLeftFull?: boolean;
  textLeft?: boolean;
}

const ZpInputPatternNumberLabel: FC<IZpInputPatternNumberLabel> = ({
  labelBackground,
  ltr = false,
  textLeftFull = false,
  textLeft = false,
  label,
  className,
  required,
  noLeftRound = false,
  noRightRound = false,
  suffixLabel,
  ...props
}) => {
  const { lang } = useSelector((store: RootState) => store.settingStore);
  const labelRef = useRef<HTMLLabelElement>(null);
  const [value, setValue] = useState<any>('');

  useLayoutEffect(() => {
    if (props.value || value) labelRef.current?.classList?.add('active-float-label');
    else labelRef.current?.classList?.remove('active-float-label');
  }, [props.value, value]);

  return (
    <div className='float-group text-left relative'>
      <ZpInputPatternNumber
        value={value}
        autoComplete='off'
        setValue={setValue}
        className={twMerge(
          `h-[50px] text-base ${props['aria-invalid'] ? 'input-error' : ''} ${noLeftRound ? 'rounded-tl-none rounded-bl-none' : ''} ${
            textLeftFull ? 'text-left-full ltr-full' : ''
          } ${textLeft ? 'text-left ltr' : ''} ${noRightRound ? 'rounded-tr-none rounded-br-none' : ''} ${className}`
        )}
        {...props}
      />
      <label ref={labelRef} className={twMerge(`float-label ${labelBackground} ${lang === 'fa' ? (ltr ? 'left-3' : 'right-3') : 'left-3'}`)}>
        {required && <span className='text-error-color-300 inline-block relative top-1 mx-1'>*</span>}
        {label}
      </label>

      <div className='absolute top-1/2 -translate-y-1/2 left-5'>{suffixLabel}</div>
    </div>
  );
};

ZpInputPatternNumberLabel.displayName = 'ZpInputPatternNumberLabel';

export default memo(ZpInputPatternNumberLabel);
