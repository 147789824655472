import useLogout from 'core/helpers/logout/useLogout';
import useModal from 'core/hooks/useModal';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenBlockListHandler, setOpenMenuHandler } from 'core/store/slice/setting/settingSlice';
import useRoute from 'core/hooks/useRoute';
import { RootState } from 'core/store';

const usePanelHomeFooterNavbar = () => {
  // hooks
  const { logout } = useLogout();
  const { routes } = useRoute();
  const { showModal: showModalPlan, modal: modalPlan, hideModal: hideModalPlan } = useModal();
  const dispatch = useDispatch();

  // store
  const { linkDetail, hasGateway, hasGatewayLinks } = useSelector((store: RootState) => store.linkStore);
  const { hasPlan, trialDays } = useSelector((store: RootState) => store.userPlanStore);

  // utils
  const showModalBlockList = () => dispatch(setOpenBlockListHandler(true));
  const gatewayRoute = !hasGateway || !hasGatewayLinks ? routes.GATEWAY_CREATE_EMPTY.link : routes.GATEWAY_DETAIL.link(linkDetail?.data?.gateways?.[0]?.id);
  const dragAndDropRoute = linkDetail?.data?.blocks?.some((item) => item.data?.length > 0) ? { to: routes.PANEL_BLOCK_DRAGGABLE.link() } : { onClick: showModalBlockList };
  const blogHandler = { premium: !hasPlan, trialDays, ...(hasPlan ? { to: routes.BLOG_PANEL, external: true } : { onClick: showModalPlan }) };

  const showModalMenu = () => {
    dispatch(setOpenMenuHandler(true));
  };

  return { gatewayRoute, dragAndDropRoute, routes, logout, showModalMenu, showModalBlockList, showModalPlan, modalPlan, hideModalPlan, hasPlan, trialDays, blogHandler };
};

export default usePanelHomeFooterNavbar;
